import React, { useState } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { FoodItem, table } from './auto-gen/table';

type PickedFood = {
  item: FoodItem;
  amt: number;
};

function FoodPrompt({ onSelect }: { onSelect: (food: PickedFood) => void }) {
  const items = table.map((e, i) => ({ id: i, name: e.name, item: e }));
  const [selectedFood, setSelectedFood] = useState<FoodItem|null>(null);
  const [amt, setAmt] = useState<number|null>(null);

  const onClick = (picked: PickedFood) => {
    onSelect(picked);
    setSelectedFood(null);
    setAmt(null);
  };
  return (
    <div className="FoodPrompt">
      <span>Escolha um ingrediente</span>
      <ReactSearchAutocomplete
        items={items}
        autoFocus
        onSelect={i => setSelectedFood(i.item)}
        fuseOptions={{ threshold: 0.5 }}
      />
      {selectedFood !== null
        ? (<div className="FoodPrompt-amount">
            <span>Escolha a quantidade (em gramas)</span>
            <div>
              <input type='number' onChange={v => setAmt(+v.target.value)}/>
              <button onClick={() => {
                if (amt !== null && amt > 0.0) onClick({ item: selectedFood, amt: amt })
              }}>
                Adicionar
              </button>
            </div>
          </div>)
        : null
      }
    </div>
  )
}

function FoodTable({ pickedFoods }: { pickedFoods: PickedFood[] }) {
  type FoodItemNumbers = Omit<FoodItem, 'name'>;

  const [totalManufactured, setTotalManufactured] = useState(0);

  const picked = pickedFoods.reduce((acc, i) => {
    (Object.keys(i.item).filter(x => x !== 'name') as (keyof FoodItemNumbers)[]).forEach(k => {

      const base = acc[k] ?? 0.0;

      // t -- totalManufactured
      // x -- 100

      // (100 * t) / totalManufactured

      const nutritional_value_in_recipie = (i.item[k] as number / 100) * i.amt;

      acc[k] = base + (
        totalManufactured > 0
          ? (100 * nutritional_value_in_recipie) / totalManufactured
          : nutritional_value_in_recipie
      );

    }, acc);

    return acc;
  }, {} as Partial<FoodItemNumbers>);

  const totalWeight = pickedFoods.reduce((t, i) => t + i.amt, 0.0);

  const main: (keyof FoodItemNumbers)[] = [
    'energia[kcal]',
    'energia[kj]',
    'lípidos[g]',
    'ácidos gordos saturados[g]',
    'hidratos de carbono [g]',
    'açúcares [g]',
    'fibra  [g]',
    'proteínas [g]',
    'sal  [g]',
    'álcool [g]',
    'água [g]',
  ];

  function NutrValue({ name }: { name: keyof FoodItemNumbers }) {
    return (
      <tr>
        <td>{name}</td>
        <td>{picked[name]?.toFixed(2)}</td>
      </tr>
    );
  }

  return (
    <div className="FoodTable">
      <div>
        <span>Total confecionado (em gramas):</span>
        <input
          type='number'
          onChange={e => setTotalManufactured(+e.target.value)}
        />
      </div>
      <h3>
        {totalManufactured <= 0
          ? "Informações nutricionais para todo o produto"
          : "Por 100g de produto"
        }
      </h3>
      {totalManufactured > totalWeight
        ? <h3 style={{color: 'red'}}>O total confecionado é mais alto que o peso dos ingredientes 🤔</h3>
        : null
      }
      <table>
        <tbody>
          {main.map((k, i) => <NutrValue key={i} name={k} />)}
          <tr><td><hr /></td></tr>
          {(Object.keys(picked) as (keyof FoodItemNumbers)[])
            .filter(k => !main.some(x => x === k))
            .map((k, i) => (<NutrValue key={i} name={k}/>))
          }
        </tbody>
      </table>
    </div>
  );
}

type SelectedFoodsProps = {
  foods: PickedFood[];
  setNewAmount: (i: number, amt: number) => void;
  delItem: (i: number) => void;
};

function SelectedFoods({ foods, delItem, setNewAmount }: SelectedFoodsProps) {
  if (foods.length === 0) return <></>;

  type SelectedFoodProps = {
    food: PickedFood;
    setAmount: (amt: number) => void;
    onDelete: () => void;
  };

  function SelectedFood({ food, onDelete, setAmount }: SelectedFoodProps) {
    const [newAmount, setNewAmount] = useState<number|null>(null);

    const toggleEditing = () => {
      if (newAmount !== null) {
        setAmount(newAmount);
        setNewAmount(null);
      } else {
        setNewAmount(food.amt);
      }
    };
    return (
      <tr>
        <td>{food.item.name}</td>
        {newAmount === null
          ? <td>{food.amt}</td>
          : (<td>
            <input
              type='number'
              onChange={e => setNewAmount(+e.target.value)}
            />
          </td>)
        }
        <td>
          <button onClick={toggleEditing}>✏️</button>
          <button onClick={onDelete}>❌</button>
        </td>
      </tr>
    );
  }

  const totalWeight = foods.reduce((t, i) => t + i.amt, 0.0);

  return (
    <table className="SelectedFoods">
      <thead>
        <tr>
          <th>Ingrediente</th>
          <th>Quantidade (gramas)</th>
        </tr>
      </thead>
      <tbody>
        {foods.map((f, i) => (
          <SelectedFood
            key={i}
            food={f}
            setAmount={amt => setNewAmount(i, amt)}
            onDelete={() => delItem(i)}
          />
        ))}
      </tbody>
      <tfoot>
        <td>Total</td>
        <td>{totalWeight}</td>
      </tfoot>
    </table>
  )
}

export default function Table() {
  const [foods, setFoods] = useState<PickedFood[]>([]);
  return (
    <div className="Table">
      <div className="Table-editor">
        <FoodPrompt onSelect={picked => setFoods([picked, ...foods])} />
        <SelectedFoods
          foods={foods}
          delItem={del => setFoods(foods.filter((_, i) => i !== del)) }
          setNewAmount={(index, amount) => {
            const newFoods = [...foods];
            newFoods[index].amt = amount;
            setFoods(newFoods);
          }}
        />
      </div>
      {foods.length > 0
        ? <FoodTable pickedFoods={foods} />
        : null
      }
    </div>
  );
}
