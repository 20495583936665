export type FoodItem = {
    name: string;
    "energia[kcal]": number;
    "energia[kj]": number;
    "lípidos[g]": number;
    "ácidos gordos saturados[g]": number;
    "ácidos gordos monoinsaturados [g]": number;
    "ácidos gordos polinsaturados [g]": number;
    "ácido linoleico [g]": number;
    "ácidos gordos trans [g]": number;
    "hidratos de carbono [g]": number;
    "açúcares [g]": number;
    "oligossacáridos [g]": number;
    "amido [g]": number;
    "fibra  [g]": number;
    "proteínas [g]": number;
    "sal  [g]": number;
    "álcool [g]": number;
    "água [g]": number;
    "ácidos orgânicos [g]": number;
    "colesterol [mg]": number;
    "vitamina a  [µg]": number;
    "caroteno [µg]": number;
    "vitamina d [µg]": number;
    "alfa-tocoferol [mg]": number;
    "tiamina [mg]": number;
    "riboflavina [mg]": number;
    "niacina [mg]": number;
    "equivalentes de niacina [mg]": number;
    "triptofano/60 [mg]": number;
    "vitamina b6 [mg]": number;
    "vitamina b12 [µg]": number;
    "vitamina c [mg]": number;
    "folatos [µg]": number;
    "cinza [g]": number;
    "sódio [mg]": number;
    "potássio [mg]": number;
    "cálcio [mg]": number;
    "fósforo [mg]": number;
    "magnésio [mg]": number;
    "ferro [mg]": number;
    "zinco [mg]": number;
    "selénio [µg]": number;
    "iodo [µg]": number
};

export const table: FoodItem[] = [
  {
    "name": "Abacate, Hass",
    "energia[kcal]": 176.0,
    "energia[kj]": 726.0,
    "l\u00edpidos[g]": 17.4,
    "\u00e1cidos gordos saturados[g]": 4.2,
    "\u00e1cidos gordos monoinsaturados [g]": 10.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.3,
    "a\u00e7\u00facares [g]": 2.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 32.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 36.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ab\u00f3bora cristalizada",
    "energia[kcal]": 293.0,
    "energia[kj]": 1240.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 72.4,
    "a\u00e7\u00facares [g]": 72.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 26.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 207.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.15,
    "s\u00f3dio [mg]": 27.0,
    "pot\u00e1ssio [mg]": 22.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 2.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ab\u00f3bora crua",
    "energia[kcal]": 11.0,
    "energia[kj]": 47.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 96.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 160.0,
    "caroteno [\u00b5g]": 962.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Abr\u00f3tea cozida",
    "energia[kcal]": 79.0,
    "energia[kj]": 334.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.4,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 26.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.47,
    "equivalentes de niacina [mg]": 4.1,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 22.0
  },
  {
    "name": "Abr\u00f3tea crua",
    "energia[kcal]": 70.0,
    "energia[kj]": 296.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 22.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.56,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 3.2,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.44,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 63.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "A\u00e7afr\u00e3o",
    "energia[kcal]": 353.0,
    "energia[kj]": 1490.0,
    "l\u00edpidos[g]": 5.9,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 61.5,
    "a\u00e7\u00facares [g]": 42.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 17.4,
    "fibra  [g]": 3.9,
    "prote\u00ednas [g]": 11.4,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 53.0,
    "caroteno [\u00b5g]": 318.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.69,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 2.2,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 1.3,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 1720.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 50.0,
    "ferro [mg]": 11.0,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "A\u00e7afr\u00e3o-da-\u00edndia seco",
    "energia[kcal]": 312.0,
    "energia[kj]": 1300.0,
    "l\u00edpidos[g]": 7.0,
    "\u00e1cidos gordos saturados[g]": 2.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 44.1,
    "a\u00e7\u00facares [g]": 44.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 22.7,
    "prote\u00ednas [g]": 6.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.0,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 3.7,
    "equivalentes de niacina [mg]": 6.2,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 7.08,
    "s\u00f3dio [mg]": 31.0,
    "pot\u00e1ssio [mg]": 2910.0,
    "c\u00e1lcio [mg]": 170.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 190.0,
    "ferro [mg]": 40.0,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Acelga crua",
    "energia[kcal]": 23.0,
    "energia[kj]": 97.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.1,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 766.0,
    "caroteno [\u00b5g]": 4600.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.69,
    "triptofano/60 [mg]": 0.29,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 30.0,
    "folatos [\u00b5g]": 89.5,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 51.0,
    "f\u00f3sforo [mg]": 46.0,
    "magn\u00e9sio [mg]": 81.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "A\u00e7orda",
    "energia[kcal]": 104.0,
    "energia[kj]": 435.0,
    "l\u00edpidos[g]": 4.0,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.53,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 13.1,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 12.6,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 3.3,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 41.0,
    "vitamina a  [\u00b5g]": 22.0,
    "caroteno [\u00b5g]": 17.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.58,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.32,
    "equivalentes de niacina [mg]": 1.13,
    "triptofano/60 [mg]": 0.81,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 1.9,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 0.764,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 58.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 56.0,
    "magn\u00e9sio [mg]": 9.4,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "A\u00e7orda \u00e0 alentejana",
    "energia[kcal]": 95.0,
    "energia[kj]": 400.0,
    "l\u00edpidos[g]": 3.3,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.512,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.3,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 11.8,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 3.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 51.0,
    "vitamina a  [\u00b5g]": 27.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.52,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 1.18,
    "triptofano/60 [mg]": 0.88,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.13,
    "vitamina c [mg]": 1.8,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 0.818,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 58.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 58.0,
    "magn\u00e9sio [mg]": 9.4,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "A\u00e7orda de bacalhau",
    "energia[kcal]": 69.0,
    "energia[kj]": 290.0,
    "l\u00edpidos[g]": 3.5,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.336,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.7,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 5.3,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 3.4,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 23.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.56,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.24,
    "equivalentes de niacina [mg]": 1.07,
    "triptofano/60 [mg]": 0.82,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.14,
    "vitamina c [mg]": 1.4,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 0.717,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 40.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 39.0,
    "magn\u00e9sio [mg]": 7.2,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "A\u00e7orda de marisco",
    "energia[kcal]": 51.0,
    "energia[kj]": 216.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.167,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.4,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.2,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 4.5,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 32.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 5.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.23,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.58,
    "equivalentes de niacina [mg]": 1.58,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 6.9,
    "vitamina c [mg]": 0.6,
    "folatos [\u00b5g]": 7.3,
    "cinza [g]": 0.817,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 47.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 61.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "A\u00e7orda de ovo",
    "energia[kcal]": 108.0,
    "energia[kj]": 454.0,
    "l\u00edpidos[g]": 4.0,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.552,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.0,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 13.5,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 3.6,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 48.0,
    "vitamina a  [\u00b5g]": 22.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.57,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.32,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.89,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.12,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 45.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 60.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "A\u00e7\u00facar amarelo",
    "energia[kcal]": 390.0,
    "energia[kj]": 1660.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 97.5,
    "a\u00e7\u00facares [g]": 97.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.35,
    "s\u00f3dio [mg]": 18.0,
    "pot\u00e1ssio [mg]": 53.0,
    "c\u00e1lcio [mg]": 40.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "A\u00e7\u00facar branco",
    "energia[kcal]": 397.0,
    "energia[kj]": 1690.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 99.3,
    "a\u00e7\u00facares [g]": 99.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.15,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 2.0,
    "c\u00e1lcio [mg]": 2.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Agri\u00e3o cru",
    "energia[kcal]": 29.0,
    "energia[kj]": 122.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 3.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 325.0,
    "caroteno [\u00b5g]": 1950.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 77.0,
    "folatos [\u00b5g]": 200.0,
    "cinza [g]": 1.15,
    "s\u00f3dio [mg]": 49.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 200.0,
    "f\u00f3sforo [mg]": 56.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00c1gua mineral natural gaseificada, \"\u00c1gua Castello\"",
    "energia[kcal]": 0.0,
    "energia[kj]": 0.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 99.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.02,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 0.1,
    "c\u00e1lcio [mg]": 9.1,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00c1gua mineral natural gaseificada, \"Vimeiro\"",
    "energia[kcal]": 0.0,
    "energia[kj]": 0.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 99.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.04,
    "s\u00f3dio [mg]": 17.0,
    "pot\u00e1ssio [mg]": 0.6,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00c1gua mineral natural gasocarb\u00f3nica, \"Pedras Salgadas\"",
    "energia[kcal]": 0.0,
    "energia[kj]": 0.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 99.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.11,
    "s\u00f3dio [mg]": 64.0,
    "pot\u00e1ssio [mg]": 2.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00c1gua mineral natural, \"Luso\"",
    "energia[kcal]": 0.0,
    "energia[kj]": 0.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 99.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.7,
    "pot\u00e1ssio [mg]": 0.1,
    "c\u00e1lcio [mg]": 0.2,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.1,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00c1gua, rede p\u00fablica de abastecimento (Lisboa)",
    "energia[kcal]": 0.0,
    "energia[kj]": 0.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 99.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.09,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 0.2,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.5,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Aguardente",
    "energia[kcal]": 308.0,
    "energia[kj]": 1280.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 44.0,
    "\u00e1gua [g]": 55.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Aipo cru",
    "energia[kcal]": 15.0,
    "energia[kj]": 64.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.5,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 94.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.94,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 55.0,
    "f\u00f3sforo [mg]": 32.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alcachofra cozida",
    "energia[kcal]": 46.0,
    "energia[kj]": 193.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.3,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.2,
    "fibra  [g]": 5.6,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 120.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 42.0,
    "cinza [g]": 0.85,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 42.0,
    "f\u00f3sforo [mg]": 90.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alcachofra crua",
    "energia[kcal]": 51.0,
    "energia[kj]": 214.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.8,
    "a\u00e7\u00facares [g]": 2.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.1,
    "fibra  [g]": 5.0,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 120.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 9.0,
    "folatos [\u00b5g]": 74.0,
    "cinza [g]": 0.85,
    "s\u00f3dio [mg]": 84.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 40.0,
    "f\u00f3sforo [mg]": 90.0,
    "magn\u00e9sio [mg]": 49.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alcaparras (picles)",
    "energia[kcal]": 44.0,
    "energia[kj]": 183.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.9,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.5,
    "fibra  [g]": 3.2,
    "prote\u00ednas [g]": 2.4,
    "sal  [g]": 7.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 14.0,
    "caroteno [\u00b5g]": 83.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 74.0,
    "cinza [g]": 3.08,
    "s\u00f3dio [mg]": 2960.0,
    "pot\u00e1ssio [mg]": 40.0,
    "c\u00e1lcio [mg]": 40.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 1.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alecrim fresco",
    "energia[kcal]": 115.0,
    "energia[kj]": 478.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 13.5,
    "a\u00e7\u00facares [g]": 13.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 7.7,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 92.0,
    "caroteno [\u00b5g]": 550.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 29.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 370.0,
    "f\u00f3sforo [mg]": 20.0,
    "magn\u00e9sio [mg]": 40.0,
    "ferro [mg]": 8.5,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alecrim, seco",
    "energia[kcal]": 377.0,
    "energia[kj]": 1580.0,
    "l\u00edpidos[g]": 15.2,
    "\u00e1cidos gordos saturados[g]": 3.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 7.8,
    "\u00e1cido linoleico [g]": 7.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 46.4,
    "a\u00e7\u00facares [g]": 46.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 17.7,
    "prote\u00ednas [g]": 4.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 313.0,
    "caroteno [\u00b5g]": 1876.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 9.15,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 50.0,
    "pot\u00e1ssio [mg]": 950.0,
    "c\u00e1lcio [mg]": 1280.0,
    "f\u00f3sforo [mg]": 70.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 29.0,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alface",
    "energia[kcal]": 15.0,
    "energia[kj]": 62.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 95.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 115.0,
    "caroteno [\u00b5g]": 688.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 55.0,
    "cinza [g]": 0.65,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 70.0,
    "f\u00f3sforo [mg]": 46.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.2
  },
  {
    "name": "Alface roxa",
    "energia[kcal]": 15.0,
    "energia[kj]": 61.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.4,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 1.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 95.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 751.0,
    "caroteno [\u00b5g]": 4495.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.15,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 0.32,
    "equivalentes de niacina [mg]": 0.42,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.7,
    "folatos [\u00b5g]": 36.0,
    "cinza [g]": 0.55,
    "s\u00f3dio [mg]": 25.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 33.0,
    "f\u00f3sforo [mg]": 28.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 1.5,
    "iodo [\u00b5g]": 2.5
  },
  {
    "name": "Alheira cozida sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 269.0,
    "energia[kj]": 1130.0,
    "l\u00edpidos[g]": 14.0,
    "\u00e1cidos gordos saturados[g]": 4.1,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 3.2,
    "\u00e1cido linoleico [g]": 2.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 26.8,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 25.8,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 8.3,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.7,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 590.0,
    "pot\u00e1ssio [mg]": 76.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 51.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Alheira crua",
    "energia[kcal]": 309.0,
    "energia[kj]": 1290.0,
    "l\u00edpidos[g]": 18.1,
    "\u00e1cidos gordos saturados[g]": 5.2,
    "\u00e1cidos gordos monoinsaturados [g]": 6.6,
    "\u00e1cidos gordos polinsaturados [g]": 4.1,
    "\u00e1cido linoleico [g]": 3.7,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 27.4,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 26.4,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 8.3,
    "sal  [g]": 1.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 43.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 32.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 3.7,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 670.0,
    "pot\u00e1ssio [mg]": 86.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 52.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alheira grelhada sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 302.0,
    "energia[kj]": 1260.0,
    "l\u00edpidos[g]": 17.0,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 6.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.9,
    "\u00e1cido linoleico [g]": 3.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 28.5,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 27.5,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 8.1,
    "sal  [g]": 1.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 44.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 33.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 3.5,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.9,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 700.0,
    "pot\u00e1ssio [mg]": 90.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 54.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Alho cru",
    "energia[kcal]": 72.0,
    "energia[kj]": 303.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.3,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 10.0,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 3.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.01,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 17.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 86.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alho em p\u00f3",
    "energia[kcal]": 310.0,
    "energia[kj]": 1310.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 52.3,
    "a\u00e7\u00facares [g]": 6.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 46.3,
    "fibra  [g]": 10.0,
    "prote\u00ednas [g]": 17.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.47,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 4.5,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.99,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.3,
    "s\u00f3dio [mg]": 23.0,
    "pot\u00e1ssio [mg]": 1230.0,
    "c\u00e1lcio [mg]": 73.0,
    "f\u00f3sforo [mg]": 320.0,
    "magn\u00e9sio [mg]": 60.0,
    "ferro [mg]": 3.3,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alho-franc\u00eas cru",
    "energia[kcal]": 26.0,
    "energia[kj]": 110.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.9,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 0.3,
    "fibra  [g]": 2.4,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 124.0,
    "caroteno [\u00b5g]": 745.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.67,
    "tiamina [mg]": 0.29,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.48,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 20.0,
    "folatos [\u00b5g]": 87.0,
    "cinza [g]": 0.86,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 44.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alm\u00f4ndegas de porco",
    "energia[kcal]": 234.0,
    "energia[kj]": 983.0,
    "l\u00edpidos[g]": 8.9,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.21,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 29.2,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 26.8,
    "fibra  [g]": 2.2,
    "prote\u00ednas [g]": 8.0,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 49.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 75.0,
    "vitamina a  [\u00b5g]": 55.0,
    "caroteno [\u00b5g]": 111.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.16,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 3.27,
    "triptofano/60 [mg]": 1.73,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.26,
    "vitamina c [mg]": 12.6,
    "folatos [\u00b5g]": 30.0,
    "cinza [g]": 1.69,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alm\u00f4ndegas de vaca",
    "energia[kcal]": 252.0,
    "energia[kj]": 1050.0,
    "l\u00edpidos[g]": 17.4,
    "\u00e1cidos gordos saturados[g]": 7.1,
    "\u00e1cidos gordos monoinsaturados [g]": 6.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.942,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 6.4,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 5.1,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 17.0,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 95.0,
    "vitamina a  [\u00b5g]": 71.0,
    "caroteno [\u00b5g]": 149.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.54,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 2.9,
    "equivalentes de niacina [mg]": 6.61,
    "triptofano/60 [mg]": 3.66,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 1.5,
    "vitamina c [mg]": 11.2,
    "folatos [\u00b5g]": 28.0,
    "cinza [g]": 1.99,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 44.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Alm\u00f4ndegas de vaca e porco ",
    "energia[kcal]": 243.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 16.7,
    "\u00e1cidos gordos saturados[g]": 6.9,
    "\u00e1cidos gordos monoinsaturados [g]": 5.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.42,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 3.8,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.6,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 19.2,
    "sal  [g]": 1.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 100.0,
    "vitamina a  [\u00b5g]": 70.0,
    "caroteno [\u00b5g]": 32.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.34,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 8.21,
    "triptofano/60 [mg]": 4.16,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.2,
    "folatos [\u00b5g]": 8.7,
    "cinza [g]": 2.85,
    "s\u00f3dio [mg]": 770.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 39.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Am\u00eaijoa aberta ao natural sem sal",
    "energia[kcal]": 131.0,
    "energia[kj]": 553.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.2,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.4,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 175.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.58,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 3.2,
    "equivalentes de niacina [mg]": 8.2,
    "triptofano/60 [mg]": 5.0,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 67.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 360.0,
    "magn\u00e9sio [mg]": 210.0,
    "ferro [mg]": 17.0,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 160.0
  },
  {
    "name": "Am\u00eaijoa crua",
    "energia[kcal]": 65.0,
    "energia[kj]": 276.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.6,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 11.7,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 44.0,
    "vitamina a  [\u00b5g]": 97.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.29,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 4.2,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 37.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.5,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 78.0,
    "c\u00e1lcio [mg]": 51.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 100.0,
    "ferro [mg]": 8.5,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Am\u00eaijoas \u00e0 Bulh\u00e3o Pato",
    "energia[kcal]": 89.0,
    "energia[kj]": 374.0,
    "l\u00edpidos[g]": 2.5,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.121,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.2,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.9,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 12.8,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 47.0,
    "vitamina a  [\u00b5g]": 110.0,
    "caroteno [\u00b5g]": 34.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.62,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 4.61,
    "triptofano/60 [mg]": 2.72,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 40.0,
    "vitamina c [mg]": 9.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.21,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 63.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 110.0,
    "ferro [mg]": 9.3,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ameixa branca",
    "energia[kcal]": 40.0,
    "energia[kj]": 171.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.8,
    "a\u00e7\u00facares [g]": 7.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.6,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 107.0,
    "caroteno [\u00b5g]": 640.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.61,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.36,
    "s\u00f3dio [mg]": 52.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 12.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ameixa encarnada",
    "energia[kcal]": 41.0,
    "energia[kj]": 172.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.4,
    "a\u00e7\u00facares [g]": 7.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.8,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 70.0,
    "caroteno [\u00b5g]": 420.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 0.31,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ameixa rainha Cl\u00e1udia",
    "energia[kcal]": 57.0,
    "energia[kj]": 242.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.8,
    "a\u00e7\u00facares [g]": 11.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 95.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 23.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ameixa seca",
    "energia[kcal]": 198.0,
    "energia[kj]": 834.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 37.8,
    "a\u00e7\u00facares [g]": 37.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 15.6,
    "prote\u00ednas [g]": 2.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 40.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 119.0,
    "caroteno [\u00b5g]": 715.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.3,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 830.0,
    "c\u00e1lcio [mg]": 38.0,
    "f\u00f3sforo [mg]": 68.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ameixa, conserva em calda de a\u00e7\u00facar",
    "energia[kcal]": 115.0,
    "energia[kj]": 486.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 27.5,
    "a\u00e7\u00facares [g]": 27.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 80.0,
    "caroteno [\u00b5g]": 480.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.25,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.25,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 95.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Am\u00eandoa, miolo, com pele",
    "energia[kcal]": 643.0,
    "energia[kj]": 2660.0,
    "l\u00edpidos[g]": 56.0,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 34.5,
    "\u00e1cidos gordos polinsaturados [g]": 14.3,
    "\u00e1cido linoleico [g]": 13.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.2,
    "a\u00e7\u00facares [g]": 4.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.6,
    "fibra  [g]": 12.0,
    "prote\u00ednas [g]": 21.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 4.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 24.0,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.75,
    "niacina [mg]": 2.2,
    "equivalentes de niacina [mg]": 5.7,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 49.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 860.0,
    "c\u00e1lcio [mg]": 270.0,
    "f\u00f3sforo [mg]": 410.0,
    "magn\u00e9sio [mg]": 260.0,
    "ferro [mg]": 4.0,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Am\u00eandoa, miolo, torrada, sem pele",
    "energia[kcal]": 650.0,
    "energia[kj]": 2690.0,
    "l\u00edpidos[g]": 56.8,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 35.1,
    "\u00e1cidos gordos polinsaturados [g]": 14.5,
    "\u00e1cido linoleico [g]": 14.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.1,
    "a\u00e7\u00facares [g]": 5.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.1,
    "fibra  [g]": 12.2,
    "prote\u00ednas [g]": 21.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 25.0,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.57,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 5.6,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 870.0,
    "c\u00e1lcio [mg]": 270.0,
    "f\u00f3sforo [mg]": 410.0,
    "magn\u00e9sio [mg]": 260.0,
    "ferro [mg]": 4.1,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Amendoim, miolo",
    "energia[kcal]": 589.0,
    "energia[kj]": 2440.0,
    "l\u00edpidos[g]": 47.7,
    "\u00e1cidos gordos saturados[g]": 8.5,
    "\u00e1cidos gordos monoinsaturados [g]": 21.8,
    "\u00e1cidos gordos polinsaturados [g]": 14.8,
    "\u00e1cido linoleico [g]": 14.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.1,
    "a\u00e7\u00facares [g]": 4.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.3,
    "fibra  [g]": 8.8,
    "prote\u00ednas [g]": 25.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 9.9,
    "tiamina [mg]": 0.9,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 15.0,
    "equivalentes de niacina [mg]": 20.0,
    "triptofano/60 [mg]": 5.5,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 110.0,
    "cinza [g]": 2.6,
    "s\u00f3dio [mg]": 7.0,
    "pot\u00e1ssio [mg]": 680.0,
    "c\u00e1lcio [mg]": 62.0,
    "f\u00f3sforo [mg]": 380.0,
    "magn\u00e9sio [mg]": 180.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Amendoim, miolo, torrado com sal",
    "energia[kcal]": 614.0,
    "energia[kj]": 2540.0,
    "l\u00edpidos[g]": 51.8,
    "\u00e1cidos gordos saturados[g]": 9.2,
    "\u00e1cidos gordos monoinsaturados [g]": 23.7,
    "\u00e1cidos gordos polinsaturados [g]": 16.1,
    "\u00e1cido linoleico [g]": 15.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.6,
    "a\u00e7\u00facares [g]": 3.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.0,
    "fibra  [g]": 8.3,
    "prote\u00ednas [g]": 26.2,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.66,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 14.0,
    "equivalentes de niacina [mg]": 19.0,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.47,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 52.0,
    "cinza [g]": 3.6,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 820.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 360.0,
    "magn\u00e9sio [mg]": 160.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Amendoim, miolo, torrado sem sal",
    "energia[kcal]": 605.0,
    "energia[kj]": 2500.0,
    "l\u00edpidos[g]": 49.6,
    "\u00e1cidos gordos saturados[g]": 8.8,
    "\u00e1cidos gordos monoinsaturados [g]": 22.7,
    "\u00e1cidos gordos polinsaturados [g]": 15.4,
    "\u00e1cido linoleico [g]": 14.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.5,
    "a\u00e7\u00facares [g]": 4.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.0,
    "fibra  [g]": 8.9,
    "prote\u00ednas [g]": 25.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 14.0,
    "equivalentes de niacina [mg]": 20.0,
    "triptofano/60 [mg]": 5.5,
    "vitamina b6 [mg]": 0.47,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 66.0,
    "cinza [g]": 3.6,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 740.0,
    "c\u00e1lcio [mg]": 57.0,
    "f\u00f3sforo [mg]": 370.0,
    "magn\u00e9sio [mg]": 170.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Amora",
    "energia[kcal]": 43.0,
    "energia[kj]": 179.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.5,
    "a\u00e7\u00facares [g]": 4.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 4.6,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.9,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 27.0,
    "caroteno [\u00b5g]": 164.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 4.42,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.54,
    "equivalentes de niacina [mg]": 0.74,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 16.5,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 0.395,
    "s\u00f3dio [mg]": 1.8,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 33.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.1,
    "iodo [\u00b5g]": 0.4
  },
  {
    "name": "Anan\u00e1s ",
    "energia[kcal]": 47.0,
    "energia[kj]": 201.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.5,
    "a\u00e7\u00facares [g]": 9.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.6,
    "\u00e1cidos org\u00e2nicos [g]": 1.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 20.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 16.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.7
  },
  {
    "name": "Anan\u00e1s desidratado",
    "energia[kcal]": 363.0,
    "energia[kj]": 1540.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 72.8,
    "a\u00e7\u00facares [g]": 72.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 9.2,
    "prote\u00ednas [g]": 3.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 8.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.77,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 5.36,
    "triptofano/60 [mg]": 0.77,
    "vitamina b6 [mg]": 0.69,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 38.3,
    "cinza [g]": 1.53,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 1230.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 54.0,
    "magn\u00e9sio [mg]": 100.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Anan\u00e1s, conserva em calda de a\u00e7\u00facar",
    "energia[kcal]": 98.0,
    "energia[kj]": 417.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 23.2,
    "a\u00e7\u00facares [g]": 23.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.6,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 7.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.34,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.6
  },
  {
    "name": "Anchova, conserva em \u00f3leo, escorrido",
    "energia[kcal]": 191.0,
    "energia[kj]": 798.0,
    "l\u00edpidos[g]": 10.0,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.76,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.2,
    "sal  [g]": 9.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 46.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 63.0,
    "vitamina a  [\u00b5g]": 57.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 3.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 8.5,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.51,
    "vitamina b12 [\u00b5g]": 11.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 11.6,
    "s\u00f3dio [mg]": 3930.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 56.0,
    "ferro [mg]": 4.1,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Anona",
    "energia[kcal]": 82.0,
    "energia[kj]": 349.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 16.8,
    "a\u00e7\u00facares [g]": 14.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.0,
    "fibra  [g]": 2.4,
    "prote\u00ednas [g]": 1.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 17.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.55,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 31.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Anona desidratada",
    "energia[kcal]": 351.0,
    "energia[kj]": 1480.0,
    "l\u00edpidos[g]": 1.7,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 71.6,
    "a\u00e7\u00facares [g]": 63.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.5,
    "fibra  [g]": 10.2,
    "prote\u00ednas [g]": 7.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.47,
    "niacina [mg]": 3.83,
    "equivalentes de niacina [mg]": 4.26,
    "triptofano/60 [mg]": 0.43,
    "vitamina b6 [mg]": 0.85,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 21.3,
    "cinza [g]": 2.34,
    "s\u00f3dio [mg]": 47.0,
    "pot\u00e1ssio [mg]": 1000.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 98.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz \u00e0 valenciana",
    "energia[kcal]": 111.0,
    "energia[kj]": 463.0,
    "l\u00edpidos[g]": 5.3,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.437,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.7,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 7.0,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 7.6,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 29.0,
    "vitamina a  [\u00b5g]": 86.0,
    "caroteno [\u00b5g]": 267.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.67,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 3.33,
    "triptofano/60 [mg]": 1.51,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 4.0,
    "vitamina c [mg]": 13.2,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 0.946,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 89.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz agulha cru",
    "energia[kcal]": 347.0,
    "energia[kj]": 1470.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 78.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 78.1,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 6.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 13.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 3.4,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 94.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 87.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz carolino branqueado cru",
    "energia[kcal]": 357.0,
    "energia[kj]": 1520.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 79.6,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 79.6,
    "fibra  [g]": 2.2,
    "prote\u00ednas [g]": 7.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 3.6,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 92.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz cozido simples",
    "energia[kcal]": 125.0,
    "energia[kj]": 532.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 28.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 28.0,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 2.5,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.8,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 36.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 33.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "Arroz de bacalhau",
    "energia[kcal]": 90.0,
    "energia[kj]": 379.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.196,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 15.3,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 13.9,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 4.0,
    "vitamina a  [\u00b5g]": 28.0,
    "caroteno [\u00b5g]": 163.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.65,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.66,
    "equivalentes de niacina [mg]": 1.25,
    "triptofano/60 [mg]": 0.58,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.07,
    "vitamina c [mg]": 7.2,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.812,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 34.0,
    "magn\u00e9sio [mg]": 9.3,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de bacalhau com margarina",
    "energia[kcal]": 91.0,
    "energia[kj]": 383.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.552,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.2,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 11.2,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 5.4,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 11.0,
    "vitamina a  [\u00b5g]": 79.0,
    "caroteno [\u00b5g]": 396.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 0.34,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.69,
    "equivalentes de niacina [mg]": 1.69,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 5.2,
    "folatos [\u00b5g]": 8.7,
    "cinza [g]": 1.32,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 47.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de cabidela",
    "energia[kcal]": 105.0,
    "energia[kj]": 444.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.249,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.2,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 11.8,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 9.4,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 50.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 3.2,
    "equivalentes de niacina [mg]": 4.91,
    "triptofano/60 [mg]": 1.79,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.26,
    "vitamina c [mg]": 4.3,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.04,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 90.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de cenoura com azeite",
    "energia[kcal]": 127.0,
    "energia[kj]": 533.0,
    "l\u00edpidos[g]": 4.2,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.286,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 19.9,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 19.0,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 143.0,
    "caroteno [\u00b5g]": 859.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.54,
    "equivalentes de niacina [mg]": 1.06,
    "triptofano/60 [mg]": 0.37,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.8,
    "folatos [\u00b5g]": 6.9,
    "cinza [g]": 0.925,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 67.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 28.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de ervilhas",
    "energia[kcal]": 85.0,
    "energia[kj]": 361.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0796,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.0,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 17.6,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 2.3,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 35.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.59,
    "equivalentes de niacina [mg]": 1.02,
    "triptofano/60 [mg]": 0.44,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.4,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 0.521,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 66.0,
    "c\u00e1lcio [mg]": 8.5,
    "f\u00f3sforo [mg]": 35.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de feij\u00e3o",
    "energia[kcal]": 139.0,
    "energia[kj]": 584.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.328,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 20.8,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 20.0,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.66,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.65,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.55,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.8,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.878,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 50.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de frango",
    "energia[kcal]": 205.0,
    "energia[kj]": 860.0,
    "l\u00edpidos[g]": 7.6,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.01,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 25.2,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 24.4,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 8.4,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 83.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.73,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 4.36,
    "triptofano/60 [mg]": 1.53,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.13,
    "vitamina c [mg]": 2.4,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 95.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 71.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de frango com feij\u00e3o e chouri\u00e7o",
    "energia[kcal]": 116.0,
    "energia[kj]": 489.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.324,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 8.8,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 8.2,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 10.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.6,
    "\u00e1gua [g]": 74.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 40.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 5.41,
    "triptofano/60 [mg]": 2.07,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.31,
    "vitamina c [mg]": 0.6,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 0.921,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de frango malandrinho \u00e0 moda de Mon\u00e7\u00e3o",
    "energia[kcal]": 132.0,
    "energia[kj]": 554.0,
    "l\u00edpidos[g]": 5.8,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.566,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.3,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 9.0,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 10.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 37.0,
    "vitamina a  [\u00b5g]": 13.0,
    "caroteno [\u00b5g]": 54.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.57,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 5.44,
    "triptofano/60 [mg]": 2.01,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.31,
    "vitamina c [mg]": 4.1,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.03,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de gambas",
    "energia[kcal]": 94.0,
    "energia[kj]": 397.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.277,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.8,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 8.2,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 6.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 52.0,
    "vitamina a  [\u00b5g]": 54.0,
    "caroteno [\u00b5g]": 324.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.89,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 2.51,
    "triptofano/60 [mg]": 1.46,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.7,
    "vitamina c [mg]": 6.3,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.785,
    "s\u00f3dio [mg]": 69.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 67.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de lulas",
    "energia[kcal]": 66.0,
    "energia[kj]": 276.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.341,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.7,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 6.2,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 4.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 36.0,
    "vitamina a  [\u00b5g]": 35.0,
    "caroteno [\u00b5g]": 151.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.54,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.53,
    "equivalentes de niacina [mg]": 1.56,
    "triptofano/60 [mg]": 1.02,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.28,
    "vitamina c [mg]": 4.5,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 1.01,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 80.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de manteiga",
    "energia[kcal]": 265.0,
    "energia[kj]": 1110.0,
    "l\u00edpidos[g]": 7.7,
    "\u00e1cidos gordos saturados[g]": 4.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.239,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 44.4,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 44.4,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 3.8,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 21.0,
    "vitamina a  [\u00b5g]": 51.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.91,
    "equivalentes de niacina [mg]": 1.93,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.6,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 43.0,
    "c\u00e1lcio [mg]": 8.7,
    "f\u00f3sforo [mg]": 50.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de marisco",
    "energia[kcal]": 72.0,
    "energia[kj]": 305.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.127,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.2,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 7.7,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 6.5,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 75.0,
    "caroteno [\u00b5g]": 90.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.98,
    "equivalentes de niacina [mg]": 2.37,
    "triptofano/60 [mg]": 1.39,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 10.0,
    "vitamina c [mg]": 5.3,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.21,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 33.0,
    "f\u00f3sforo [mg]": 93.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 2.6,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de pato",
    "energia[kcal]": 115.0,
    "energia[kj]": 484.0,
    "l\u00edpidos[g]": 4.2,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.418,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.4,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.2,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 10.8,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 5.31,
    "triptofano/60 [mg]": 2.34,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 1.6,
    "vitamina c [mg]": 0.4,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 1.11,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 9.3,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de peixe",
    "energia[kcal]": 125.0,
    "energia[kj]": 525.0,
    "l\u00edpidos[g]": 3.7,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 0.935,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 15.5,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 14.7,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 7.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 58.0,
    "vitamina d [\u00b5g]": 1.2,
    "alfa-tocoferol [mg]": 1.26,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.59,
    "equivalentes de niacina [mg]": 2.14,
    "triptofano/60 [mg]": 1.34,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.08,
    "vitamina c [mg]": 1.7,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 75.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de peixe com ervilhas",
    "energia[kcal]": 110.0,
    "energia[kj]": 465.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.159,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 19.8,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.5,
    "amido [g]": 18.6,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 4.9,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 14.0,
    "caroteno [\u00b5g]": 78.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.24,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.89,
    "equivalentes de niacina [mg]": 1.78,
    "triptofano/60 [mg]": 0.89,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.06,
    "vitamina c [mg]": 6.3,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.21,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 74.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de polvo com azeite",
    "energia[kcal]": 127.0,
    "energia[kj]": 532.0,
    "l\u00edpidos[g]": 4.6,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.276,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.9,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 10.6,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 10.2,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 42.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.46,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.96,
    "equivalentes de niacina [mg]": 3.6,
    "triptofano/60 [mg]": 2.26,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.55,
    "vitamina c [mg]": 0.5,
    "folatos [\u00b5g]": 6.3,
    "cinza [g]": 1.02,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 70.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 80.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de polvo com tomate ",
    "energia[kcal]": 128.0,
    "energia[kj]": 534.0,
    "l\u00edpidos[g]": 7.1,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.463,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.0,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 9.4,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 5.7,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 19.0,
    "vitamina a  [\u00b5g]": 14.0,
    "caroteno [\u00b5g]": 81.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.74,
    "equivalentes de niacina [mg]": 1.92,
    "triptofano/60 [mg]": 1.18,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.38,
    "vitamina c [mg]": 5.5,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.24,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 67.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de polvo com tomate e vinho",
    "energia[kcal]": 90.0,
    "energia[kj]": 376.0,
    "l\u00edpidos[g]": 3.6,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.24,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.9,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 6.4,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 6.5,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.4,
    "\u00e1gua [g]": 81.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 23.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 129.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.87,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.74,
    "equivalentes de niacina [mg]": 2.09,
    "triptofano/60 [mg]": 1.36,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.47,
    "vitamina c [mg]": 7.9,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.93,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 74.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de tamboril",
    "energia[kcal]": 87.0,
    "energia[kj]": 363.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.291,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.1,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 6.4,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 5.7,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 16.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 169.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.81,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.88,
    "equivalentes de niacina [mg]": 1.98,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 3.0,
    "vitamina c [mg]": 10.4,
    "folatos [\u00b5g]": 9.1,
    "cinza [g]": 0.945,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 71.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de tamboril malandrinho",
    "energia[kcal]": 67.0,
    "energia[kj]": 283.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0667,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.0,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 9.3,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 5.9,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 11.0,
    "vitamina a  [\u00b5g]": 26.0,
    "caroteno [\u00b5g]": 117.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.23,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.93,
    "equivalentes de niacina [mg]": 2.06,
    "triptofano/60 [mg]": 1.13,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.07,
    "vitamina c [mg]": 8.1,
    "folatos [\u00b5g]": 8.7,
    "cinza [g]": 0.953,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 8.5,
    "f\u00f3sforo [mg]": 73.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de tomate com azeite",
    "energia[kcal]": 129.0,
    "energia[kj]": 541.0,
    "l\u00edpidos[g]": 4.2,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.359,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 20.1,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 18.4,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 216.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.09,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.58,
    "equivalentes de niacina [mg]": 1.17,
    "triptofano/60 [mg]": 0.39,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.1,
    "folatos [\u00b5g]": 7.7,
    "cinza [g]": 1.04,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 80.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de tomate com margarina",
    "energia[kcal]": 122.0,
    "energia[kj]": 513.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.903,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 20.2,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 18.4,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 59.0,
    "caroteno [\u00b5g]": 231.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.55,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.58,
    "equivalentes de niacina [mg]": 1.17,
    "triptofano/60 [mg]": 0.39,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.1,
    "folatos [\u00b5g]": 7.7,
    "cinza [g]": 1.16,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 80.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz de tomate malandrinho",
    "energia[kcal]": 132.0,
    "energia[kj]": 553.0,
    "l\u00edpidos[g]": 6.0,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.48,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 16.8,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 15.2,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 1.9,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 269.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.35,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.68,
    "equivalentes de niacina [mg]": 1.06,
    "triptofano/60 [mg]": 0.37,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 17.7,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 31.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz doce",
    "energia[kcal]": 233.0,
    "energia[kj]": 987.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.402,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 45.2,
    "a\u00e7\u00facares [g]": 29.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 16.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 4.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 45.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 129.0,
    "vitamina a  [\u00b5g]": 58.0,
    "caroteno [\u00b5g]": 5.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.54,
    "triptofano/60 [mg]": 1.05,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.32,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.64,
    "s\u00f3dio [mg]": 23.0,
    "pot\u00e1ssio [mg]": 97.0,
    "c\u00e1lcio [mg]": 63.0,
    "f\u00f3sforo [mg]": 99.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Arroz integral cru",
    "energia[kcal]": 351.0,
    "energia[kj]": 1490.0,
    "l\u00edpidos[g]": 2.5,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 71.6,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 71.6,
    "fibra  [g]": 3.8,
    "prote\u00ednas [g]": 8.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.4,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 4.1,
    "equivalentes de niacina [mg]": 6.0,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.6,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 55.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 110.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Atum conserva em \u00f3leo",
    "energia[kcal]": 214.0,
    "energia[kj]": 894.0,
    "l\u00edpidos[g]": 13.0,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.8,
    "\u00e1cidos gordos polinsaturados [g]": 7.1,
    "\u00e1cido linoleico [g]": 6.8,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 41.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 1.9,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 9.8,
    "equivalentes de niacina [mg]": 14.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 40.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 20.0
  },
  {
    "name": "Atum de cebolada",
    "energia[kcal]": 135.0,
    "energia[kj]": 561.0,
    "l\u00edpidos[g]": 9.0,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 6.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.589,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 0.1,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 9.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 76.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 10.0,
    "vitamina a  [\u00b5g]": 35.0,
    "caroteno [\u00b5g]": 190.0,
    "vitamina d [\u00b5g]": 1.5,
    "alfa-tocoferol [mg]": 1.78,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 5.74,
    "triptofano/60 [mg]": 1.68,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.83,
    "vitamina c [mg]": 10.5,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.01,
    "s\u00f3dio [mg]": 25.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Atum fresco cru",
    "energia[kcal]": 141.0,
    "energia[kj]": 591.0,
    "l\u00edpidos[g]": 4.9,
    "\u00e1cidos gordos saturados[g]": 1.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 4.2,
    "alfa-tocoferol [mg]": 0.64,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 10.0,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.56,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.3,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 45.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Atum fresco grelhado",
    "energia[kcal]": 166.0,
    "energia[kj]": 697.0,
    "l\u00edpidos[g]": 5.8,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.4,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 35.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 4.7,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 11.0,
    "equivalentes de niacina [mg]": 16.0,
    "triptofano/60 [mg]": 5.3,
    "vitamina b6 [mg]": 0.56,
    "vitamina b12 [\u00b5g]": 2.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.2,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 17.0
  },
  {
    "name": "Avel\u00e3, miolo",
    "energia[kcal]": 689.0,
    "energia[kj]": 2840.0,
    "l\u00edpidos[g]": 66.3,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 52.2,
    "\u00e1cidos gordos polinsaturados [g]": 6.2,
    "\u00e1cido linoleico [g]": 6.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.0,
    "a\u00e7\u00facares [g]": 3.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.1,
    "fibra  [g]": 6.1,
    "prote\u00ednas [g]": 14.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 4.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 25.0,
    "tiamina [mg]": 0.3,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 5.2,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.59,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 73.0,
    "cinza [g]": 2.45,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 730.0,
    "c\u00e1lcio [mg]": 250.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 160.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Azeite (4 marcas)",
    "energia[kcal]": 899.0,
    "energia[kj]": 3700.0,
    "l\u00edpidos[g]": 99.9,
    "\u00e1cidos gordos saturados[g]": 14.4,
    "\u00e1cidos gordos monoinsaturados [g]": 78.6,
    "\u00e1cidos gordos polinsaturados [g]": 6.9,
    "\u00e1cido linoleico [g]": 6.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 14.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Azeitona",
    "energia[kcal]": 180.0,
    "energia[kj]": 740.0,
    "l\u00edpidos[g]": 18.5,
    "\u00e1cidos gordos saturados[g]": 2.9,
    "\u00e1cidos gordos monoinsaturados [g]": 9.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 4.0,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 5.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 39.0,
    "caroteno [\u00b5g]": 236.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 5.1,
    "s\u00f3dio [mg]": 2100.0,
    "pot\u00e1ssio [mg]": 60.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 14.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 4.1
  },
  {
    "name": "Bacalhau \u00e0 Br\u00e1s",
    "energia[kcal]": 164.0,
    "energia[kj]": 683.0,
    "l\u00edpidos[g]": 10.1,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 3.8,
    "\u00e1cidos gordos polinsaturados [g]": 4.2,
    "\u00e1cido linoleico [g]": 4.13,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.3,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 7.4,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 9.5,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 84.0,
    "vitamina a  [\u00b5g]": 32.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.5,
    "alfa-tocoferol [mg]": 4.37,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.85,
    "equivalentes de niacina [mg]": 2.94,
    "triptofano/60 [mg]": 2.03,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.44,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 1.89,
    "s\u00f3dio [mg]": 540.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 86.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacalhau \u00e0 Br\u00e1s com azeite",
    "energia[kcal]": 195.0,
    "energia[kj]": 809.0,
    "l\u00edpidos[g]": 14.7,
    "\u00e1cidos gordos saturados[g]": 2.3,
    "\u00e1cidos gordos monoinsaturados [g]": 10.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.5,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 5.5,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 8.7,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 75.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 68.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 2.35,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 2.64,
    "triptofano/60 [mg]": 1.83,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.43,
    "vitamina c [mg]": 10.4,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 1.79,
    "s\u00f3dio [mg]": 570.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 84.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacalhau \u00e0 Br\u00e1s com azeite e azeitonas",
    "energia[kcal]": 93.0,
    "energia[kj]": 389.0,
    "l\u00edpidos[g]": 3.6,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.504,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.5,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 5.1,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 8.0,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 92.0,
    "vitamina a  [\u00b5g]": 54.0,
    "caroteno [\u00b5g]": 96.0,
    "vitamina d [\u00b5g]": 1.3,
    "alfa-tocoferol [mg]": 0.88,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.81,
    "equivalentes de niacina [mg]": 2.56,
    "triptofano/60 [mg]": 1.76,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.41,
    "vitamina c [mg]": 13.0,
    "folatos [\u00b5g]": 32.0,
    "cinza [g]": 1.71,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 36.0,
    "f\u00f3sforo [mg]": 88.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacalhau \u00e0 Gomes de S\u00e1",
    "energia[kcal]": 139.0,
    "energia[kj]": 581.0,
    "l\u00edpidos[g]": 6.3,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 13.1,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 11.4,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 6.7,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 39.0,
    "vitamina a  [\u00b5g]": 14.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 2.4,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.26,
    "vitamina c [mg]": 7.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 530.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 68.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacalhau \u00e0 Gomes de S\u00e1, com azeite",
    "energia[kcal]": 160.0,
    "energia[kj]": 666.0,
    "l\u00edpidos[g]": 10.4,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 7.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.774,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.4,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 6.1,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 8.6,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 115.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 1.75,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.93,
    "equivalentes de niacina [mg]": 2.66,
    "triptofano/60 [mg]": 1.72,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.4,
    "vitamina c [mg]": 14.4,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 2.02,
    "s\u00f3dio [mg]": 640.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 34.0,
    "f\u00f3sforo [mg]": 81.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacalhau assado no forno com azeite",
    "energia[kcal]": 136.0,
    "energia[kj]": 571.0,
    "l\u00edpidos[g]": 4.9,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.297,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 20.8,
    "sal  [g]": 3.4,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 67.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 56.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 4.2,
    "alfa-tocoferol [mg]": 0.99,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.82,
    "equivalentes de niacina [mg]": 4.83,
    "triptofano/60 [mg]": 3.82,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.93,
    "vitamina c [mg]": 1.4,
    "folatos [\u00b5g]": 9.8,
    "cinza [g]": 3.86,
    "s\u00f3dio [mg]": 1370.0,
    "pot\u00e1ssio [mg]": 88.0,
    "c\u00e1lcio [mg]": 44.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacalhau com natas",
    "energia[kcal]": 98.0,
    "energia[kj]": 414.0,
    "l\u00edpidos[g]": 2.6,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0895,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 9.2,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 7.3,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 9.0,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 27.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 1.8,
    "alfa-tocoferol [mg]": 0.29,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.97,
    "equivalentes de niacina [mg]": 2.68,
    "triptofano/60 [mg]": 1.7,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.39,
    "vitamina c [mg]": 6.8,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 38.0,
    "f\u00f3sforo [mg]": 81.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacalhau com natas, com queijo ralado",
    "energia[kcal]": 167.0,
    "energia[kj]": 693.0,
    "l\u00edpidos[g]": 12.6,
    "\u00e1cidos gordos saturados[g]": 6.3,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.43,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 7.6,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 5.2,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 5.3,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 142.0,
    "caroteno [\u00b5g]": 73.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 1.01,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 0.85,
    "equivalentes de niacina [mg]": 1.89,
    "triptofano/60 [mg]": 1.03,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.23,
    "vitamina c [mg]": 7.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.45,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 52.0,
    "f\u00f3sforo [mg]": 69.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacalhau cozido",
    "energia[kcal]": 106.0,
    "energia[kj]": 449.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.2,
    "sal  [g]": 3.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 72.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.28,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.28,
    "equivalentes de niacina [mg]": 5.2,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.35,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.7,
    "cinza [g]": 3.2,
    "s\u00f3dio [mg]": 1230.0,
    "pot\u00e1ssio [mg]": 21.0,
    "c\u00e1lcio [mg]": 46.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 56.0
  },
  {
    "name": "Bacalhau fresco cozido",
    "energia[kcal]": 84.0,
    "energia[kj]": 354.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.1,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 5.9,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 340.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 140.0
  },
  {
    "name": "Bacalhau fresco cru",
    "energia[kcal]": 76.0,
    "energia[kj]": 321.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 44.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 5.6,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 65.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacalhau grelhado",
    "energia[kcal]": 123.0,
    "energia[kj]": 521.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 30.2,
    "sal  [g]": 3.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 79.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.34,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.48,
    "equivalentes de niacina [mg]": 6.1,
    "triptofano/60 [mg]": 5.6,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.9,
    "cinza [g]": 4.9,
    "s\u00f3dio [mg]": 1250.0,
    "pot\u00e1ssio [mg]": 40.0,
    "c\u00e1lcio [mg]": 52.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 57.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 56.0
  },
  {
    "name": "Bacalhau seco e salgado, demolhado cru",
    "energia[kcal]": 80.0,
    "energia[kj]": 338.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.0,
    "sal  [g]": 3.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 52.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 4.5,
    "alfa-tocoferol [mg]": 0.28,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.76,
    "equivalentes de niacina [mg]": 4.3,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.95,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.1,
    "cinza [g]": 3.4,
    "s\u00f3dio [mg]": 1480.0,
    "pot\u00e1ssio [mg]": 36.0,
    "c\u00e1lcio [mg]": 33.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ba\u00e7o de porco",
    "energia[kcal]": 107.0,
    "energia[kj]": 450.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.8,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 363.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.59,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 3.81,
    "equivalentes de niacina [mg]": 3.81,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 6.0,
    "vitamina c [mg]": 31.3,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 130.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 20.0,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacon",
    "energia[kcal]": 367.0,
    "energia[kj]": 1510.0,
    "l\u00edpidos[g]": 34.6,
    "\u00e1cidos gordos saturados[g]": 11.9,
    "\u00e1cidos gordos monoinsaturados [g]": 11.6,
    "\u00e1cidos gordos polinsaturados [g]": 5.6,
    "\u00e1cido linoleico [g]": 4.8,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.8,
    "sal  [g]": 3.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 67.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 0.54,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 6.0,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 2.8,
    "s\u00f3dio [mg]": 1190.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bacon, grelhado sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 372.0,
    "energia[kj]": 1540.0,
    "l\u00edpidos[g]": 31.5,
    "\u00e1cidos gordos saturados[g]": 10.8,
    "\u00e1cidos gordos monoinsaturados [g]": 10.5,
    "\u00e1cidos gordos polinsaturados [g]": 5.1,
    "\u00e1cido linoleico [g]": 4.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.2,
    "sal  [g]": 4.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 92.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.46,
    "niacina [mg]": 5.7,
    "equivalentes de niacina [mg]": 8.5,
    "triptofano/60 [mg]": 2.8,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.9,
    "cinza [g]": 3.7,
    "s\u00f3dio [mg]": 1720.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bagas goji secas",
    "energia[kcal]": 303.0,
    "energia[kj]": 1280.0,
    "l\u00edpidos[g]": 2.8,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.7,
    "\u00e1cido linoleico [g]": 1.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 51.8,
    "a\u00e7\u00facares [g]": 39.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 12.2,
    "fibra  [g]": 12.8,
    "prote\u00ednas [g]": 11.2,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 16.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 27.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.1,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.76,
    "equivalentes de niacina [mg]": 3.79,
    "triptofano/60 [mg]": 3.03,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.5,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 4.95,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 1410.0,
    "c\u00e1lcio [mg]": 48.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 72.0,
    "ferro [mg]": 4.2,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Banana",
    "energia[kcal]": 104.0,
    "energia[kj]": 441.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 21.8,
    "a\u00e7\u00facares [g]": 19.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.2,
    "fibra  [g]": 3.1,
    "prote\u00ednas [g]": 1.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 21.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.27,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 10.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.89,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 25.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.8
  },
  {
    "name": "Banha de porco",
    "energia[kcal]": 896.0,
    "energia[kj]": 3680.0,
    "l\u00edpidos[g]": 99.5,
    "\u00e1cidos gordos saturados[g]": 26.3,
    "\u00e1cidos gordos monoinsaturados [g]": 58.6,
    "\u00e1cidos gordos polinsaturados [g]": 10.4,
    "\u00e1cido linoleico [g]": 9.6,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 82.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.1,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 1.0,
    "c\u00e1lcio [mg]": 1.0,
    "f\u00f3sforo [mg]": 3.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Base em p\u00f3 para bebida de anan\u00e1s",
    "energia[kcal]": 369.0,
    "energia[kj]": 1570.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 89.5,
    "a\u00e7\u00facares [g]": 89.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 1.0,
    "\u00e1cidos org\u00e2nicos [g]": 3.7,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 230.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Base em p\u00f3 para bebida de laranja",
    "energia[kcal]": 375.0,
    "energia[kj]": 1590.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 88.9,
    "a\u00e7\u00facares [g]": 88.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.7,
    "\u00e1cidos org\u00e2nicos [g]": 6.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 375.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 1.08,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Batata assada com pele, sem sal (s\u00f3 a polpa)",
    "energia[kcal]": 90.0,
    "energia[kj]": 383.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 19.2,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 18.0,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 2.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 1.8,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 13.0,
    "folatos [\u00b5g]": 28.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 480.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 44.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Batata assada no forno",
    "energia[kcal]": 159.0,
    "energia[kj]": 670.0,
    "l\u00edpidos[g]": 4.8,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.61,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 24.8,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 23.2,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 3.2,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.82,
    "tiamina [mg]": 0.23,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 2.58,
    "triptofano/60 [mg]": 0.77,
    "vitamina b6 [mg]": 0.51,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 15.9,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 2.25,
    "s\u00f3dio [mg]": 540.0,
    "pot\u00e1ssio [mg]": 580.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 54.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Batata cozida",
    "energia[kcal]": 87.0,
    "energia[kj]": 368.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.5,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 17.3,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 2.4,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 11.0,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 0.66,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 38.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Batata crua",
    "energia[kcal]": 90.0,
    "energia[kj]": 382.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 19.2,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 18.0,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 2.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 2.0,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 14.0,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 0.74,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 42.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Batata doce assada",
    "energia[kcal]": 123.0,
    "energia[kj]": 522.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 28.3,
    "a\u00e7\u00facares [g]": 7.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 20.4,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 650.0,
    "caroteno [\u00b5g]": 3900.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.1,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 25.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 0.74,
    "s\u00f3dio [mg]": 23.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 36.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Batata doce crua",
    "energia[kcal]": 123.0,
    "energia[kj]": 520.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 28.3,
    "a\u00e7\u00facares [g]": 7.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 20.4,
    "fibra  [g]": 2.7,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 650.0,
    "caroteno [\u00b5g]": 3900.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 4.6,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 25.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.67,
    "s\u00f3dio [mg]": 21.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 32.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Batata estufada com cebola, azeite e \u00f3leo alimentar",
    "energia[kcal]": 97.0,
    "energia[kj]": 406.0,
    "l\u00edpidos[g]": 3.1,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.09,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.5,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 13.1,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 27.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.26,
    "tiamina [mg]": 0.16,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 1.57,
    "triptofano/60 [mg]": 0.46,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 11.3,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 1.43,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 36.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Batata frita caseira (em palitos)",
    "energia[kcal]": 227.0,
    "energia[kj]": 951.0,
    "l\u00edpidos[g]": 10.8,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 5.1,
    "\u00e1cido linoleico [g]": 5.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 27.6,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 25.9,
    "fibra  [g]": 2.4,
    "prote\u00ednas [g]": 3.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 54.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 3.8,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.5,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 13.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 14.0,
    "pot\u00e1ssio [mg]": 690.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 64.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Batata frita, de pacote (em rodelas)",
    "energia[kcal]": 543.0,
    "energia[kj]": 2260.0,
    "l\u00edpidos[g]": 38.1,
    "\u00e1cidos gordos saturados[g]": 14.7,
    "\u00e1cidos gordos monoinsaturados [g]": 13.5,
    "\u00e1cidos gordos polinsaturados [g]": 6.9,
    "\u00e1cido linoleico [g]": 6.4,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 39.0,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 38.4,
    "fibra  [g]": 10.7,
    "prote\u00ednas [g]": 5.7,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.5,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 2.5,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 27.0,
    "folatos [\u00b5g]": 41.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 480.0,
    "pot\u00e1ssio [mg]": 1060.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 45.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Batata, f\u00e9cula",
    "energia[kcal]": 345.0,
    "energia[kj]": 1470.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 85.0,
    "a\u00e7\u00facares [g]": 3.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 81.9,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 13.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 5.9,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 35.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 35.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bebida \u00e0 base de soja com a\u00e7\u00facar, com c\u00e1lcio, sal e aromas",
    "energia[kcal]": 53.0,
    "energia[kj]": 220.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.2,
    "a\u00e7\u00facares [g]": 4.0,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 3.6,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.61,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 48.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "Bebida \u00e0 base de soja com a\u00e7\u00facar, sal e aromas",
    "energia[kcal]": 61.0,
    "energia[kj]": 257.0,
    "l\u00edpidos[g]": 2.2,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.4,
    "a\u00e7\u00facares [g]": 6.1,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 3.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 80.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 48.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "Bebida \u00e0 base de soja natural, sem a\u00e7\u00facar e sem sal",
    "energia[kcal]": 37.0,
    "energia[kj]": 154.0,
    "l\u00edpidos[g]": 2.2,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 3.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 30.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 48.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "Bebida refrigerante, cola",
    "energia[kcal]": 36.0,
    "energia[kj]": 153.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.0,
    "a\u00e7\u00facares [g]": 9.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.1,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 1.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bebida refrigerante, cola sem a\u00e7\u00facar",
    "energia[kcal]": 0.0,
    "energia[kj]": 0.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 99.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.02,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 1.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 8.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bebida refrigerante, gasosa",
    "energia[kcal]": 28.0,
    "energia[kj]": 119.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.0,
    "a\u00e7\u00facares [g]": 7.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.08,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 1.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bebida refrigerante, laranja",
    "energia[kcal]": 42.0,
    "energia[kj]": 179.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.5,
    "a\u00e7\u00facares [g]": 10.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.02,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 2.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 1.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Beldroega crua",
    "energia[kcal]": 13.0,
    "energia[kj]": 54.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.0,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.5,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 95.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 478.0,
    "caroteno [\u00b5g]": 2860.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.7,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 20.0,
    "folatos [\u00b5g]": 130.9,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 30.0,
    "pot\u00e1ssio [mg]": 800.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 70.0,
    "magn\u00e9sio [mg]": 68.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 4.0
  },
  {
    "name": "Berbig\u00e3o aberto ao natural sem sal",
    "energia[kcal]": 118.0,
    "energia[kj]": 501.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.4,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.4,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.0,
    "sal  [g]": 1.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 7.7,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 74.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 31.0,
    "cinza [g]": 3.3,
    "s\u00f3dio [mg]": 750.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 320.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 12.0,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Berbig\u00e3o cru",
    "energia[kcal]": 59.0,
    "energia[kj]": 250.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.7,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 10.5,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 3.9,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 41.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 3.2,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 62.0,
    "c\u00e1lcio [mg]": 56.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 58.0,
    "ferro [mg]": 5.9,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Beringela crua",
    "energia[kcal]": 21.0,
    "energia[kj]": 90.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 2.5,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 51.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 26.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Beringela grelhada com azeite",
    "energia[kcal]": 99.0,
    "energia[kj]": 410.0,
    "l\u00edpidos[g]": 7.1,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.417,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.3,
    "a\u00e7\u00facares [g]": 3.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.4,
    "fibra  [g]": 4.4,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 91.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.24,
    "triptofano/60 [mg]": 0.36,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.4,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 2.07,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 46.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Besugo cru",
    "energia[kcal]": 102.0,
    "energia[kj]": 431.0,
    "l\u00edpidos[g]": 2.7,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.0225,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.5,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 57.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 6.1,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 4.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 40.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Beterraba (raiz) cozida sem sal",
    "energia[kcal]": 23.0,
    "energia[kj]": 95.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.4,
    "a\u00e7\u00facares [g]": 3.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.5,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 14.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 67.0,
    "cinza [g]": 0.51,
    "s\u00f3dio [mg]": 57.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 25.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Beterraba (raiz) crua",
    "energia[kcal]": 23.0,
    "energia[kj]": 97.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 3.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 15.0,
    "folatos [\u00b5g]": 110.0,
    "cinza [g]": 0.59,
    "s\u00f3dio [mg]": 70.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 25.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de atum fresco, estufado com azeite e vinho",
    "energia[kcal]": 196.0,
    "energia[kj]": 818.0,
    "l\u00edpidos[g]": 10.1,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.431,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.1,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 1.3,
    "\u00e1gua [g]": 61.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.8,
    "colesterol [mg]": 29.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 3.3,
    "alfa-tocoferol [mg]": 1.37,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 6.7,
    "equivalentes de niacina [mg]": 14.3,
    "triptofano/60 [mg]": 4.4,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.6,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 7.3,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de cavalo, alcatra crua",
    "energia[kcal]": 116.0,
    "energia[kj]": 488.0,
    "l\u00edpidos[g]": 3.5,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 54.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 7.9,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.43,
    "vitamina b12 [\u00b5g]": 3.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 51.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 3.7,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de cavalo, alcatra frita com manteiga",
    "energia[kcal]": 177.0,
    "energia[kj]": 740.0,
    "l\u00edpidos[g]": 9.3,
    "\u00e1cidos gordos saturados[g]": 4.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 1.01,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 75.0,
    "vitamina a  [\u00b5g]": 37.0,
    "caroteno [\u00b5g]": 3.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 8.71,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.09,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 9.9,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 4.0,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de cavalo, alcatra frita com margarina",
    "energia[kcal]": 176.0,
    "energia[kj]": 737.0,
    "l\u00edpidos[g]": 9.2,
    "\u00e1cidos gordos saturados[g]": 4.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.21,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 27.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 8.71,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.19,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 9.1,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 4.0,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de cavalo, alcatra frita, sem molho",
    "energia[kcal]": 147.0,
    "energia[kj]": 620.0,
    "l\u00edpidos[g]": 5.4,
    "\u00e1cidos gordos saturados[g]": 2.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.7,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 67.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 7.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 8.7,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 2.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 4.4,
    "zinco [mg]": 3.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de espadarte, estufado com azeite e vinho",
    "energia[kcal]": 152.0,
    "energia[kj]": 636.0,
    "l\u00edpidos[g]": 8.1,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.335,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.1,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 1.1,
    "\u00e1gua [g]": 70.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 43.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 0.83,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 7.08,
    "triptofano/60 [mg]": 3.16,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.2,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.01,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 7.8,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de porco grelhado",
    "energia[kcal]": 203.0,
    "energia[kj]": 843.0,
    "l\u00edpidos[g]": 14.2,
    "\u00e1cidos gordos saturados[g]": 6.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.08,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 16.7,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.3,
    "\u00e1cidos org\u00e2nicos [g]": 1.1,
    "colesterol [mg]": 72.0,
    "vitamina a  [\u00b5g]": 130.0,
    "caroteno [\u00b5g]": 437.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 1.21,
    "tiamina [mg]": 0.59,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 5.7,
    "equivalentes de niacina [mg]": 9.46,
    "triptofano/60 [mg]": 3.71,
    "vitamina b6 [mg]": 0.39,
    "vitamina b12 [\u00b5g]": 0.77,
    "vitamina c [mg]": 41.5,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 1.64,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 460.0,
    "c\u00e1lcio [mg]": 43.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de vaca (valor m\u00e9dio de ac\u00e9m, alcatra e lombo) cru",
    "energia[kcal]": 122.0,
    "energia[kj]": 514.0,
    "l\u00edpidos[g]": 4.3,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 9.1,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.51,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 60.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de vaca (valor m\u00e9dio de ac\u00e9m, alcatra e lombo) frito com manteiga",
    "energia[kcal]": 201.0,
    "energia[kj]": 839.0,
    "l\u00edpidos[g]": 11.5,
    "\u00e1cidos gordos saturados[g]": 5.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.276,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.2,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 89.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.21,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 10.5,
    "triptofano/60 [mg]": 5.21,
    "vitamina b6 [mg]": 0.33,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.41,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de vaca (valor m\u00e9dio de ac\u00e9m, alcatra e lombo) frito, sem molho",
    "energia[kcal]": 183.0,
    "energia[kj]": 767.0,
    "l\u00edpidos[g]": 7.5,
    "\u00e1cidos gordos saturados[g]": 3.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.8,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 89.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.1,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 6.1,
    "vitamina b6 [mg]": 0.53,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 5.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Bife de vaca (valor m\u00e9dio de ac\u00e9m, alcatra e lombo) grelhado",
    "energia[kcal]": 163.0,
    "energia[kj]": 686.0,
    "l\u00edpidos[g]": 6.4,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.4,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 5.4,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.6,
    "vitamina b6 [mg]": 0.52,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 4.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Bife de vaca \u00e0 caf\u00e9",
    "energia[kcal]": 226.0,
    "energia[kj]": 941.0,
    "l\u00edpidos[g]": 16.5,
    "\u00e1cidos gordos saturados[g]": 8.8,
    "\u00e1cidos gordos monoinsaturados [g]": 4.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.418,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 1.6,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.6,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 16.9,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.9,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 91.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.47,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 3.7,
    "equivalentes de niacina [mg]": 7.37,
    "triptofano/60 [mg]": 3.63,
    "vitamina b6 [mg]": 0.42,
    "vitamina b12 [\u00b5g]": 1.6,
    "vitamina c [mg]": 10.3,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.55,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de vaca \u00e0 Marrare com manteiga",
    "energia[kcal]": 368.0,
    "energia[kj]": 1520.0,
    "l\u00edpidos[g]": 33.3,
    "\u00e1cidos gordos saturados[g]": 18.4,
    "\u00e1cidos gordos monoinsaturados [g]": 8.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.822,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 0.9,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 16.0,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 47.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 126.0,
    "vitamina a  [\u00b5g]": 247.0,
    "caroteno [\u00b5g]": 55.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.94,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 6.91,
    "triptofano/60 [mg]": 3.43,
    "vitamina b6 [mg]": 0.39,
    "vitamina b12 [\u00b5g]": 1.5,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 570.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de vaca \u00e0 Marrare com margarina",
    "energia[kcal]": 363.0,
    "energia[kj]": 1500.0,
    "l\u00edpidos[g]": 32.8,
    "\u00e1cidos gordos saturados[g]": 16.6,
    "\u00e1cidos gordos monoinsaturados [g]": 7.8,
    "\u00e1cidos gordos polinsaturados [g]": 7.0,
    "\u00e1cido linoleico [g]": 6.22,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 16.0,
    "sal  [g]": 1.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 47.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 58.0,
    "vitamina a  [\u00b5g]": 257.0,
    "caroteno [\u00b5g]": 160.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.35,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 6.91,
    "triptofano/60 [mg]": 3.43,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 1.5,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.61,
    "s\u00f3dio [mg]": 710.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de vaca com ovo a cavalo com banha",
    "energia[kcal]": 227.0,
    "energia[kj]": 947.0,
    "l\u00edpidos[g]": 14.5,
    "\u00e1cidos gordos saturados[g]": 4.6,
    "\u00e1cidos gordos monoinsaturados [g]": 7.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.05,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.5,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 23.5,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.15,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 5.2,
    "equivalentes de niacina [mg]": 10.2,
    "triptofano/60 [mg]": 5.05,
    "vitamina b6 [mg]": 0.58,
    "vitamina b12 [\u00b5g]": 2.2,
    "vitamina c [mg]": 0.5,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 2.01,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de vaca com ovo a cavalo com manteiga",
    "energia[kcal]": 199.0,
    "energia[kj]": 830.0,
    "l\u00edpidos[g]": 11.1,
    "\u00e1cidos gordos saturados[g]": 5.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.278,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 23.9,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 87.0,
    "vitamina a  [\u00b5g]": 43.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.21,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 5.2,
    "equivalentes de niacina [mg]": 10.4,
    "triptofano/60 [mg]": 5.14,
    "vitamina b6 [mg]": 0.59,
    "vitamina b12 [\u00b5g]": 2.3,
    "vitamina c [mg]": 0.5,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 2.18,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 440.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bife de vitela cru",
    "energia[kcal]": 149.0,
    "energia[kj]": 623.0,
    "l\u00edpidos[g]": 7.6,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.17,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 3.6,
    "equivalentes de niacina [mg]": 7.8,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.7,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 25.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 25.0
  },
  {
    "name": "Bife de vitela frito",
    "energia[kcal]": 229.0,
    "energia[kj]": 952.0,
    "l\u00edpidos[g]": 16.4,
    "\u00e1cidos gordos saturados[g]": 4.3,
    "\u00e1cidos gordos monoinsaturados [g]": 10.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.778,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 19.0,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 60.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 79.0,
    "vitamina a  [\u00b5g]": 109.0,
    "caroteno [\u00b5g]": 656.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 1.78,
    "tiamina [mg]": 0.39,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 5.9,
    "equivalentes de niacina [mg]": 10.1,
    "triptofano/60 [mg]": 4.02,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 43.3,
    "folatos [\u00b5g]": 38.0,
    "cinza [g]": 2.83,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 550.0,
    "c\u00e1lcio [mg]": 53.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Biscoitos caseiros",
    "energia[kcal]": 423.0,
    "energia[kj]": 1780.0,
    "l\u00edpidos[g]": 11.0,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.07,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 73.4,
    "a\u00e7\u00facares [g]": 32.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 41.5,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 6.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 76.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 1.22,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.64,
    "equivalentes de niacina [mg]": 2.18,
    "triptofano/60 [mg]": 1.52,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.14,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.675,
    "s\u00f3dio [mg]": 86.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Biscoitos lim\u00e3o",
    "energia[kcal]": 382.0,
    "energia[kj]": 1620.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 77.9,
    "a\u00e7\u00facares [g]": 24.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 53.9,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 6.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 8.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 64.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 2.1,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.31,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 53.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 80.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Biscoitos, argolas",
    "energia[kcal]": 451.0,
    "energia[kj]": 1900.0,
    "l\u00edpidos[g]": 16.5,
    "\u00e1cidos gordos saturados[g]": 6.2,
    "\u00e1cidos gordos monoinsaturados [g]": 6.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 69.7,
    "a\u00e7\u00facares [g]": 25.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 43.8,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 5.4,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 102.0,
    "vitamina a  [\u00b5g]": 182.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.9,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.7,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.55,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 95.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 79.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Biscoitos, l\u00ednguas de gato",
    "energia[kcal]": 377.0,
    "energia[kj]": 1600.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 84.1,
    "a\u00e7\u00facares [g]": 37.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 46.6,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 5.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 4.0,
    "vitamina a  [\u00b5g]": 14.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.28,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.7,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.03,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.8,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 52.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 85.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Biscoitos, l\u00ednguas de veado",
    "energia[kcal]": 474.0,
    "energia[kj]": 1990.0,
    "l\u00edpidos[g]": 20.7,
    "\u00e1cidos gordos saturados[g]": 8.0,
    "\u00e1cidos gordos monoinsaturados [g]": 7.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.7,
    "\u00e1cido linoleico [g]": 3.3,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 65.1,
    "a\u00e7\u00facares [g]": 28.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 36.9,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 5.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 86.0,
    "vitamina a  [\u00b5g]": 202.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 3.6,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.2,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.31,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 91.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 78.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Biscoitos, palitos la Reine",
    "energia[kcal]": 381.0,
    "energia[kj]": 1610.0,
    "l\u00edpidos[g]": 7.2,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 66.6,
    "a\u00e7\u00facares [g]": 36.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 29.9,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 11.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 81.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 2.7,
    "triptofano/60 [mg]": 2.4,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 82.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 75.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bola de Berlim sem creme",
    "energia[kcal]": 402.0,
    "energia[kj]": 1680.0,
    "l\u00edpidos[g]": 21.5,
    "\u00e1cidos gordos saturados[g]": 7.0,
    "\u00e1cidos gordos monoinsaturados [g]": 6.4,
    "\u00e1cidos gordos polinsaturados [g]": 4.4,
    "\u00e1cido linoleico [g]": 4.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 43.7,
    "a\u00e7\u00facares [g]": 8.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 35.2,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 6.8,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 24.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 3.9,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.3,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 290.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 89.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha \"Belga\"",
    "energia[kcal]": 487.0,
    "energia[kj]": 2050.0,
    "l\u00edpidos[g]": 19.6,
    "\u00e1cidos gordos saturados[g]": 9.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 70.1,
    "a\u00e7\u00facares [g]": 28.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 41.7,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 7.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 1.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 16.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 2.6,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.03,
    "s\u00f3dio [mg]": 54.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 85.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha \"Cream cracker\"",
    "energia[kcal]": 442.0,
    "energia[kj]": 1860.0,
    "l\u00edpidos[g]": 16.2,
    "\u00e1cidos gordos saturados[g]": 6.9,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 61.6,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 61.6,
    "fibra  [g]": 3.1,
    "prote\u00ednas [g]": 10.8,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 3.1,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.79,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 98.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha \"waffer\" baunilha",
    "energia[kcal]": 564.0,
    "energia[kj]": 2350.0,
    "l\u00edpidos[g]": 36.4,
    "\u00e1cidos gordos saturados[g]": 15.7,
    "\u00e1cidos gordos monoinsaturados [g]": 11.6,
    "\u00e1cidos gordos polinsaturados [g]": 6.8,
    "\u00e1cido linoleico [g]": 6.3,
    "\u00e1cidos gordos trans [g]": 1.6,
    "hidratos de carbono [g]": 55.3,
    "a\u00e7\u00facares [g]": 30.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 24.8,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 3.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 15.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 1.8,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 55.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 34.0,
    "f\u00f3sforo [mg]": 86.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha \u00e1gua e sal",
    "energia[kcal]": 450.0,
    "energia[kj]": 1890.0,
    "l\u00edpidos[g]": 17.8,
    "\u00e1cidos gordos saturados[g]": 7.6,
    "\u00e1cidos gordos monoinsaturados [g]": 5.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.6,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 61.0,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 59.6,
    "fibra  [g]": 3.2,
    "prote\u00ednas [g]": 9.8,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.81,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 550.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha aveia",
    "energia[kcal]": 443.0,
    "energia[kj]": 1860.0,
    "l\u00edpidos[g]": 18.3,
    "\u00e1cidos gordos saturados[g]": 8.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.8,
    "\u00e1cidos gordos polinsaturados [g]": 3.4,
    "\u00e1cido linoleico [g]": 3.2,
    "\u00e1cidos gordos trans [g]": 0.8,
    "hidratos de carbono [g]": 57.5,
    "a\u00e7\u00facares [g]": 3.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 54.5,
    "fibra  [g]": 4.0,
    "prote\u00ednas [g]": 10.0,
    "sal  [g]": 3.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.1,
    "tiamina [mg]": 0.32,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 3.0,
    "triptofano/60 [mg]": 2.3,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 4.7,
    "s\u00f3dio [mg]": 1230.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 420.0,
    "magn\u00e9sio [mg]": 100.0,
    "ferro [mg]": 4.5,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha chocolate",
    "energia[kcal]": 469.0,
    "energia[kj]": 1970.0,
    "l\u00edpidos[g]": 19.8,
    "\u00e1cidos gordos saturados[g]": 12.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 65.4,
    "a\u00e7\u00facares [g]": 24.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 41.4,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 5.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.6,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 80.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha integral (trigo)",
    "energia[kcal]": 450.0,
    "energia[kj]": 1890.0,
    "l\u00edpidos[g]": 15.6,
    "\u00e1cidos gordos saturados[g]": 7.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.7,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 65.6,
    "a\u00e7\u00facares [g]": 3.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 62.6,
    "fibra  [g]": 6.0,
    "prote\u00ednas [g]": 8.8,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 4.0,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 23.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 630.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 96.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 43.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha manteiga",
    "energia[kcal]": 483.0,
    "energia[kj]": 2030.0,
    "l\u00edpidos[g]": 21.3,
    "\u00e1cidos gordos saturados[g]": 11.3,
    "\u00e1cidos gordos monoinsaturados [g]": 4.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 65.2,
    "a\u00e7\u00facares [g]": 18.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 46.9,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 6.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 47.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 2.2,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 0.89,
    "s\u00f3dio [mg]": 41.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha Maria",
    "energia[kcal]": 436.0,
    "energia[kj]": 1840.0,
    "l\u00edpidos[g]": 12.2,
    "\u00e1cidos gordos saturados[g]": 5.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 72.0,
    "a\u00e7\u00facares [g]": 21.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 50.5,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 8.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.65,
    "riboflavina [mg]": 0.45,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 3.7,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.38,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 38.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolacha torrada",
    "energia[kcal]": 437.0,
    "energia[kj]": 1840.0,
    "l\u00edpidos[g]": 14.4,
    "\u00e1cidos gordos saturados[g]": 7.0,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 68.5,
    "a\u00e7\u00facares [g]": 20.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 48.0,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 7.3,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.45,
    "riboflavina [mg]": 0.6,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 3.4,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.26,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 39.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolo de arroz",
    "energia[kcal]": 404.0,
    "energia[kj]": 1700.0,
    "l\u00edpidos[g]": 14.8,
    "\u00e1cidos gordos saturados[g]": 3.9,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 4.1,
    "\u00e1cido linoleico [g]": 3.9,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 62.7,
    "a\u00e7\u00facares [g]": 29.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 33.0,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 4.4,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 16.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 25.0,
    "vitamina a  [\u00b5g]": 192.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 1.7,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.6,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 95.0,
    "c\u00e1lcio [mg]": 29.0,
    "f\u00f3sforo [mg]": 90.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolo de bolacha Maria",
    "energia[kcal]": 389.0,
    "energia[kj]": 1630.0,
    "l\u00edpidos[g]": 20.7,
    "\u00e1cidos gordos saturados[g]": 11.2,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.971,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 46.3,
    "a\u00e7\u00facares [g]": 27.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 19.1,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 3.9,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 27.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 117.0,
    "caroteno [\u00b5g]": 9.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.03,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 0.74,
    "equivalentes de niacina [mg]": 1.62,
    "triptofano/60 [mg]": 0.88,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.05,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.1,
    "cinza [g]": 0.966,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 73.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 63.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolo de chocolate",
    "energia[kcal]": 463.0,
    "energia[kj]": 1930.0,
    "l\u00edpidos[g]": 26.4,
    "\u00e1cidos gordos saturados[g]": 11.1,
    "\u00e1cidos gordos monoinsaturados [g]": 11.3,
    "\u00e1cidos gordos polinsaturados [g]": 4.7,
    "\u00e1cido linoleico [g]": 4.3,
    "\u00e1cidos gordos trans [g]": 1.0,
    "hidratos de carbono [g]": 47.0,
    "a\u00e7\u00facares [g]": 26.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 20.2,
    "fibra  [g]": 3.8,
    "prote\u00ednas [g]": 7.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 125.0,
    "vitamina a  [\u00b5g]": 270.0,
    "caroteno [\u00b5g]": 30.0,
    "vitamina d [\u00b5g]": 2.5,
    "alfa-tocoferol [mg]": 2.7,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 2.5,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.5,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 75.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolo de coco",
    "energia[kcal]": 454.0,
    "energia[kj]": 1890.0,
    "l\u00edpidos[g]": 31.0,
    "\u00e1cidos gordos saturados[g]": 21.5,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.2,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 37.8,
    "a\u00e7\u00facares [g]": 32.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.1,
    "fibra  [g]": 3.4,
    "prote\u00ednas [g]": 4.2,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 22.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 87.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 40.0,
    "vitamina d [\u00b5g]": 1.7,
    "alfa-tocoferol [mg]": 2.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.95,
    "s\u00f3dio [mg]": 290.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 87.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolo ferradura",
    "energia[kcal]": 351.0,
    "energia[kj]": 1480.0,
    "l\u00edpidos[g]": 4.1,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.929,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 71.6,
    "a\u00e7\u00facares [g]": 26.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 44.8,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 5.9,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 15.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 29.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.41,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.81,
    "equivalentes de niacina [mg]": 2.13,
    "triptofano/60 [mg]": 1.28,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.07,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 0.813,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 98.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolo ingl\u00eas",
    "energia[kcal]": 380.0,
    "energia[kj]": 1600.0,
    "l\u00edpidos[g]": 13.9,
    "\u00e1cidos gordos saturados[g]": 6.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.2,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 57.0,
    "a\u00e7\u00facares [g]": 37.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 19.2,
    "fibra  [g]": 3.2,
    "prote\u00ednas [g]": 5.1,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 19.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 113.0,
    "vitamina a  [\u00b5g]": 110.0,
    "caroteno [\u00b5g]": 150.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.8,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bolo-Rei",
    "energia[kcal]": 407.0,
    "energia[kj]": 1710.0,
    "l\u00edpidos[g]": 14.9,
    "\u00e1cidos gordos saturados[g]": 5.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 3.2,
    "\u00e1cido linoleico [g]": 2.78,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 57.4,
    "a\u00e7\u00facares [g]": 17.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 40.4,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 7.9,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 1.0,
    "\u00e1gua [g]": 15.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 91.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 1.13,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 2.55,
    "triptofano/60 [mg]": 1.73,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.14,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.38,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Brandy",
    "energia[kcal]": 246.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 35.2,
    "\u00e1gua [g]": 63.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Br\u00f3colos cozidos",
    "energia[kcal]": 27.0,
    "energia[kj]": 114.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.1,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 114.0,
    "caroteno [\u00b5g]": 687.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 18.0,
    "folatos [\u00b5g]": 47.0,
    "cinza [g]": 0.97,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 56.0,
    "f\u00f3sforo [mg]": 39.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.3
  },
  {
    "name": "Br\u00f3colos crus",
    "energia[kcal]": 32.0,
    "energia[kj]": 134.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.5,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.1,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 3.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 178.0,
    "caroteno [\u00b5g]": 1066.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.6,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 41.0,
    "folatos [\u00b5g]": 90.0,
    "cinza [g]": 0.97,
    "s\u00f3dio [mg]": 8.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 67.0,
    "f\u00f3sforo [mg]": 50.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Bulgur",
    "energia[kcal]": 344.0,
    "energia[kj]": 1460.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 65.6,
    "a\u00e7\u00facares [g]": 2.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 62.9,
    "fibra  [g]": 8.0,
    "prote\u00ednas [g]": 12.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.47,
    "equivalentes de niacina [mg]": 2.37,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 1.25,
    "s\u00f3dio [mg]": 9.5,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 68.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "B\u00fazio cru",
    "energia[kcal]": 82.0,
    "energia[kj]": 349.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.002,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.3,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.8,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 110.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 19.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.8,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 3.4,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 6.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 60.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 92.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cabe\u00e7a de porco",
    "energia[kcal]": 355.0,
    "energia[kj]": 1470.0,
    "l\u00edpidos[g]": 32.9,
    "\u00e1cidos gordos saturados[g]": 9.3,
    "\u00e1cidos gordos monoinsaturados [g]": 17.3,
    "\u00e1cidos gordos polinsaturados [g]": 4.7,
    "\u00e1cido linoleico [g]": 3.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 14.3,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 100.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 2.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 3.4,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Ca\u00e7\u00e3o cru",
    "energia[kcal]": 82.0,
    "energia[kj]": 347.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.0,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 25.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.35,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 5.6,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 3.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.2,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ca\u00e7\u00e3o frito",
    "energia[kcal]": 170.0,
    "energia[kj]": 714.0,
    "l\u00edpidos[g]": 7.0,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 4.4,
    "\u00e1cido linoleico [g]": 4.32,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.7,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 24.0,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 4.54,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 6.74,
    "triptofano/60 [mg]": 4.45,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 3.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 2.09,
    "s\u00f3dio [mg]": 480.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cacau em p\u00f3",
    "energia[kcal]": 358.0,
    "energia[kj]": 1480.0,
    "l\u00edpidos[g]": 23.4,
    "\u00e1cidos gordos saturados[g]": 13.8,
    "\u00e1cidos gordos monoinsaturados [g]": 7.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 11.1,
    "fibra  [g]": 12.1,
    "prote\u00ednas [g]": 19.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 7.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 3.1,
    "equivalentes de niacina [mg]": 6.6,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 34.0,
    "cinza [g]": 4.4,
    "s\u00f3dio [mg]": 34.0,
    "pot\u00e1ssio [mg]": 1840.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 550.0,
    "magn\u00e9sio [mg]": 410.0,
    "ferro [mg]": 5.8,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cacha\u00e7o de vaca cru",
    "energia[kcal]": 137.0,
    "energia[kj]": 574.0,
    "l\u00edpidos[g]": 5.6,
    "\u00e1cidos gordos saturados[g]": 2.3,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 8.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.51,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.95,
    "s\u00f3dio [mg]": 98.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cachucho cru",
    "energia[kcal]": 80.0,
    "energia[kj]": 337.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 34.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 1.9,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 5.0,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 1.25,
    "s\u00f3dio [mg]": 61.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caf\u00e9 sol\u00favel em p\u00f3 com cafe\u00edna",
    "energia[kcal]": 272.0,
    "energia[kj]": 1140.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 41.1,
    "a\u00e7\u00facares [g]": 3.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 21.5,
    "prote\u00ednas [g]": 14.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 25.0,
    "equivalentes de niacina [mg]": 28.0,
    "triptofano/60 [mg]": 3.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 9.1,
    "s\u00f3dio [mg]": 42.0,
    "pot\u00e1ssio [mg]": 3250.0,
    "c\u00e1lcio [mg]": 89.0,
    "f\u00f3sforo [mg]": 320.0,
    "magn\u00e9sio [mg]": 220.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caf\u00e9 sol\u00favel em p\u00f3 descafeinado",
    "energia[kcal]": 274.0,
    "energia[kj]": 1150.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 42.6,
    "a\u00e7\u00facares [g]": 3.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 21.5,
    "prote\u00ednas [g]": 14.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 25.0,
    "equivalentes de niacina [mg]": 28.0,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 8.93,
    "s\u00f3dio [mg]": 55.0,
    "pot\u00e1ssio [mg]": 3420.0,
    "c\u00e1lcio [mg]": 97.0,
    "f\u00f3sforo [mg]": 370.0,
    "magn\u00e9sio [mg]": 270.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caf\u00e9, infus\u00e3o - bica (3 marcas)",
    "energia[kcal]": 4.0,
    "energia[kj]": 16.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 96.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.52,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 11.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caf\u00e9, infus\u00e3o - caf\u00e9 de cafeteira (3 marcas)",
    "energia[kcal]": 2.0,
    "energia[kj]": 9.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 97.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 2.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caf\u00e9, infus\u00e3o - carioca (2 marcas)",
    "energia[kcal]": 2.0,
    "energia[kj]": 9.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 98.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 1.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caf\u00e9, infus\u00e3o - valor m\u00e9dio (bica 60% e caf\u00e9 de cafeteira 40%)",
    "energia[kcal]": 3.0,
    "energia[kj]": 14.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 97.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.43,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 11.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de bacalhau",
    "energia[kcal]": 67.0,
    "energia[kj]": 279.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.57,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.1,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 5.0,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 4.2,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 56.0,
    "caroteno [\u00b5g]": 254.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.54,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.83,
    "equivalentes de niacina [mg]": 1.61,
    "triptofano/60 [mg]": 0.77,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.15,
    "vitamina c [mg]": 17.4,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 1.33,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 42.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de bacalhau com enchidos e massa",
    "energia[kcal]": 146.0,
    "energia[kj]": 610.0,
    "l\u00edpidos[g]": 8.5,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 0.964,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.7,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 7.7,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 7.0,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 25.0,
    "vitamina a  [\u00b5g]": 81.0,
    "caroteno [\u00b5g]": 477.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.44,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 2.6,
    "triptofano/60 [mg]": 1.44,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.29,
    "vitamina c [mg]": 31.2,
    "folatos [\u00b5g]": 34.0,
    "cinza [g]": 1.85,
    "s\u00f3dio [mg]": 540.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 39.0,
    "f\u00f3sforo [mg]": 79.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de cabrito com azeite e banha",
    "energia[kcal]": 119.0,
    "energia[kj]": 500.0,
    "l\u00edpidos[g]": 4.3,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.311,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 8.3,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 7.3,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 11.3,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 92.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.34,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 2.2,
    "equivalentes de niacina [mg]": 5.98,
    "triptofano/60 [mg]": 2.42,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.33,
    "vitamina c [mg]": 9.1,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.74,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de cabrito com azeite e margarina",
    "energia[kcal]": 116.0,
    "energia[kj]": 488.0,
    "l\u00edpidos[g]": 4.0,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.439,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 8.3,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 7.3,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 11.3,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 97.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.33,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 2.2,
    "equivalentes de niacina [mg]": 5.98,
    "triptofano/60 [mg]": 2.42,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.33,
    "vitamina c [mg]": 9.1,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.78,
    "s\u00f3dio [mg]": 340.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de enguias",
    "energia[kcal]": 171.0,
    "energia[kj]": 709.0,
    "l\u00edpidos[g]": 11.9,
    "\u00e1cidos gordos saturados[g]": 3.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 3.9,
    "\u00e1cido linoleico [g]": 0.16,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.2,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 6.3,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 6.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 70.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 10.0,
    "vitamina a  [\u00b5g]": 369.0,
    "caroteno [\u00b5g]": 112.0,
    "vitamina d [\u00b5g]": 4.6,
    "alfa-tocoferol [mg]": 1.42,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 0.95,
    "equivalentes de niacina [mg]": 2.51,
    "triptofano/60 [mg]": 1.24,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.54,
    "vitamina c [mg]": 7.4,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.53,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 68.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de enguias \u00e0 moda de Aveiro",
    "energia[kcal]": 245.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 21.4,
    "\u00e1cidos gordos saturados[g]": 5.8,
    "\u00e1cidos gordos monoinsaturados [g]": 6.5,
    "\u00e1cidos gordos polinsaturados [g]": 5.3,
    "\u00e1cido linoleico [g]": 0.689,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.3,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 4.4,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 7.4,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 16.0,
    "vitamina a  [\u00b5g]": 446.0,
    "caroteno [\u00b5g]": 68.0,
    "vitamina d [\u00b5g]": 7.8,
    "alfa-tocoferol [mg]": 1.84,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.5,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.93,
    "vitamina c [mg]": 9.3,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 1.53,
    "s\u00f3dio [mg]": 290.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 80.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de pargo e peixe-espada-preto",
    "energia[kcal]": 138.0,
    "energia[kj]": 575.0,
    "l\u00edpidos[g]": 9.8,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 7.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.619,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.4,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 2.9,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 7.2,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 75.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 10.0,
    "vitamina a  [\u00b5g]": 31.0,
    "caroteno [\u00b5g]": 161.0,
    "vitamina d [\u00b5g]": 1.7,
    "alfa-tocoferol [mg]": 1.82,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 3.64,
    "triptofano/60 [mg]": 1.35,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.67,
    "vitamina c [mg]": 11.2,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.41,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 92.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de peixe",
    "energia[kcal]": 84.0,
    "energia[kj]": 350.0,
    "l\u00edpidos[g]": 3.5,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.266,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.4,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 2.9,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 7.5,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.4,
    "\u00e1gua [g]": 81.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 17.0,
    "vitamina a  [\u00b5g]": 61.0,
    "caroteno [\u00b5g]": 186.0,
    "vitamina d [\u00b5g]": 5.9,
    "alfa-tocoferol [mg]": 0.99,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 2.64,
    "triptofano/60 [mg]": 1.41,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 4.2,
    "vitamina c [mg]": 12.7,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.45,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 36.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de safio com am\u00eaijoas",
    "energia[kcal]": 80.0,
    "energia[kj]": 335.0,
    "l\u00edpidos[g]": 2.7,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.154,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.8,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 4.3,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 6.9,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.4,
    "\u00e1gua [g]": 81.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 18.0,
    "vitamina a  [\u00b5g]": 116.0,
    "caroteno [\u00b5g]": 202.0,
    "vitamina d [\u00b5g]": 25.0,
    "alfa-tocoferol [mg]": 0.96,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.44,
    "triptofano/60 [mg]": 1.33,
    "vitamina b6 [mg]": 0.33,
    "vitamina b12 [\u00b5g]": 3.5,
    "vitamina c [mg]": 14.9,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.02,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 49.0,
    "f\u00f3sforo [mg]": 96.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de safio com batatas",
    "energia[kcal]": 81.0,
    "energia[kj]": 339.0,
    "l\u00edpidos[g]": 3.1,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.2,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 5.3,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 4.5,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.6,
    "\u00e1gua [g]": 83.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 11.0,
    "vitamina a  [\u00b5g]": 116.0,
    "caroteno [\u00b5g]": 323.0,
    "vitamina d [\u00b5g]": 17.7,
    "alfa-tocoferol [mg]": 1.15,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.88,
    "equivalentes de niacina [mg]": 2.13,
    "triptofano/60 [mg]": 1.03,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.06,
    "vitamina c [mg]": 16.6,
    "folatos [\u00b5g]": 19.8,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 56.0,
    "f\u00f3sforo [mg]": 73.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldeirada de safio, raia e tamboril",
    "energia[kcal]": 98.0,
    "energia[kj]": 412.0,
    "l\u00edpidos[g]": 2.9,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.215,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.8,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 7.8,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 7.0,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 76.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 14.0,
    "vitamina a  [\u00b5g]": 44.0,
    "caroteno [\u00b5g]": 113.0,
    "vitamina d [\u00b5g]": 6.1,
    "alfa-tocoferol [mg]": 0.87,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 2.79,
    "triptofano/60 [mg]": 1.33,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 8.9,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.85,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldo preparado com cubo de carne de galinha (dilui\u00e7\u00e3o 2%)",
    "energia[kcal]": 5.0,
    "energia[kj]": 20.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 98.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.01,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.09,
    "equivalentes de niacina [mg]": 0.14,
    "triptofano/60 [mg]": 0.06,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.1,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 8.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caldo preparado com cubo de carne de vaca (dilui\u00e7\u00e3o 2%)",
    "energia[kcal]": 4.0,
    "energia[kj]": 18.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 98.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.11,
    "equivalentes de niacina [mg]": 0.19,
    "triptofano/60 [mg]": 0.07,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.02,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.7,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 9.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Camar\u00e3o cozido",
    "energia[kcal]": 99.0,
    "energia[kj]": 421.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.4,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.6,
    "sal  [g]": 4.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 198.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 7.2,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.8,
    "cinza [g]": 5.0,
    "s\u00f3dio [mg]": 1600.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 71.0
  },
  {
    "name": "Camar\u00e3o cozido sem sal",
    "energia[kcal]": 92.0,
    "energia[kj]": 389.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.2,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 308.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.82,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 2.2,
    "equivalentes de niacina [mg]": 6.5,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 71.0
  },
  {
    "name": "Camar\u00e3o cru",
    "energia[kcal]": 77.0,
    "energia[kj]": 327.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.6,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 154.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 5.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 2.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 87.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Canela mo\u00edda",
    "energia[kcal]": 315.0,
    "energia[kj]": 1320.0,
    "l\u00edpidos[g]": 3.2,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 55.5,
    "a\u00e7\u00facares [g]": 55.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 24.4,
    "prote\u00ednas [g]": 3.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 26.0,
    "caroteno [\u00b5g]": 155.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 28.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.6,
    "s\u00f3dio [mg]": 26.0,
    "pot\u00e1ssio [mg]": 500.0,
    "c\u00e1lcio [mg]": 1230.0,
    "f\u00f3sforo [mg]": 61.0,
    "magn\u00e9sio [mg]": 56.0,
    "ferro [mg]": 38.0,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Canja de frango com aipo",
    "energia[kcal]": 56.0,
    "energia[kj]": 236.0,
    "l\u00edpidos[g]": 1.7,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.268,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.3,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 2.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 7.8,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 29.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 2.7,
    "equivalentes de niacina [mg]": 4.05,
    "triptofano/60 [mg]": 1.46,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.25,
    "vitamina c [mg]": 0.7,
    "folatos [\u00b5g]": 5.6,
    "cinza [g]": 1.07,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 71.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Canja de frango com massa",
    "energia[kcal]": 61.0,
    "energia[kj]": 258.0,
    "l\u00edpidos[g]": 1.0,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.212,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 10.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 40.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 3.7,
    "equivalentes de niacina [mg]": 5.59,
    "triptofano/60 [mg]": 2.02,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.33,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.6,
    "cinza [g]": 0.68,
    "s\u00f3dio [mg]": 97.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 8.3,
    "f\u00f3sforo [mg]": 96.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Can\u00f3nigos crus",
    "energia[kcal]": 24.0,
    "energia[kj]": 100.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.0,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 680.0,
    "caroteno [\u00b5g]": 4070.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 39.0,
    "folatos [\u00b5g]": 94.2,
    "cinza [g]": 0.65,
    "s\u00f3dio [mg]": 9.5,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 52.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 3.1,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 35.0
  },
  {
    "name": "Cantarilho (Redfish) assado com cebola, tomate, azeite e bacon",
    "energia[kcal]": 142.0,
    "energia[kj]": 593.0,
    "l\u00edpidos[g]": 8.6,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.808,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 13.6,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 73.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 32.0,
    "caroteno [\u00b5g]": 106.0,
    "vitamina d [\u00b5g]": 1.4,
    "alfa-tocoferol [mg]": 1.89,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 4.76,
    "triptofano/60 [mg]": 2.52,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 1.5,
    "vitamina c [mg]": 3.9,
    "folatos [\u00b5g]": 7.2,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cantarilho (Redfish) com batata, no forno",
    "energia[kcal]": 108.0,
    "energia[kj]": 452.0,
    "l\u00edpidos[g]": 4.9,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.475,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.7,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 3.5,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 8.7,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.6,
    "\u00e1gua [g]": 77.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 18.0,
    "vitamina a  [\u00b5g]": 39.0,
    "caroteno [\u00b5g]": 196.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 1.27,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 3.56,
    "triptofano/60 [mg]": 1.66,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.81,
    "vitamina c [mg]": 14.1,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 1.38,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cantarilho (Redfish) cozido",
    "energia[kcal]": 99.0,
    "energia[kj]": 417.0,
    "l\u00edpidos[g]": 2.4,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.3,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 46.0,
    "vitamina a  [\u00b5g]": 18.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.3,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 2.8,
    "equivalentes de niacina [mg]": 6.4,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 340.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 39.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 22.0
  },
  {
    "name": "Cantarilho (Redfish) cru",
    "energia[kcal]": 101.0,
    "energia[kj]": 424.0,
    "l\u00edpidos[g]": 2.9,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 42.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.3,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 2.8,
    "equivalentes de niacina [mg]": 6.3,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 2.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 78.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cantarilho (Redfish) no forno",
    "energia[kcal]": 114.0,
    "energia[kj]": 481.0,
    "l\u00edpidos[g]": 2.7,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.371,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.1,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 17.3,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 2.2,
    "\u00e1gua [g]": 74.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 39.0,
    "vitamina a  [\u00b5g]": 176.0,
    "caroteno [\u00b5g]": 947.0,
    "vitamina d [\u00b5g]": 2.1,
    "alfa-tocoferol [mg]": 1.19,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 2.8,
    "equivalentes de niacina [mg]": 5.99,
    "triptofano/60 [mg]": 3.25,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 2.1,
    "vitamina c [mg]": 0.6,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.05,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caracol cru",
    "energia[kcal]": 85.0,
    "energia[kj]": 360.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 90.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 3.4,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 5.0,
    "vitamina c [mg]": 15.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 73.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 170.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 250.0,
    "ferro [mg]": 3.5,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 50.0,
    "iodo [\u00b5g]": 5.9
  },
  {
    "name": "Carambola",
    "energia[kcal]": 40.0,
    "energia[kj]": 171.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.1,
    "a\u00e7\u00facares [g]": 6.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 49.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 27.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Carapau cru",
    "energia[kcal]": 105.0,
    "energia[kj]": 442.0,
    "l\u00edpidos[g]": 2.9,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 36.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 4.1,
    "alfa-tocoferol [mg]": 0.37,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 8.7,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 5.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 80.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 69.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Carapau frito",
    "energia[kcal]": 186.0,
    "energia[kj]": 780.0,
    "l\u00edpidos[g]": 9.6,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 4.2,
    "\u00e1cido linoleico [g]": 3.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.0,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 39.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.8,
    "alfa-tocoferol [mg]": 0.79,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 5.5,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 6.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 2.6,
    "s\u00f3dio [mg]": 530.0,
    "pot\u00e1ssio [mg]": 470.0,
    "c\u00e1lcio [mg]": 86.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 44.0
  },
  {
    "name": "Carapau grelhado",
    "energia[kcal]": 139.0,
    "energia[kj]": 584.0,
    "l\u00edpidos[g]": 3.7,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 48.0,
    "vitamina a  [\u00b5g]": 21.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 3.5,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 5.9,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 6.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 470.0,
    "c\u00e1lcio [mg]": 61.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 44.0
  },
  {
    "name": "Cardamomo verde",
    "energia[kcal]": 335.0,
    "energia[kj]": 1410.0,
    "l\u00edpidos[g]": 6.2,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.31,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 49.5,
    "a\u00e7\u00facares [g]": 5.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 31.0,
    "fibra  [g]": 19.0,
    "prote\u00ednas [g]": 10.8,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 9.6,
    "triptofano/60 [mg]": 8.5,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 21.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 4.2,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 240.0,
    "f\u00f3sforo [mg]": 66.0,
    "magn\u00e9sio [mg]": 69.0,
    "ferro [mg]": 5.6,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Caril em p\u00f3",
    "energia[kcal]": 342.0,
    "energia[kj]": 1420.0,
    "l\u00edpidos[g]": 13.8,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 25.2,
    "a\u00e7\u00facares [g]": 25.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 33.2,
    "prote\u00ednas [g]": 12.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 594.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 22.0,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 3.47,
    "equivalentes de niacina [mg]": 3.47,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 1.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 11.4,
    "folatos [\u00b5g]": 154.0,
    "cinza [g]": 5.6,
    "s\u00f3dio [mg]": 52.0,
    "pot\u00e1ssio [mg]": 1540.0,
    "c\u00e1lcio [mg]": 480.0,
    "f\u00f3sforo [mg]": 350.0,
    "magn\u00e9sio [mg]": 250.0,
    "ferro [mg]": 30.0,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Carne \u00e0 bolonhesa",
    "energia[kcal]": 238.0,
    "energia[kj]": 992.0,
    "l\u00edpidos[g]": 14.0,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.6,
    "\u00e1cido linoleico [g]": 2.36,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 15.6,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 13.7,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 11.5,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 55.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 27.0,
    "vitamina a  [\u00b5g]": 126.0,
    "caroteno [\u00b5g]": 558.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.17,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 4.69,
    "triptofano/60 [mg]": 2.56,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.72,
    "vitamina c [mg]": 5.2,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.78,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 86.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Carne \u00e0 jardineira",
    "energia[kcal]": 140.0,
    "energia[kj]": 585.0,
    "l\u00edpidos[g]": 8.1,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.491,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 5.6,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.9,
    "amido [g]": 3.0,
    "fibra  [g]": 3.1,
    "prote\u00ednas [g]": 9.7,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 19.0,
    "vitamina a  [\u00b5g]": 138.0,
    "caroteno [\u00b5g]": 798.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.46,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 3.72,
    "triptofano/60 [mg]": 1.83,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.56,
    "vitamina c [mg]": 13.1,
    "folatos [\u00b5g]": 44.0,
    "cinza [g]": 1.62,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Carne de porco \u00e0 alentejana",
    "energia[kcal]": 229.0,
    "energia[kj]": 951.0,
    "l\u00edpidos[g]": 16.9,
    "\u00e1cidos gordos saturados[g]": 5.1,
    "\u00e1cidos gordos monoinsaturados [g]": 7.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.01,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.8,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.3,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 14.6,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 1.4,
    "\u00e1gua [g]": 61.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 62.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 78.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.35,
    "tiamina [mg]": 0.4,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 7.43,
    "triptofano/60 [mg]": 2.83,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 18.0,
    "vitamina c [mg]": 9.2,
    "folatos [\u00b5g]": 7.4,
    "cinza [g]": 1.98,
    "s\u00f3dio [mg]": 500.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 62.0,
    "ferro [mg]": 4.8,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Carne de porco \u00e0 alentejana sem massa de piment\u00e3o",
    "energia[kcal]": 183.0,
    "energia[kj]": 764.0,
    "l\u00edpidos[g]": 11.8,
    "\u00e1cidos gordos saturados[g]": 3.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.7,
    "\u00e1cido linoleico [g]": 1.39,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 2.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.0,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 16.0,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.5,
    "\u00e1gua [g]": 65.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 77.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.33,
    "tiamina [mg]": 0.33,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.2,
    "equivalentes de niacina [mg]": 7.46,
    "triptofano/60 [mg]": 3.19,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 29.0,
    "vitamina c [mg]": 3.2,
    "folatos [\u00b5g]": 8.3,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 87.0,
    "ferro [mg]": 7.0,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Case\u00edna",
    "energia[kcal]": 358.0,
    "energia[kj]": 1520.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.025,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 86.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 26.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 18.33,
    "triptofano/60 [mg]": 18.33,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 2.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 2.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Castanha assada com sal",
    "energia[kcal]": 222.0,
    "energia[kj]": 937.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 45.5,
    "a\u00e7\u00facares [g]": 11.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 34.3,
    "fibra  [g]": 7.0,
    "prote\u00ednas [g]": 3.5,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 39.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 66.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 46.0,
    "folatos [\u00b5g]": 56.0,
    "cinza [g]": 4.6,
    "s\u00f3dio [mg]": 550.0,
    "pot\u00e1ssio [mg]": 570.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 72.0,
    "magn\u00e9sio [mg]": 48.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Castanha cozida sem sal",
    "energia[kcal]": 175.0,
    "energia[kj]": 738.0,
    "l\u00edpidos[g]": 1.0,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.36,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 35.9,
    "a\u00e7\u00facares [g]": 8.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 27.0,
    "fibra  [g]": 5.5,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 54.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 58.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.08,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.45,
    "equivalentes de niacina [mg]": 0.99,
    "triptofano/60 [mg]": 0.54,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 45.9,
    "folatos [\u00b5g]": 55.0,
    "cinza [g]": 0.991,
    "s\u00f3dio [mg]": 8.1,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 57.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Castanha de caju torrada  e salgada",
    "energia[kcal]": 613.0,
    "energia[kj]": 2540.0,
    "l\u00edpidos[g]": 50.0,
    "\u00e1cidos gordos saturados[g]": 9.9,
    "\u00e1cidos gordos monoinsaturados [g]": 28.9,
    "\u00e1cidos gordos polinsaturados [g]": 8.9,
    "\u00e1cido linoleico [g]": 8.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 19.4,
    "a\u00e7\u00facares [g]": 5.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 13.5,
    "fibra  [g]": 3.3,
    "prote\u00ednas [g]": 19.6,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.42,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 6.5,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 68.0,
    "cinza [g]": 4.5,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 700.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 490.0,
    "magn\u00e9sio [mg]": 250.0,
    "ferro [mg]": 5.7,
    "zinco [mg]": 5.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Castanha de caju torrada, sem sal",
    "energia[kcal]": 613.0,
    "energia[kj]": 2540.0,
    "l\u00edpidos[g]": 50.0,
    "\u00e1cidos gordos saturados[g]": 9.9,
    "\u00e1cidos gordos monoinsaturados [g]": 28.9,
    "\u00e1cidos gordos polinsaturados [g]": 8.9,
    "\u00e1cido linoleico [g]": 8.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 19.4,
    "a\u00e7\u00facares [g]": 5.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 13.5,
    "fibra  [g]": 3.3,
    "prote\u00ednas [g]": 19.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.42,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 6.5,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 68.0,
    "cinza [g]": 2.5,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 700.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 490.0,
    "magn\u00e9sio [mg]": 250.0,
    "ferro [mg]": 5.7,
    "zinco [mg]": 5.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Castanha do Brasil",
    "energia[kcal]": 690.0,
    "energia[kj]": 2840.0,
    "l\u00edpidos[g]": 66.7,
    "\u00e1cidos gordos saturados[g]": 16.0,
    "\u00e1cidos gordos monoinsaturados [g]": 20.4,
    "\u00e1cidos gordos polinsaturados [g]": 27.4,
    "\u00e1cido linoleico [g]": 27.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 20.5,
    "prote\u00ednas [g]": 9.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.72,
    "tiamina [mg]": 0.24,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.69,
    "equivalentes de niacina [mg]": 3.53,
    "triptofano/60 [mg]": 2.84,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.5,
    "cinza [g]": 3.5,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 650.0,
    "c\u00e1lcio [mg]": 170.0,
    "f\u00f3sforo [mg]": 710.0,
    "magn\u00e9sio [mg]": 380.0,
    "ferro [mg]": 2.5,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 180.0,
    "iodo [\u00b5g]": 10.0
  },
  {
    "name": "Castanha pilada",
    "energia[kcal]": 341.0,
    "energia[kj]": 1440.0,
    "l\u00edpidos[g]": 2.0,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 70.0,
    "a\u00e7\u00facares [g]": 17.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 52.8,
    "fibra  [g]": 11.3,
    "prote\u00ednas [g]": 5.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.2,
    "tiamina [mg]": 0.3,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.8,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.55,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 61.0,
    "cinza [g]": 2.05,
    "s\u00f3dio [mg]": 17.0,
    "pot\u00e1ssio [mg]": 930.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 61.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Castanha, miolo",
    "energia[kcal]": 194.0,
    "energia[kj]": 819.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 39.8,
    "a\u00e7\u00facares [g]": 9.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 30.0,
    "fibra  [g]": 6.1,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 64.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 51.0,
    "folatos [\u00b5g]": 61.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 500.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 63.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cavala cozida",
    "energia[kcal]": 192.0,
    "energia[kj]": 800.0,
    "l\u00edpidos[g]": 12.2,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 4.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.5,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 48.0,
    "vitamina a  [\u00b5g]": 21.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.3,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 8.6,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.75,
    "vitamina b12 [\u00b5g]": 12.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 42.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 31.0
  },
  {
    "name": "Cavala crua",
    "energia[kcal]": 202.0,
    "energia[kj]": 841.0,
    "l\u00edpidos[g]": 13.4,
    "\u00e1cidos gordos saturados[g]": 3.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.7,
    "\u00e1cidos gordos polinsaturados [g]": 4.7,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 45.0,
    "vitamina a  [\u00b5g]": 28.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.4,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 9.0,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 1.0,
    "vitamina b12 [\u00b5g]": 14.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 78.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 39.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cavala em filetes, conserva em azeite",
    "energia[kcal]": 182.0,
    "energia[kj]": 763.0,
    "l\u00edpidos[g]": 9.6,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 6.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.0,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 35.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 1.9,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 17.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 620.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cavala grelhada",
    "energia[kcal]": 192.0,
    "energia[kj]": 799.0,
    "l\u00edpidos[g]": 12.2,
    "\u00e1cidos gordos saturados[g]": 3.3,
    "\u00e1cidos gordos monoinsaturados [g]": 3.4,
    "\u00e1cidos gordos polinsaturados [g]": 4.3,
    "\u00e1cido linoleico [g]": 0.211,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 18.5,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.1,
    "\u00e1cidos org\u00e2nicos [g]": 1.3,
    "colesterol [mg]": 41.0,
    "vitamina a  [\u00b5g]": 26.0,
    "caroteno [\u00b5g]": 3.0,
    "vitamina d [\u00b5g]": 2.2,
    "alfa-tocoferol [mg]": 1.34,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 8.2,
    "equivalentes de niacina [mg]": 11.8,
    "triptofano/60 [mg]": 3.46,
    "vitamina b6 [mg]": 0.92,
    "vitamina b12 [\u00b5g]": 13.0,
    "vitamina c [mg]": 15.4,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.09,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 44.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cavala no forno",
    "energia[kcal]": 192.0,
    "energia[kj]": 796.0,
    "l\u00edpidos[g]": 14.3,
    "\u00e1cidos gordos saturados[g]": 3.1,
    "\u00e1cidos gordos monoinsaturados [g]": 7.0,
    "\u00e1cidos gordos polinsaturados [g]": 3.4,
    "\u00e1cido linoleico [g]": 0.567,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 2.3,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.6,
    "amido [g]": 0.2,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 12.9,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 27.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.4,
    "alfa-tocoferol [mg]": 1.83,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 8.33,
    "triptofano/60 [mg]": 2.38,
    "vitamina b6 [mg]": 0.74,
    "vitamina b12 [\u00b5g]": 8.5,
    "vitamina c [mg]": 5.5,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 2.09,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cebola cozida",
    "energia[kcal]": 18.0,
    "energia[kj]": 76.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.7,
    "amido [g]": 0.0,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 94.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.15,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 33.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Cebola crua",
    "energia[kcal]": 20.0,
    "energia[kj]": 86.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.1,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.9,
    "amido [g]": 0.0,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 31.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Cebola doce, crua",
    "energia[kcal]": 25.0,
    "energia[kj]": 106.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.9,
    "a\u00e7\u00facares [g]": 4.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.0,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.35,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 31.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Cebola frita com \u00f3leo alimentar",
    "energia[kcal]": 138.0,
    "energia[kj]": 573.0,
    "l\u00edpidos[g]": 11.2,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 7.2,
    "\u00e1cido linoleico [g]": 7.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.2,
    "a\u00e7\u00facares [g]": 4.4,
    "oligossac\u00e1ridos [g]": 1.8,
    "amido [g]": 0.0,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 1.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.3,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 10.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 40.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 62.0,
    "f\u00f3sforo [mg]": 60.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Cebola roxa, crua",
    "energia[kcal]": 43.0,
    "energia[kj]": 180.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.6,
    "a\u00e7\u00facares [g]": 5.9,
    "oligossac\u00e1ridos [g]": 0.9,
    "amido [g]": 0.0,
    "fibra  [g]": 2.7,
    "prote\u00ednas [g]": 0.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.47,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 31.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Cebolinho fresco",
    "energia[kcal]": 28.0,
    "energia[kj]": 116.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.36,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 567.0,
    "caroteno [\u00b5g]": 3402.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.6,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.07,
    "triptofano/60 [mg]": 0.47,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 41.0,
    "folatos [\u00b5g]": 64.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 77.0,
    "f\u00f3sforo [mg]": 51.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cenoura baby crua",
    "energia[kcal]": 29.0,
    "energia[kj]": 122.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.9,
    "a\u00e7\u00facares [g]": 4.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.0,
    "fibra  [g]": 2.9,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1065.0,
    "caroteno [\u00b5g]": 6391.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.56,
    "equivalentes de niacina [mg]": 0.66,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.6,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 0.63,
    "s\u00f3dio [mg]": 78.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 28.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.9,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Cenoura cozida",
    "energia[kcal]": 23.0,
    "energia[kj]": 97.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.6,
    "a\u00e7\u00facares [g]": 3.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.2,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 0.7,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 963.0,
    "caroteno [\u00b5g]": 5780.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 34.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Cenoura crua",
    "energia[kcal]": 25.0,
    "energia[kj]": 106.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.4,
    "a\u00e7\u00facares [g]": 4.1,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.2,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 933.0,
    "caroteno [\u00b5g]": 5600.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 28.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 58.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 41.0,
    "f\u00f3sforo [mg]": 33.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Cereal de pequeno almo\u00e7o \u00e0 base de farelo de trigo (tipo \"All-Bran\")",
    "energia[kcal]": 310.0,
    "energia[kj]": 1300.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 1.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 39.8,
    "a\u00e7\u00facares [g]": 14.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 25.1,
    "fibra  [g]": 30.0,
    "prote\u00ednas [g]": 15.1,
    "sal  [g]": 3.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.8,
    "alfa-tocoferol [mg]": 2.2,
    "tiamina [mg]": 1.0,
    "riboflavina [mg]": 1.5,
    "niacina [mg]": 16.0,
    "equivalentes de niacina [mg]": 19.0,
    "triptofano/60 [mg]": 3.2,
    "vitamina b6 [mg]": 1.8,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 250.0,
    "cinza [g]": 6.5,
    "s\u00f3dio [mg]": 1480.0,
    "pot\u00e1ssio [mg]": 900.0,
    "c\u00e1lcio [mg]": 69.0,
    "f\u00f3sforo [mg]": 620.0,
    "magn\u00e9sio [mg]": 370.0,
    "ferro [mg]": 12.0,
    "zinco [mg]": 8.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Cereal de pequeno almo\u00e7o de trigo integral tipo \"Weetabix\"",
    "energia[kcal]": 364.0,
    "energia[kj]": 1540.0,
    "l\u00edpidos[g]": 2.0,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 71.5,
    "a\u00e7\u00facares [g]": 6.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 65.4,
    "fibra  [g]": 8.5,
    "prote\u00ednas [g]": 10.7,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.7,
    "riboflavina [mg]": 1.0,
    "niacina [mg]": 10.0,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 2.1,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 50.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 6.0,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Ceref\u00f3lio fresco",
    "energia[kcal]": 80.0,
    "energia[kj]": 335.0,
    "l\u00edpidos[g]": 1.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 12.0,
    "fibra  [g]": 3.3,
    "prote\u00ednas [g]": 4.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 245.0,
    "caroteno [\u00b5g]": 1470.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 3.0,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.34,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 35.0,
    "folatos [\u00b5g]": 44.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 600.0,
    "c\u00e1lcio [mg]": 260.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cereja (4 variedades)",
    "energia[kcal]": 67.0,
    "energia[kj]": 284.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 13.3,
    "a\u00e7\u00facares [g]": 13.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 24.0,
    "caroteno [\u00b5g]": 141.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.43,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cereja desidratada",
    "energia[kcal]": 366.0,
    "energia[kj]": 1550.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 72.6,
    "a\u00e7\u00facares [g]": 72.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 8.7,
    "prote\u00ednas [g]": 4.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 2.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.71,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 1.09,
    "equivalentes de niacina [mg]": 1.64,
    "triptofano/60 [mg]": 0.55,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 27.3,
    "cinza [g]": 2.35,
    "s\u00f3dio [mg]": 5.5,
    "pot\u00e1ssio [mg]": 1150.0,
    "c\u00e1lcio [mg]": 76.0,
    "f\u00f3sforo [mg]": 82.0,
    "magn\u00e9sio [mg]": 55.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cereja, conserva em calda de a\u00e7\u00facar",
    "energia[kcal]": 120.0,
    "energia[kj]": 510.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 28.7,
    "a\u00e7\u00facares [g]": 28.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 32.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.27,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 12.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cereja, cristalizada",
    "energia[kcal]": 326.0,
    "energia[kj]": 1390.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 79.9,
    "a\u00e7\u00facares [g]": 79.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 17.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 39.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.41,
    "s\u00f3dio [mg]": 27.0,
    "pot\u00e1ssio [mg]": 24.0,
    "c\u00e1lcio [mg]": 38.0,
    "f\u00f3sforo [mg]": 8.0,
    "magn\u00e9sio [mg]": 0.1,
    "ferro [mg]": 1.8,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cerveja branca",
    "energia[kcal]": 30.0,
    "energia[kj]": 123.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.5,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 3.7,
    "\u00e1gua [g]": 91.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.14,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.1,
    "cinza [g]": 0.16,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 52.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cerveja preta",
    "energia[kcal]": 23.0,
    "energia[kj]": 94.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 2.6,
    "\u00e1gua [g]": 93.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.15,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.5,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 14.0,
    "pot\u00e1ssio [mg]": 65.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cerveja sem \u00e1lcool",
    "energia[kcal]": 7.0,
    "energia[kj]": 31.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.5,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 96.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 44.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ch\u00e1, infus\u00e3o, preto",
    "energia[kcal]": 0.0,
    "energia[kj]": 2.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 99.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.04,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 11.0,
    "c\u00e1lcio [mg]": 1.0,
    "f\u00f3sforo [mg]": 1.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ch\u00e1, infus\u00e3o, verde",
    "energia[kcal]": 0.0,
    "energia[kj]": 2.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 99.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 0.1,
    "cinza [g]": 0.03,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 10.0,
    "c\u00e1lcio [mg]": 2.0,
    "f\u00f3sforo [mg]": 1.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chamb\u00e3o de vitela cru",
    "energia[kcal]": 195.0,
    "energia[kj]": 814.0,
    "l\u00edpidos[g]": 12.6,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 6.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 77.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.17,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 3.6,
    "equivalentes de niacina [mg]": 7.8,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.7,
    "cinza [g]": 1.12,
    "s\u00f3dio [mg]": 25.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 25.0
  },
  {
    "name": "Chamu\u00e7a",
    "energia[kcal]": 344.0,
    "energia[kj]": 1440.0,
    "l\u00edpidos[g]": 16.3,
    "\u00e1cidos gordos saturados[g]": 7.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.8,
    "\u00e1cidos gordos trans [g]": 1.5,
    "hidratos de carbono [g]": 39.3,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 37.9,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 9.1,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 33.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 14.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 8.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 2.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 2.5,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.33,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 8.6,
    "cinza [g]": 1.15,
    "s\u00f3dio [mg]": 550.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cherne cozido",
    "energia[kcal]": 124.0,
    "energia[kj]": 521.0,
    "l\u00edpidos[g]": 5.4,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.9,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 52.0,
    "vitamina a  [\u00b5g]": 43.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.1,
    "alfa-tocoferol [mg]": 2.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.62,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.7,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cherne cru",
    "energia[kcal]": 132.0,
    "energia[kj]": 552.0,
    "l\u00edpidos[g]": 6.7,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 47.0,
    "vitamina a  [\u00b5g]": 55.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 6.6,
    "alfa-tocoferol [mg]": 2.6,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 5.1,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cherne grelhado",
    "energia[kcal]": 153.0,
    "energia[kj]": 643.0,
    "l\u00edpidos[g]": 6.3,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 2.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.1,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 63.0,
    "vitamina a  [\u00b5g]": 62.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 7.5,
    "alfa-tocoferol [mg]": 3.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 6.8,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.89,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.8,
    "cinza [g]": 2.5,
    "s\u00f3dio [mg]": 480.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chicharro cozido",
    "energia[kcal]": 111.0,
    "energia[kj]": 470.0,
    "l\u00edpidos[g]": 3.0,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.1,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 41.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 4.2,
    "alfa-tocoferol [mg]": 0.42,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 8.1,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 5.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 78.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chicharro cru",
    "energia[kcal]": 105.0,
    "energia[kj]": 442.0,
    "l\u00edpidos[g]": 2.9,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 36.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 4.1,
    "alfa-tocoferol [mg]": 0.37,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 8.7,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 5.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 80.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 69.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chicharro grelhado",
    "energia[kcal]": 139.0,
    "energia[kj]": 584.0,
    "l\u00edpidos[g]": 3.7,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 48.0,
    "vitamina a  [\u00b5g]": 21.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 3.5,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 5.9,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 6.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 470.0,
    "c\u00e1lcio [mg]": 61.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chic\u00f3ria crua",
    "energia[kcal]": 14.0,
    "energia[kj]": 57.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.9,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 95.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 120.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.37,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 23.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chispe de porco",
    "energia[kcal]": 369.0,
    "energia[kj]": 1530.0,
    "l\u00edpidos[g]": 33.8,
    "\u00e1cidos gordos saturados[g]": 14.6,
    "\u00e1cidos gordos monoinsaturados [g]": 13.9,
    "\u00e1cidos gordos polinsaturados [g]": 3.8,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.65,
    "tiamina [mg]": 0.43,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 11.27,
    "triptofano/60 [mg]": 6.97,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.5,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 71.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Choco cru",
    "energia[kcal]": 79.0,
    "energia[kj]": 336.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.9,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.94,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 5.1,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.8,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 49.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Choco grelhado",
    "energia[kcal]": 109.0,
    "energia[kj]": 461.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.8,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 110.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 6.9,
    "triptofano/60 [mg]": 5.5,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 1.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.8,
    "s\u00f3dio [mg]": 530.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 350.0,
    "magn\u00e9sio [mg]": 64.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chocolate de leite",
    "energia[kcal]": 552.0,
    "energia[kj]": 2300.0,
    "l\u00edpidos[g]": 33.9,
    "\u00e1cidos gordos saturados[g]": 19.8,
    "\u00e1cidos gordos monoinsaturados [g]": 10.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 53.1,
    "a\u00e7\u00facares [g]": 53.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 8.0,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 1.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 40.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.74,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.46,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 3.3,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.06,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 190.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 55.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chocolate em barra, culin\u00e1ria",
    "energia[kcal]": 502.0,
    "energia[kj]": 2090.0,
    "l\u00edpidos[g]": 30.5,
    "\u00e1cidos gordos saturados[g]": 19.3,
    "\u00e1cidos gordos monoinsaturados [g]": 10.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 44.0,
    "a\u00e7\u00facares [g]": 44.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 15.0,
    "prote\u00ednas [g]": 5.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 38.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 17.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 43.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 110.0,
    "ferro [mg]": 2.9,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chocolate em p\u00f3",
    "energia[kcal]": 469.0,
    "energia[kj]": 1970.0,
    "l\u00edpidos[g]": 20.3,
    "\u00e1cidos gordos saturados[g]": 12.8,
    "\u00e1cidos gordos monoinsaturados [g]": 6.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 63.8,
    "a\u00e7\u00facares [g]": 60.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.3,
    "fibra  [g]": 7.3,
    "prote\u00ednas [g]": 4.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 39.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.27,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 3.0,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 42.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 100.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chocolate negro (aproximadamente 50% cacau)",
    "energia[kcal]": 530.0,
    "energia[kj]": 2210.0,
    "l\u00edpidos[g]": 31.7,
    "\u00e1cidos gordos saturados[g]": 19.9,
    "\u00e1cidos gordos monoinsaturados [g]": 10.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 52.6,
    "a\u00e7\u00facares [g]": 49.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.7,
    "fibra  [g]": 7.2,
    "prote\u00ednas [g]": 5.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 21.0,
    "vitamina a  [\u00b5g]": 19.0,
    "caroteno [\u00b5g]": 38.0,
    "vitamina d [\u00b5g]": 1.4,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.65,
    "equivalentes de niacina [mg]": 1.45,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.5,
    "cinza [g]": 0.981,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 630.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 130.0,
    "ferro [mg]": 5.3,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 5.0,
    "iodo [\u00b5g]": 1.2
  },
  {
    "name": "Chouri\u00e7o de carne de porco, cru",
    "energia[kcal]": 476.0,
    "energia[kj]": 1970.0,
    "l\u00edpidos[g]": 44.1,
    "\u00e1cidos gordos saturados[g]": 15.2,
    "\u00e1cidos gordos monoinsaturados [g]": 17.4,
    "\u00e1cidos gordos polinsaturados [g]": 5.1,
    "\u00e1cido linoleico [g]": 4.35,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.0,
    "sal  [g]": 6.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 27.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.54,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 7.75,
    "triptofano/60 [mg]": 3.25,
    "vitamina b6 [mg]": 0.33,
    "vitamina b12 [\u00b5g]": 1.75,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 8.6,
    "s\u00f3dio [mg]": 2470.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 2.6,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Chouri\u00e7o de carne de porco, gordo, cru",
    "energia[kcal]": 544.0,
    "energia[kj]": 2250.0,
    "l\u00edpidos[g]": 53.6,
    "\u00e1cidos gordos saturados[g]": 18.5,
    "\u00e1cidos gordos monoinsaturados [g]": 21.2,
    "\u00e1cidos gordos polinsaturados [g]": 6.2,
    "\u00e1cido linoleico [g]": 5.3,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 15.4,
    "sal  [g]": 6.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 23.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 96.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.38,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 7.7,
    "s\u00f3dio [mg]": 2630.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 2.5,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Chouri\u00e7o de carne de porco, magro, cozido sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 334.0,
    "energia[kj]": 1390.0,
    "l\u00edpidos[g]": 26.1,
    "\u00e1cidos gordos saturados[g]": 9.0,
    "\u00e1cidos gordos monoinsaturados [g]": 10.3,
    "\u00e1cidos gordos polinsaturados [g]": 3.0,
    "\u00e1cido linoleico [g]": 2.6,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.8,
    "sal  [g]": 5.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 41.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.55,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 5.4,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.7,
    "cinza [g]": 8.0,
    "s\u00f3dio [mg]": 2060.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 2.8,
    "zinco [mg]": 3.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chouri\u00e7o de carne de porco, magro, cru",
    "energia[kcal]": 409.0,
    "energia[kj]": 1690.0,
    "l\u00edpidos[g]": 34.5,
    "\u00e1cidos gordos saturados[g]": 11.9,
    "\u00e1cidos gordos monoinsaturados [g]": 13.6,
    "\u00e1cidos gordos polinsaturados [g]": 4.0,
    "\u00e1cido linoleico [g]": 3.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.5,
    "sal  [g]": 5.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 32.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.69,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 6.3,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 9.5,
    "s\u00f3dio [mg]": 2300.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 2.7,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Chouri\u00e7o de carne de porco, magro, grelhado sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 343.0,
    "energia[kj]": 1430.0,
    "l\u00edpidos[g]": 24.1,
    "\u00e1cidos gordos saturados[g]": 8.3,
    "\u00e1cidos gordos monoinsaturados [g]": 9.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 31.6,
    "sal  [g]": 6.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 33.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 84.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.68,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 5.6,
    "equivalentes de niacina [mg]": 7.3,
    "triptofano/60 [mg]": 1.7,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.1,
    "cinza [g]": 11.2,
    "s\u00f3dio [mg]": 2720.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 39.0,
    "f\u00f3sforo [mg]": 350.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 3.2,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chouri\u00e7o de sangue cozido sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 263.0,
    "energia[kj]": 1090.0,
    "l\u00edpidos[g]": 24.2,
    "\u00e1cidos gordos saturados[g]": 9.4,
    "\u00e1cidos gordos monoinsaturados [g]": 10.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.6,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 11.2,
    "sal  [g]": 2.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 111.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 3.0,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.2,
    "cinza [g]": 2.7,
    "s\u00f3dio [mg]": 1000.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 43.0,
    "f\u00f3sforo [mg]": 70.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 14.0,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chouri\u00e7o de sangue, cru",
    "energia[kcal]": 328.0,
    "energia[kj]": 1360.0,
    "l\u00edpidos[g]": 31.6,
    "\u00e1cidos gordos saturados[g]": 12.3,
    "\u00e1cidos gordos monoinsaturados [g]": 14.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.6,
    "\u00e1cido linoleico [g]": 2.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 11.0,
    "sal  [g]": 2.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 54.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 110.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.15,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 3.1,
    "triptofano/60 [mg]": 2.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.6,
    "cinza [g]": 3.2,
    "s\u00f3dio [mg]": 1100.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 40.0,
    "f\u00f3sforo [mg]": 70.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 14.0,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Chuchu",
    "energia[kcal]": 19.0,
    "energia[kj]": 80.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.04,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.9,
    "a\u00e7\u00facares [g]": 2.9,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 0.0,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 94.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 30.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.51,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.47,
    "equivalentes de niacina [mg]": 0.65,
    "triptofano/60 [mg]": 0.18,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 7.7,
    "folatos [\u00b5g]": 93.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 18.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Chucrute",
    "energia[kcal]": 15.0,
    "energia[kj]": 61.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.1,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 1.3,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 18.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 15.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 1.76,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 49.0,
    "f\u00f3sforo [mg]": 33.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Clara de ovo de galinha, crua",
    "energia[kcal]": 47.0,
    "energia[kj]": 198.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 11.0,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 0.03,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 3.2,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Clara de ovo de galinha, pasteurizada",
    "energia[kcal]": 46.0,
    "energia[kj]": 196.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 10.5,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.29,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 2.77,
    "triptofano/60 [mg]": 2.67,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 11.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Clementina",
    "energia[kcal]": 53.0,
    "energia[kj]": 223.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.1,
    "a\u00e7\u00facares [g]": 11.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 75.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.33,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 40.0,
    "folatos [\u00b5g]": 32.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 29.0,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Coco seco, ralado",
    "energia[kcal]": 648.0,
    "energia[kj]": 2670.0,
    "l\u00edpidos[g]": 62.0,
    "\u00e1cidos gordos saturados[g]": 53.3,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.4,
    "a\u00e7\u00facares [g]": 6.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 21.1,
    "prote\u00ednas [g]": 5.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 2.0,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 28.0,
    "pot\u00e1ssio [mg]": 660.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 90.0,
    "ferro [mg]": 3.6,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Codorniz carne sem pele, crua ",
    "energia[kcal]": 119.0,
    "energia[kj]": 502.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 9.1,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 5.8,
    "vitamina b6 [mg]": 0.56,
    "vitamina b12 [\u00b5g]": 0.47,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 45.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 3.1,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Codorniz com pele, crua",
    "energia[kcal]": 170.0,
    "energia[kj]": 710.0,
    "l\u00edpidos[g]": 9.3,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 1.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.5,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 72.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 7.5,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.6,
    "vitamina b12 [\u00b5g]": 0.43,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 47.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 2.7,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Codorniz com pele, estufada com azeite",
    "energia[kcal]": 234.0,
    "energia[kj]": 976.0,
    "l\u00edpidos[g]": 13.4,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.29,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.3,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 25.6,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 1.1,
    "\u00e1gua [g]": 56.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 71.0,
    "vitamina a  [\u00b5g]": 85.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.47,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 5.4,
    "equivalentes de niacina [mg]": 15.5,
    "triptofano/60 [mg]": 6.18,
    "vitamina b6 [mg]": 0.45,
    "vitamina b12 [\u00b5g]": 0.25,
    "vitamina c [mg]": 0.8,
    "folatos [\u00b5g]": 5.5,
    "cinza [g]": 2.32,
    "s\u00f3dio [mg]": 500.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 2.7,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Codorniz com pele, grelhada",
    "energia[kcal]": 202.0,
    "energia[kj]": 846.0,
    "l\u00edpidos[g]": 11.6,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.5,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 86.0,
    "vitamina a  [\u00b5g]": 68.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 7.7,
    "equivalentes de niacina [mg]": 14.0,
    "triptofano/60 [mg]": 6.1,
    "vitamina b6 [mg]": 0.46,
    "vitamina b12 [\u00b5g]": 0.36,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.1,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 4.8,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Coelho \u00e0 ca\u00e7ador",
    "energia[kcal]": 182.0,
    "energia[kj]": 757.0,
    "l\u00edpidos[g]": 12.7,
    "\u00e1cidos gordos saturados[g]": 3.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.57,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.6,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 12.3,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 1.5,
    "\u00e1gua [g]": 69.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 33.0,
    "vitamina a  [\u00b5g]": 35.0,
    "caroteno [\u00b5g]": 207.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.84,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 2.23,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 4.9,
    "vitamina c [mg]": 12.4,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 29.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Coelho cru",
    "energia[kcal]": 117.0,
    "energia[kj]": 493.0,
    "l\u00edpidos[g]": 4.0,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 48.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 7.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.5,
    "vitamina b12 [\u00b5g]": 9.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 58.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Coelho em vinha de alhos",
    "energia[kcal]": 174.0,
    "energia[kj]": 722.0,
    "l\u00edpidos[g]": 11.6,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 6.5,
    "\u00e1cido linoleico [g]": 6.41,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.4,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 0.1,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 11.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 2.5,
    "\u00e1gua [g]": 71.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 26.0,
    "vitamina a  [\u00b5g]": 46.0,
    "caroteno [\u00b5g]": 274.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 6.08,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 4.55,
    "triptofano/60 [mg]": 2.08,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 4.8,
    "vitamina c [mg]": 4.5,
    "folatos [\u00b5g]": 9.1,
    "cinza [g]": 1.08,
    "s\u00f3dio [mg]": 40.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Coelho estufado",
    "energia[kcal]": 211.0,
    "energia[kj]": 884.0,
    "l\u00edpidos[g]": 10.2,
    "\u00e1cidos gordos saturados[g]": 1.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.7,
    "\u00e1cido linoleico [g]": 1.52,
    "\u00e1cidos gordos trans [g]": 1.7,
    "hidratos de carbono [g]": 0.9,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 26.6,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 57.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 62.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.64,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 10.3,
    "triptofano/60 [mg]": 4.96,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 7.0,
    "vitamina c [mg]": 1.4,
    "folatos [\u00b5g]": 7.1,
    "cinza [g]": 3.06,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Coelho estufado com azeite",
    "energia[kcal]": 218.0,
    "energia[kj]": 912.0,
    "l\u00edpidos[g]": 11.0,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.16,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.9,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 26.6,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 57.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 62.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 1.04,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 10.3,
    "triptofano/60 [mg]": 4.95,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 7.0,
    "vitamina c [mg]": 1.4,
    "folatos [\u00b5g]": 7.1,
    "cinza [g]": 2.96,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Coelho estufado com margarina",
    "energia[kcal]": 205.0,
    "energia[kj]": 856.0,
    "l\u00edpidos[g]": 9.5,
    "\u00e1cidos gordos saturados[g]": 3.8,
    "\u00e1cidos gordos monoinsaturados [g]": 2.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 1.87,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.9,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 26.6,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 58.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 63.0,
    "vitamina a  [\u00b5g]": 32.0,
    "caroteno [\u00b5g]": 21.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 10.3,
    "triptofano/60 [mg]": 4.96,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 7.0,
    "vitamina c [mg]": 1.4,
    "folatos [\u00b5g]": 7.1,
    "cinza [g]": 3.15,
    "s\u00f3dio [mg]": 550.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Coentros crus",
    "energia[kcal]": 28.0,
    "energia[kj]": 117.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.8,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 2.9,
    "prote\u00ednas [g]": 2.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 102.0,
    "caroteno [\u00b5g]": 610.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 63.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 28.0,
    "pot\u00e1ssio [mg]": 540.0,
    "c\u00e1lcio [mg]": 98.0,
    "f\u00f3sforo [mg]": 36.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cogumelos crus",
    "energia[kcal]": 18.0,
    "energia[kj]": 76.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.5,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 3.2,
    "equivalentes de niacina [mg]": 3.5,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 44.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 80.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cogumelos enlatados, escorridos",
    "energia[kcal]": 17.0,
    "energia[kj]": 72.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.7,
    "prote\u00ednas [g]": 2.1,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 61.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.6
  },
  {
    "name": "Cogumelos fritos com \u00f3leo alimentar",
    "energia[kcal]": 163.0,
    "energia[kj]": 669.0,
    "l\u00edpidos[g]": 16.2,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.4,
    "\u00e1cidos gordos polinsaturados [g]": 10.4,
    "\u00e1cido linoleico [g]": 10.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 2.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.9,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.34,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.6
  },
  {
    "name": "Colorau",
    "energia[kcal]": 358.0,
    "energia[kj]": 1490.0,
    "l\u00edpidos[g]": 13.0,
    "\u00e1cidos gordos saturados[g]": 2.1,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 8.4,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 35.0,
    "a\u00e7\u00facares [g]": 33.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.8,
    "fibra  [g]": 20.9,
    "prote\u00ednas [g]": 14.8,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 6042.0,
    "caroteno [\u00b5g]": 18126.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.65,
    "riboflavina [mg]": 1.7,
    "niacina [mg]": 15.0,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 3.4,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 7.7,
    "s\u00f3dio [mg]": 34.0,
    "pot\u00e1ssio [mg]": 2340.0,
    "c\u00e1lcio [mg]": 180.0,
    "f\u00f3sforo [mg]": 350.0,
    "magn\u00e9sio [mg]": 190.0,
    "ferro [mg]": 24.0,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cominhos",
    "energia[kcal]": 427.0,
    "energia[kj]": 1780.0,
    "l\u00edpidos[g]": 22.0,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 8.6,
    "\u00e1cidos gordos polinsaturados [g]": 10.6,
    "\u00e1cido linoleico [g]": 10.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 34.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 34.0,
    "fibra  [g]": 10.5,
    "prote\u00ednas [g]": 18.0,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 18.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 130.0,
    "caroteno [\u00b5g]": 762.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 3.33,
    "tiamina [mg]": 0.63,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 13.1,
    "triptofano/60 [mg]": 8.5,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.83,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 1790.0,
    "c\u00e1lcio [mg]": 930.0,
    "f\u00f3sforo [mg]": 500.0,
    "magn\u00e9sio [mg]": 370.0,
    "ferro [mg]": 66.0,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Compota de ameixa",
    "energia[kcal]": 200.0,
    "energia[kj]": 849.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 49.2,
    "a\u00e7\u00facares [g]": 49.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 49.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 50.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.22,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 72.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Compota de anan\u00e1s",
    "energia[kcal]": 211.0,
    "energia[kj]": 895.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 50.8,
    "a\u00e7\u00facares [g]": 50.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 46.8,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.49,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 86.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Compota de cereja",
    "energia[kcal]": 251.0,
    "energia[kj]": 1070.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 61.9,
    "a\u00e7\u00facares [g]": 61.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 36.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.18,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 94.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Compota de damasco",
    "energia[kcal]": 239.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 58.9,
    "a\u00e7\u00facares [g]": 58.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 39.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.35,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 86.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 21.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Compota de laranja",
    "energia[kcal]": 242.0,
    "energia[kj]": 1030.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 59.5,
    "a\u00e7\u00facares [g]": 59.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 38.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 80.0,
    "caroteno [\u00b5g]": 50.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 11.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.29,
    "s\u00f3dio [mg]": 14.0,
    "pot\u00e1ssio [mg]": 46.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 12.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Condimento de mostarda",
    "energia[kcal]": 158.0,
    "energia[kj]": 662.0,
    "l\u00edpidos[g]": 6.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.5,
    "a\u00e7\u00facares [g]": 14.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.0,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 5.7,
    "sal  [g]": 1.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.8,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.8,
    "cinza [g]": 1.26,
    "s\u00f3dio [mg]": 760.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cora\u00e7\u00e3o de galinha cru",
    "energia[kcal]": 149.0,
    "energia[kj]": 621.0,
    "l\u00edpidos[g]": 9.3,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.7,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 15.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 136.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.37,
    "tiamina [mg]": 0.31,
    "riboflavina [mg]": 0.73,
    "niacina [mg]": 4.88,
    "equivalentes de niacina [mg]": 8.21,
    "triptofano/60 [mg]": 3.33,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 7.29,
    "vitamina c [mg]": 3.2,
    "folatos [\u00b5g]": 45.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 64.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 6.0,
    "zinco [mg]": 6.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cora\u00e7\u00e3o de porco, cru",
    "energia[kcal]": 113.0,
    "energia[kj]": 476.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.4,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 92.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.37,
    "tiamina [mg]": 0.52,
    "riboflavina [mg]": 0.97,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 8.9,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.31,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 1.18,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 2.7,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cora\u00e7\u00e3o de porco, estufado com banha e margarina",
    "energia[kcal]": 193.0,
    "energia[kj]": 805.0,
    "l\u00edpidos[g]": 10.5,
    "\u00e1cidos gordos saturados[g]": 3.8,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.63,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.0,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 20.9,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 1.4,
    "\u00e1gua [g]": 63.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 106.0,
    "vitamina a  [\u00b5g]": 117.0,
    "caroteno [\u00b5g]": 542.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.55,
    "tiamina [mg]": 0.44,
    "riboflavina [mg]": 0.99,
    "niacina [mg]": 4.2,
    "equivalentes de niacina [mg]": 10.2,
    "triptofano/60 [mg]": 4.41,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 4.8,
    "cinza [g]": 2.73,
    "s\u00f3dio [mg]": 570.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cora\u00e7\u00e3o de vaca, cozido",
    "energia[kcal]": 123.0,
    "energia[kj]": 520.0,
    "l\u00edpidos[g]": 3.3,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.4,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 128.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.43,
    "riboflavina [mg]": 0.49,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 8.8,
    "triptofano/60 [mg]": 5.0,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 12.0,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 3.9,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 7.6,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cora\u00e7\u00e3o de vaca, cru",
    "energia[kcal]": 92.0,
    "energia[kj]": 389.0,
    "l\u00edpidos[g]": 2.7,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.0,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.45,
    "tiamina [mg]": 0.4,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 8.6,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 13.0,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 1.04,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 5.0,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cora\u00e7\u00e3o de vaca, estufado com banha e margarina",
    "energia[kcal]": 154.0,
    "energia[kj]": 643.0,
    "l\u00edpidos[g]": 7.8,
    "\u00e1cidos gordos saturados[g]": 3.0,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.981,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.9,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 17.7,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 69.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 96.0,
    "vitamina a  [\u00b5g]": 105.0,
    "caroteno [\u00b5g]": 496.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.58,
    "tiamina [mg]": 0.32,
    "riboflavina [mg]": 0.38,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 9.02,
    "triptofano/60 [mg]": 3.73,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 7.0,
    "vitamina c [mg]": 6.3,
    "folatos [\u00b5g]": 5.5,
    "cinza [g]": 2.35,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 4.9,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cora\u00e7\u00e3o de vitela, cru",
    "energia[kcal]": 100.0,
    "energia[kj]": 419.0,
    "l\u00edpidos[g]": 3.6,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.8,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.45,
    "tiamina [mg]": 0.5,
    "riboflavina [mg]": 0.49,
    "niacina [mg]": 6.5,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 13.0,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 5.5,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Corvina cozida",
    "energia[kcal]": 95.0,
    "energia[kj]": 404.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.7,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 54.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 16.0,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 2.6,
    "equivalentes de niacina [mg]": 6.5,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 40.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Corvina crua",
    "energia[kcal]": 94.0,
    "energia[kj]": 399.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 16.0,
    "alfa-tocoferol [mg]": 0.45,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.7,
    "equivalentes de niacina [mg]": 6.5,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 56.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de cabrito crua",
    "energia[kcal]": 120.0,
    "energia[kj]": 506.0,
    "l\u00edpidos[g]": 2.7,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 6.6,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.1,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 94.0,
    "pot\u00e1ssio [mg]": 440.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 4.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de cabrito grelhada",
    "energia[kcal]": 151.0,
    "energia[kj]": 636.0,
    "l\u00edpidos[g]": 3.0,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 30.9,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.98,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.35,
    "niacina [mg]": 6.3,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.8,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.4,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 480.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 6.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de carneiro, crua",
    "energia[kcal]": 151.0,
    "energia[kj]": 631.0,
    "l\u00edpidos[g]": 7.5,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 66.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 4.8,
    "equivalentes de niacina [mg]": 8.7,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 88.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 4.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco gorda crua",
    "energia[kcal]": 355.0,
    "energia[kj]": 1470.0,
    "l\u00edpidos[g]": 31.8,
    "\u00e1cidos gordos saturados[g]": 10.9,
    "\u00e1cidos gordos monoinsaturados [g]": 10.6,
    "\u00e1cidos gordos polinsaturados [g]": 5.2,
    "\u00e1cido linoleico [g]": 4.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 50.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 74.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.63,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 5.5,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 1.02,
    "s\u00f3dio [mg]": 61.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco gorda grelhada",
    "energia[kcal]": 305.0,
    "energia[kj]": 1270.0,
    "l\u00edpidos[g]": 23.1,
    "\u00e1cidos gordos saturados[g]": 7.9,
    "\u00e1cidos gordos monoinsaturados [g]": 7.7,
    "\u00e1cidos gordos polinsaturados [g]": 3.8,
    "\u00e1cido linoleico [g]": 3.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.3,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 50.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 111.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.5,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.74,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 6.1,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.7,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco grelhada",
    "energia[kcal]": 212.0,
    "energia[kj]": 881.0,
    "l\u00edpidos[g]": 14.4,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 4.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.02,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 18.1,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.8,
    "\u00e1cidos org\u00e2nicos [g]": 1.6,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.68,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 5.9,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 3.83,
    "vitamina b6 [mg]": 0.39,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 18.5,
    "folatos [\u00b5g]": 8.5,
    "cinza [g]": 1.85,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco meio gorda crua",
    "energia[kcal]": 221.0,
    "energia[kj]": 921.0,
    "l\u00edpidos[g]": 15.8,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.6,
    "\u00e1cido linoleico [g]": 2.2,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.8,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 66.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.74,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 6.5,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.06,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco meio gorda estufada",
    "energia[kcal]": 277.0,
    "energia[kj]": 1150.0,
    "l\u00edpidos[g]": 20.5,
    "\u00e1cidos gordos saturados[g]": 3.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 3.9,
    "\u00e1cido linoleico [g]": 3.42,
    "\u00e1cidos gordos trans [g]": 3.9,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 21.1,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 53.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 70.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 3.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 1.07,
    "tiamina [mg]": 0.81,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 11.7,
    "triptofano/60 [mg]": 4.46,
    "vitamina b6 [mg]": 0.46,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 1.4,
    "folatos [\u00b5g]": 9.1,
    "cinza [g]": 2.22,
    "s\u00f3dio [mg]": 500.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco meio gorda estufada com azeite e banha",
    "energia[kcal]": 280.0,
    "energia[kj]": 1160.0,
    "l\u00edpidos[g]": 20.7,
    "\u00e1cidos gordos saturados[g]": 6.5,
    "\u00e1cidos gordos monoinsaturados [g]": 8.4,
    "\u00e1cidos gordos polinsaturados [g]": 3.1,
    "\u00e1cido linoleico [g]": 2.64,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 21.1,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 53.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 71.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.51,
    "tiamina [mg]": 0.36,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 11.7,
    "triptofano/60 [mg]": 4.46,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 6.2,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco meio gorda estufada com azeite e margarina",
    "energia[kcal]": 277.0,
    "energia[kj]": 1150.0,
    "l\u00edpidos[g]": 20.4,
    "\u00e1cidos gordos saturados[g]": 6.7,
    "\u00e1cidos gordos monoinsaturados [g]": 7.7,
    "\u00e1cidos gordos polinsaturados [g]": 3.3,
    "\u00e1cido linoleico [g]": 2.81,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 21.1,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 53.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 70.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.36,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 11.7,
    "triptofano/60 [mg]": 4.46,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 6.2,
    "cinza [g]": 2.25,
    "s\u00f3dio [mg]": 460.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco meio gorda estufada com margarina",
    "energia[kcal]": 272.0,
    "energia[kj]": 1130.0,
    "l\u00edpidos[g]": 19.9,
    "\u00e1cidos gordos saturados[g]": 7.3,
    "\u00e1cidos gordos monoinsaturados [g]": 6.3,
    "\u00e1cidos gordos polinsaturados [g]": 3.7,
    "\u00e1cido linoleico [g]": 3.14,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 21.1,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 54.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 70.0,
    "vitamina a  [\u00b5g]": 24.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.36,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 11.7,
    "triptofano/60 [mg]": 4.46,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 6.2,
    "cinza [g]": 2.32,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco meio gorda estufada com \u00f3leo alimentar e banha",
    "energia[kcal]": 280.0,
    "energia[kj]": 1160.0,
    "l\u00edpidos[g]": 20.7,
    "\u00e1cidos gordos saturados[g]": 6.4,
    "\u00e1cidos gordos monoinsaturados [g]": 7.0,
    "\u00e1cidos gordos polinsaturados [g]": 4.5,
    "\u00e1cido linoleico [g]": 4.02,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 21.1,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 53.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 71.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 1.64,
    "tiamina [mg]": 0.36,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 11.7,
    "triptofano/60 [mg]": 4.46,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 6.2,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco meio gorda estufada, sem molho",
    "energia[kcal]": 284.0,
    "energia[kj]": 1180.0,
    "l\u00edpidos[g]": 19.6,
    "\u00e1cidos gordos saturados[g]": 6.7,
    "\u00e1cidos gordos monoinsaturados [g]": 6.5,
    "\u00e1cidos gordos polinsaturados [g]": 3.2,
    "\u00e1cido linoleico [g]": 2.7,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.9,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 51.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.14,
    "tiamina [mg]": 0.49,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 4.7,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 5.7,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.88,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.2,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco meio gorda frita panada",
    "energia[kcal]": 341.0,
    "energia[kj]": 1420.0,
    "l\u00edpidos[g]": 24.1,
    "\u00e1cidos gordos saturados[g]": 6.5,
    "\u00e1cidos gordos monoinsaturados [g]": 7.2,
    "\u00e1cidos gordos polinsaturados [g]": 7.5,
    "\u00e1cido linoleico [g]": 7.04,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 8.6,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.3,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 22.1,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 113.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 4.8,
    "tiamina [mg]": 0.38,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 4.9,
    "equivalentes de niacina [mg]": 11.6,
    "triptofano/60 [mg]": 4.77,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.82,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.03,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de porco meio gorda grelhada",
    "energia[kcal]": 261.0,
    "energia[kj]": 1090.0,
    "l\u00edpidos[g]": 17.0,
    "\u00e1cidos gordos saturados[g]": 5.9,
    "\u00e1cidos gordos monoinsaturados [g]": 5.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 53.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 96.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.85,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 5.8,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.3,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Costeleta de vitela crua",
    "energia[kcal]": 121.0,
    "energia[kj]": 507.0,
    "l\u00edpidos[g]": 4.5,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 73.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 6.6,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.31,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.3,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 26.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de vitela frita com margarina",
    "energia[kcal]": 183.0,
    "energia[kj]": 764.0,
    "l\u00edpidos[g]": 9.9,
    "\u00e1cidos gordos saturados[g]": 4.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 1.29,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 35.0,
    "caroteno [\u00b5g]": 23.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.35,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 12.8,
    "triptofano/60 [mg]": 5.02,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.85,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.1,
    "cinza [g]": 3.21,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de vitela frita panada",
    "energia[kcal]": 148.0,
    "energia[kj]": 622.0,
    "l\u00edpidos[g]": 5.3,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.352,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 3.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.0,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 19.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 1.0,
    "\u00e1gua [g]": 68.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 114.0,
    "vitamina a  [\u00b5g]": 84.0,
    "caroteno [\u00b5g]": 360.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.76,
    "tiamina [mg]": 0.26,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 10.2,
    "triptofano/60 [mg]": 4.32,
    "vitamina b6 [mg]": 0.33,
    "vitamina b12 [\u00b5g]": 0.98,
    "vitamina c [mg]": 23.8,
    "folatos [\u00b5g]": 30.0,
    "cinza [g]": 2.06,
    "s\u00f3dio [mg]": 63.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 43.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta de vitela frita, sem molho",
    "energia[kcal]": 159.0,
    "energia[kj]": 668.0,
    "l\u00edpidos[g]": 6.9,
    "\u00e1cidos gordos saturados[g]": 3.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.3,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 6.8,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.5,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.8,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 4.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Costeleta de vitela grelhada",
    "energia[kcal]": 131.0,
    "energia[kj]": 551.0,
    "l\u00edpidos[g]": 3.3,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.2,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 101.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.22,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 5.9,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.4,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.97,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Costeleta ou perna de borrego assada com azeite e margarina",
    "energia[kcal]": 234.0,
    "energia[kj]": 973.0,
    "l\u00edpidos[g]": 15.6,
    "\u00e1cidos gordos saturados[g]": 5.5,
    "\u00e1cidos gordos monoinsaturados [g]": 7.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.53,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 80.0,
    "vitamina a  [\u00b5g]": 32.0,
    "caroteno [\u00b5g]": 22.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.87,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 4.4,
    "equivalentes de niacina [mg]": 10.3,
    "triptofano/60 [mg]": 4.37,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.9,
    "cinza [g]": 2.65,
    "s\u00f3dio [mg]": 560.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 4.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta ou perna de borrego assada com margarina",
    "energia[kcal]": 224.0,
    "energia[kj]": 933.0,
    "l\u00edpidos[g]": 14.5,
    "\u00e1cidos gordos saturados[g]": 6.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.6,
    "\u00e1cido linoleico [g]": 2.28,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 64.0,
    "caroteno [\u00b5g]": 43.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.12,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 4.4,
    "equivalentes de niacina [mg]": 10.3,
    "triptofano/60 [mg]": 4.37,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.9,
    "cinza [g]": 2.82,
    "s\u00f3dio [mg]": 620.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 4.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta ou perna de borrego frita com margarina, sem molho",
    "energia[kcal]": 166.0,
    "energia[kj]": 698.0,
    "l\u00edpidos[g]": 7.6,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.5,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 87.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.1,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 5.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 7.0
  },
  {
    "name": "Costeleta ou perna de borrego, assada, sem molho",
    "energia[kcal]": 168.0,
    "energia[kj]": 706.0,
    "l\u00edpidos[g]": 8.0,
    "\u00e1cidos gordos saturados[g]": 3.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 9.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.1,
    "cinza [g]": 2.19,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 4.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 7.0
  },
  {
    "name": "Costeleta ou perna de borrego, cozida ",
    "energia[kcal]": 157.0,
    "energia[kj]": 661.0,
    "l\u00edpidos[g]": 5.7,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.5,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.5,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 2.4,
    "zinco [mg]": 5.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 7.0
  },
  {
    "name": "Costeleta ou perna de borrego, crua ",
    "energia[kcal]": 124.0,
    "energia[kj]": 520.0,
    "l\u00edpidos[g]": 5.0,
    "\u00e1cidos gordos saturados[g]": 2.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 68.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 8.7,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 64.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 3.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta ou perna de borrego, estufada com azeite e margarina",
    "energia[kcal]": 183.0,
    "energia[kj]": 763.0,
    "l\u00edpidos[g]": 12.0,
    "\u00e1cidos gordos saturados[g]": 4.2,
    "\u00e1cidos gordos monoinsaturados [g]": 5.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 1.31,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 1.6,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.0,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 16.2,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.4,
    "\u00e1gua [g]": 66.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 57.0,
    "caroteno [\u00b5g]": 203.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 1.15,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 7.28,
    "triptofano/60 [mg]": 3.01,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.96,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 6.8,
    "cinza [g]": 2.08,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 2.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta ou perna de borrego, estufada com margarina",
    "energia[kcal]": 175.0,
    "energia[kj]": 730.0,
    "l\u00edpidos[g]": 11.1,
    "\u00e1cidos gordos saturados[g]": 5.3,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 1.92,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 1.6,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.0,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 16.2,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.4,
    "\u00e1gua [g]": 67.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 83.0,
    "caroteno [\u00b5g]": 221.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.54,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 7.28,
    "triptofano/60 [mg]": 3.01,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.96,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 6.8,
    "cinza [g]": 2.22,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 2.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Costeleta ou perna de borrego, estufada sem molho",
    "energia[kcal]": 185.0,
    "energia[kj]": 774.0,
    "l\u00edpidos[g]": 9.2,
    "\u00e1cidos gordos saturados[g]": 4.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 96.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 3.7,
    "equivalentes de niacina [mg]": 8.5,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 1.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.7,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 5.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 7.0
  },
  {
    "name": "Costeleta ou perna de borrego, grelhada",
    "energia[kcal]": 152.0,
    "energia[kj]": 640.0,
    "l\u00edpidos[g]": 5.5,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.7,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 4.8,
    "equivalentes de niacina [mg]": 9.6,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 1.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.3,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 5.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 7.0
  },
  {
    "name": "Costeleta ou perna de carneiro magra frita com margarina, sem molho",
    "energia[kcal]": 174.0,
    "energia[kj]": 731.0,
    "l\u00edpidos[g]": 8.4,
    "\u00e1cidos gordos saturados[g]": 3.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.7,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 87.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.3,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Couve-branca cozida",
    "energia[kcal]": 23.0,
    "energia[kj]": 95.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.2,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 1.7,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 40.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 25.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 32.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Couve-branca crua",
    "energia[kcal]": 28.0,
    "energia[kj]": 117.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 3.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 2.4,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 40.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 40.0,
    "folatos [\u00b5g]": 34.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 7.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 50.0,
    "f\u00f3sforo [mg]": 38.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Couve-de-Bruxelas cozida",
    "energia[kcal]": 43.0,
    "energia[kj]": 178.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 3.0,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.3,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 2.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 53.0,
    "caroteno [\u00b5g]": 320.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.13,
    "equivalentes de niacina [mg]": 0.63,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 60.0,
    "folatos [\u00b5g]": 110.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 73.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 61.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Couve-de-Bruxelas crua",
    "energia[kcal]": 50.0,
    "energia[kj]": 210.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.0,
    "a\u00e7\u00facares [g]": 3.1,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.7,
    "fibra  [g]": 3.8,
    "prote\u00ednas [g]": 3.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 215.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 115.0,
    "folatos [\u00b5g]": 135.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 77.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Couve-flor cozida",
    "energia[kcal]": 21.0,
    "energia[kj]": 88.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.3,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.3,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 1.6,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 30.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 45.0,
    "folatos [\u00b5g]": 44.0,
    "cinza [g]": 0.61,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 29.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.3
  },
  {
    "name": "Couve-flor crua",
    "energia[kcal]": 34.0,
    "energia[kj]": 142.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.3,
    "a\u00e7\u00facares [g]": 2.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.4,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 3.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 30.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.22,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.5,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 73.0,
    "folatos [\u00b5g]": 66.0,
    "cinza [g]": 0.95,
    "s\u00f3dio [mg]": 14.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 34.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Couve-galega cozida",
    "energia[kcal]": 29.0,
    "energia[kj]": 121.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.9,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.4,
    "fibra  [g]": 2.7,
    "prote\u00ednas [g]": 2.1,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 362.0,
    "caroteno [\u00b5g]": 2170.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 58.0,
    "folatos [\u00b5g]": 38.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 130.0,
    "pot\u00e1ssio [mg]": 90.0,
    "c\u00e1lcio [mg]": 260.0,
    "f\u00f3sforo [mg]": 35.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Couve-galega crua",
    "energia[kcal]": 32.0,
    "energia[kj]": 133.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.1,
    "a\u00e7\u00facares [g]": 2.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.4,
    "fibra  [g]": 3.1,
    "prote\u00ednas [g]": 2.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 414.0,
    "caroteno [\u00b5g]": 2490.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 2.0,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 148.0,
    "folatos [\u00b5g]": 78.0,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 21.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 290.0,
    "f\u00f3sforo [mg]": 40.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Couve-lombarda cozida",
    "energia[kcal]": 22.0,
    "energia[kj]": 92.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.4,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 2.9,
    "prote\u00ednas [g]": 2.2,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 104.0,
    "caroteno [\u00b5g]": 625.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 44.0,
    "folatos [\u00b5g]": 70.0,
    "cinza [g]": 0.64,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 46.0,
    "f\u00f3sforo [mg]": 57.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Couve-lombarda crua",
    "energia[kcal]": 26.0,
    "energia[kj]": 109.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.1,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 3.1,
    "prote\u00ednas [g]": 2.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 166.0,
    "caroteno [\u00b5g]": 995.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 67.0,
    "folatos [\u00b5g]": 150.0,
    "cinza [g]": 1.04,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 51.0,
    "f\u00f3sforo [mg]": 64.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Couve-portuguesa cozida",
    "energia[kcal]": 27.0,
    "energia[kj]": 112.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.5,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 2.4,
    "prote\u00ednas [g]": 2.1,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 207.0,
    "caroteno [\u00b5g]": 1245.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 58.0,
    "folatos [\u00b5g]": 46.0,
    "cinza [g]": 0.51,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 71.0,
    "f\u00f3sforo [mg]": 61.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Couve-portuguesa crua",
    "energia[kcal]": 31.0,
    "energia[kj]": 131.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 3.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 2.4,
    "prote\u00ednas [g]": 2.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 233.0,
    "caroteno [\u00b5g]": 1400.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 90.0,
    "folatos [\u00b5g]": 78.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 76.0,
    "f\u00f3sforo [mg]": 65.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Couve-roxa crua",
    "energia[kcal]": 30.0,
    "energia[kj]": 127.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.9,
    "a\u00e7\u00facares [g]": 3.3,
    "oligossac\u00e1ridos [g]": 0.5,
    "amido [g]": 0.1,
    "fibra  [g]": 3.3,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 57.0,
    "folatos [\u00b5g]": 39.0,
    "cinza [g]": 0.48,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 60.0,
    "f\u00f3sforo [mg]": 28.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cozido \u00e0 portuguesa",
    "energia[kcal]": 120.0,
    "energia[kj]": 500.0,
    "l\u00edpidos[g]": 7.0,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.634,
    "\u00e1cidos gordos trans [g]": 0.8,
    "hidratos de carbono [g]": 6.2,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 3.8,
    "fibra  [g]": 2.5,
    "prote\u00ednas [g]": 6.8,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 20.0,
    "vitamina a  [\u00b5g]": 159.0,
    "caroteno [\u00b5g]": 948.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.23,
    "tiamina [mg]": 0.23,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 2.97,
    "triptofano/60 [mg]": 1.39,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.37,
    "vitamina c [mg]": 34.3,
    "folatos [\u00b5g]": 66.0,
    "cinza [g]": 1.72,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 40.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cozido \u00e0 portuguesa com gr\u00e3o",
    "energia[kcal]": 259.0,
    "energia[kj]": 1080.0,
    "l\u00edpidos[g]": 13.5,
    "\u00e1cidos gordos saturados[g]": 4.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.81,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 20.5,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.7,
    "amido [g]": 18.3,
    "fibra  [g]": 4.1,
    "prote\u00ednas [g]": 11.7,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 23.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 120.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.75,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 4.22,
    "triptofano/60 [mg]": 2.12,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.59,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 68.0,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 470.0,
    "c\u00e1lcio [mg]": 49.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cravinho",
    "energia[kcal]": 431.0,
    "energia[kj]": 1800.0,
    "l\u00edpidos[g]": 20.0,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 7.7,
    "\u00e1cidos gordos polinsaturados [g]": 9.6,
    "\u00e1cido linoleico [g]": 9.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 52.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 52.0,
    "fibra  [g]": 9.6,
    "prote\u00ednas [g]": 6.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 7.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 300.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 9.0,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 1.5,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 81.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 1100.0,
    "c\u00e1lcio [mg]": 650.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 200.0,
    "ferro [mg]": 30.0,
    "zinco [mg]": 3.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme culin\u00e1rio",
    "energia[kcal]": 531.0,
    "energia[kj]": 2180.0,
    "l\u00edpidos[g]": 59.0,
    "\u00e1cidos gordos saturados[g]": 31.0,
    "\u00e1cidos gordos monoinsaturados [g]": 21.0,
    "\u00e1cidos gordos polinsaturados [g]": 6.5,
    "\u00e1cido linoleico [g]": 5.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 40.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 800.0,
    "caroteno [\u00b5g]": 530.0,
    "vitamina d [\u00b5g]": 7.5,
    "alfa-tocoferol [mg]": 18.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 1.2,
    "pot\u00e1ssio [mg]": 21.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 12.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme culin\u00e1rio l\u00edquido, 65% gordura, com sal",
    "energia[kcal]": 588.0,
    "energia[kj]": 2420.0,
    "l\u00edpidos[g]": 65.3,
    "\u00e1cidos gordos saturados[g]": 8.4,
    "\u00e1cidos gordos monoinsaturados [g]": 15.0,
    "\u00e1cidos gordos polinsaturados [g]": 35.6,
    "\u00e1cido linoleico [g]": 35.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 3.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 32.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 750.0,
    "caroteno [\u00b5g]": 400.0,
    "vitamina d [\u00b5g]": 2.0,
    "alfa-tocoferol [mg]": 14.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.3,
    "s\u00f3dio [mg]": 1200.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme de camar\u00e3o",
    "energia[kcal]": 39.0,
    "energia[kj]": 165.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.074,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 1.1,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 3.6,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 91.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 32.0,
    "vitamina a  [\u00b5g]": 14.0,
    "caroteno [\u00b5g]": 35.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.51,
    "equivalentes de niacina [mg]": 1.26,
    "triptofano/60 [mg]": 0.75,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.39,
    "vitamina c [mg]": 1.5,
    "folatos [\u00b5g]": 4.9,
    "cinza [g]": 1.05,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 81.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 36.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme de camar\u00e3o com natas",
    "energia[kcal]": 51.0,
    "energia[kj]": 212.0,
    "l\u00edpidos[g]": 2.9,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.256,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.9,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 1.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 3.5,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 89.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 33.0,
    "vitamina a  [\u00b5g]": 69.0,
    "caroteno [\u00b5g]": 267.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.47,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.51,
    "equivalentes de niacina [mg]": 1.24,
    "triptofano/60 [mg]": 0.73,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.38,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 6.5,
    "cinza [g]": 1.32,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 92.0,
    "c\u00e1lcio [mg]": 29.0,
    "f\u00f3sforo [mg]": 37.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme de ervilhas",
    "energia[kcal]": 43.0,
    "energia[kj]": 178.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.444,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.0,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.5,
    "amido [g]": 1.3,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 88.0,
    "caroteno [\u00b5g]": 469.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.12,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.57,
    "equivalentes de niacina [mg]": 1.01,
    "triptofano/60 [mg]": 0.45,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.03,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.05,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 49.0,
    "magn\u00e9sio [mg]": 9.9,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme para barrar de cacau e avel\u00e3s (8 marcas)",
    "energia[kcal]": 545.0,
    "energia[kj]": 2270.0,
    "l\u00edpidos[g]": 33.3,
    "\u00e1cidos gordos saturados[g]": 8.2,
    "\u00e1cidos gordos monoinsaturados [g]": 17.6,
    "\u00e1cidos gordos polinsaturados [g]": 6.1,
    "\u00e1cido linoleico [g]": 5.9,
    "\u00e1cidos gordos trans [g]": 2.0,
    "hidratos de carbono [g]": 54.7,
    "a\u00e7\u00facares [g]": 53.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.3,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 5.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 1.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.6,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 47.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 67.0,
    "ferro [mg]": 3.4,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme vegetal culin\u00e1rio, 75% gordura, sem sal",
    "energia[kcal]": 677.0,
    "energia[kj]": 2780.0,
    "l\u00edpidos[g]": 75.0,
    "\u00e1cidos gordos saturados[g]": 18.0,
    "\u00e1cidos gordos monoinsaturados [g]": 17.9,
    "\u00e1cidos gordos polinsaturados [g]": 38.6,
    "\u00e1cido linoleico [g]": 38.4,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 24.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 600.0,
    "caroteno [\u00b5g]": 320.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 40.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 27.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 16.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme vegetal para barrar  72% gordura, 33% \u00e1cidos gordos polinsaturados",
    "energia[kcal]": 651.0,
    "energia[kj]": 2680.0,
    "l\u00edpidos[g]": 72.1,
    "\u00e1cidos gordos saturados[g]": 18.8,
    "\u00e1cidos gordos monoinsaturados [g]": 18.7,
    "\u00e1cidos gordos polinsaturados [g]": 33.7,
    "\u00e1cido linoleico [g]": 33.5,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 26.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 600.0,
    "caroteno [\u00b5g]": 350.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 40.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 340.0,
    "pot\u00e1ssio [mg]": 27.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 16.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme vegetal para barrar 35% gordura, com fitoster\u00f3is",
    "energia[kcal]": 328.0,
    "energia[kj]": 1350.0,
    "l\u00edpidos[g]": 35.0,
    "\u00e1cidos gordos saturados[g]": 8.0,
    "\u00e1cidos gordos monoinsaturados [g]": 9.0,
    "\u00e1cidos gordos polinsaturados [g]": 17.5,
    "\u00e1cido linoleico [g]": 15.8,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 3.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 35.0,
    "vitamina a  [\u00b5g]": 646.0,
    "caroteno [\u00b5g]": 3880.0,
    "vitamina d [\u00b5g]": 8.0,
    "alfa-tocoferol [mg]": 66.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 39.0,
    "pot\u00e1ssio [mg]": 22.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme vegetal para barrar 37% gordura",
    "energia[kcal]": 343.0,
    "energia[kj]": 1410.0,
    "l\u00edpidos[g]": 37.4,
    "\u00e1cidos gordos saturados[g]": 10.2,
    "\u00e1cidos gordos monoinsaturados [g]": 9.4,
    "\u00e1cidos gordos polinsaturados [g]": 17.3,
    "\u00e1cido linoleico [g]": 14.7,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 600.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 40.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 22.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme vegetal para barrar 58% gordura, com c\u00e1lcio",
    "energia[kcal]": 525.0,
    "energia[kj]": 2160.0,
    "l\u00edpidos[g]": 58.0,
    "\u00e1cidos gordos saturados[g]": 19.1,
    "\u00e1cidos gordos monoinsaturados [g]": 15.4,
    "\u00e1cidos gordos polinsaturados [g]": 23.1,
    "\u00e1cido linoleico [g]": 20.6,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 0.5,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 38.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 4.0,
    "vitamina a  [\u00b5g]": 600.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 11.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 6.0,
    "c\u00e1lcio [mg]": 480.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme vegetal para barrar 70% gordura, 53% \u00e1cidos gordos monoinsaturados",
    "energia[kcal]": 632.0,
    "energia[kj]": 2600.0,
    "l\u00edpidos[g]": 70.0,
    "\u00e1cidos gordos saturados[g]": 9.4,
    "\u00e1cidos gordos monoinsaturados [g]": 53.2,
    "\u00e1cidos gordos polinsaturados [g]": 6.6,
    "\u00e1cido linoleico [g]": 6.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.5,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 2.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 27.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 4.0,
    "vitamina a  [\u00b5g]": 600.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 40.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 870.0,
    "pot\u00e1ssio [mg]": 7.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Creme vegetal para barrar 70% gordura, com sal",
    "energia[kcal]": 632.0,
    "energia[kj]": 2600.0,
    "l\u00edpidos[g]": 70.0,
    "\u00e1cidos gordos saturados[g]": 30.7,
    "\u00e1cidos gordos monoinsaturados [g]": 16.4,
    "\u00e1cidos gordos polinsaturados [g]": 22.6,
    "\u00e1cido linoleico [g]": 20.2,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 3.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 26.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 600.0,
    "caroteno [\u00b5g]": 580.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 40.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.3,
    "s\u00f3dio [mg]": 1200.0,
    "pot\u00e1ssio [mg]": 7.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Croissant",
    "energia[kcal]": 416.0,
    "energia[kj]": 1740.0,
    "l\u00edpidos[g]": 23.5,
    "\u00e1cidos gordos saturados[g]": 10.4,
    "\u00e1cidos gordos monoinsaturados [g]": 7.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.5,
    "\u00e1cidos gordos trans [g]": 1.8,
    "hidratos de carbono [g]": 42.2,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 41.6,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 7.6,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 22.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 52.0,
    "vitamina a  [\u00b5g]": 21.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 3.6,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 73.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 5.0
  },
  {
    "name": "Croquete",
    "energia[kcal]": 316.0,
    "energia[kj]": 1320.0,
    "l\u00edpidos[g]": 18.8,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 5.4,
    "\u00e1cidos gordos polinsaturados [g]": 5.8,
    "\u00e1cido linoleico [g]": 5.7,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 23.1,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 22.6,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 13.2,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 84.0,
    "vitamina a  [\u00b5g]": 41.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 2.9,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 2.8,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.93,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.6,
    "cinza [g]": 2.5,
    "s\u00f3dio [mg]": 640.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Croquete de carne de vaca meio-gorda",
    "energia[kcal]": 257.0,
    "energia[kj]": 1070.0,
    "l\u00edpidos[g]": 11.9,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.9,
    "\u00e1cido linoleico [g]": 1.74,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 19.1,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 1.0,
    "amido [g]": 16.5,
    "fibra  [g]": 5.8,
    "prote\u00ednas [g]": 15.1,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 44.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 75.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 24.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.56,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 5.06,
    "triptofano/60 [mg]": 3.05,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 0.81,
    "vitamina c [mg]": 7.7,
    "folatos [\u00b5g]": 95.0,
    "cinza [g]": 2.48,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 490.0,
    "c\u00e1lcio [mg]": 63.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 49.0,
    "ferro [mg]": 2.7,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cubo de carne de galinha para caldo",
    "energia[kcal]": 236.0,
    "energia[kj]": 985.0,
    "l\u00edpidos[g]": 15.4,
    "\u00e1cidos gordos saturados[g]": 3.6,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 3.2,
    "\u00e1cido linoleico [g]": 2.8,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 9.0,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 7.2,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 15.4,
    "sal  [g]": 41.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 7.2,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 46.1,
    "s\u00f3dio [mg]": 16300.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 47.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Cubo de carne de vaca para caldo",
    "energia[kcal]": 183.0,
    "energia[kj]": 769.0,
    "l\u00edpidos[g]": 8.0,
    "\u00e1cidos gordos saturados[g]": 3.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 8.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.8,
    "sal  [g]": 38.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 5.6,
    "equivalentes de niacina [mg]": 9.3,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 33.0,
    "cinza [g]": 43.3,
    "s\u00f3dio [mg]": 15000.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 180.0,
    "f\u00f3sforo [mg]": 340.0,
    "magn\u00e9sio [mg]": 110.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Curgete crua",
    "energia[kcal]": 19.0,
    "energia[kj]": 80.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.0,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 1.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 94.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 55.0,
    "caroteno [\u00b5g]": 332.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 17.0,
    "folatos [\u00b5g]": 41.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 33.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Curgete frita com \u00f3leo alimentar",
    "energia[kcal]": 64.0,
    "energia[kj]": 265.0,
    "l\u00edpidos[g]": 4.8,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 3.0,
    "\u00e1cido linoleico [g]": 2.95,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.5,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 70.0,
    "caroteno [\u00b5g]": 420.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.7,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.48,
    "equivalentes de niacina [mg]": 0.89,
    "triptofano/60 [mg]": 0.38,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 17.6,
    "folatos [\u00b5g]": 36.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 42.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Damasco",
    "energia[kcal]": 48.0,
    "energia[kj]": 203.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.5,
    "a\u00e7\u00facares [g]": 8.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.8,
    "\u00e1cidos org\u00e2nicos [g]": 1.9,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 180.0,
    "caroteno [\u00b5g]": 1100.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Damasco seco",
    "energia[kcal]": 242.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 41.2,
    "a\u00e7\u00facares [g]": 41.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 19.0,
    "prote\u00ednas [g]": 5.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 27.3,
    "\u00e1cidos org\u00e2nicos [g]": 3.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 417.0,
    "caroteno [\u00b5g]": 2500.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 4.5,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 3.7,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 3.2,
    "s\u00f3dio [mg]": 44.0,
    "pot\u00e1ssio [mg]": 1480.0,
    "c\u00e1lcio [mg]": 53.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 51.0,
    "ferro [mg]": 5.8,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Damasco, conserva em calda de a\u00e7\u00facar",
    "energia[kcal]": 163.0,
    "energia[kj]": 690.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 39.3,
    "a\u00e7\u00facares [g]": 39.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 57.0,
    "caroteno [\u00b5g]": 337.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.37,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 12.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Di\u00f3spiro",
    "energia[kcal]": 65.0,
    "energia[kj]": 274.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.8,
    "a\u00e7\u00facares [g]": 14.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 177.0,
    "caroteno [\u00b5g]": 1060.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.55,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Di\u00f3spiro desidratado",
    "energia[kcal]": 353.0,
    "energia[kj]": 1490.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 80.8,
    "a\u00e7\u00facares [g]": 80.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 8.2,
    "prote\u00ednas [g]": 3.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.55,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 1.64,
    "equivalentes de niacina [mg]": 2.73,
    "triptofano/60 [mg]": 1.09,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 38.2,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 27.0,
    "pot\u00e1ssio [mg]": 1240.0,
    "c\u00e1lcio [mg]": 55.0,
    "f\u00f3sforo [mg]": 71.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Doce de cereja",
    "energia[kcal]": 262.0,
    "energia[kj]": 1110.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 64.9,
    "a\u00e7\u00facares [g]": 64.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 33.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.22,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 12.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Doce de damasco",
    "energia[kcal]": 233.0,
    "energia[kj]": 992.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 57.4,
    "a\u00e7\u00facares [g]": 57.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 41.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.18,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 90.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Doce de framboesa",
    "energia[kcal]": 242.0,
    "energia[kj]": 1030.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 59.7,
    "a\u00e7\u00facares [g]": 59.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 38.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.23,
    "s\u00f3dio [mg]": 16.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 12.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Doce de ginja",
    "energia[kcal]": 263.0,
    "energia[kj]": 1120.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 64.8,
    "a\u00e7\u00facares [g]": 64.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 33.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.39,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 99.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 14.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Doce de ma\u00e7\u00e3",
    "energia[kcal]": 239.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 59.0,
    "a\u00e7\u00facares [g]": 59.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 39.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.26,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 96.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Doce de morango",
    "energia[kcal]": 245.0,
    "energia[kj]": 1040.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 60.5,
    "a\u00e7\u00facares [g]": 60.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 38.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.29,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Doce de p\u00eassego",
    "energia[kcal]": 209.0,
    "energia[kj]": 887.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 51.3,
    "a\u00e7\u00facares [g]": 51.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 47.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.29,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 16.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Donut",
    "energia[kcal]": 400.0,
    "energia[kj]": 1670.0,
    "l\u00edpidos[g]": 21.7,
    "\u00e1cidos gordos saturados[g]": 9.6,
    "\u00e1cidos gordos monoinsaturados [g]": 7.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.7,
    "\u00e1cidos gordos trans [g]": 1.1,
    "hidratos de carbono [g]": 43.0,
    "a\u00e7\u00facares [g]": 14.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 28.4,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 6.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 23.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 180.0,
    "caroteno [\u00b5g]": 270.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.3,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 90.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 95.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Donut recheado com doce de fruta",
    "energia[kcal]": 348.0,
    "energia[kj]": 1460.0,
    "l\u00edpidos[g]": 14.5,
    "\u00e1cidos gordos saturados[g]": 6.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.8,
    "hidratos de carbono [g]": 47.0,
    "a\u00e7\u00facares [g]": 18.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 28.4,
    "fibra  [g]": 2.5,
    "prote\u00ednas [g]": 6.0,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 28.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 15.0,
    "vitamina a  [\u00b5g]": 21.0,
    "caroteno [\u00b5g]": 20.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 2.5,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 50.0,
    "f\u00f3sforo [mg]": 71.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Dourada cozida",
    "energia[kcal]": 196.0,
    "energia[kj]": 816.0,
    "l\u00edpidos[g]": 12.5,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 3.7,
    "\u00e1cidos gordos polinsaturados [g]": 4.1,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 116.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 8.4,
    "alfa-tocoferol [mg]": 0.32,
    "tiamina [mg]": 0.23,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 2.9,
    "equivalentes de niacina [mg]": 6.8,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 4.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 17.0
  },
  {
    "name": "Dourada crua",
    "energia[kcal]": 167.0,
    "energia[kj]": 698.0,
    "l\u00edpidos[g]": 9.8,
    "\u00e1cidos gordos saturados[g]": 2.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 51.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 12.0,
    "alfa-tocoferol [mg]": 0.82,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 5.1,
    "equivalentes de niacina [mg]": 8.8,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 4.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 59.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Dourada grelhada",
    "energia[kcal]": 178.0,
    "energia[kj]": 745.0,
    "l\u00edpidos[g]": 9.9,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.3,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.3,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 97.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 7.9,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.23,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 4.4,
    "equivalentes de niacina [mg]": 8.6,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 4.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 650.0,
    "pot\u00e1ssio [mg]": 490.0,
    "c\u00e1lcio [mg]": 65.0,
    "f\u00f3sforo [mg]": 320.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 17.0
  },
  {
    "name": "\u00c9clair de chocolate",
    "energia[kcal]": 378.0,
    "energia[kj]": 1570.0,
    "l\u00edpidos[g]": 23.8,
    "\u00e1cidos gordos saturados[g]": 10.3,
    "\u00e1cidos gordos monoinsaturados [g]": 7.6,
    "\u00e1cidos gordos polinsaturados [g]": 4.5,
    "\u00e1cido linoleico [g]": 4.1,
    "\u00e1cidos gordos trans [g]": 1.1,
    "hidratos de carbono [g]": 36.5,
    "a\u00e7\u00facares [g]": 25.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 10.8,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 4.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 33.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 105.0,
    "vitamina a  [\u00b5g]": 210.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 2.3,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 88.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 73.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Empada",
    "energia[kcal]": 374.0,
    "energia[kj]": 1560.0,
    "l\u00edpidos[g]": 21.9,
    "\u00e1cidos gordos saturados[g]": 9.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.7,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 33.9,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 32.0,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 9.6,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 32.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 17.0,
    "vitamina a  [\u00b5g]": 143.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 2.5,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 3.5,
    "triptofano/60 [mg]": 2.1,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.44,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.2,
    "cinza [g]": 0.99,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 74.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Empad\u00e3o de atum",
    "energia[kcal]": 234.0,
    "energia[kj]": 973.0,
    "l\u00edpidos[g]": 15.2,
    "\u00e1cidos gordos saturados[g]": 6.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.8,
    "\u00e1cidos gordos polinsaturados [g]": 3.9,
    "\u00e1cido linoleico [g]": 3.57,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 10.7,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 9.7,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 12.9,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 32.0,
    "vitamina a  [\u00b5g]": 93.0,
    "caroteno [\u00b5g]": 41.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.78,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 6.46,
    "triptofano/60 [mg]": 2.69,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.91,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.63,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Empad\u00e3o de bacalhau",
    "energia[kcal]": 118.0,
    "energia[kj]": 495.0,
    "l\u00edpidos[g]": 5.0,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.9,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 8.7,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 6.9,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 26.0,
    "vitamina a  [\u00b5g]": 43.0,
    "caroteno [\u00b5g]": 24.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.89,
    "equivalentes de niacina [mg]": 2.29,
    "triptofano/60 [mg]": 1.39,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.28,
    "vitamina c [mg]": 6.6,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 1.79,
    "s\u00f3dio [mg]": 510.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 46.0,
    "f\u00f3sforo [mg]": 79.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Empad\u00e3o de carne com manteiga",
    "energia[kcal]": 134.0,
    "energia[kj]": 559.0,
    "l\u00edpidos[g]": 6.3,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.273,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 10.7,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 9.3,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 6.6,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 73.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 29.0,
    "vitamina a  [\u00b5g]": 26.0,
    "caroteno [\u00b5g]": 46.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.29,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 3.13,
    "triptofano/60 [mg]": 1.44,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.46,
    "vitamina c [mg]": 8.3,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.19,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 91.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Empad\u00e3o de carne com margarina",
    "energia[kcal]": 134.0,
    "energia[kj]": 559.0,
    "l\u00edpidos[g]": 6.3,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.67,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 10.7,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 9.3,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 6.6,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 73.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 24.0,
    "vitamina a  [\u00b5g]": 27.0,
    "caroteno [\u00b5g]": 54.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.25,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 3.13,
    "triptofano/60 [mg]": 1.44,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.46,
    "vitamina c [mg]": 8.3,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.22,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 90.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Empad\u00e3o de carne guisada",
    "energia[kcal]": 167.0,
    "energia[kj]": 696.0,
    "l\u00edpidos[g]": 9.3,
    "\u00e1cidos gordos saturados[g]": 4.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.359,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 10.9,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 9.4,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 9.4,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 39.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 4.1,
    "triptofano/60 [mg]": 2.08,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 0.71,
    "vitamina c [mg]": 7.4,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 1.64,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Empad\u00e3o de pescada e camar\u00e3o",
    "energia[kcal]": 122.0,
    "energia[kj]": 513.0,
    "l\u00edpidos[g]": 4.5,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.916,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.0,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 8.7,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 8.7,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 71.0,
    "vitamina a  [\u00b5g]": 72.0,
    "caroteno [\u00b5g]": 165.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.61,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.77,
    "equivalentes de niacina [mg]": 2.65,
    "triptofano/60 [mg]": 1.89,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.49,
    "vitamina c [mg]": 11.0,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 1.93,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 68.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "End\u00edvia crua",
    "energia[kcal]": 19.0,
    "energia[kj]": 80.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 73.0,
    "caroteno [\u00b5g]": 440.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 35.2,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 22.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 44.0,
    "f\u00f3sforo [mg]": 36.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 2.8,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 1.0,
    "iodo [\u00b5g]": 6.4
  },
  {
    "name": "Enguia crua",
    "energia[kcal]": 303.0,
    "energia[kj]": 1250.0,
    "l\u00edpidos[g]": 27.7,
    "\u00e1cidos gordos saturados[g]": 8.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 9.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.4,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 26.0,
    "vitamina a  [\u00b5g]": 887.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 16.0,
    "alfa-tocoferol [mg]": 2.4,
    "tiamina [mg]": 0.28,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 1.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.3,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 77.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Enguia frita",
    "energia[kcal]": 267.0,
    "energia[kj]": 1110.0,
    "l\u00edpidos[g]": 21.6,
    "\u00e1cidos gordos saturados[g]": 6.2,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 8.2,
    "\u00e1cido linoleico [g]": 1.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.2,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.1,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 14.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 806.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 14.0,
    "alfa-tocoferol [mg]": 4.4,
    "tiamina [mg]": 0.26,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 4.2,
    "triptofano/60 [mg]": 2.8,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.3,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 2.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Entrecosto de porco cozido",
    "energia[kcal]": 235.0,
    "energia[kj]": 983.0,
    "l\u00edpidos[g]": 14.2,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 4.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.9,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 96.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.33,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 5.5,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.7,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.92,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.8,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Entrecosto de porco cru",
    "energia[kcal]": 190.0,
    "energia[kj]": 792.0,
    "l\u00edpidos[g]": 12.4,
    "\u00e1cidos gordos saturados[g]": 4.2,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.7,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 66.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.73,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 7.2,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 72.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Entrecosto de porco estufado com azeite e banha",
    "energia[kcal]": 246.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 17.1,
    "\u00e1cidos gordos saturados[g]": 5.2,
    "\u00e1cidos gordos monoinsaturados [g]": 7.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.12,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 20.9,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 58.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 71.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.49,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 11.7,
    "triptofano/60 [mg]": 4.46,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 2.24,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Entrecosto de porco estufado com azeite e margarina",
    "energia[kcal]": 244.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 16.8,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 6.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.28,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 20.9,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 58.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 70.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 11.7,
    "triptofano/60 [mg]": 4.46,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 2.29,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Entrecosto de porco estufado com margarina",
    "energia[kcal]": 239.0,
    "energia[kj]": 995.0,
    "l\u00edpidos[g]": 16.3,
    "\u00e1cidos gordos saturados[g]": 6.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 3.0,
    "\u00e1cido linoleico [g]": 2.61,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 20.9,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 58.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 70.0,
    "vitamina a  [\u00b5g]": 24.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.14,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 11.7,
    "triptofano/60 [mg]": 4.46,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 2.37,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Entrecosto de porco estufado com \u00f3leo alimentar e banha",
    "energia[kcal]": 246.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 17.1,
    "\u00e1cidos gordos saturados[g]": 5.1,
    "\u00e1cidos gordos monoinsaturados [g]": 5.8,
    "\u00e1cidos gordos polinsaturados [g]": 3.8,
    "\u00e1cido linoleico [g]": 3.49,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 20.9,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 58.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 71.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 1.62,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 11.7,
    "triptofano/60 [mg]": 4.46,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 2.24,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Entrecosto de porco estufado, sem molho",
    "energia[kcal]": 245.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 15.4,
    "\u00e1cidos gordos saturados[g]": 5.2,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.6,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.48,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 5.3,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.7,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.88,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Entrecosto de porco grelhado",
    "energia[kcal]": 243.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 15.1,
    "\u00e1cidos gordos saturados[g]": 5.1,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.8,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 90.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.84,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 7.8,
    "equivalentes de niacina [mg]": 14.0,
    "triptofano/60 [mg]": 5.7,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.2,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Ervilhas estufadas",
    "energia[kcal]": 113.0,
    "energia[kj]": 470.0,
    "l\u00edpidos[g]": 5.1,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.357,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 9.6,
    "a\u00e7\u00facares [g]": 2.8,
    "oligossac\u00e1ridos [g]": 1.3,
    "amido [g]": 5.5,
    "fibra  [g]": 3.9,
    "prote\u00ednas [g]": 5.1,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 13.0,
    "vitamina a  [\u00b5g]": 103.0,
    "caroteno [\u00b5g]": 434.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.57,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.8,
    "triptofano/60 [mg]": 0.79,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 31.4,
    "folatos [\u00b5g]": 60.0,
    "cinza [g]": 1.31,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 49.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ervilhas secas cozidas",
    "energia[kcal]": 114.0,
    "energia[kj]": 481.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.1,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.7,
    "amido [g]": 16.5,
    "fibra  [g]": 5.1,
    "prote\u00ednas [g]": 6.9,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 13.0,
    "caroteno [\u00b5g]": 80.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.34,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 2.1,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 0.87,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ervilhas secas cruas",
    "energia[kcal]": 330.0,
    "energia[kj]": 1390.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 49.4,
    "a\u00e7\u00facares [g]": 2.3,
    "oligossac\u00e1ridos [g]": 1.7,
    "amido [g]": 45.4,
    "fibra  [g]": 15.0,
    "prote\u00ednas [g]": 22.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 44.0,
    "caroteno [\u00b5g]": 262.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.88,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 2.9,
    "equivalentes de niacina [mg]": 6.6,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 33.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 40.0,
    "pot\u00e1ssio [mg]": 1040.0,
    "c\u00e1lcio [mg]": 61.0,
    "f\u00f3sforo [mg]": 340.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 3.7,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ervilhas, gr\u00e3o, congeladas cozidas",
    "energia[kcal]": 72.0,
    "energia[kj]": 300.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.5,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 1.3,
    "amido [g]": 4.7,
    "fibra  [g]": 7.3,
    "prote\u00ednas [g]": 5.6,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 64.0,
    "caroteno [\u00b5g]": 383.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 2.3,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 10.0,
    "folatos [\u00b5g]": 47.0,
    "cinza [g]": 0.55,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 91.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Ervilhas, gr\u00e3o, congeladas cruas",
    "energia[kcal]": 68.0,
    "energia[kj]": 284.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.0,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 1.2,
    "amido [g]": 4.4,
    "fibra  [g]": 7.0,
    "prote\u00ednas [g]": 5.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 67.0,
    "caroteno [\u00b5g]": 400.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.32,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 2.6,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 14.0,
    "folatos [\u00b5g]": 58.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 96.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ervilhas, gr\u00e3o, frescas cozidas",
    "energia[kcal]": 72.0,
    "energia[kj]": 304.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.9,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 1.4,
    "amido [g]": 4.9,
    "fibra  [g]": 4.8,
    "prote\u00ednas [g]": 6.2,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 44.0,
    "caroteno [\u00b5g]": 266.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.6,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 13.0,
    "folatos [\u00b5g]": 38.0,
    "cinza [g]": 0.66,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 68.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Ervilhas, gr\u00e3o, frescas cruas",
    "energia[kcal]": 76.0,
    "energia[kj]": 319.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.6,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 1.5,
    "amido [g]": 5.3,
    "fibra  [g]": 4.7,
    "prote\u00ednas [g]": 6.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 48.0,
    "caroteno [\u00b5g]": 290.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.74,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.1,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 20.0,
    "folatos [\u00b5g]": 62.0,
    "cinza [g]": 0.92,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 36.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ervilhas, vagens cozidas",
    "energia[kcal]": 30.0,
    "energia[kj]": 126.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.9,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 31.0,
    "caroteno [\u00b5g]": 184.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 19.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 99.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 41.0,
    "f\u00f3sforo [mg]": 36.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ervilhas, vagens cruas",
    "energia[kcal]": 33.0,
    "energia[kj]": 138.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.9,
    "a\u00e7\u00facares [g]": 2.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 200.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 30.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.48,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 39.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Espadarte cru",
    "energia[kcal]": 97.0,
    "energia[kj]": 410.0,
    "l\u00edpidos[g]": 2.9,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 45.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.3,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 4.1,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.47,
    "vitamina b12 [\u00b5g]": 1.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 72.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Espadarte grelhado",
    "energia[kcal]": 116.0,
    "energia[kj]": 488.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.3,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 54.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.5,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 4.7,
    "equivalentes de niacina [mg]": 8.7,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.47,
    "vitamina b12 [\u00b5g]": 1.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Espargos cozidos",
    "energia[kcal]": 20.0,
    "energia[kj]": 84.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.2,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 2.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 56.0,
    "caroteno [\u00b5g]": 334.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 155.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 98.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 47.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 6.5
  },
  {
    "name": "Espargos crus",
    "energia[kcal]": 22.0,
    "energia[kj]": 94.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 2.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 67.0,
    "caroteno [\u00b5g]": 400.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.16,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 10.0,
    "folatos [\u00b5g]": 175.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 68.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Esparguete cozido",
    "energia[kcal]": 102.0,
    "energia[kj]": 430.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 19.9,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 19.0,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 3.4,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 31.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 45.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Esparguete cru",
    "energia[kcal]": 360.0,
    "energia[kj]": 1530.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 71.1,
    "a\u00e7\u00facares [g]": 3.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 68.0,
    "fibra  [g]": 5.1,
    "prote\u00ednas [g]": 12.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.24,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 4.8,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 34.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Esparguete estufado com cenoura e azeite",
    "energia[kcal]": 121.0,
    "energia[kj]": 509.0,
    "l\u00edpidos[g]": 3.9,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.479,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 17.6,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 15.5,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 118.0,
    "caroteno [\u00b5g]": 708.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.73,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.74,
    "equivalentes de niacina [mg]": 1.38,
    "triptofano/60 [mg]": 0.61,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.1,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 0.895,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 48.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Esparguete estufado com cenoura e margarina",
    "energia[kcal]": 115.0,
    "energia[kj]": 483.0,
    "l\u00edpidos[g]": 3.2,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.954,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 17.6,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 15.5,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 139.0,
    "caroteno [\u00b5g]": 722.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.26,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.74,
    "equivalentes de niacina [mg]": 1.38,
    "triptofano/60 [mg]": 0.61,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.1,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.01,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 49.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Espetada de porco grelhada",
    "energia[kcal]": 285.0,
    "energia[kj]": 1180.0,
    "l\u00edpidos[g]": 24.8,
    "\u00e1cidos gordos saturados[g]": 8.4,
    "\u00e1cidos gordos monoinsaturados [g]": 9.0,
    "\u00e1cidos gordos polinsaturados [g]": 3.8,
    "\u00e1cido linoleico [g]": 3.25,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 13.6,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 58.0,
    "vitamina a  [\u00b5g]": 91.0,
    "caroteno [\u00b5g]": 543.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.44,
    "tiamina [mg]": 0.5,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 7.74,
    "triptofano/60 [mg]": 2.67,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 0.87,
    "vitamina c [mg]": 40.5,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 2.08,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Espetada de vaca grelhada",
    "energia[kcal]": 213.0,
    "energia[kj]": 889.0,
    "l\u00edpidos[g]": 12.8,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 5.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.376,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.7,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 23.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 73.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 4.2,
    "equivalentes de niacina [mg]": 9.18,
    "triptofano/60 [mg]": 4.96,
    "vitamina b6 [mg]": 0.54,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.8,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 0.906,
    "s\u00f3dio [mg]": 81.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 4.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Espinafres crus",
    "energia[kcal]": 27.0,
    "energia[kj]": 112.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 550.0,
    "caroteno [\u00b5g]": 3300.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.7,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 35.0,
    "folatos [\u00b5g]": 150.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 470.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 45.0,
    "magn\u00e9sio [mg]": 54.0,
    "ferro [mg]": 2.4,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Estrag\u00e3o fresco",
    "energia[kcal]": 55.0,
    "energia[kj]": 229.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.27,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.3,
    "a\u00e7\u00facares [g]": 6.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.9,
    "prote\u00ednas [g]": 3.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 63.0,
    "caroteno [\u00b5g]": 375.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 170.0,
    "f\u00f3sforo [mg]": 46.0,
    "magn\u00e9sio [mg]": 51.0,
    "ferro [mg]": 29.0,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Estrag\u00e3o seco",
    "energia[kcal]": 363.0,
    "energia[kj]": 1530.0,
    "l\u00edpidos[g]": 7.2,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 42.8,
    "a\u00e7\u00facares [g]": 42.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 18.1,
    "prote\u00ednas [g]": 22.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 7.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 420.0,
    "caroteno [\u00b5g]": 2520.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 8.9,
    "equivalentes de niacina [mg]": 11.4,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 4.92,
    "s\u00f3dio [mg]": 62.0,
    "pot\u00e1ssio [mg]": 3020.0,
    "c\u00e1lcio [mg]": 1140.0,
    "f\u00f3sforo [mg]": 310.0,
    "magn\u00e9sio [mg]": 350.0,
    "ferro [mg]": 32.0,
    "zinco [mg]": 3.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Faneca crua",
    "energia[kcal]": 82.0,
    "energia[kj]": 347.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 5.1,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.22,
    "s\u00f3dio [mg]": 68.0,
    "pot\u00e1ssio [mg]": 530.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 80.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farelo de trigo",
    "energia[kcal]": 293.0,
    "energia[kj]": 1220.0,
    "l\u00edpidos[g]": 5.3,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.58,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 24.9,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 22.5,
    "fibra  [g]": 40.2,
    "prote\u00ednas [g]": 16.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 8.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.6,
    "tiamina [mg]": 0.89,
    "riboflavina [mg]": 0.36,
    "niacina [mg]": 29.6,
    "equivalentes de niacina [mg]": 33.1,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 1.38,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 140.0,
    "cinza [g]": 5.4,
    "s\u00f3dio [mg]": 28.0,
    "pot\u00e1ssio [mg]": 1340.0,
    "c\u00e1lcio [mg]": 75.0,
    "f\u00f3sforo [mg]": 1060.0,
    "magn\u00e9sio [mg]": 480.0,
    "ferro [mg]": 19.0,
    "zinco [mg]": 7.7,
    "sel\u00e9nio [\u00b5g]": 6.8,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Farinha de alfarroba",
    "energia[kcal]": 368.0,
    "energia[kj]": 1560.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 85.6,
    "a\u00e7\u00facares [g]": 42.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 43.6,
    "fibra  [g]": 5.0,
    "prote\u00ednas [g]": 3.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.46,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 35.0,
    "pot\u00e1ssio [mg]": 830.0,
    "c\u00e1lcio [mg]": 350.0,
    "f\u00f3sforo [mg]": 79.0,
    "magn\u00e9sio [mg]": 54.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de aveia",
    "energia[kcal]": 363.0,
    "energia[kj]": 1530.0,
    "l\u00edpidos[g]": 7.3,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.54,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 57.0,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 55.7,
    "fibra  [g]": 5.7,
    "prote\u00ednas [g]": 14.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.33,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 5.5,
    "triptofano/60 [mg]": 3.1,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 46.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 43.0,
    "f\u00f3sforo [mg]": 430.0,
    "magn\u00e9sio [mg]": 130.0,
    "ferro [mg]": 6.3,
    "zinco [mg]": 4.5,
    "sel\u00e9nio [\u00b5g]": 8.6,
    "iodo [\u00b5g]": 20.0
  },
  {
    "name": "Farinha de centeio",
    "energia[kcal]": 370.0,
    "energia[kj]": 1570.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 77.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 77.3,
    "fibra  [g]": 9.4,
    "prote\u00ednas [g]": 7.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.85,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 2.0,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 71.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 71.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de centeio tipo 70",
    "energia[kcal]": 364.0,
    "energia[kj]": 1540.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 78.5,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 78.5,
    "fibra  [g]": 7.0,
    "prote\u00ednas [g]": 6.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.31,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 2.0,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 63.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 49.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de centeio tipo 85",
    "energia[kcal]": 375.0,
    "energia[kj]": 1590.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 76.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 76.0,
    "fibra  [g]": 11.7,
    "prote\u00ednas [g]": 9.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.38,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 2.0,
    "triptofano/60 [mg]": 1.7,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 78.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 36.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 92.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de cevada",
    "energia[kcal]": 330.0,
    "energia[kj]": 1400.0,
    "l\u00edpidos[g]": 2.5,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 64.4,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 63.5,
    "fibra  [g]": 7.6,
    "prote\u00ednas [g]": 8.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 14.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.37,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 5.7,
    "equivalentes de niacina [mg]": 7.7,
    "triptofano/60 [mg]": 2.0,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.45,
    "s\u00f3dio [mg]": 3.3,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 89.0,
    "ferro [mg]": 4.5,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 4.8,
    "iodo [\u00b5g]": 5.0
  },
  {
    "name": "Farinha de espelta",
    "energia[kcal]": 339.0,
    "energia[kj]": 1430.0,
    "l\u00edpidos[g]": 2.3,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 61.8,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 59.9,
    "fibra  [g]": 7.4,
    "prote\u00ednas [g]": 14.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 13.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 5.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.35,
    "equivalentes de niacina [mg]": 2.55,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 45.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 310.0,
    "magn\u00e9sio [mg]": 89.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de milho tipo 70",
    "energia[kcal]": 359.0,
    "energia[kj]": 1520.0,
    "l\u00edpidos[g]": 2.2,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 75.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 75.3,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 8.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 13.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.36,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.56,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de pau (mandioca)",
    "energia[kcal]": 350.0,
    "energia[kj]": 1490.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 84.6,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 84.6,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 25.0,
    "pot\u00e1ssio [mg]": 20.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 59.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de trigo (valor m\u00e9dio tipo 150 e tipo 55)",
    "energia[kcal]": 349.0,
    "energia[kj]": 1480.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 73.7,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 71.6,
    "fibra  [g]": 3.3,
    "prote\u00ednas [g]": 8.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 2.65,
    "triptofano/60 [mg]": 1.75,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 1.05,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de trigo integral",
    "energia[kcal]": 338.0,
    "energia[kj]": 1430.0,
    "l\u00edpidos[g]": 2.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 65.2,
    "a\u00e7\u00facares [g]": 2.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 62.9,
    "fibra  [g]": 8.6,
    "prote\u00ednas [g]": 9.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.37,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 5.4,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 2.0,
    "vitamina b6 [mg]": 0.43,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 43.0,
    "cinza [g]": 1.58,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 430.0,
    "magn\u00e9sio [mg]": 140.0,
    "ferro [mg]": 4.3,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de trigo tipo 150",
    "energia[kcal]": 352.0,
    "energia[kj]": 1490.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 73.0,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 70.4,
    "fibra  [g]": 3.7,
    "prote\u00ednas [g]": 9.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 2.6,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 29.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha de trigo tipo 55",
    "energia[kcal]": 344.0,
    "energia[kj]": 1460.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 74.3,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 72.8,
    "fibra  [g]": 2.9,
    "prote\u00ednas [g]": 7.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 13.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.7,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha l\u00e1ctea 5 frutos tipo \"Cerelac\" (com farinha de trigo)",
    "energia[kcal]": 408.0,
    "energia[kj]": 1720.0,
    "l\u00edpidos[g]": 9.0,
    "\u00e1cidos gordos saturados[g]": 4.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 2.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 65.0,
    "a\u00e7\u00facares [g]": 40.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 24.7,
    "fibra  [g]": 3.6,
    "prote\u00ednas [g]": 15.0,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 4.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 309.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 3.0,
    "tiamina [mg]": 0.8,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 6.0,
    "triptofano/60 [mg]": 3.0,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.7,
    "vitamina c [mg]": 35.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 2.8,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 600.0,
    "c\u00e1lcio [mg]": 420.0,
    "f\u00f3sforo [mg]": 340.0,
    "magn\u00e9sio [mg]": 52.0,
    "ferro [mg]": 7.5,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha l\u00e1ctea ma\u00e7\u00e3s tipo \"Cerelac\" (com farinha de trigo)",
    "energia[kcal]": 412.0,
    "energia[kj]": 1740.0,
    "l\u00edpidos[g]": 9.0,
    "\u00e1cidos gordos saturados[g]": 4.2,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 66.0,
    "a\u00e7\u00facares [g]": 41.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 25.0,
    "fibra  [g]": 3.7,
    "prote\u00ednas [g]": 15.0,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 309.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 3.0,
    "tiamina [mg]": 0.8,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 6.1,
    "triptofano/60 [mg]": 3.0,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.7,
    "vitamina c [mg]": 35.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 2.7,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 550.0,
    "c\u00e1lcio [mg]": 420.0,
    "f\u00f3sforo [mg]": 340.0,
    "magn\u00e9sio [mg]": 55.0,
    "ferro [mg]": 7.5,
    "zinco [mg]": 1.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinha l\u00e1ctea tipo \"Cerelac\" (com farinha de trigo)",
    "energia[kcal]": 416.0,
    "energia[kj]": 1760.0,
    "l\u00edpidos[g]": 9.0,
    "\u00e1cidos gordos saturados[g]": 3.9,
    "\u00e1cidos gordos monoinsaturados [g]": 2.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 2.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 68.0,
    "a\u00e7\u00facares [g]": 35.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 32.2,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 15.0,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 309.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 3.0,
    "tiamina [mg]": 0.8,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 7.0,
    "triptofano/60 [mg]": 3.0,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.7,
    "vitamina c [mg]": 35.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 2.6,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 530.0,
    "c\u00e1lcio [mg]": 480.0,
    "f\u00f3sforo [mg]": 380.0,
    "magn\u00e9sio [mg]": 39.0,
    "ferro [mg]": 7.5,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinheira cozida sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 404.0,
    "energia[kj]": 1680.0,
    "l\u00edpidos[g]": 30.7,
    "\u00e1cidos gordos saturados[g]": 10.9,
    "\u00e1cidos gordos monoinsaturados [g]": 12.0,
    "\u00e1cidos gordos polinsaturados [g]": 5.0,
    "\u00e1cido linoleico [g]": 4.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 26.4,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 25.9,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 4.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 35.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 57.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 2.4,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.1,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 85.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 69.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Farinheira crua",
    "energia[kcal]": 497.0,
    "energia[kj]": 2060.0,
    "l\u00edpidos[g]": 41.0,
    "\u00e1cidos gordos saturados[g]": 14.5,
    "\u00e1cidos gordos monoinsaturados [g]": 16.0,
    "\u00e1cidos gordos polinsaturados [g]": 6.6,
    "\u00e1cido linoleico [g]": 5.8,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 26.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 26.2,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 4.8,
    "sal  [g]": 2.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 25.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 58.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.16,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 2.8,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 2.8,
    "s\u00f3dio [mg]": 960.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 70.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Favada \u00e0 portuguesa",
    "energia[kcal]": 130.0,
    "energia[kj]": 542.0,
    "l\u00edpidos[g]": 5.9,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.692,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.4,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.5,
    "amido [g]": 6.1,
    "fibra  [g]": 5.6,
    "prote\u00ednas [g]": 8.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 10.0,
    "vitamina a  [\u00b5g]": 18.0,
    "caroteno [\u00b5g]": 100.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.52,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 2.78,
    "triptofano/60 [mg]": 1.41,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.11,
    "vitamina c [mg]": 24.2,
    "folatos [\u00b5g]": 140.0,
    "cinza [g]": 1.51,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 31.0,
    "f\u00f3sforo [mg]": 95.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Favas frescas cozidas",
    "energia[kcal]": 73.0,
    "energia[kj]": 305.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.4,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.5,
    "amido [g]": 5.5,
    "fibra  [g]": 5.8,
    "prote\u00ednas [g]": 6.7,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 55.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 14.0,
    "folatos [\u00b5g]": 83.0,
    "cinza [g]": 0.62,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 79.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.9
  },
  {
    "name": "Favas frescas cruas",
    "energia[kcal]": 80.0,
    "energia[kj]": 338.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.5,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.5,
    "amido [g]": 6.4,
    "fibra  [g]": 6.1,
    "prote\u00ednas [g]": 7.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 64.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.46,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 2.4,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 23.0,
    "folatos [\u00b5g]": 145.0,
    "cinza [g]": 0.84,
    "s\u00f3dio [mg]": 7.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 87.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Favas secas cozidas (demolhadas)",
    "energia[kcal]": 90.0,
    "energia[kj]": 378.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.7,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 9.1,
    "fibra  [g]": 5.0,
    "prote\u00ednas [g]": 7.9,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 38.0,
    "caroteno [\u00b5g]": 225.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 4.3,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 32.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 56.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Favas secas cruas",
    "energia[kcal]": 301.0,
    "energia[kj]": 1270.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 32.8,
    "a\u00e7\u00facares [g]": 6.2,
    "oligossac\u00e1ridos [g]": 2.3,
    "amido [g]": 24.3,
    "fibra  [g]": 27.6,
    "prote\u00ednas [g]": 25.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 30.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.46,
    "tiamina [mg]": 0.43,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 2.6,
    "equivalentes de niacina [mg]": 6.7,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 150.0,
    "cinza [g]": 3.5,
    "s\u00f3dio [mg]": 13.0,
    "pot\u00e1ssio [mg]": 1090.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 330.0,
    "magn\u00e9sio [mg]": 200.0,
    "ferro [mg]": 5.0,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Feij\u00e3o branco cozido (demolhado)",
    "energia[kcal]": 103.0,
    "energia[kj]": 433.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.6,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.6,
    "amido [g]": 13.3,
    "fibra  [g]": 6.7,
    "prote\u00ednas [g]": 6.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.7,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 43.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 65.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 47.0,
    "ferro [mg]": 2.5,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Feij\u00e3o branco, cru, seco",
    "energia[kcal]": 308.0,
    "energia[kj]": 1300.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.01,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 43.9,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 3.8,
    "amido [g]": 41.9,
    "fibra  [g]": 22.9,
    "prote\u00ednas [g]": 21.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 4.6,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 300.0,
    "cinza [g]": 3.95,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 1490.0,
    "c\u00e1lcio [mg]": 150.0,
    "f\u00f3sforo [mg]": 480.0,
    "magn\u00e9sio [mg]": 160.0,
    "ferro [mg]": 6.5,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 17.0,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Feij\u00e3o catarino, cru, seco",
    "energia[kcal]": 302.0,
    "energia[kj]": 1280.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.01,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 47.9,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 36.5,
    "fibra  [g]": 14.0,
    "prote\u00ednas [g]": 20.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 13.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.65,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.42,
    "equivalentes de niacina [mg]": 5.12,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 130.0,
    "cinza [g]": 3.44,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 1270.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 400.0,
    "magn\u00e9sio [mg]": 150.0,
    "ferro [mg]": 7.2,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 2.8,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Feij\u00e3o encarnado, cru, seco",
    "energia[kcal]": 310.0,
    "energia[kj]": 1310.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 47.9,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 46.2,
    "fibra  [g]": 14.0,
    "prote\u00ednas [g]": 21.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.65,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 5.8,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 130.0,
    "cinza [g]": 3.67,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 1330.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 420.0,
    "magn\u00e9sio [mg]": 150.0,
    "ferro [mg]": 8.0,
    "zinco [mg]": 2.9,
    "sel\u00e9nio [\u00b5g]": 15.0,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Feij\u00e3o frade, cru, seco",
    "energia[kcal]": 339.0,
    "energia[kj]": 1430.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 55.3,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 3.9,
    "amido [g]": 53.6,
    "fibra  [g]": 9.4,
    "prote\u00ednas [g]": 21.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 30.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.54,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 1.01,
    "equivalentes de niacina [mg]": 6.9,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 630.0,
    "cinza [g]": 3.3,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 1150.0,
    "c\u00e1lcio [mg]": 62.0,
    "f\u00f3sforo [mg]": 340.0,
    "magn\u00e9sio [mg]": 170.0,
    "ferro [mg]": 5.5,
    "zinco [mg]": 3.4,
    "sel\u00e9nio [\u00b5g]": 50.0,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Feij\u00e3o frade, demolhado, cozido",
    "energia[kcal]": 123.0,
    "energia[kj]": 521.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.1,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.7,
    "amido [g]": 16.4,
    "fibra  [g]": 4.7,
    "prote\u00ednas [g]": 8.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 13.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 2.4,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 210.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 47.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Feij\u00e3o manteiga cozido (demolhado)",
    "energia[kcal]": 107.0,
    "energia[kj]": 449.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.0,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 1.7,
    "amido [g]": 11.5,
    "fibra  [g]": 7.0,
    "prote\u00ednas [g]": 7.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 43.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 50.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 51.0,
    "ferro [mg]": 2.7,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Feij\u00e3o manteiga, cru, seco",
    "energia[kcal]": 313.0,
    "energia[kj]": 1320.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.01,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 42.6,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 3.6,
    "amido [g]": 40.9,
    "fibra  [g]": 22.9,
    "prote\u00ednas [g]": 24.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.61,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.57,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.6,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 360.0,
    "cinza [g]": 4.01,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 1630.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 420.0,
    "magn\u00e9sio [mg]": 140.0,
    "ferro [mg]": 6.9,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 8.5,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Feij\u00e3o preto, cru, seco",
    "energia[kcal]": 318.0,
    "energia[kj]": 1340.0,
    "l\u00edpidos[g]": 1.0,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 47.9,
    "a\u00e7\u00facares [g]": 4.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 32.0,
    "fibra  [g]": 14.0,
    "prote\u00ednas [g]": 22.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.65,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 4.2,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 130.0,
    "cinza [g]": 3.7,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 1530.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 370.0,
    "magn\u00e9sio [mg]": 160.0,
    "ferro [mg]": 6.7,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 8.8,
    "iodo [\u00b5g]": 73.0
  },
  {
    "name": "Feij\u00e3o-verde fresco cozido",
    "energia[kcal]": 30.0,
    "energia[kj]": 125.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 239.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 11.0,
    "folatos [\u00b5g]": 49.0,
    "cinza [g]": 0.65,
    "s\u00f3dio [mg]": 98.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 41.0,
    "f\u00f3sforo [mg]": 34.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Feij\u00e3o-verde fresco cru",
    "energia[kcal]": 32.0,
    "energia[kj]": 132.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.8,
    "a\u00e7\u00facares [g]": 2.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 1.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 43.0,
    "caroteno [\u00b5g]": 260.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 17.0,
    "folatos [\u00b5g]": 80.0,
    "cinza [g]": 0.98,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 40.0,
    "f\u00f3sforo [mg]": 35.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Feijoa (polpa)",
    "energia[kcal]": 52.0,
    "energia[kj]": 221.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.8,
    "a\u00e7\u00facares [g]": 9.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 31.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 29.0,
    "folatos [\u00b5g]": 38.0,
    "cinza [g]": 0.74,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Feijoada com carne de porco",
    "energia[kcal]": 187.0,
    "energia[kj]": 784.0,
    "l\u00edpidos[g]": 7.2,
    "\u00e1cidos gordos saturados[g]": 2.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.886,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 13.3,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 1.2,
    "amido [g]": 10.0,
    "fibra  [g]": 6.8,
    "prote\u00ednas [g]": 13.6,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.2,
    "\u00e1gua [g]": 56.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 25.0,
    "vitamina a  [\u00b5g]": 135.0,
    "caroteno [\u00b5g]": 808.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.69,
    "tiamina [mg]": 0.23,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 5.39,
    "triptofano/60 [mg]": 2.35,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.26,
    "vitamina c [mg]": 3.4,
    "folatos [\u00b5g]": 54.0,
    "cinza [g]": 2.57,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 490.0,
    "c\u00e1lcio [mg]": 65.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 60.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Feijoada com carne de porco e de vaca",
    "energia[kcal]": 191.0,
    "energia[kj]": 797.0,
    "l\u00edpidos[g]": 7.5,
    "\u00e1cidos gordos saturados[g]": 2.3,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.768,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 13.3,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 1.2,
    "amido [g]": 10.0,
    "fibra  [g]": 6.8,
    "prote\u00ednas [g]": 13.7,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.2,
    "\u00e1gua [g]": 55.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 26.0,
    "vitamina a  [\u00b5g]": 135.0,
    "caroteno [\u00b5g]": 808.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.58,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 4.92,
    "triptofano/60 [mg]": 2.34,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.35,
    "vitamina c [mg]": 3.4,
    "folatos [\u00b5g]": 55.0,
    "cinza [g]": 2.56,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 490.0,
    "c\u00e1lcio [mg]": 65.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 60.0,
    "ferro [mg]": 2.4,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Feijoada com enchidos e ovos",
    "energia[kcal]": 240.0,
    "energia[kj]": 998.0,
    "l\u00edpidos[g]": 15.1,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 6.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.04,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.1,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 1.0,
    "amido [g]": 11.0,
    "fibra  [g]": 5.8,
    "prote\u00ednas [g]": 9.0,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 54.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 74.0,
    "vitamina a  [\u00b5g]": 49.0,
    "caroteno [\u00b5g]": 130.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.93,
    "tiamina [mg]": 0.24,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.91,
    "triptofano/60 [mg]": 1.82,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.31,
    "vitamina c [mg]": 7.1,
    "folatos [\u00b5g]": 95.0,
    "cinza [g]": 1.95,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 470.0,
    "c\u00e1lcio [mg]": 59.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 44.0,
    "ferro [mg]": 2.7,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Fermento em p\u00f3",
    "energia[kcal]": 156.0,
    "energia[kj]": 665.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 34.4,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 34.4,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.7,
    "sal  [g]": 46.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 47.5,
    "s\u00f3dio [mg]": 18500.0,
    "pot\u00e1ssio [mg]": 50.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 10500.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Fermento fresco de padeiro",
    "energia[kcal]": 78.0,
    "energia[kj]": 328.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 7.3,
    "prote\u00ednas [g]": 12.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.81,
    "riboflavina [mg]": 1.7,
    "niacina [mg]": 13.0,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 2.0,
    "vitamina b6 [mg]": 0.72,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 810.0,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 23.0,
    "pot\u00e1ssio [mg]": 620.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 420.0,
    "magn\u00e9sio [mg]": 55.0,
    "ferro [mg]": 3.9,
    "zinco [mg]": 5.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Fermento seco de padeiro",
    "energia[kcal]": 228.0,
    "energia[kj]": 956.0,
    "l\u00edpidos[g]": 2.5,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.2,
    "fibra  [g]": 19.7,
    "prote\u00ednas [g]": 38.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 2.3,
    "riboflavina [mg]": 4.7,
    "niacina [mg]": 8.2,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 6.7,
    "vitamina b6 [mg]": 1.9,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2500.0,
    "cinza [g]": 7.7,
    "s\u00f3dio [mg]": 48.0,
    "pot\u00e1ssio [mg]": 2000.0,
    "c\u00e1lcio [mg]": 63.0,
    "f\u00f3sforo [mg]": 1250.0,
    "magn\u00e9sio [mg]": 170.0,
    "ferro [mg]": 18.0,
    "zinco [mg]": 9.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Fiambre de aves",
    "energia[kcal]": 83.0,
    "energia[kj]": 351.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.35,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 13.8,
    "sal  [g]": 2.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 6.4,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 3.38,
    "s\u00f3dio [mg]": 910.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.0
  },
  {
    "name": "Fiambre de porco",
    "energia[kcal]": 111.0,
    "energia[kj]": 466.0,
    "l\u00edpidos[g]": 4.6,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.55,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 15.3,
    "sal  [g]": 2.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.32,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 6.4,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 3.4,
    "s\u00f3dio [mg]": 940.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.0
  },
  {
    "name": "Fiambre, p\u00e1",
    "energia[kcal]": 112.0,
    "energia[kj]": 467.0,
    "l\u00edpidos[g]": 5.1,
    "\u00e1cidos gordos saturados[g]": 1.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 14.0,
    "sal  [g]": 2.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.28,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 6.4,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 3.34,
    "s\u00f3dio [mg]": 940.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.0
  },
  {
    "name": "Fiambre, peito de frango",
    "energia[kcal]": 82.0,
    "energia[kj]": 344.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.8,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 13.7,
    "sal  [g]": 2.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 6.4,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 3.58,
    "s\u00f3dio [mg]": 950.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.0
  },
  {
    "name": "Fiambre, peito de peru",
    "energia[kcal]": 85.0,
    "energia[kj]": 359.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 13.8,
    "sal  [g]": 2.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 6.4,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 3.17,
    "s\u00f3dio [mg]": 870.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.0
  },
  {
    "name": "Fiambre, perna",
    "energia[kcal]": 111.0,
    "energia[kj]": 464.0,
    "l\u00edpidos[g]": 4.1,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 16.5,
    "sal  [g]": 2.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.36,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 6.4,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 3.46,
    "s\u00f3dio [mg]": 930.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.0
  },
  {
    "name": "F\u00edgado de galinha",
    "energia[kcal]": 92.0,
    "energia[kj]": 386.0,
    "l\u00edpidos[g]": 2.3,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.26,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 380.0,
    "vitamina a  [\u00b5g]": 9700.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.48,
    "riboflavina [mg]": 2.16,
    "niacina [mg]": 10.6,
    "equivalentes de niacina [mg]": 14.7,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.82,
    "vitamina b12 [\u00b5g]": 35.0,
    "vitamina c [mg]": 28.0,
    "folatos [\u00b5g]": 995.0,
    "cinza [g]": 1.46,
    "s\u00f3dio [mg]": 76.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 9.2,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de porco, cru",
    "energia[kcal]": 129.0,
    "energia[kj]": 540.0,
    "l\u00edpidos[g]": 5.0,
    "\u00e1cidos gordos saturados[g]": 1.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 237.0,
    "vitamina a  [\u00b5g]": 9000.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 0.33,
    "tiamina [mg]": 0.41,
    "riboflavina [mg]": 3.3,
    "niacina [mg]": 16.0,
    "equivalentes de niacina [mg]": 20.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.58,
    "vitamina b12 [\u00b5g]": 23.0,
    "vitamina c [mg]": 28.0,
    "folatos [\u00b5g]": 290.0,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 320.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 7.8,
    "zinco [mg]": 3.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de porco, frito com margarina e banha",
    "energia[kcal]": 211.0,
    "energia[kj]": 879.0,
    "l\u00edpidos[g]": 13.1,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.03,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.2,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 267.0,
    "vitamina a  [\u00b5g]": 10000.0,
    "caroteno [\u00b5g]": 17.0,
    "vitamina d [\u00b5g]": 1.2,
    "alfa-tocoferol [mg]": 0.41,
    "tiamina [mg]": 0.39,
    "riboflavina [mg]": 3.5,
    "niacina [mg]": 15.0,
    "equivalentes de niacina [mg]": 22.2,
    "triptofano/60 [mg]": 5.0,
    "vitamina b6 [mg]": 0.52,
    "vitamina b12 [\u00b5g]": 19.0,
    "vitamina c [mg]": 23.3,
    "folatos [\u00b5g]": 270.0,
    "cinza [g]": 2.84,
    "s\u00f3dio [mg]": 530.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 8.3,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de porco, frito, sem molho",
    "energia[kcal]": 180.0,
    "energia[kj]": 754.0,
    "l\u00edpidos[g]": 8.8,
    "\u00e1cidos gordos saturados[g]": 3.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.2,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 256.0,
    "vitamina a  [\u00b5g]": 10300.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.44,
    "riboflavina [mg]": 4.0,
    "niacina [mg]": 17.0,
    "equivalentes de niacina [mg]": 22.0,
    "triptofano/60 [mg]": 5.4,
    "vitamina b6 [mg]": 0.59,
    "vitamina b12 [\u00b5g]": 22.0,
    "vitamina c [mg]": 27.0,
    "folatos [\u00b5g]": 310.0,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 330.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 9.4,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de porco, grelhado",
    "energia[kcal]": 162.0,
    "energia[kj]": 680.0,
    "l\u00edpidos[g]": 6.3,
    "\u00e1cidos gordos saturados[g]": 2.2,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.3,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 267.0,
    "vitamina a  [\u00b5g]": 10700.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.4,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.46,
    "riboflavina [mg]": 4.2,
    "niacina [mg]": 17.0,
    "equivalentes de niacina [mg]": 23.0,
    "triptofano/60 [mg]": 5.6,
    "vitamina b6 [mg]": 0.61,
    "vitamina b12 [\u00b5g]": 23.0,
    "vitamina c [mg]": 28.0,
    "folatos [\u00b5g]": 330.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 340.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 9.8,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de vaca, cru",
    "energia[kcal]": 132.0,
    "energia[kj]": 554.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 1.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 2.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.1,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 283.0,
    "vitamina a  [\u00b5g]": 9600.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 0.42,
    "tiamina [mg]": 0.45,
    "riboflavina [mg]": 3.2,
    "niacina [mg]": 10.0,
    "equivalentes de niacina [mg]": 14.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.87,
    "vitamina b12 [\u00b5g]": 110.0,
    "vitamina c [mg]": 27.0,
    "folatos [\u00b5g]": 330.0,
    "cinza [g]": 1.42,
    "s\u00f3dio [mg]": 130.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 5.7,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de vaca, frito, sem molho",
    "energia[kcal]": 171.0,
    "energia[kj]": 718.0,
    "l\u00edpidos[g]": 7.0,
    "\u00e1cidos gordos saturados[g]": 2.9,
    "\u00e1cidos gordos monoinsaturados [g]": 2.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 2.5,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.5,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.5,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 297.0,
    "vitamina a  [\u00b5g]": 10700.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 1.3,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.47,
    "riboflavina [mg]": 3.8,
    "niacina [mg]": 10.0,
    "equivalentes de niacina [mg]": 16.0,
    "triptofano/60 [mg]": 5.5,
    "vitamina b6 [mg]": 0.86,
    "vitamina b12 [\u00b5g]": 100.0,
    "vitamina c [mg]": 24.0,
    "folatos [\u00b5g]": 350.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 6.3,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de vitela, cru",
    "energia[kcal]": 119.0,
    "energia[kj]": 500.0,
    "l\u00edpidos[g]": 3.5,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 1.5,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.5,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.3,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 286.0,
    "vitamina a  [\u00b5g]": 11900.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.42,
    "tiamina [mg]": 0.26,
    "riboflavina [mg]": 1.6,
    "niacina [mg]": 11.0,
    "equivalentes de niacina [mg]": 16.0,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.52,
    "vitamina b12 [\u00b5g]": 64.0,
    "vitamina c [mg]": 30.0,
    "folatos [\u00b5g]": 160.0,
    "cinza [g]": 1.37,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 320.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 6.5,
    "zinco [mg]": 5.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de vitela, frito com banha e manteiga",
    "energia[kcal]": 201.0,
    "energia[kj]": 839.0,
    "l\u00edpidos[g]": 11.5,
    "\u00e1cidos gordos saturados[g]": 4.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.599,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.7,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.6,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 331.0,
    "vitamina a  [\u00b5g]": 13200.0,
    "caroteno [\u00b5g]": 2.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.59,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 1.7,
    "niacina [mg]": 10.0,
    "equivalentes de niacina [mg]": 17.8,
    "triptofano/60 [mg]": 5.33,
    "vitamina b6 [mg]": 0.46,
    "vitamina b12 [\u00b5g]": 53.0,
    "vitamina c [mg]": 25.0,
    "folatos [\u00b5g]": 150.0,
    "cinza [g]": 2.52,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 6.9,
    "zinco [mg]": 4.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de vitela, frito, sem molho",
    "energia[kcal]": 179.0,
    "energia[kj]": 751.0,
    "l\u00edpidos[g]": 8.3,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 1.5,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.5,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.6,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 310.0,
    "vitamina a  [\u00b5g]": 11400.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.54,
    "tiamina [mg]": 0.28,
    "riboflavina [mg]": 2.0,
    "niacina [mg]": 12.0,
    "equivalentes de niacina [mg]": 17.0,
    "triptofano/60 [mg]": 5.4,
    "vitamina b6 [mg]": 0.53,
    "vitamina b12 [\u00b5g]": 69.0,
    "vitamina c [mg]": 31.0,
    "folatos [\u00b5g]": 170.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 330.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 7.9,
    "zinco [mg]": 5.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "F\u00edgado de vitela, grelhado",
    "energia[kcal]": 153.0,
    "energia[kj]": 643.0,
    "l\u00edpidos[g]": 4.7,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 2.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 1.9,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.9,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.7,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 387.0,
    "vitamina a  [\u00b5g]": 14200.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.56,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 2.4,
    "niacina [mg]": 15.0,
    "equivalentes de niacina [mg]": 21.0,
    "triptofano/60 [mg]": 6.0,
    "vitamina b6 [mg]": 0.66,
    "vitamina b12 [\u00b5g]": 87.0,
    "vitamina c [mg]": 38.0,
    "folatos [\u00b5g]": 210.0,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 490.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 410.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 9.8,
    "zinco [mg]": 6.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Figo (5 variedades)",
    "energia[kcal]": 79.0,
    "energia[kj]": 332.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 16.3,
    "a\u00e7\u00facares [g]": 16.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 50.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.77,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.61,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 29.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Figo cristalizado",
    "energia[kcal]": 295.0,
    "energia[kj]": 1250.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 71.2,
    "a\u00e7\u00facares [g]": 71.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 25.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.48,
    "s\u00f3dio [mg]": 65.0,
    "pot\u00e1ssio [mg]": 18.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 11.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Figo seco",
    "energia[kcal]": 270.0,
    "energia[kj]": 1140.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 58.3,
    "a\u00e7\u00facares [g]": 58.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 11.0,
    "prote\u00ednas [g]": 2.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 25.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 65.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 62.0,
    "pot\u00e1ssio [mg]": 940.0,
    "c\u00e1lcio [mg]": 240.0,
    "f\u00f3sforo [mg]": 72.0,
    "magn\u00e9sio [mg]": 86.0,
    "ferro [mg]": 2.6,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Filetes de bacalhau fritos",
    "energia[kcal]": 194.0,
    "energia[kj]": 811.0,
    "l\u00edpidos[g]": 10.7,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 6.0,
    "\u00e1cido linoleico [g]": 5.89,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.0,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 19.2,
    "sal  [g]": 2.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 104.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 3.7,
    "alfa-tocoferol [mg]": 5.94,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.68,
    "equivalentes de niacina [mg]": 4.53,
    "triptofano/60 [mg]": 3.75,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.89,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 3.17,
    "s\u00f3dio [mg]": 1140.0,
    "pot\u00e1ssio [mg]": 54.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Fis\u00e1lis",
    "energia[kcal]": 68.0,
    "energia[kj]": 287.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.04,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.7,
    "a\u00e7\u00facares [g]": 11.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 4.2,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 275.0,
    "caroteno [\u00b5g]": 1650.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 1.8,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 45.5,
    "folatos [\u00b5g]": 50.5,
    "cinza [g]": 4.21,
    "s\u00f3dio [mg]": 0.9,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 8.3,
    "f\u00f3sforo [mg]": 39.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 1.6,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Flocos de arroz",
    "energia[kcal]": 379.0,
    "energia[kj]": 1610.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 83.4,
    "a\u00e7\u00facares [g]": 7.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 76.4,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 7.0,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 4.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 3.9,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.52,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 94.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Flocos de aveia",
    "energia[kcal]": 366.0,
    "energia[kj]": 1550.0,
    "l\u00edpidos[g]": 5.8,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 61.7,
    "a\u00e7\u00facares [g]": 3.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 58.7,
    "fibra  [g]": 6.7,
    "prote\u00ednas [g]": 13.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.27,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 5.5,
    "triptofano/60 [mg]": 3.1,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 56.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 40.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 4.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Flocos de centeio",
    "energia[kcal]": 352.0,
    "energia[kj]": 1490.0,
    "l\u00edpidos[g]": 2.0,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 69.0,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 68.0,
    "fibra  [g]": 5.0,
    "prote\u00ednas [g]": 12.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 14.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 3.4,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 78.0,
    "cinza [g]": 1.13,
    "s\u00f3dio [mg]": 40.0,
    "pot\u00e1ssio [mg]": 530.0,
    "c\u00e1lcio [mg]": 64.0,
    "f\u00f3sforo [mg]": 370.0,
    "magn\u00e9sio [mg]": 110.0,
    "ferro [mg]": 3.7,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 2.0,
    "iodo [\u00b5g]": 4.6
  },
  {
    "name": "Flocos de cereais e frutos secos tipo \"Muesli\"",
    "energia[kcal]": 388.0,
    "energia[kj]": 1640.0,
    "l\u00edpidos[g]": 6.3,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.6,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 69.1,
    "a\u00e7\u00facares [g]": 16.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 52.3,
    "fibra  [g]": 6.8,
    "prote\u00ednas [g]": 10.4,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 3.2,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 3.3,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 1.6,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 130.0,
    "cinza [g]": 1.67,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 86.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Flocos de cevada",
    "energia[kcal]": 324.0,
    "energia[kj]": 1370.0,
    "l\u00edpidos[g]": 2.0,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.621,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 64.4,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 63.5,
    "fibra  [g]": 7.6,
    "prote\u00ednas [g]": 8.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 5.7,
    "equivalentes de niacina [mg]": 7.7,
    "triptofano/60 [mg]": 2.0,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 70.0,
    "ferro [mg]": 3.2,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 8.6,
    "iodo [\u00b5g]": 10.0
  },
  {
    "name": "Flocos de milho tipo \"Corn Flakes\"",
    "energia[kcal]": 374.0,
    "energia[kj]": 1580.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 81.1,
    "a\u00e7\u00facares [g]": 6.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 74.9,
    "fibra  [g]": 3.9,
    "prote\u00ednas [g]": 7.9,
    "sal  [g]": 1.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.8,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.48,
    "riboflavina [mg]": 0.9,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 1.8,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 167.0,
    "cinza [g]": 3.07,
    "s\u00f3dio [mg]": 700.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 2.0,
    "f\u00f3sforo [mg]": 47.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Flocos de trigo",
    "energia[kcal]": 373.0,
    "energia[kj]": 1580.0,
    "l\u00edpidos[g]": 2.5,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 69.0,
    "a\u00e7\u00facares [g]": 6.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 62.2,
    "fibra  [g]": 9.3,
    "prote\u00ednas [g]": 14.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.0,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 4.1,
    "equivalentes de niacina [mg]": 5.9,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 32.0,
    "cinza [g]": 1.61,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 140.0,
    "ferro [mg]": 2.4,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Flocos de trigo com figos tipo \"Nestum\"",
    "energia[kcal]": 370.0,
    "energia[kj]": 1570.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 76.3,
    "a\u00e7\u00facares [g]": 28.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 48.3,
    "fibra  [g]": 7.7,
    "prote\u00ednas [g]": 9.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 210.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 6.3,
    "alfa-tocoferol [mg]": 5.0,
    "tiamina [mg]": 0.5,
    "riboflavina [mg]": 0.5,
    "niacina [mg]": 8.0,
    "equivalentes de niacina [mg]": 9.1,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 65.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 55.0,
    "ferro [mg]": 15.0,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Flocos de trigo com mel tipo \"Nestum\"",
    "energia[kcal]": 385.0,
    "energia[kj]": 1630.0,
    "l\u00edpidos[g]": 1.0,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 84.6,
    "a\u00e7\u00facares [g]": 29.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 55.6,
    "fibra  [g]": 2.4,
    "prote\u00ednas [g]": 8.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 210.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 6.3,
    "alfa-tocoferol [mg]": 5.0,
    "tiamina [mg]": 0.5,
    "riboflavina [mg]": 0.51,
    "niacina [mg]": 8.0,
    "equivalentes de niacina [mg]": 9.3,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 65.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 30.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 15.0,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Flocos de trigo e arroz enriquecidos com vitaminas, c\u00e1lcio e ferro",
    "energia[kcal]": 372.0,
    "energia[kj]": 1570.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 79.2,
    "a\u00e7\u00facares [g]": 17.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 62.0,
    "fibra  [g]": 6.5,
    "prote\u00ednas [g]": 7.5,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 17.0,
    "tiamina [mg]": 2.4,
    "riboflavina [mg]": 2.7,
    "niacina [mg]": 31.0,
    "equivalentes de niacina [mg]": 33.0,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 3.4,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 102.0,
    "folatos [\u00b5g]": 340.0,
    "cinza [g]": 2.5,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 500.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 50.0,
    "ferro [mg]": 24.0,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Flocos de trigo integral tipo \"All-Bran Flakes\"",
    "energia[kcal]": 349.0,
    "energia[kj]": 1470.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 64.2,
    "a\u00e7\u00facares [g]": 18.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 46.1,
    "fibra  [g]": 17.3,
    "prote\u00ednas [g]": 10.2,
    "sal  [g]": 2.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.8,
    "alfa-tocoferol [mg]": 2.2,
    "tiamina [mg]": 1.0,
    "riboflavina [mg]": 1.5,
    "niacina [mg]": 16.0,
    "equivalentes de niacina [mg]": 18.0,
    "triptofano/60 [mg]": 2.4,
    "vitamina b6 [mg]": 1.8,
    "vitamina b12 [\u00b5g]": 0.8,
    "vitamina c [mg]": 35.0,
    "folatos [\u00b5g]": 250.0,
    "cinza [g]": 3.4,
    "s\u00f3dio [mg]": 910.0,
    "pot\u00e1ssio [mg]": 540.0,
    "c\u00e1lcio [mg]": 50.0,
    "f\u00f3sforo [mg]": 450.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 12.0,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Flor de sal",
    "energia[kcal]": 0.0,
    "energia[kj]": 0.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 100.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 98.0,
    "s\u00f3dio [mg]": 40000.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 300.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Folhas de rabanete cruas",
    "energia[kcal]": 39.0,
    "energia[kj]": 165.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.9,
    "a\u00e7\u00facares [g]": 3.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.2,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 3.5,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 612.0,
    "caroteno [\u00b5g]": 3670.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.35,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.6,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 63.0,
    "folatos [\u00b5g]": 90.0,
    "cinza [g]": 0.81,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 200.0,
    "f\u00f3sforo [mg]": 44.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 3.8,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Framboesa",
    "energia[kcal]": 49.0,
    "energia[kj]": 203.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.1,
    "a\u00e7\u00facares [g]": 5.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 6.7,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.3,
    "\u00e1cidos org\u00e2nicos [g]": 1.9,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 30.0,
    "folatos [\u00b5g]": 33.0,
    "cinza [g]": 0.54,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 23.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Framboesa desidratada",
    "energia[kcal]": 293.0,
    "energia[kj]": 1230.0,
    "l\u00edpidos[g]": 3.6,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 30.9,
    "a\u00e7\u00facares [g]": 30.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 40.5,
    "prote\u00ednas [g]": 5.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 11.5,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.21,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 3.63,
    "equivalentes de niacina [mg]": 4.84,
    "triptofano/60 [mg]": 1.21,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 199.7,
    "cinza [g]": 3.27,
    "s\u00f3dio [mg]": 6.1,
    "pot\u00e1ssio [mg]": 1390.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 1.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Francesinha, com molho",
    "energia[kcal]": 249.0,
    "energia[kj]": 1040.0,
    "l\u00edpidos[g]": 17.7,
    "\u00e1cidos gordos saturados[g]": 6.3,
    "\u00e1cidos gordos monoinsaturados [g]": 6.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.69,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 6.5,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.4,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 13.3,
    "sal  [g]": 3.2,
    "\u00e1lcool [g]": 1.4,
    "\u00e1gua [g]": 55.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 39.0,
    "vitamina a  [\u00b5g]": 34.0,
    "caroteno [\u00b5g]": 79.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.26,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 5.07,
    "triptofano/60 [mg]": 2.63,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 1.4,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 3.95,
    "s\u00f3dio [mg]": 1270.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 88.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango (1/4) peito e asa com pele, cozidos",
    "energia[kcal]": 202.0,
    "energia[kj]": 844.0,
    "l\u00edpidos[g]": 10.3,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 3.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 1.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.2,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 132.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 7.3,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.1,
    "vitamina b6 [mg]": 0.33,
    "vitamina b12 [\u00b5g]": 0.44,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.2,
    "cinza [g]": 1.32,
    "s\u00f3dio [mg]": 340.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango (1/4) peito e asa com pele, estufados com azeite e margarina",
    "energia[kcal]": 204.0,
    "energia[kj]": 849.0,
    "l\u00edpidos[g]": 13.5,
    "\u00e1cidos gordos saturados[g]": 3.3,
    "\u00e1cidos gordos monoinsaturados [g]": 4.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.41,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 18.5,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 79.0,
    "vitamina a  [\u00b5g]": 53.0,
    "caroteno [\u00b5g]": 180.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 3.24,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.27,
    "vitamina c [mg]": 7.1,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.03,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango (1/4) peito e asa com pele, estufados com margarina",
    "energia[kcal]": 201.0,
    "energia[kj]": 837.0,
    "l\u00edpidos[g]": 13.2,
    "\u00e1cidos gordos saturados[g]": 3.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.59,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 18.5,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 79.0,
    "vitamina a  [\u00b5g]": 61.0,
    "caroteno [\u00b5g]": 185.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.52,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 3.24,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.27,
    "vitamina c [mg]": 7.1,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.07,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango (1/4) peito e asa com pele, estufados sem molho",
    "energia[kcal]": 223.0,
    "energia[kj]": 933.0,
    "l\u00edpidos[g]": 11.8,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.2,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 29.2,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 120.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 7.6,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 5.5,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 0.47,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.5,
    "cinza [g]": 1.63,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango (1/4) peito e asa com pele, grelhados",
    "energia[kcal]": 235.0,
    "energia[kj]": 984.0,
    "l\u00edpidos[g]": 13.4,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 4.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.7,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 55.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 108.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 9.2,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 5.4,
    "vitamina b6 [mg]": 0.31,
    "vitamina b12 [\u00b5g]": 0.54,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.2,
    "cinza [g]": 1.99,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango (1/4) perna com costa e pele, assada sem molho",
    "energia[kcal]": 231.0,
    "energia[kj]": 964.0,
    "l\u00edpidos[g]": 13.7,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 4.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.9,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 131.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.38,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.0,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.91,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.6,
    "cinza [g]": 2.28,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango (1/4) perna com costa e pele, cozida",
    "energia[kcal]": 197.0,
    "energia[kj]": 826.0,
    "l\u00edpidos[g]": 10.6,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 1.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 139.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 9.4,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.74,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 1.53,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango (1/4) perna com costa e pele, estufada com azeite e margarina",
    "energia[kcal]": 203.0,
    "energia[kj]": 846.0,
    "l\u00edpidos[g]": 13.8,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.41,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 17.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 95.0,
    "vitamina a  [\u00b5g]": 62.0,
    "caroteno [\u00b5g]": 180.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.78,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 7.96,
    "triptofano/60 [mg]": 3.06,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.44,
    "vitamina c [mg]": 7.1,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.24,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango (1/4) perna com costa e pele, estufada com margarina",
    "energia[kcal]": 200.0,
    "energia[kj]": 835.0,
    "l\u00edpidos[g]": 13.5,
    "\u00e1cidos gordos saturados[g]": 3.7,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.0,
    "\u00e1cido linoleico [g]": 2.59,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 17.3,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 96.0,
    "vitamina a  [\u00b5g]": 70.0,
    "caroteno [\u00b5g]": 185.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 7.96,
    "triptofano/60 [mg]": 3.06,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.44,
    "vitamina c [mg]": 7.1,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.28,
    "s\u00f3dio [mg]": 460.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango (1/4) perna com costa e pele, estufada sem molho",
    "energia[kcal]": 215.0,
    "energia[kj]": 898.0,
    "l\u00edpidos[g]": 11.9,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.9,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 140.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.39,
    "niacina [mg]": 4.8,
    "equivalentes de niacina [mg]": 9.8,
    "triptofano/60 [mg]": 5.0,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.76,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.83,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango (1/4), peito e asa com pele, crus",
    "energia[kcal]": 196.0,
    "energia[kj]": 817.0,
    "l\u00edpidos[g]": 12.7,
    "\u00e1cidos gordos saturados[g]": 3.0,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.6,
    "\u00e1cido linoleico [g]": 2.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.4,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 89.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 8.2,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 61.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango (1/4), perna com costa e pele, crua",
    "energia[kcal]": 195.0,
    "energia[kj]": 814.0,
    "l\u00edpidos[g]": 13.0,
    "\u00e1cidos gordos saturados[g]": 3.1,
    "\u00e1cidos gordos monoinsaturados [g]": 4.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.6,
    "colesterol [mg]": 108.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 5.2,
    "equivalentes de niacina [mg]": 8.6,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.8,
    "cinza [g]": 1.03,
    "s\u00f3dio [mg]": 81.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango (1/4), perna com costa e pele, grelhada",
    "energia[kcal]": 231.0,
    "energia[kj]": 964.0,
    "l\u00edpidos[g]": 13.7,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 4.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.9,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 131.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.38,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.0,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.91,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.6,
    "cinza [g]": 2.28,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango inteiro com pele, assado sem molho",
    "energia[kcal]": 239.0,
    "energia[kj]": 998.0,
    "l\u00edpidos[g]": 14.3,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 4.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.6,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.6,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 55.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 139.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 7.5,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 0.73,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.9,
    "cinza [g]": 2.08,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango inteiro com pele, cozido",
    "energia[kcal]": 204.0,
    "energia[kj]": 854.0,
    "l\u00edpidos[g]": 11.1,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.1,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 128.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.35,
    "niacina [mg]": 6.1,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.59,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 1.39,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango inteiro com pele, cru",
    "energia[kcal]": 201.0,
    "energia[kj]": 836.0,
    "l\u00edpidos[g]": 13.6,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 4.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 99.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 6.6,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.4,
    "cinza [g]": 0.88,
    "s\u00f3dio [mg]": 70.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango inteiro com pele, estufado com azeite e margarina",
    "energia[kcal]": 224.0,
    "energia[kj]": 931.0,
    "l\u00edpidos[g]": 15.4,
    "\u00e1cidos gordos saturados[g]": 3.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.6,
    "\u00e1cidos gordos polinsaturados [g]": 3.1,
    "\u00e1cido linoleico [g]": 2.69,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.8,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 19.1,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 58.0,
    "caroteno [\u00b5g]": 194.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.84,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 4.1,
    "equivalentes de niacina [mg]": 9.9,
    "triptofano/60 [mg]": 3.29,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.38,
    "vitamina c [mg]": 7.7,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.26,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango inteiro com pele, estufado com margarina",
    "energia[kcal]": 221.0,
    "energia[kj]": 920.0,
    "l\u00edpidos[g]": 15.1,
    "\u00e1cidos gordos saturados[g]": 4.1,
    "\u00e1cidos gordos monoinsaturados [g]": 4.8,
    "\u00e1cidos gordos polinsaturados [g]": 3.3,
    "\u00e1cido linoleico [g]": 2.88,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.8,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 19.1,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 66.0,
    "caroteno [\u00b5g]": 199.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.65,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 4.1,
    "equivalentes de niacina [mg]": 9.9,
    "triptofano/60 [mg]": 3.29,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.38,
    "vitamina c [mg]": 7.7,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.31,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango inteiro com pele, estufado sem molho",
    "energia[kcal]": 233.0,
    "energia[kj]": 974.0,
    "l\u00edpidos[g]": 13.1,
    "\u00e1cidos gordos saturados[g]": 3.1,
    "\u00e1cidos gordos monoinsaturados [g]": 4.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.8,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 138.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.01,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 6.4,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.4,
    "vitamina b6 [mg]": 0.49,
    "vitamina b12 [\u00b5g]": 0.64,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.5,
    "cinza [g]": 1.76,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango inteiro com pele, grelhado",
    "energia[kcal]": 239.0,
    "energia[kj]": 998.0,
    "l\u00edpidos[g]": 14.3,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 4.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.6,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.6,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 55.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 139.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 7.5,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 0.73,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.9,
    "cinza [g]": 2.08,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango inteiro sem pele, cozido",
    "energia[kcal]": 169.0,
    "energia[kj]": 713.0,
    "l\u00edpidos[g]": 4.2,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 32.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 117.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 6.8,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 6.1,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 0.49,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.5,
    "cinza [g]": 1.46,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango inteiro sem pele, cru",
    "energia[kcal]": 110.0,
    "energia[kj]": 463.0,
    "l\u00edpidos[g]": 2.0,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 87.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 8.0,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.72,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.03,
    "s\u00f3dio [mg]": 77.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango inteiro sem pele, estufado com azeite e margarina",
    "energia[kcal]": 129.0,
    "energia[kj]": 543.0,
    "l\u00edpidos[g]": 4.1,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.739,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 21.0,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 183.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 11.1,
    "triptofano/60 [mg]": 3.91,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 0.32,
    "vitamina c [mg]": 7.2,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.27,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango inteiro sem pele, estufado com margarina",
    "energia[kcal]": 126.0,
    "energia[kj]": 532.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.921,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 21.0,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 53.0,
    "caroteno [\u00b5g]": 188.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.61,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 11.1,
    "triptofano/60 [mg]": 3.91,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 0.32,
    "vitamina c [mg]": 7.2,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.31,
    "s\u00f3dio [mg]": 460.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Frango inteiro sem pele, estufado sem molho",
    "energia[kcal]": 185.0,
    "energia[kj]": 779.0,
    "l\u00edpidos[g]": 5.8,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 33.2,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 137.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 7.6,
    "equivalentes de niacina [mg]": 14.0,
    "triptofano/60 [mg]": 6.2,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.55,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.89,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango inteiro sem pele, grelhado",
    "energia[kcal]": 168.0,
    "energia[kj]": 708.0,
    "l\u00edpidos[g]": 4.9,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 31.0,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 117.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 8.8,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 6.0,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 2.19,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Frango, pele crua",
    "energia[kcal]": 474.0,
    "energia[kj]": 1950.0,
    "l\u00edpidos[g]": 48.3,
    "\u00e1cidos gordos saturados[g]": 11.4,
    "\u00e1cidos gordos monoinsaturados [g]": 16.0,
    "\u00e1cidos gordos polinsaturados [g]": 10.0,
    "\u00e1cido linoleico [g]": 8.7,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 9.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 135.0,
    "vitamina a  [\u00b5g]": 64.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.9,
    "alfa-tocoferol [mg]": 0.15,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.44,
    "niacina [mg]": 2.7,
    "equivalentes de niacina [mg]": 3.6,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 0.41,
    "s\u00f3dio [mg]": 50.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 91.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Funcho fresco",
    "energia[kcal]": 32.0,
    "energia[kj]": 133.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.04,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.3,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 350.0,
    "caroteno [\u00b5g]": 2100.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.23,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.67,
    "triptofano/60 [mg]": 0.47,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 31.0,
    "folatos [\u00b5g]": 3.3,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 7.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 64.0,
    "f\u00f3sforo [mg]": 51.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 2.7,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Galinha de angola com pele, crua",
    "energia[kcal]": 152.0,
    "energia[kj]": 636.0,
    "l\u00edpidos[g]": 6.5,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.19,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.4,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 74.0,
    "vitamina a  [\u00b5g]": 28.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.01,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 7.7,
    "equivalentes de niacina [mg]": 11.3,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 0.34,
    "vitamina c [mg]": 1.3,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 1.25,
    "s\u00f3dio [mg]": 67.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 16.0,
    "iodo [\u00b5g]": 6.0
  },
  {
    "name": "Gamba crua",
    "energia[kcal]": 88.0,
    "energia[kj]": 373.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0021,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.5,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 79.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 7.1,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 4.0,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.2,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 87.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Garoupa cozida",
    "energia[kcal]": 101.0,
    "energia[kj]": 428.0,
    "l\u00edpidos[g]": 2.0,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.8,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 40.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 6.8,
    "alfa-tocoferol [mg]": 2.8,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 5.0,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.86,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Garoupa crua",
    "energia[kcal]": 95.0,
    "energia[kj]": 400.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.5,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 37.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 7.0,
    "alfa-tocoferol [mg]": 2.6,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 130.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Garoupa grelhada",
    "energia[kcal]": 121.0,
    "energia[kj]": 511.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.9,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 47.0,
    "vitamina a  [\u00b5g]": 63.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 8.4,
    "alfa-tocoferol [mg]": 3.5,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 6.5,
    "triptofano/60 [mg]": 5.1,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gelado caseiro com bolachas e natas",
    "energia[kcal]": 358.0,
    "energia[kj]": 1500.0,
    "l\u00edpidos[g]": 21.2,
    "\u00e1cidos gordos saturados[g]": 12.2,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 36.5,
    "a\u00e7\u00facares [g]": 28.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.3,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 5.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 35.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 62.0,
    "vitamina a  [\u00b5g]": 242.0,
    "caroteno [\u00b5g]": 122.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.34,
    "niacina [mg]": 0.92,
    "equivalentes de niacina [mg]": 2.08,
    "triptofano/60 [mg]": 1.16,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.28,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.4,
    "cinza [g]": 1.08,
    "s\u00f3dio [mg]": 86.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gelado caseiro com palitos la Reine",
    "energia[kcal]": 263.0,
    "energia[kj]": 1100.0,
    "l\u00edpidos[g]": 12.4,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 6.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.34,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 28.7,
    "a\u00e7\u00facares [g]": 23.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.7,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 7.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.2,
    "\u00e1gua [g]": 48.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 119.0,
    "vitamina a  [\u00b5g]": 70.0,
    "caroteno [\u00b5g]": 5.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 3.85,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.29,
    "niacina [mg]": 0.44,
    "equivalentes de niacina [mg]": 2.09,
    "triptofano/60 [mg]": 1.64,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.49,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 38.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 43.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gelado de \u00e1gua (sorvete)",
    "energia[kcal]": 132.0,
    "energia[kj]": 561.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 32.6,
    "a\u00e7\u00facares [g]": 32.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.1,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gelado de leite",
    "energia[kcal]": 199.0,
    "energia[kj]": 833.0,
    "l\u00edpidos[g]": 10.9,
    "\u00e1cidos gordos saturados[g]": 6.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 21.7,
    "a\u00e7\u00facares [g]": 21.7,
    "oligossac\u00e1ridos [g]": 2.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 33.0,
    "vitamina a  [\u00b5g]": 115.0,
    "caroteno [\u00b5g]": 195.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.4,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.63,
    "s\u00f3dio [mg]": 56.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gelatina desidratada (p\u00f3 ou folha)",
    "energia[kcal]": 349.0,
    "energia[kj]": 1480.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 87.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 32.0,
    "pot\u00e1ssio [mg]": 16.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 32.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gelatina preparada com anan\u00e1s em conserva",
    "energia[kcal]": 97.0,
    "energia[kj]": 410.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 21.6,
    "a\u00e7\u00facares [g]": 21.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 2.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.08,
    "equivalentes de niacina [mg]": 0.08,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.9,
    "folatos [\u00b5g]": 0.4,
    "cinza [g]": 0.235,
    "s\u00f3dio [mg]": 2.6,
    "pot\u00e1ssio [mg]": 46.0,
    "c\u00e1lcio [mg]": 9.5,
    "f\u00f3sforo [mg]": 2.8,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gelatina preparada com laranja e sumo de laranja",
    "energia[kcal]": 84.0,
    "energia[kj]": 355.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0173,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 17.4,
    "a\u00e7\u00facares [g]": 17.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 58.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.32,
    "equivalentes de niacina [mg]": 0.38,
    "triptofano/60 [mg]": 0.06,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 30.3,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.314,
    "s\u00f3dio [mg]": 3.8,
    "pot\u00e1ssio [mg]": 88.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 5.8,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Geleia de casca de laranja",
    "energia[kcal]": 275.0,
    "energia[kj]": 1170.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 68.0,
    "a\u00e7\u00facares [g]": 68.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 30.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 50.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 18.0,
    "pot\u00e1ssio [mg]": 44.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gema de ovo de galinha, crua",
    "energia[kcal]": 342.0,
    "energia[kj]": 1420.0,
    "l\u00edpidos[g]": 30.9,
    "\u00e1cidos gordos saturados[g]": 8.3,
    "\u00e1cidos gordos monoinsaturados [g]": 11.7,
    "\u00e1cidos gordos polinsaturados [g]": 4.6,
    "\u00e1cido linoleico [g]": 3.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 51.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1280.0,
    "vitamina a  [\u00b5g]": 500.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 4.9,
    "alfa-tocoferol [mg]": 4.6,
    "tiamina [mg]": 0.24,
    "riboflavina [mg]": 0.65,
    "niacina [mg]": 0.01,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 4.7,
    "vitamina b6 [mg]": 0.8,
    "vitamina b12 [\u00b5g]": 2.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 130.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 48.0,
    "pot\u00e1ssio [mg]": 90.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 480.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 5.5,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gema de ovo de galinha, pasteurizada",
    "energia[kcal]": 294.0,
    "energia[kj]": 1220.0,
    "l\u00edpidos[g]": 25.2,
    "\u00e1cidos gordos saturados[g]": 7.9,
    "\u00e1cidos gordos monoinsaturados [g]": 9.8,
    "\u00e1cidos gordos polinsaturados [g]": 4.2,
    "\u00e1cido linoleico [g]": 3.98,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 15.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 991.0,
    "vitamina a  [\u00b5g]": 395.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 5.2,
    "alfa-tocoferol [mg]": 2.24,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.56,
    "niacina [mg]": 0.03,
    "equivalentes de niacina [mg]": 3.75,
    "triptofano/60 [mg]": 3.72,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 1.64,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 151.0,
    "cinza [g]": 1.37,
    "s\u00f3dio [mg]": 71.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 420.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 3.7,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 56.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gengibre fresco",
    "energia[kcal]": 82.0,
    "energia[kj]": 345.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.143,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 15.8,
    "a\u00e7\u00facares [g]": 15.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.75,
    "equivalentes de niacina [mg]": 0.95,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 13.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 34.0,
    "magn\u00e9sio [mg]": 43.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "G\u00e9rmen de trigo",
    "energia[kcal]": 391.0,
    "energia[kj]": 1640.0,
    "l\u00edpidos[g]": 9.6,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 4.3,
    "\u00e1cido linoleico [g]": 4.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 41.8,
    "a\u00e7\u00facares [g]": 15.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 26.1,
    "fibra  [g]": 12.3,
    "prote\u00ednas [g]": 28.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 11.0,
    "tiamina [mg]": 1.45,
    "riboflavina [mg]": 0.61,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 11.47,
    "triptofano/60 [mg]": 5.67,
    "vitamina b6 [mg]": 1.42,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 190.0,
    "cinza [g]": 4.4,
    "s\u00f3dio [mg]": 5.5,
    "pot\u00e1ssio [mg]": 1050.0,
    "c\u00e1lcio [mg]": 41.0,
    "f\u00f3sforo [mg]": 1000.0,
    "magn\u00e9sio [mg]": 260.0,
    "ferro [mg]": 5.0,
    "zinco [mg]": 18.0,
    "sel\u00e9nio [\u00b5g]": 3.0,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Gin - Rum- Whisky",
    "energia[kcal]": 222.0,
    "energia[kj]": 919.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 31.7,
    "\u00e1gua [g]": 68.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ginja",
    "energia[kcal]": 57.0,
    "energia[kj]": 240.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.5,
    "a\u00e7\u00facares [g]": 11.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 123.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 10.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 0.55,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Goiaba crua, polpa",
    "energia[kcal]": 41.0,
    "energia[kj]": 169.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.0,
    "a\u00e7\u00facares [g]": 4.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 5.3,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.8,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 73.0,
    "caroteno [\u00b5g]": 435.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.55,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 230.0,
    "folatos [\u00b5g]": 40.8,
    "cinza [g]": 0.29,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 25.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Goraz assado com cebola, tomate, azeite e \u00f3leo alimentar",
    "energia[kcal]": 117.0,
    "energia[kj]": 490.0,
    "l\u00edpidos[g]": 5.1,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.9,
    "\u00e1cido linoleico [g]": 1.26,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.5,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 14.8,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 75.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 28.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 110.0,
    "vitamina d [\u00b5g]": 10.0,
    "alfa-tocoferol [mg]": 2.09,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 4.98,
    "triptofano/60 [mg]": 2.73,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 0.69,
    "vitamina c [mg]": 4.5,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.15,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Goraz cozido",
    "energia[kcal]": 108.0,
    "energia[kj]": 456.0,
    "l\u00edpidos[g]": 3.5,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.2,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 36.0,
    "vitamina a  [\u00b5g]": 19.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 15.0,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 6.1,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.31,
    "vitamina b12 [\u00b5g]": 0.87,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 130.0
  },
  {
    "name": "Goraz cru",
    "energia[kcal]": 100.0,
    "energia[kj]": 420.0,
    "l\u00edpidos[g]": 2.7,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.8,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 36.0,
    "vitamina a  [\u00b5g]": 19.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 15.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 2.6,
    "equivalentes de niacina [mg]": 6.1,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Goraz grelhado",
    "energia[kcal]": 118.0,
    "energia[kj]": 499.0,
    "l\u00edpidos[g]": 3.2,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.4,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 43.0,
    "vitamina a  [\u00b5g]": 21.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 17.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 7.2,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.7,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 130.0
  },
  {
    "name": "Goraz no forno",
    "energia[kcal]": 110.0,
    "energia[kj]": 462.0,
    "l\u00edpidos[g]": 2.7,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.0218,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 18.7,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.9,
    "\u00e1gua [g]": 73.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 36.0,
    "vitamina a  [\u00b5g]": 19.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 15.0,
    "alfa-tocoferol [mg]": 0.77,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 2.7,
    "equivalentes de niacina [mg]": 6.15,
    "triptofano/60 [mg]": 3.48,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 0.99,
    "vitamina c [mg]": 4.6,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 2.39,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gr\u00e3o-de-bico cozido (demolhado)",
    "energia[kcal]": 130.0,
    "energia[kj]": 545.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 16.7,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.6,
    "amido [g]": 15.1,
    "fibra  [g]": 5.1,
    "prote\u00ednas [g]": 8.4,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 23.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 1.8,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 54.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 46.0,
    "f\u00f3sforo [mg]": 83.0,
    "magn\u00e9sio [mg]": 39.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Gr\u00e3o-de-bico cru",
    "energia[kcal]": 354.0,
    "energia[kj]": 1490.0,
    "l\u00edpidos[g]": 5.0,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 51.4,
    "a\u00e7\u00facares [g]": 2.8,
    "oligossac\u00e1ridos [g]": 3.4,
    "amido [g]": 45.2,
    "fibra  [g]": 13.5,
    "prote\u00ednas [g]": 19.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 8.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 60.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.7,
    "tiamina [mg]": 0.41,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 4.4,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.5,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 180.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 980.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 100.0,
    "ferro [mg]": 6.3,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Grelos de couve cozidos",
    "energia[kcal]": 22.0,
    "energia[kj]": 91.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.5,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 1.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 161.0,
    "caroteno [\u00b5g]": 770.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 35.0,
    "folatos [\u00b5g]": 48.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 41.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Grelos de couve crus",
    "energia[kcal]": 28.0,
    "energia[kj]": 119.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.5,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.2,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 2.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 180.0,
    "caroteno [\u00b5g]": 1080.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 79.0,
    "folatos [\u00b5g]": 90.0,
    "cinza [g]": 1.04,
    "s\u00f3dio [mg]": 19.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 150.0,
    "f\u00f3sforo [mg]": 51.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Grelos de nabo cozidos",
    "energia[kcal]": 20.0,
    "energia[kj]": 85.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 2.2,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 174.0,
    "caroteno [\u00b5g]": 838.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 43.0,
    "folatos [\u00b5g]": 54.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 22.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Grelos de nabo crus",
    "energia[kcal]": 29.0,
    "energia[kj]": 119.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.3,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.2,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 2.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 204.0,
    "caroteno [\u00b5g]": 1230.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 84.0,
    "folatos [\u00b5g]": 90.0,
    "cinza [g]": 0.81,
    "s\u00f3dio [mg]": 19.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 43.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Gressino",
    "energia[kcal]": 396.0,
    "energia[kj]": 1670.0,
    "l\u00edpidos[g]": 8.7,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 4.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 67.0,
    "a\u00e7\u00facares [g]": 4.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 62.7,
    "fibra  [g]": 3.5,
    "prote\u00ednas [g]": 10.7,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 8.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.4,
    "riboflavina [mg]": 0.52,
    "niacina [mg]": 6.0,
    "equivalentes de niacina [mg]": 7.6,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 2.02,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 3.2,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Groselha",
    "energia[kcal]": 52.0,
    "energia[kj]": 219.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.0,
    "a\u00e7\u00facares [g]": 5.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 8.2,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.8,
    "\u00e1cidos org\u00e2nicos [g]": 2.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 14.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 40.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.38,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 36.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Hamb\u00farguer de feij\u00e3o manteiga frito com azeite",
    "energia[kcal]": 169.0,
    "energia[kj]": 708.0,
    "l\u00edpidos[g]": 5.3,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.631,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 19.8,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 1.1,
    "amido [g]": 17.4,
    "fibra  [g]": 4.9,
    "prote\u00ednas [g]": 8.0,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 47.0,
    "vitamina a  [\u00b5g]": 41.0,
    "caroteno [\u00b5g]": 113.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.89,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.74,
    "equivalentes de niacina [mg]": 2.28,
    "triptofano/60 [mg]": 1.49,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.11,
    "vitamina c [mg]": 6.9,
    "folatos [\u00b5g]": 30.0,
    "cinza [g]": 2.27,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 42.0,
    "ferro [mg]": 2.5,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Hamb\u00farguer de feij\u00e3o manteiga frito com \u00f3leo de milho",
    "energia[kcal]": 169.0,
    "energia[kj]": 708.0,
    "l\u00edpidos[g]": 5.3,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.17,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 19.8,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 1.1,
    "amido [g]": 17.4,
    "fibra  [g]": 4.9,
    "prote\u00ednas [g]": 8.0,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 47.0,
    "vitamina a  [\u00b5g]": 42.0,
    "caroteno [\u00b5g]": 118.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.26,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.74,
    "equivalentes de niacina [mg]": 2.28,
    "triptofano/60 [mg]": 1.49,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.11,
    "vitamina c [mg]": 6.9,
    "folatos [\u00b5g]": 30.0,
    "cinza [g]": 2.27,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 42.0,
    "ferro [mg]": 2.5,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Hamb\u00farguer de peru, frito",
    "energia[kcal]": 56.0,
    "energia[kj]": 238.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.193,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.0,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.8,
    "amido [g]": 0.0,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 8.8,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 19.0,
    "vitamina a  [\u00b5g]": 29.0,
    "caroteno [\u00b5g]": 174.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.36,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 3.2,
    "equivalentes de niacina [mg]": 4.73,
    "triptofano/60 [mg]": 1.59,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 0.34,
    "vitamina c [mg]": 18.9,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 2.06,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 99.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Hamb\u00farguer de porco, frito, sem molho",
    "energia[kcal]": 264.0,
    "energia[kj]": 1100.0,
    "l\u00edpidos[g]": 17.1,
    "\u00e1cidos gordos saturados[g]": 5.8,
    "\u00e1cidos gordos monoinsaturados [g]": 5.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 53.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 96.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.54,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 7.1,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 5.9,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.5,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.0
  },
  {
    "name": "Hamb\u00farguer de porco, grelhado",
    "energia[kcal]": 261.0,
    "energia[kj]": 1090.0,
    "l\u00edpidos[g]": 16.6,
    "\u00e1cidos gordos saturados[g]": 5.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 53.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 83.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.6,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 7.2,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 5.9,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.6,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.0
  },
  {
    "name": "Hamb\u00farguer de vaca, cru",
    "energia[kcal]": 142.0,
    "energia[kj]": 595.0,
    "l\u00edpidos[g]": 6.8,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 3.4,
    "equivalentes de niacina [mg]": 7.7,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.45,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 64.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 3.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Hamb\u00farguer de vaca, frito ",
    "energia[kcal]": 207.0,
    "energia[kj]": 862.0,
    "l\u00edpidos[g]": 13.8,
    "\u00e1cidos gordos saturados[g]": 5.3,
    "\u00e1cidos gordos monoinsaturados [g]": 6.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.472,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 0.0,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 19.1,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 53.0,
    "vitamina a  [\u00b5g]": 49.0,
    "caroteno [\u00b5g]": 291.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.37,
    "tiamina [mg]": 0.16,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.7,
    "equivalentes de niacina [mg]": 7.74,
    "triptofano/60 [mg]": 3.99,
    "vitamina b6 [mg]": 0.5,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 22.1,
    "folatos [\u00b5g]": 34.0,
    "cinza [g]": 2.18,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 41.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Hamb\u00farguer de vaca, grelhado",
    "energia[kcal]": 183.0,
    "energia[kj]": 768.0,
    "l\u00edpidos[g]": 8.2,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.3,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 86.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 9.6,
    "triptofano/60 [mg]": 5.8,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 4.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.0
  },
  {
    "name": "Hortel\u00e3 fresca",
    "energia[kcal]": 51.0,
    "energia[kj]": 212.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.3,
    "a\u00e7\u00facares [g]": 5.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.9,
    "prote\u00ednas [g]": 3.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 123.0,
    "caroteno [\u00b5g]": 740.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.0,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 1.6,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 31.0,
    "folatos [\u00b5g]": 110.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 210.0,
    "f\u00f3sforo [mg]": 75.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 9.5,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Imperador assado com cebola, tomate, azeite e \u00f3leo alimentar",
    "energia[kcal]": 94.0,
    "energia[kj]": 393.0,
    "l\u00edpidos[g]": 3.1,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.15,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.4,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 13.7,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.6,
    "\u00e1gua [g]": 78.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 23.0,
    "vitamina a  [\u00b5g]": 24.0,
    "caroteno [\u00b5g]": 101.0,
    "vitamina d [\u00b5g]": 1.7,
    "alfa-tocoferol [mg]": 1.86,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 5.48,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.23,
    "vitamina c [mg]": 4.1,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.69,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Imperador cozido",
    "energia[kcal]": 84.0,
    "energia[kj]": 357.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.9,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 35.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.7,
    "alfa-tocoferol [mg]": 0.69,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 3.4,
    "equivalentes de niacina [mg]": 7.1,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.36,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 340.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 42.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Imperador cru",
    "energia[kcal]": 80.0,
    "energia[kj]": 338.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 32.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.7,
    "alfa-tocoferol [mg]": 0.62,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.36,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 72.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Imperador grelhado",
    "energia[kcal]": 97.0,
    "energia[kj]": 410.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 39.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 3.1,
    "alfa-tocoferol [mg]": 0.79,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 8.8,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.37,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Inhame cru ",
    "energia[kcal]": 114.0,
    "energia[kj]": 484.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 25.7,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 25.0,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 1.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 14.0,
    "caroteno [\u00b5g]": 83.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 4.6,
    "tiamina [mg]": 0.16,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.47,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 27.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 1.0,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Iogurte gordo, aromatizado, a\u00e7ucarado ",
    "energia[kcal]": 83.0,
    "energia[kj]": 349.0,
    "l\u00edpidos[g]": 3.6,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 8.5,
    "a\u00e7\u00facares [g]": 8.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 55.0,
    "caroteno [\u00b5g]": 31.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 46.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 95.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte gordo, com polpa e/ou peda\u00e7os de fruta e cereais a\u00e7ucarado",
    "energia[kcal]": 114.0,
    "energia[kj]": 478.0,
    "l\u00edpidos[g]": 3.2,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 15.8,
    "a\u00e7\u00facares [g]": 15.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 4.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.1,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 54.0,
    "caroteno [\u00b5g]": 119.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 48.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte gordo, com polpa e/ou peda\u00e7os de fruta, a\u00e7ucarado ",
    "energia[kcal]": 96.0,
    "energia[kj]": 403.0,
    "l\u00edpidos[g]": 3.2,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 12.4,
    "a\u00e7\u00facares [g]": 12.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 12.0,
    "vitamina a  [\u00b5g]": 52.0,
    "caroteno [\u00b5g]": 115.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.5,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 39.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 91.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte grego com fruta a\u00e7ucarado",
    "energia[kcal]": 113.0,
    "energia[kj]": 475.0,
    "l\u00edpidos[g]": 5.0,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 13.7,
    "a\u00e7\u00facares [g]": 13.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 2.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 17.0,
    "vitamina a  [\u00b5g]": 121.0,
    "caroteno [\u00b5g]": 36.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.38,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.7,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 33.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 95.0,
    "f\u00f3sforo [mg]": 80.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte grego magro natural ",
    "energia[kcal]": 56.0,
    "energia[kj]": 236.0,
    "l\u00edpidos[g]": 2.0,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 3.9,
    "a\u00e7\u00facares [g]": 3.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 5.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 17.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.7,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 0.1,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte grego natural",
    "energia[kcal]": 85.0,
    "energia[kj]": 354.0,
    "l\u00edpidos[g]": 6.6,
    "\u00e1cidos gordos saturados[g]": 4.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 4.8,
    "a\u00e7\u00facares [g]": 4.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 17.0,
    "vitamina a  [\u00b5g]": 121.0,
    "caroteno [\u00b5g]": 36.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.38,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.7,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 32.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 97.0,
    "magn\u00e9sio [mg]": 8.4,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte l\u00edquido magro, aromatizado, a\u00e7ucarado ",
    "energia[kcal]": 62.0,
    "energia[kj]": 263.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.6,
    "a\u00e7\u00facares [g]": 11.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 9.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 60.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 81.0,
    "f\u00f3sforo [mg]": 72.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte l\u00edquido meio gordo, aromatizado, a\u00e7ucarado ",
    "energia[kcal]": 70.0,
    "energia[kj]": 297.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 11.5,
    "a\u00e7\u00facares [g]": 11.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 19.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 42.0,
    "pot\u00e1ssio [mg]": 98.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 78.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte l\u00edquido meio gordo, natural, a\u00e7ucarado",
    "energia[kcal]": 70.0,
    "energia[kj]": 295.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 11.2,
    "a\u00e7\u00facares [g]": 11.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 19.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.1,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 46.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 85.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte magro, aromatizado, a\u00e7ucarado ",
    "energia[kcal]": 69.0,
    "energia[kj]": 294.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.8,
    "a\u00e7\u00facares [g]": 11.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 5.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 18.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.8,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 73.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 150.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte magro, com cereais e edulcorantes",
    "energia[kcal]": 50.0,
    "energia[kj]": 210.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.7,
    "a\u00e7\u00facares [g]": 5.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 4.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.6,
    "\u00e1cidos org\u00e2nicos [g]": 1.3,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 17.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.7,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte magro, natural ",
    "energia[kcal]": 42.0,
    "energia[kj]": 177.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.2,
    "a\u00e7\u00facares [g]": 5.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.5,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 72.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte magro, natural, com edulcorantes ",
    "energia[kcal]": 47.0,
    "energia[kj]": 202.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.3,
    "a\u00e7\u00facares [g]": 6.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 5.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 41.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.8,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte meio gordo, aromatizado, a\u00e7ucarado",
    "energia[kcal]": 79.0,
    "energia[kj]": 333.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.05,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 11.3,
    "a\u00e7\u00facares [g]": 11.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 38.0,
    "caroteno [\u00b5g]": 22.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 70.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte meio gordo, com polpa e/ou peda\u00e7os de fruta, a\u00e7ucarado",
    "energia[kcal]": 92.0,
    "energia[kj]": 390.0,
    "l\u00edpidos[g]": 1.7,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 14.6,
    "a\u00e7\u00facares [g]": 14.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 20.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.3,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 72.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte meio gordo, natural",
    "energia[kcal]": 54.0,
    "energia[kj]": 228.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 5.0,
    "a\u00e7\u00facares [g]": 5.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 17.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.7,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 62.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte meio gordo, natural, a\u00e7ucarado ",
    "energia[kcal]": 91.0,
    "energia[kj]": 386.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 13.1,
    "a\u00e7\u00facares [g]": 13.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 26.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 81.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte sem lactose enriquecido em prote\u00edna, magro, aromatizado, com peda\u00e7os e/ou polpa de fruta, a\u00e7ucarado",
    "energia[kcal]": 76.0,
    "energia[kj]": 323.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.7,
    "a\u00e7\u00facares [g]": 8.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 9.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 18.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.8,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 73.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 92.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte sem lactose l\u00edquido, magro, aromatizado, com polpa de fruta, a\u00e7ucarado",
    "energia[kcal]": 78.0,
    "energia[kj]": 332.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 16.0,
    "a\u00e7\u00facares [g]": 16.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 9.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 60.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 78.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Iogurte sem lactose l\u00edquido, magro, aromatizado, com polpa de fruta, com edulcorantes",
    "energia[kcal]": 34.0,
    "energia[kj]": 145.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.0,
    "a\u00e7\u00facares [g]": 4.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.3,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 3.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 9.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 60.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 72.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte sem lactose l\u00edquido, meio gordo, aromatizado, a\u00e7ucarado",
    "energia[kcal]": 71.0,
    "energia[kj]": 302.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 11.8,
    "a\u00e7\u00facares [g]": 11.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 19.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 42.0,
    "pot\u00e1ssio [mg]": 98.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 78.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte sem lactose l\u00edquido, meio gordo, aromatizado, com polpa de fruta, a\u00e7ucarado",
    "energia[kcal]": 73.0,
    "energia[kj]": 308.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 12.5,
    "a\u00e7\u00facares [g]": 12.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 19.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 42.0,
    "pot\u00e1ssio [mg]": 98.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 72.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte sem lactose l\u00edquido, meio gordo, natural, a\u00e7ucarado",
    "energia[kcal]": 70.0,
    "energia[kj]": 296.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 11.7,
    "a\u00e7\u00facares [g]": 11.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 19.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.1,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 46.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 85.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte sem lactose magro, aromatizado, com peda\u00e7os e/ou polpa de fruta, com edulcorantes",
    "energia[kcal]": 42.0,
    "energia[kj]": 176.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.8,
    "a\u00e7\u00facares [g]": 4.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 4.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.8,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 27.0
  },
  {
    "name": "Iogurte sem lactose magro, natural",
    "energia[kcal]": 41.0,
    "energia[kj]": 175.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.0,
    "a\u00e7\u00facares [g]": 5.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.5,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 72.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 150.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 35.0
  },
  {
    "name": "Iogurte sem lactose meio gordo, aromatizado, a\u00e7ucarado",
    "energia[kcal]": 76.0,
    "energia[kj]": 322.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 12.0,
    "a\u00e7\u00facares [g]": 11.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 38.0,
    "caroteno [\u00b5g]": 22.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.32,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.6,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 81.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 97.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 24.0
  },
  {
    "name": "Iogurte sem lactose meio gordo, aromatizado, com frutos secos e cereais, a\u00e7ucarado",
    "energia[kcal]": 98.0,
    "energia[kj]": 414.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 16.1,
    "a\u00e7\u00facares [g]": 16.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 3.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.1,
    "\u00e1cidos org\u00e2nicos [g]": 1.3,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 38.0,
    "caroteno [\u00b5g]": 22.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.7,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte sem lactose meio gordo, aromatizado, com peda\u00e7os e/ou polpa de fruta, a\u00e7ucarado",
    "energia[kcal]": 83.0,
    "energia[kj]": 350.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 13.6,
    "a\u00e7\u00facares [g]": 13.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 3.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 20.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.3,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 72.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte sem lactose meio gordo, natural",
    "energia[kcal]": 48.0,
    "energia[kj]": 202.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.5,
    "a\u00e7\u00facares [g]": 4.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 17.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.7,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 62.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Iogurte sem lactose meio gordo, natural, a\u00e7ucarado",
    "energia[kcal]": 78.0,
    "energia[kj]": 330.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 12.2,
    "a\u00e7\u00facares [g]": 11.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 26.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.32,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 81.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 97.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 24.0
  },
  {
    "name": "Jarrete de porco",
    "energia[kcal]": 140.0,
    "energia[kj]": 587.0,
    "l\u00edpidos[g]": 6.0,
    "\u00e1cidos gordos saturados[g]": 2.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.4,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 63.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.52,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.2,
    "equivalentes de niacina [mg]": 6.9,
    "triptofano/60 [mg]": 4.7,
    "vitamina b6 [mg]": 0.46,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.21,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 480.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Javali cru",
    "energia[kcal]": 108.0,
    "energia[kj]": 457.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 65.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.21,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.1,
    "equivalentes de niacina [mg]": 9.8,
    "triptofano/60 [mg]": 4.7,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 5.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 94.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 6.0
  },
  {
    "name": "Jesu\u00edta",
    "energia[kcal]": 518.0,
    "energia[kj]": 2160.0,
    "l\u00edpidos[g]": 31.2,
    "\u00e1cidos gordos saturados[g]": 11.4,
    "\u00e1cidos gordos monoinsaturados [g]": 9.5,
    "\u00e1cidos gordos polinsaturados [g]": 4.8,
    "\u00e1cido linoleico [g]": 4.5,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 54.0,
    "a\u00e7\u00facares [g]": 26.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 27.9,
    "fibra  [g]": 2.5,
    "prote\u00ednas [g]": 4.1,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 7.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 150.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.3,
    "alfa-tocoferol [mg]": 1.6,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 67.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 45.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lagosta cozida",
    "energia[kcal]": 90.0,
    "energia[kj]": 383.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.6,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 93.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 5.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 3.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 60.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 42.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lagosta crua",
    "energia[kcal]": 79.0,
    "energia[kj]": 336.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 4.4,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.1,
    "cinza [g]": 2.18,
    "s\u00f3dio [mg]": 88.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 59.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lagostim cozido",
    "energia[kcal]": 90.0,
    "energia[kj]": 382.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.2,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 70.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 6.0,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.7,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 550.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 38.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lagostim cru",
    "energia[kcal]": 89.0,
    "energia[kj]": 377.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.9,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 68.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 5.9,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 42.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lampreia crua",
    "energia[kcal]": 314.0,
    "energia[kj]": 1300.0,
    "l\u00edpidos[g]": 27.4,
    "\u00e1cidos gordos saturados[g]": 5.8,
    "\u00e1cidos gordos monoinsaturados [g]": 11.8,
    "\u00e1cidos gordos polinsaturados [g]": 6.0,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.7,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 54.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 108.0,
    "vitamina a  [\u00b5g]": 5350.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 14.3,
    "alfa-tocoferol [mg]": 5.9,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.6,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 5.5,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 2.95,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 16.5,
    "cinza [g]": 1.55,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 7.5,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 4.1,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 57.0,
    "iodo [\u00b5g]": 35.0
  },
  {
    "name": "Lapas cruas",
    "energia[kcal]": 66.0,
    "energia[kj]": 280.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 14.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 98.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 3.9,
    "triptofano/60 [mg]": 2.6,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 41.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 1.79,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 51.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 95.0,
    "ferro [mg]": 21.0,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Laranja (3 variedades)",
    "energia[kcal]": 47.0,
    "energia[kj]": 201.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.9,
    "a\u00e7\u00facares [g]": 8.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.7,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 120.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 57.0,
    "folatos [\u00b5g]": 31.0,
    "cinza [g]": 0.42,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Lasanha \u00e0 bolonhesa",
    "energia[kcal]": 183.0,
    "energia[kj]": 763.0,
    "l\u00edpidos[g]": 12.4,
    "\u00e1cidos gordos saturados[g]": 5.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.79,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 9.2,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 6.7,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 7.7,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.4,
    "\u00e1gua [g]": 67.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 21.0,
    "vitamina a  [\u00b5g]": 144.0,
    "caroteno [\u00b5g]": 465.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 2.86,
    "triptofano/60 [mg]": 1.61,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.46,
    "vitamina c [mg]": 5.5,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.49,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 96.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lebre crua",
    "energia[kcal]": 113.0,
    "energia[kj]": 478.0,
    "l\u00edpidos[g]": 3.0,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 8.07,
    "equivalentes de niacina [mg]": 12.07,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 77.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 9.1,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 10.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "Leite condensado",
    "energia[kcal]": 338.0,
    "energia[kj]": 1420.0,
    "l\u00edpidos[g]": 9.0,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 56.4,
    "a\u00e7\u00facares [g]": 56.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 7.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 25.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 34.0,
    "vitamina a  [\u00b5g]": 161.0,
    "caroteno [\u00b5g]": 74.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 3.0,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 340.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite creme",
    "energia[kcal]": 163.0,
    "energia[kj]": 683.0,
    "l\u00edpidos[g]": 6.8,
    "\u00e1cidos gordos saturados[g]": 2.3,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.674,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 19.5,
    "a\u00e7\u00facares [g]": 18.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 5.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 227.0,
    "vitamina a  [\u00b5g]": 106.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.82,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.68,
    "triptofano/60 [mg]": 1.48,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.59,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.03,
    "s\u00f3dio [mg]": 46.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite de cabra cru",
    "energia[kcal]": 70.0,
    "energia[kj]": 291.0,
    "l\u00edpidos[g]": 4.0,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.6,
    "a\u00e7\u00facares [g]": 4.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.8,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 11.0,
    "vitamina a  [\u00b5g]": 53.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.08,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.85,
    "s\u00f3dio [mg]": 40.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 150.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite de coco, enlatado",
    "energia[kcal]": 185.0,
    "energia[kj]": 762.0,
    "l\u00edpidos[g]": 18.4,
    "\u00e1cidos gordos saturados[g]": 14.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.87,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.8,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.7,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 13.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 96.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 3.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite de ovelha cru",
    "energia[kcal]": 93.0,
    "energia[kj]": 388.0,
    "l\u00edpidos[g]": 6.2,
    "\u00e1cidos gordos saturados[g]": 3.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 4.2,
    "a\u00e7\u00facares [g]": 4.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 5.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.15,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.93,
    "s\u00f3dio [mg]": 37.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 190.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite evaporado",
    "energia[kcal]": 136.0,
    "energia[kj]": 567.0,
    "l\u00edpidos[g]": 7.8,
    "\u00e1cidos gordos saturados[g]": 4.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 9.8,
    "a\u00e7\u00facares [g]": 9.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 6.6,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 28.0,
    "vitamina a  [\u00b5g]": 129.0,
    "caroteno [\u00b5g]": 106.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.14,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 2.2,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.08,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 260.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite fermentado (Bifidus) gordo, natural",
    "energia[kcal]": 67.0,
    "energia[kj]": 282.0,
    "l\u00edpidos[g]": 3.6,
    "\u00e1cidos gordos saturados[g]": 2.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.5,
    "a\u00e7\u00facares [g]": 4.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 11.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.09,
    "equivalentes de niacina [mg]": 0.89,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 45.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 150.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 12.0
  },
  {
    "name": "Leite fermentado (Bifidus) sem lactose enriquecido com fibra, magro, aromatizado, com peda\u00e7os e/ou polpa de fruta, com sementes e/ou cereais, com frutos secos e/ou de casca rija, com edulcorantes ",
    "energia[kcal]": 57.0,
    "energia[kj]": 241.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.6,
    "a\u00e7\u00facares [g]": 5.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.2,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 4.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.6,
    "\u00e1cidos org\u00e2nicos [g]": 1.3,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 17.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.7,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 24.0
  },
  {
    "name": "Leite fermentado (Bifidus) sem lactose l\u00edquido, meio gordo, aromatizado, com peda\u00e7os e/ou polpa de fruta, a\u00e7ucarado",
    "energia[kcal]": 71.0,
    "energia[kj]": 298.0,
    "l\u00edpidos[g]": 1.7,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 11.0,
    "a\u00e7\u00facares [g]": 10.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 19.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 42.0,
    "pot\u00e1ssio [mg]": 98.0,
    "c\u00e1lcio [mg]": 96.0,
    "f\u00f3sforo [mg]": 78.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite fermentado (Bifidus) sem lactose magro, natural, com edulcorantes",
    "energia[kcal]": 51.0,
    "energia[kj]": 217.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.2,
    "a\u00e7\u00facares [g]": 6.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 5.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 41.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.8,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite fermentado (Bifidus) sem lactose, gordo, natural",
    "energia[kcal]": 70.0,
    "energia[kj]": 294.0,
    "l\u00edpidos[g]": 3.7,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.8,
    "a\u00e7\u00facares [g]": 4.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 11.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.09,
    "equivalentes de niacina [mg]": 0.89,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 45.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 7.5
  },
  {
    "name": "Leite fermentado (Bifidus) sem lactose, magro, aromatizado, com peda\u00e7os e/ou polpa de fruta e/ou hort\u00edcolas, a\u00e7ucarado, com edulcorantes",
    "energia[kcal]": 57.0,
    "energia[kj]": 243.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.2,
    "a\u00e7\u00facares [g]": 7.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 41.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.8,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite gordo especial, pasteurizado ",
    "energia[kcal]": 62.0,
    "energia[kj]": 260.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.8,
    "a\u00e7\u00facares [g]": 4.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 13.0,
    "vitamina a  [\u00b5g]": 38.0,
    "caroteno [\u00b5g]": 140.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.39,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 0.72,
    "s\u00f3dio [mg]": 37.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 91.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite gordo sem lactose, enriquecido com vitaminas A, D, E e B9 (\u00e1cido f\u00f3lico), UHT",
    "energia[kcal]": 62.0,
    "energia[kj]": 258.0,
    "l\u00edpidos[g]": 3.5,
    "\u00e1cidos gordos saturados[g]": 2.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.6,
    "a\u00e7\u00facares [g]": 4.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 13.0,
    "vitamina a  [\u00b5g]": 117.0,
    "caroteno [\u00b5g]": 29.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 1.7,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.18,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 43.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 77.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite gordo, pasteurizado",
    "energia[kcal]": 62.0,
    "energia[kj]": 260.0,
    "l\u00edpidos[g]": 3.5,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.7,
    "a\u00e7\u00facares [g]": 4.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 13.0,
    "vitamina a  [\u00b5g]": 56.0,
    "caroteno [\u00b5g]": 21.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.39,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 0.72,
    "s\u00f3dio [mg]": 43.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 88.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite gordo, p\u00f3",
    "energia[kcal]": 495.0,
    "energia[kj]": 2070.0,
    "l\u00edpidos[g]": 25.9,
    "\u00e1cidos gordos saturados[g]": 14.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.9,
    "hidratos de carbono [g]": 38.7,
    "a\u00e7\u00facares [g]": 38.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.8,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 296.0,
    "caroteno [\u00b5g]": 158.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.61,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 1.2,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 7.6,
    "triptofano/60 [mg]": 6.3,
    "vitamina b6 [mg]": 0.48,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 46.0,
    "cinza [g]": 6.0,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 1220.0,
    "c\u00e1lcio [mg]": 920.0,
    "f\u00f3sforo [mg]": 750.0,
    "magn\u00e9sio [mg]": 110.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite gordo, UHT",
    "energia[kcal]": 62.0,
    "energia[kj]": 260.0,
    "l\u00edpidos[g]": 3.5,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.7,
    "a\u00e7\u00facares [g]": 4.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 13.0,
    "vitamina a  [\u00b5g]": 59.0,
    "caroteno [\u00b5g]": 29.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.18,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.75,
    "s\u00f3dio [mg]": 43.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 77.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite humano",
    "energia[kcal]": 66.0,
    "energia[kj]": 277.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.5,
    "a\u00e7\u00facares [g]": 7.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 16.0,
    "vitamina a  [\u00b5g]": 68.0,
    "caroteno [\u00b5g]": 24.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.35,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 19.0,
    "pot\u00e1ssio [mg]": 69.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite humano, colostro",
    "energia[kcal]": 60.0,
    "energia[kj]": 250.0,
    "l\u00edpidos[g]": 2.8,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.3,
    "a\u00e7\u00facares [g]": 6.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 184.0,
    "caroteno [\u00b5g]": 135.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.3,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.05,
    "equivalentes de niacina [mg]": 0.85,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 59.0,
    "pot\u00e1ssio [mg]": 83.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite humano, transi\u00e7\u00e3o",
    "energia[kcal]": 66.0,
    "energia[kj]": 276.0,
    "l\u00edpidos[g]": 3.7,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.6,
    "a\u00e7\u00facares [g]": 6.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 1.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 24.0,
    "vitamina a  [\u00b5g]": 117.0,
    "caroteno [\u00b5g]": 32.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.53,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.03,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 38.0,
    "pot\u00e1ssio [mg]": 68.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 20.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite magro de pastagem, UHT ",
    "energia[kcal]": 33.0,
    "energia[kj]": 139.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.9,
    "a\u00e7\u00facares [g]": 4.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 4.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.18,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 34.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 91.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 14.0
  },
  {
    "name": "Leite magro sem lactose, UHT",
    "energia[kcal]": 36.0,
    "energia[kj]": 152.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.1,
    "a\u00e7\u00facares [g]": 4.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.11,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.77,
    "s\u00f3dio [mg]": 41.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 82.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 16.0
  },
  {
    "name": "Leite magro, p\u00f3",
    "energia[kcal]": 359.0,
    "energia[kj]": 1530.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 52.7,
    "a\u00e7\u00facares [g]": 52.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 35.1,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 23.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.01,
    "tiamina [mg]": 0.32,
    "riboflavina [mg]": 1.8,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 8.2,
    "vitamina b6 [mg]": 0.58,
    "vitamina b12 [\u00b5g]": 3.0,
    "vitamina c [mg]": 9.0,
    "folatos [\u00b5g]": 50.0,
    "cinza [g]": 8.05,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 1690.0,
    "c\u00e1lcio [mg]": 1270.0,
    "f\u00f3sforo [mg]": 1030.0,
    "magn\u00e9sio [mg]": 140.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite magro, UHT",
    "energia[kcal]": 35.0,
    "energia[kj]": 149.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.9,
    "a\u00e7\u00facares [g]": 4.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.11,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.77,
    "s\u00f3dio [mg]": 41.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 82.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 16.0
  },
  {
    "name": "Leite meio gordo aromatizado, UHT ",
    "energia[kcal]": 60.0,
    "energia[kj]": 252.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 7.9,
    "a\u00e7\u00facares [g]": 7.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 31.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.19,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.72,
    "s\u00f3dio [mg]": 42.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 86.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite meio gordo de pastagem, UHT",
    "energia[kcal]": 46.0,
    "energia[kj]": 192.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 5.0,
    "a\u00e7\u00facares [g]": 4.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 21.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.19,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 34.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 89.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 15.0
  },
  {
    "name": "Leite meio gordo sem lactose, UHT",
    "energia[kcal]": 48.0,
    "energia[kj]": 202.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 5.1,
    "a\u00e7\u00facares [g]": 4.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 22.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.12,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.76,
    "s\u00f3dio [mg]": 40.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 81.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 16.0
  },
  {
    "name": "Leite meio gordo, p\u00f3",
    "energia[kcal]": 427.0,
    "energia[kj]": 1800.0,
    "l\u00edpidos[g]": 13.5,
    "\u00e1cidos gordos saturados[g]": 7.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 45.6,
    "a\u00e7\u00facares [g]": 45.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 30.7,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 41.0,
    "vitamina a  [\u00b5g]": 212.0,
    "caroteno [\u00b5g]": 113.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.26,
    "riboflavina [mg]": 1.7,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 7.2,
    "vitamina b6 [mg]": 0.51,
    "vitamina b12 [\u00b5g]": 2.5,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 43.0,
    "cinza [g]": 7.05,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 1460.0,
    "c\u00e1lcio [mg]": 1150.0,
    "f\u00f3sforo [mg]": 880.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Leite meio gordo, UHT",
    "energia[kcal]": 47.0,
    "energia[kj]": 199.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.9,
    "a\u00e7\u00facares [g]": 4.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 22.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.12,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.76,
    "s\u00f3dio [mg]": 40.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 81.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 16.0
  },
  {
    "name": "Lentilhas secas cozidas (demolhadas)",
    "energia[kcal]": 115.0,
    "energia[kj]": 485.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 16.7,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 1.0,
    "amido [g]": 15.3,
    "fibra  [g]": 4.4,
    "prote\u00ednas [g]": 9.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 22.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.7,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lentilhas vermelhas, secas, cruas",
    "energia[kcal]": 327.0,
    "energia[kj]": 1380.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 46.6,
    "a\u00e7\u00facares [g]": 3.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 43.0,
    "fibra  [g]": 17.4,
    "prote\u00ednas [g]": 25.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 60.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.34,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 1.25,
    "equivalentes de niacina [mg]": 4.15,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 164.0,
    "cinza [g]": 2.53,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 900.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 370.0,
    "magn\u00e9sio [mg]": 73.0,
    "ferro [mg]": 6.2,
    "zinco [mg]": 3.9,
    "sel\u00e9nio [\u00b5g]": 17.0,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Lentilhas, secas, cruas",
    "energia[kcal]": 321.0,
    "energia[kj]": 1360.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 47.6,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 3.1,
    "amido [g]": 43.3,
    "fibra  [g]": 11.8,
    "prote\u00ednas [g]": 25.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 60.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.43,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 5.3,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.93,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 110.0,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 940.0,
    "c\u00e1lcio [mg]": 74.0,
    "f\u00f3sforo [mg]": 360.0,
    "magn\u00e9sio [mg]": 110.0,
    "ferro [mg]": 6.8,
    "zinco [mg]": 3.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lichia fresca",
    "energia[kcal]": 70.0,
    "energia[kj]": 294.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.067,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.8,
    "a\u00e7\u00facares [g]": 14.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.55,
    "equivalentes de niacina [mg]": 0.66,
    "triptofano/60 [mg]": 0.11,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 58.3,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 5.5,
    "f\u00f3sforo [mg]": 31.0,
    "magn\u00e9sio [mg]": 9.5,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Licor beneditino",
    "energia[kcal]": 352.0,
    "energia[kj]": 1470.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 34.8,
    "a\u00e7\u00facares [g]": 34.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 30.4,
    "\u00e1gua [g]": 34.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.07,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 2.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Licor de anis",
    "energia[kcal]": 385.0,
    "energia[kj]": 1610.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 36.0,
    "a\u00e7\u00facares [g]": 36.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 34.4,
    "\u00e1gua [g]": 29.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Licor de ginja",
    "energia[kcal]": 239.0,
    "energia[kj]": 998.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 19.0,
    "a\u00e7\u00facares [g]": 19.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 23.2,
    "\u00e1gua [g]": 57.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.08,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 18.0,
    "c\u00e1lcio [mg]": 2.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Licor simples",
    "energia[kcal]": 294.0,
    "energia[kj]": 1230.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 24.4,
    "a\u00e7\u00facares [g]": 24.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 28.0,
    "\u00e1gua [g]": 47.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lima",
    "energia[kcal]": 31.0,
    "energia[kj]": 132.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.036,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.8,
    "prote\u00ednas [g]": 0.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.3,
    "\u00e1cidos org\u00e2nicos [g]": 4.7,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.22,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.25,
    "triptofano/60 [mg]": 0.05,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 29.1,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 33.0,
    "f\u00f3sforo [mg]": 18.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.4,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lim\u00e3o",
    "energia[kcal]": 31.0,
    "energia[kj]": 130.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.9,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.1,
    "\u00e1cidos org\u00e2nicos [g]": 4.7,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 55.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 0.42,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 16.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "L\u00edngua de porco crua",
    "energia[kcal]": 220.0,
    "energia[kj]": 914.0,
    "l\u00edpidos[g]": 17.2,
    "\u00e1cidos gordos saturados[g]": 6.0,
    "\u00e1cidos gordos monoinsaturados [g]": 8.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.3,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 101.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.28,
    "tiamina [mg]": 0.49,
    "riboflavina [mg]": 0.49,
    "niacina [mg]": 5.3,
    "equivalentes de niacina [mg]": 8.7,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 2.84,
    "vitamina c [mg]": 4.4,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 3.4,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 10.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "L\u00edngua de vaca, crua",
    "energia[kcal]": 199.0,
    "energia[kj]": 825.0,
    "l\u00edpidos[g]": 15.0,
    "\u00e1cidos gordos saturados[g]": 5.7,
    "\u00e1cidos gordos monoinsaturados [g]": 6.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 15.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 89.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.28,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.36,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 5.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.16,
    "s\u00f3dio [mg]": 96.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 3.8,
    "zinco [mg]": 3.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "L\u00edngua de vaca, estufada, sem molho",
    "energia[kcal]": 267.0,
    "energia[kj]": 1110.0,
    "l\u00edpidos[g]": 20.9,
    "\u00e1cidos gordos saturados[g]": 8.0,
    "\u00e1cidos gordos monoinsaturados [g]": 9.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.9,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 95.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.5,
    "niacina [mg]": 6.5,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 4.6,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 6.2,
    "zinco [mg]": 3.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Linguado cru",
    "energia[kcal]": 82.0,
    "energia[kj]": 349.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 44.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 9.4,
    "alfa-tocoferol [mg]": 0.32,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 2.8,
    "equivalentes de niacina [mg]": 6.6,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.33,
    "vitamina b12 [\u00b5g]": 0.94,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 88.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Linguado frito",
    "energia[kcal]": 164.0,
    "energia[kj]": 689.0,
    "l\u00edpidos[g]": 6.2,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 4.0,
    "\u00e1cido linoleico [g]": 3.83,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.6,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 24.3,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 53.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 9.8,
    "alfa-tocoferol [mg]": 4.04,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 7.99,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.45,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 39.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Linguado grelhado",
    "energia[kcal]": 94.0,
    "energia[kj]": 400.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.1,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 51.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 10.0,
    "alfa-tocoferol [mg]": 0.39,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 3.1,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.7,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lingui\u00e7a",
    "energia[kcal]": 437.0,
    "energia[kj]": 1810.0,
    "l\u00edpidos[g]": 39.0,
    "\u00e1cidos gordos saturados[g]": 13.4,
    "\u00e1cidos gordos monoinsaturados [g]": 15.4,
    "\u00e1cidos gordos polinsaturados [g]": 4.5,
    "\u00e1cido linoleico [g]": 3.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.5,
    "sal  [g]": 7.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 31.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.6,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 9.6,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 8.4,
    "s\u00f3dio [mg]": 2910.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de porco assado",
    "energia[kcal]": 220.0,
    "energia[kj]": 921.0,
    "l\u00edpidos[g]": 11.3,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.63,
    "\u00e1cidos gordos trans [g]": 2.8,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.5,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 57.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 72.0,
    "vitamina a  [\u00b5g]": 43.0,
    "caroteno [\u00b5g]": 8.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 2.08,
    "tiamina [mg]": 0.45,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 4.9,
    "equivalentes de niacina [mg]": 12.4,
    "triptofano/60 [mg]": 5.82,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.1,
    "cinza [g]": 2.41,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 9.5,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de porco assado com azeite e margarina",
    "energia[kcal]": 219.0,
    "energia[kj]": 916.0,
    "l\u00edpidos[g]": 11.1,
    "\u00e1cidos gordos saturados[g]": 3.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.64,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.5,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 57.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 72.0,
    "vitamina a  [\u00b5g]": 49.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 1.03,
    "tiamina [mg]": 0.45,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 4.9,
    "equivalentes de niacina [mg]": 12.4,
    "triptofano/60 [mg]": 5.82,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.1,
    "cinza [g]": 2.44,
    "s\u00f3dio [mg]": 480.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 9.5,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de porco assado com margarina",
    "energia[kcal]": 208.0,
    "energia[kj]": 872.0,
    "l\u00edpidos[g]": 10.6,
    "\u00e1cidos gordos saturados[g]": 4.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.2,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 79.0,
    "vitamina a  [\u00b5g]": 41.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.73,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 5.6,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 6.0,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.6,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 490.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Lombo de porco assado com \u00f3leo alimentar e margarina",
    "energia[kcal]": 219.0,
    "energia[kj]": 916.0,
    "l\u00edpidos[g]": 11.1,
    "\u00e1cidos gordos saturados[g]": 3.5,
    "\u00e1cidos gordos monoinsaturados [g]": 3.1,
    "\u00e1cidos gordos polinsaturados [g]": 3.5,
    "\u00e1cido linoleico [g]": 3.32,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.5,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 57.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 72.0,
    "vitamina a  [\u00b5g]": 49.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 2.41,
    "tiamina [mg]": 0.45,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 4.9,
    "equivalentes de niacina [mg]": 12.4,
    "triptofano/60 [mg]": 5.82,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.1,
    "cinza [g]": 2.44,
    "s\u00f3dio [mg]": 480.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 9.5,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de porco assado, sem molho",
    "energia[kcal]": 210.0,
    "energia[kj]": 882.0,
    "l\u00edpidos[g]": 8.8,
    "\u00e1cidos gordos saturados[g]": 3.0,
    "\u00e1cidos gordos monoinsaturados [g]": 3.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 32.7,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 86.0,
    "vitamina a  [\u00b5g]": 31.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.55,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 6.2,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 6.9,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.6,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Lombo de porco cru",
    "energia[kcal]": 131.0,
    "energia[kj]": 551.0,
    "l\u00edpidos[g]": 4.7,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 58.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.7,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 5.3,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 4.7,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 53.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de porco frito",
    "energia[kcal]": 182.0,
    "energia[kj]": 763.0,
    "l\u00edpidos[g]": 7.4,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 84.0,
    "vitamina a  [\u00b5g]": 35.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.68,
    "tiamina [mg]": 0.48,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 5.4,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 6.1,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Lombo de porco frito com manteiga",
    "energia[kcal]": 180.0,
    "energia[kj]": 756.0,
    "l\u00edpidos[g]": 7.2,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.59,
    "tiamina [mg]": 0.48,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 5.4,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 6.1,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Lombo de porco frito com margarina",
    "energia[kcal]": 184.0,
    "energia[kj]": 771.0,
    "l\u00edpidos[g]": 7.6,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 82.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.76,
    "tiamina [mg]": 0.48,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 5.4,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 6.1,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Lombo de porco frito panado",
    "energia[kcal]": 249.0,
    "energia[kj]": 1040.0,
    "l\u00edpidos[g]": 12.5,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.4,
    "\u00e1cidos gordos polinsaturados [g]": 5.0,
    "\u00e1cido linoleico [g]": 4.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.5,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.2,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 25.3,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 51.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 112.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.62,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.6,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Lombo de porco grelhado",
    "energia[kcal]": 189.0,
    "energia[kj]": 794.0,
    "l\u00edpidos[g]": 8.1,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 29.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 29.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.77,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 5.5,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 6.2,
    "vitamina b6 [mg]": 0.42,
    "vitamina b12 [\u00b5g]": 0.97,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 440.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Lombo de vaca magro assado com azeite e margarina",
    "energia[kcal]": 205.0,
    "energia[kj]": 856.0,
    "l\u00edpidos[g]": 11.1,
    "\u00e1cidos gordos saturados[g]": 3.9,
    "\u00e1cidos gordos monoinsaturados [g]": 5.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.15,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.2,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.61,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 9.85,
    "triptofano/60 [mg]": 5.61,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.33,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 4.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vaca magro assado com azeite, manteiga e \u00f3leo alimentar",
    "energia[kcal]": 207.0,
    "energia[kj]": 867.0,
    "l\u00edpidos[g]": 11.4,
    "\u00e1cidos gordos saturados[g]": 3.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.2,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 82.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 2.07,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 9.85,
    "triptofano/60 [mg]": 5.61,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.25,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 4.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vaca magro assado com margarina",
    "energia[kcal]": 198.0,
    "energia[kj]": 830.0,
    "l\u00edpidos[g]": 10.4,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.69,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.2,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 47.0,
    "caroteno [\u00b5g]": 31.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 9.85,
    "triptofano/60 [mg]": 5.61,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.46,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 4.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vaca magro assado com \u00f3leo alimentar e margarina",
    "energia[kcal]": 205.0,
    "energia[kj]": 856.0,
    "l\u00edpidos[g]": 11.1,
    "\u00e1cidos gordos saturados[g]": 3.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.6,
    "\u00e1cido linoleico [g]": 3.4,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.2,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 2.44,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 9.85,
    "triptofano/60 [mg]": 5.61,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.33,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 4.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vaca magro assado, sem molho",
    "energia[kcal]": 172.0,
    "energia[kj]": 722.0,
    "l\u00edpidos[g]": 6.1,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 29.2,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 89.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 6.2,
    "vitamina b6 [mg]": 0.31,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 5.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vaca magro cru",
    "energia[kcal]": 114.0,
    "energia[kj]": 479.0,
    "l\u00edpidos[g]": 3.3,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.4,
    "equivalentes de niacina [mg]": 7.9,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.47,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 60.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vaca magro no forno, com tomate, cebola, cenoura e alho",
    "energia[kcal]": 128.0,
    "energia[kj]": 534.0,
    "l\u00edpidos[g]": 6.8,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.432,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 2.5,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.1,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 11.9,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 1.0,
    "\u00e1gua [g]": 74.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 33.0,
    "vitamina a  [\u00b5g]": 168.0,
    "caroteno [\u00b5g]": 1010.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 1.14,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 4.77,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 7.1,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 1.75,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 99.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vitela assado com azeite e margarina",
    "energia[kcal]": 225.0,
    "energia[kj]": 936.0,
    "l\u00edpidos[g]": 14.4,
    "\u00e1cidos gordos saturados[g]": 5.3,
    "\u00e1cidos gordos monoinsaturados [g]": 7.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 0.888,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 59.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 105.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.68,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.7,
    "equivalentes de niacina [mg]": 12.7,
    "triptofano/60 [mg]": 4.84,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 5.5,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 3.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vitela assado com azeite, margarina e \u00f3leo alimentar",
    "energia[kcal]": 225.0,
    "energia[kj]": 936.0,
    "l\u00edpidos[g]": 14.4,
    "\u00e1cidos gordos saturados[g]": 5.2,
    "\u00e1cidos gordos monoinsaturados [g]": 6.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.9,
    "\u00e1cido linoleico [g]": 1.55,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 59.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 105.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.22,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.7,
    "equivalentes de niacina [mg]": 12.7,
    "triptofano/60 [mg]": 4.84,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 5.5,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 3.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vitela assado com margarina e \u00f3leo alimentar",
    "energia[kcal]": 225.0,
    "energia[kj]": 936.0,
    "l\u00edpidos[g]": 14.4,
    "\u00e1cidos gordos saturados[g]": 5.2,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 3.2,
    "\u00e1cido linoleico [g]": 2.88,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 59.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 105.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.3,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.7,
    "equivalentes de niacina [mg]": 12.7,
    "triptofano/60 [mg]": 4.84,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 5.5,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 3.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vitela assado, sem molho",
    "energia[kcal]": 151.0,
    "energia[kj]": 634.0,
    "l\u00edpidos[g]": 5.6,
    "\u00e1cidos gordos saturados[g]": 2.3,
    "\u00e1cidos gordos monoinsaturados [g]": 2.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 121.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.4,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 1.71,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 4.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Lombo de vitela cru",
    "energia[kcal]": 148.0,
    "energia[kj]": 620.0,
    "l\u00edpidos[g]": 7.6,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.17,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 6.6,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.7,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 24.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vitela frito com margarina",
    "energia[kcal]": 217.0,
    "energia[kj]": 906.0,
    "l\u00edpidos[g]": 13.7,
    "\u00e1cidos gordos saturados[g]": 6.2,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.7,
    "\u00e1cido linoleico [g]": 1.31,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.5,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 108.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 24.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 5.9,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 4.97,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.6,
    "cinza [g]": 2.54,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo de vitela frito, sem molho",
    "energia[kcal]": 195.0,
    "energia[kj]": 816.0,
    "l\u00edpidos[g]": 10.6,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.9,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 102.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.5,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 4.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Lombo de vitela grelhado",
    "energia[kcal]": 150.0,
    "energia[kj]": 632.0,
    "l\u00edpidos[g]": 5.6,
    "\u00e1cidos gordos saturados[g]": 2.3,
    "\u00e1cidos gordos monoinsaturados [g]": 2.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 125.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.12,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.3,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 4.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Lombo ou p\u00e1 de cavalo assado com azeite e manteiga",
    "energia[kcal]": 167.0,
    "energia[kj]": 701.0,
    "l\u00edpidos[g]": 8.0,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.956,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.7,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.6,
    "\u00e1gua [g]": 66.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 66.0,
    "vitamina a  [\u00b5g]": 37.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 8.33,
    "triptofano/60 [mg]": 4.28,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 2.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.26,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 4.1,
    "zinco [mg]": 3.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo ou p\u00e1 de cavalo assado com margarina",
    "energia[kcal]": 161.0,
    "energia[kj]": 675.0,
    "l\u00edpidos[g]": 7.3,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.86,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.7,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.6,
    "\u00e1gua [g]": 67.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 59.0,
    "caroteno [\u00b5g]": 23.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 8.33,
    "triptofano/60 [mg]": 4.28,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 2.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.41,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 4.1,
    "zinco [mg]": 3.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo ou p\u00e1 de cavalo assado, sem molho",
    "energia[kcal]": 136.0,
    "energia[kj]": 571.0,
    "l\u00edpidos[g]": 4.3,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.2,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 58.0,
    "vitamina a  [\u00b5g]": 22.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 3.7,
    "equivalentes de niacina [mg]": 8.5,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 2.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 4.5,
    "zinco [mg]": 3.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo ou p\u00e1 de cavalo cru",
    "energia[kcal]": 99.0,
    "energia[kj]": 418.0,
    "l\u00edpidos[g]": 2.3,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 52.0,
    "vitamina a  [\u00b5g]": 21.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 7.2,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 3.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 1.12,
    "s\u00f3dio [mg]": 48.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 3.6,
    "zinco [mg]": 2.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo ou p\u00e1 de cavalo frito com manteiga",
    "energia[kcal]": 158.0,
    "energia[kj]": 663.0,
    "l\u00edpidos[g]": 8.0,
    "\u00e1cidos gordos saturados[g]": 4.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.794,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.6,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 73.0,
    "vitamina a  [\u00b5g]": 61.0,
    "caroteno [\u00b5g]": 3.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 2.9,
    "equivalentes de niacina [mg]": 7.94,
    "triptofano/60 [mg]": 4.08,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.12,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 3.9,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lombo ou p\u00e1 de cavalo frito com margarina",
    "energia[kcal]": 157.0,
    "energia[kj]": 656.0,
    "l\u00edpidos[g]": 7.8,
    "\u00e1cidos gordos saturados[g]": 3.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 1.99,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.6,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 58.0,
    "vitamina a  [\u00b5g]": 63.0,
    "caroteno [\u00b5g]": 27.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 2.9,
    "equivalentes de niacina [mg]": 7.94,
    "triptofano/60 [mg]": 4.08,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.21,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 3.9,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lula crua",
    "energia[kcal]": 71.0,
    "energia[kj]": 302.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 15.8,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 140.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 3.5,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 4.4,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.1,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 49.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lula estufada com cebola, tomate e azeite",
    "energia[kcal]": 135.0,
    "energia[kj]": 566.0,
    "l\u00edpidos[g]": 6.3,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.452,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.9,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 0.0,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 16.1,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 136.0,
    "vitamina a  [\u00b5g]": 53.0,
    "caroteno [\u00b5g]": 259.0,
    "vitamina d [\u00b5g]": 2.8,
    "alfa-tocoferol [mg]": 2.56,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 3.39,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.85,
    "vitamina c [mg]": 11.2,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 2.95,
    "s\u00f3dio [mg]": 640.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 38.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 54.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Lula grelhada",
    "energia[kcal]": 144.0,
    "energia[kj]": 612.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 32.5,
    "sal  [g]": 2.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 260.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 6.3,
    "alfa-tocoferol [mg]": 1.8,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.63,
    "equivalentes de niacina [mg]": 7.5,
    "triptofano/60 [mg]": 6.9,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.8,
    "cinza [g]": 3.6,
    "s\u00f3dio [mg]": 820.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 49.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 22.0
  },
  {
    "name": "Ma\u00e7\u00e3 (m\u00e9dia com e sem casca)",
    "energia[kcal]": 62.0,
    "energia[kj]": 262.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 13.1,
    "a\u00e7\u00facares [g]": 13.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 26.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.32,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 5.5,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 6.5,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Ma\u00e7\u00e3 assada com a\u00e7\u00facar",
    "energia[kcal]": 106.0,
    "energia[kj]": 448.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 23.9,
    "a\u00e7\u00facares [g]": 23.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.2,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 22.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 7.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ma\u00e7\u00e3 assada sem a\u00e7\u00facar",
    "energia[kcal]": 75.0,
    "energia[kj]": 315.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 15.7,
    "a\u00e7\u00facares [g]": 15.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.6,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 30.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 4.8,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 8.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 8.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ma\u00e7\u00e3 com casca",
    "energia[kcal]": 64.0,
    "energia[kj]": 269.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 13.4,
    "a\u00e7\u00facares [g]": 13.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 26.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.59,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 7.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.32,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 8.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ma\u00e7\u00e3 cozida com a\u00e7\u00facar",
    "energia[kcal]": 77.0,
    "energia[kj]": 325.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 17.0,
    "a\u00e7\u00facares [g]": 17.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 17.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 2.4,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 65.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ma\u00e7\u00e3 cozida sem a\u00e7\u00facar",
    "energia[kcal]": 51.0,
    "energia[kj]": 215.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.5,
    "a\u00e7\u00facares [g]": 10.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 20.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ma\u00e7\u00e3 desidratada",
    "energia[kcal]": 354.0,
    "energia[kj]": 1500.0,
    "l\u00edpidos[g]": 2.9,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 74.5,
    "a\u00e7\u00facares [g]": 74.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 11.4,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.45,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.57,
    "equivalentes de niacina [mg]": 1.14,
    "triptofano/60 [mg]": 0.57,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 28.5,
    "cinza [g]": 1.83,
    "s\u00f3dio [mg]": 34.0,
    "pot\u00e1ssio [mg]": 730.0,
    "c\u00e1lcio [mg]": 31.0,
    "f\u00f3sforo [mg]": 40.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ma\u00e7\u00e3 seca",
    "energia[kcal]": 257.0,
    "energia[kj]": 1090.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 57.1,
    "a\u00e7\u00facares [g]": 57.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 9.5,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 29.6,
    "\u00e1cidos org\u00e2nicos [g]": 1.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 26.0,
    "pot\u00e1ssio [mg]": 550.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 33.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ma\u00e7\u00e3 sem casca",
    "energia[kcal]": 61.0,
    "energia[kj]": 256.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.7,
    "a\u00e7\u00facares [g]": 12.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 25.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.27,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.32,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Madalena",
    "energia[kcal]": 443.0,
    "energia[kj]": 1860.0,
    "l\u00edpidos[g]": 22.6,
    "\u00e1cidos gordos saturados[g]": 3.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 13.3,
    "\u00e1cido linoleico [g]": 13.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 54.7,
    "a\u00e7\u00facares [g]": 36.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 18.5,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 4.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 16.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 77.0,
    "vitamina a  [\u00b5g]": 39.0,
    "caroteno [\u00b5g]": 2.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 12.8,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 1.54,
    "triptofano/60 [mg]": 1.23,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.5,
    "cinza [g]": 0.711,
    "s\u00f3dio [mg]": 130.0,
    "pot\u00e1ssio [mg]": 85.0,
    "c\u00e1lcio [mg]": 34.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Maionese caseira, com ovo e azeite",
    "energia[kcal]": 657.0,
    "energia[kj]": 2710.0,
    "l\u00edpidos[g]": 71.2,
    "\u00e1cidos gordos saturados[g]": 10.6,
    "\u00e1cidos gordos monoinsaturados [g]": 54.6,
    "\u00e1cidos gordos polinsaturados [g]": 5.3,
    "\u00e1cido linoleico [g]": 4.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.0,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 24.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 122.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 10.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.01,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.29,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 40.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 55.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Maionese caseira, com ovo e \u00f3leo de soja",
    "energia[kcal]": 644.0,
    "energia[kj]": 2650.0,
    "l\u00edpidos[g]": 69.8,
    "\u00e1cidos gordos saturados[g]": 11.3,
    "\u00e1cidos gordos monoinsaturados [g]": 16.1,
    "\u00e1cidos gordos polinsaturados [g]": 38.2,
    "\u00e1cido linoleico [g]": 34.9,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 3.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 24.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 122.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 12.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.01,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 40.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 55.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Malagueta vermelha fresca",
    "energia[kcal]": 31.0,
    "energia[kj]": 131.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.3,
    "a\u00e7\u00facares [g]": 4.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.0,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 685.0,
    "caroteno [\u00b5g]": 4105.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 2.2,
    "equivalentes de niacina [mg]": 2.5,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 225.0,
    "folatos [\u00b5g]": 23.0,
    "cinza [g]": 0.53,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 440.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 39.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Mandioca crua",
    "energia[kcal]": 145.0,
    "energia[kj]": 615.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 33.8,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 32.4,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.68,
    "equivalentes de niacina [mg]": 0.89,
    "triptofano/60 [mg]": 0.21,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 25.8,
    "folatos [\u00b5g]": 23.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 9.5,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 31.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Manga",
    "energia[kcal]": 59.0,
    "energia[kj]": 250.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.7,
    "a\u00e7\u00facares [g]": 11.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 2.9,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.6,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 300.0,
    "caroteno [\u00b5g]": 1800.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 23.0,
    "folatos [\u00b5g]": 36.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 14.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Manga desidratada",
    "energia[kcal]": 340.0,
    "energia[kj]": 1440.0,
    "l\u00edpidos[g]": 1.7,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 67.4,
    "a\u00e7\u00facares [g]": 66.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.2,
    "fibra  [g]": 16.7,
    "prote\u00ednas [g]": 2.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 3.5,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.76,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.29,
    "niacina [mg]": 2.88,
    "equivalentes de niacina [mg]": 3.46,
    "triptofano/60 [mg]": 0.58,
    "vitamina b6 [mg]": 0.75,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 207.3,
    "cinza [g]": 2.88,
    "s\u00f3dio [mg]": 81.0,
    "pot\u00e1ssio [mg]": 660.0,
    "c\u00e1lcio [mg]": 52.0,
    "f\u00f3sforo [mg]": 58.0,
    "magn\u00e9sio [mg]": 75.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Manjeric\u00e3o fresco",
    "energia[kcal]": 48.0,
    "energia[kj]": 200.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.1,
    "a\u00e7\u00facares [g]": 5.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.9,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 660.0,
    "caroteno [\u00b5g]": 3950.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.0,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 1.6,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 26.0,
    "folatos [\u00b5g]": 110.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 250.0,
    "f\u00f3sforo [mg]": 37.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 5.5,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Manjeric\u00e3o seco",
    "energia[kcal]": 244.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 4.1,
    "\u00e1cidos gordos saturados[g]": 2.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.0,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.3,
    "fibra  [g]": 37.7,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 938.0,
    "caroteno [\u00b5g]": 5630.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 10.7,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 1.2,
    "niacina [mg]": 4.9,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 1.34,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 14.8,
    "s\u00f3dio [mg]": 76.0,
    "pot\u00e1ssio [mg]": 2630.0,
    "c\u00e1lcio [mg]": 2240.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 710.0,
    "ferro [mg]": 90.0,
    "zinco [mg]": 7.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Manjerona seca",
    "energia[kcal]": 320.0,
    "energia[kj]": 1340.0,
    "l\u00edpidos[g]": 7.0,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 2.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 42.5,
    "a\u00e7\u00facares [g]": 42.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 18.1,
    "prote\u00ednas [g]": 12.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 7.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 807.0,
    "caroteno [\u00b5g]": 4842.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 9.15,
    "tiamina [mg]": 0.29,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 4.1,
    "equivalentes de niacina [mg]": 6.6,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 4.22,
    "s\u00f3dio [mg]": 77.0,
    "pot\u00e1ssio [mg]": 1520.0,
    "c\u00e1lcio [mg]": 1990.0,
    "f\u00f3sforo [mg]": 310.0,
    "magn\u00e9sio [mg]": 200.0,
    "ferro [mg]": 120.0,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Manteiga com sal",
    "energia[kcal]": 739.0,
    "energia[kj]": 3040.0,
    "l\u00edpidos[g]": 81.8,
    "\u00e1cidos gordos saturados[g]": 46.3,
    "\u00e1cidos gordos monoinsaturados [g]": 18.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 3.3,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 1.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 16.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 230.0,
    "vitamina a  [\u00b5g]": 565.0,
    "caroteno [\u00b5g]": 45.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 2.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 750.0,
    "pot\u00e1ssio [mg]": 15.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 24.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Manteiga sem sal",
    "energia[kcal]": 750.0,
    "energia[kj]": 3080.0,
    "l\u00edpidos[g]": 83.0,
    "\u00e1cidos gordos saturados[g]": 47.0,
    "\u00e1cidos gordos monoinsaturados [g]": 19.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 3.3,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 16.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 230.0,
    "vitamina a  [\u00b5g]": 565.0,
    "caroteno [\u00b5g]": 45.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 2.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 8.0,
    "pot\u00e1ssio [mg]": 15.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 24.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Maracuj\u00e1",
    "energia[kcal]": 52.0,
    "energia[kj]": 221.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.7,
    "a\u00e7\u00facares [g]": 5.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.3,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.9,
    "\u00e1cidos org\u00e2nicos [g]": 3.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 125.0,
    "caroteno [\u00b5g]": 750.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 23.0,
    "folatos [\u00b5g]": 9.2,
    "cinza [g]": 0.79,
    "s\u00f3dio [mg]": 19.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 64.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 1.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Margarina",
    "energia[kcal]": 740.0,
    "energia[kj]": 3040.0,
    "l\u00edpidos[g]": 82.0,
    "\u00e1cidos gordos saturados[g]": 35.4,
    "\u00e1cidos gordos monoinsaturados [g]": 23.0,
    "\u00e1cidos gordos polinsaturados [g]": 16.9,
    "\u00e1cido linoleico [g]": 15.5,
    "\u00e1cidos gordos trans [g]": 2.4,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 2.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 14.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 151.0,
    "vitamina a  [\u00b5g]": 305.0,
    "caroteno [\u00b5g]": 133.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 12.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.1,
    "s\u00f3dio [mg]": 1120.0,
    "pot\u00e1ssio [mg]": 6.7,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 5.7,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Margarina 3/4, de girassol",
    "energia[kcal]": 524.0,
    "energia[kj]": 2150.0,
    "l\u00edpidos[g]": 58.0,
    "\u00e1cidos gordos saturados[g]": 13.7,
    "\u00e1cidos gordos monoinsaturados [g]": 13.0,
    "\u00e1cidos gordos polinsaturados [g]": 30.6,
    "\u00e1cido linoleico [g]": 30.5,
    "\u00e1cidos gordos trans [g]": 1.4,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 40.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 1090.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 18.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 21.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 12.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Margarina culin\u00e1ria para folhados, com sal",
    "energia[kcal]": 772.0,
    "energia[kj]": 3170.0,
    "l\u00edpidos[g]": 85.5,
    "\u00e1cidos gordos saturados[g]": 31.4,
    "\u00e1cidos gordos monoinsaturados [g]": 26.1,
    "\u00e1cidos gordos polinsaturados [g]": 13.2,
    "\u00e1cido linoleico [g]": 12.4,
    "\u00e1cidos gordos trans [g]": 3.6,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 2.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 225.0,
    "vitamina a  [\u00b5g]": 157.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 36.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.7,
    "s\u00f3dio [mg]": 970.0,
    "pot\u00e1ssio [mg]": 8.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Margarina industrial para pastelaria, com sal",
    "energia[kcal]": 727.0,
    "energia[kj]": 2990.0,
    "l\u00edpidos[g]": 80.5,
    "\u00e1cidos gordos saturados[g]": 34.7,
    "\u00e1cidos gordos monoinsaturados [g]": 25.7,
    "\u00e1cidos gordos polinsaturados [g]": 15.1,
    "\u00e1cido linoleico [g]": 14.0,
    "\u00e1cidos gordos trans [g]": 3.6,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 3.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 16.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 225.0,
    "vitamina a  [\u00b5g]": 157.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.3,
    "s\u00f3dio [mg]": 1200.0,
    "pot\u00e1ssio [mg]": 6.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Margarina vegetal culin\u00e1ria, 80% gordura, com sal",
    "energia[kcal]": 722.0,
    "energia[kj]": 2970.0,
    "l\u00edpidos[g]": 80.0,
    "\u00e1cidos gordos saturados[g]": 40.2,
    "\u00e1cidos gordos monoinsaturados [g]": 17.2,
    "\u00e1cidos gordos polinsaturados [g]": 22.3,
    "\u00e1cido linoleico [g]": 20.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 3.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 16.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 600.0,
    "caroteno [\u00b5g]": 400.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.3,
    "s\u00f3dio [mg]": 1200.0,
    "pot\u00e1ssio [mg]": 6.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Margarina vegetal culin\u00e1ria, com alho e sal",
    "energia[kcal]": 692.0,
    "energia[kj]": 2850.0,
    "l\u00edpidos[g]": 74.3,
    "\u00e1cidos gordos saturados[g]": 29.7,
    "\u00e1cidos gordos monoinsaturados [g]": 23.6,
    "\u00e1cidos gordos polinsaturados [g]": 20.6,
    "\u00e1cido linoleico [g]": 18.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.4,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.5,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 3.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 16.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 560.0,
    "caroteno [\u00b5g]": 372.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.05,
    "equivalentes de niacina [mg]": 0.35,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.4,
    "s\u00f3dio [mg]": 1200.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 21.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Marmelada",
    "energia[kcal]": 284.0,
    "energia[kj]": 1200.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 69.7,
    "a\u00e7\u00facares [g]": 69.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.2,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 24.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Marmelo",
    "energia[kcal]": 53.0,
    "energia[kj]": 223.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.3,
    "a\u00e7\u00facares [g]": 9.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 6.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 29.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.55,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 14.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.35,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 14.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Maruca cozida",
    "energia[kcal]": 73.0,
    "energia[kj]": 308.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.9,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.52,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.48,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.8,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 27.0
  },
  {
    "name": "Maruca crua",
    "energia[kcal]": 70.0,
    "energia[kj]": 296.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.2,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 28.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.67,
    "equivalentes de niacina [mg]": 3.9,
    "triptofano/60 [mg]": 3.2,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.48,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.8,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Massa com ovo cozida",
    "energia[kcal]": 71.0,
    "energia[kj]": 301.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 13.3,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 13.0,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 16.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 2.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 28.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 41.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Massa com ovo crua",
    "energia[kcal]": 360.0,
    "energia[kj]": 1520.0,
    "l\u00edpidos[g]": 3.1,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 67.6,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 65.8,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 13.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 79.0,
    "vitamina a  [\u00b5g]": 37.0,
    "caroteno [\u00b5g]": 9.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 2.8,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 0.97,
    "s\u00f3dio [mg]": 19.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 52.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Massa de piment\u00e3o",
    "energia[kcal]": 44.0,
    "energia[kj]": 182.0,
    "l\u00edpidos[g]": 1.0,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.49,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.4,
    "a\u00e7\u00facares [g]": 4.1,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.2,
    "fibra  [g]": 3.3,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 3.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 355.0,
    "caroteno [\u00b5g]": 2120.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.31,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.98,
    "equivalentes de niacina [mg]": 1.31,
    "triptofano/60 [mg]": 0.33,
    "vitamina b6 [mg]": 0.51,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 147.0,
    "folatos [\u00b5g]": 46.0,
    "cinza [g]": 3.86,
    "s\u00f3dio [mg]": 1310.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 39.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Massa folhada, congelada",
    "energia[kcal]": 398.0,
    "energia[kj]": 1650.0,
    "l\u00edpidos[g]": 27.5,
    "\u00e1cidos gordos saturados[g]": 10.3,
    "\u00e1cidos gordos monoinsaturados [g]": 10.4,
    "\u00e1cidos gordos polinsaturados [g]": 4.7,
    "\u00e1cido linoleico [g]": 4.25,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 31.8,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 31.6,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 5.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 33.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 310.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.4,
    "alfa-tocoferol [mg]": 2.72,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 1.87,
    "equivalentes de niacina [mg]": 2.7,
    "triptofano/60 [mg]": 0.83,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.03,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.2,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 3.5,
    "pot\u00e1ssio [mg]": 61.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 59.0,
    "magn\u00e9sio [mg]": 9.5,
    "ferro [mg]": 3.1,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Massa mi\u00fada crua",
    "energia[kcal]": 356.0,
    "energia[kj]": 1510.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 70.0,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 67.8,
    "fibra  [g]": 5.1,
    "prote\u00ednas [g]": 12.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.24,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Massa para lasanha cozida",
    "energia[kcal]": 115.0,
    "energia[kj]": 486.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 22.6,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 21.8,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 4.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 30.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 57.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Massa para lasanha crua",
    "energia[kcal]": 359.0,
    "energia[kj]": 1520.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 71.4,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 68.8,
    "fibra  [g]": 3.1,
    "prote\u00ednas [g]": 12.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.5,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 4.4,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Massa para pizza",
    "energia[kcal]": 289.0,
    "energia[kj]": 1220.0,
    "l\u00edpidos[g]": 4.8,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 3.0,
    "\u00e1cido linoleico [g]": 2.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 52.5,
    "a\u00e7\u00facares [g]": 3.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 49.2,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 7.8,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 30.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.85,
    "tiamina [mg]": 0.41,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 4.2,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 86.0,
    "f\u00f3sforo [mg]": 85.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Massa quebrada para quiche",
    "energia[kcal]": 498.0,
    "energia[kj]": 2080.0,
    "l\u00edpidos[g]": 29.2,
    "\u00e1cidos gordos saturados[g]": 15.3,
    "\u00e1cidos gordos monoinsaturados [g]": 6.4,
    "\u00e1cidos gordos polinsaturados [g]": 4.6,
    "\u00e1cido linoleico [g]": 4.16,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 52.4,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 51.1,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 5.5,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 41.0,
    "vitamina a  [\u00b5g]": 205.0,
    "caroteno [\u00b5g]": 78.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.56,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.77,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 1.12,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.62,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 95.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Meia-desfeita de bacalhau",
    "energia[kcal]": 156.0,
    "energia[kj]": 657.0,
    "l\u00edpidos[g]": 4.2,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.977,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.8,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 1.2,
    "amido [g]": 12.2,
    "fibra  [g]": 4.2,
    "prote\u00ednas [g]": 12.7,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 51.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 138.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.97,
    "equivalentes de niacina [mg]": 3.15,
    "triptofano/60 [mg]": 2.17,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.39,
    "vitamina c [mg]": 11.2,
    "folatos [\u00b5g]": 66.0,
    "cinza [g]": 2.53,
    "s\u00f3dio [mg]": 610.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 69.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Mel",
    "energia[kcal]": 314.0,
    "energia[kj]": 1330.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 78.0,
    "a\u00e7\u00facares [g]": 78.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 18.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.16,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 51.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Mel de cana",
    "energia[kcal]": 311.0,
    "energia[kj]": 1320.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 77.4,
    "a\u00e7\u00facares [g]": 77.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 20.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.39,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 58.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 1.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Mela\u00e7o",
    "energia[kcal]": 269.0,
    "energia[kj]": 1140.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 66.6,
    "a\u00e7\u00facares [g]": 57.8,
    "oligossac\u00e1ridos [g]": 8.8,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 25.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.41,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.45,
    "equivalentes de niacina [mg]": 0.45,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.53,
    "s\u00f3dio [mg]": 67.0,
    "pot\u00e1ssio [mg]": 1470.0,
    "c\u00e1lcio [mg]": 360.0,
    "f\u00f3sforo [mg]": 31.0,
    "magn\u00e9sio [mg]": 190.0,
    "ferro [mg]": 7.0,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Melancia",
    "energia[kcal]": 26.0,
    "energia[kj]": 110.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.5,
    "a\u00e7\u00facares [g]": 5.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 300.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.29,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Mel\u00e3o (3 variedades)",
    "energia[kcal]": 30.0,
    "energia[kj]": 125.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.7,
    "a\u00e7\u00facares [g]": 5.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 167.0,
    "caroteno [\u00b5g]": 1000.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 30.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.68,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 12.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Meloa",
    "energia[kcal]": 23.0,
    "energia[kj]": 98.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.2,
    "a\u00e7\u00facares [g]": 4.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 167.0,
    "caroteno [\u00b5g]": 1000.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 26.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.68,
    "s\u00f3dio [mg]": 8.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Merengue",
    "energia[kcal]": 353.0,
    "energia[kj]": 1500.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 83.0,
    "a\u00e7\u00facares [g]": 83.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 4.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.8,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 97.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Mexilh\u00e3o cozido sem sal",
    "energia[kcal]": 97.0,
    "energia[kj]": 411.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.8,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.8,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.8,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 56.0,
    "vitamina a  [\u00b5g]": 360.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 21.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 36.0,
    "cinza [g]": 2.5,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 78.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 42.0,
    "ferro [mg]": 4.0,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 290.0
  },
  {
    "name": "Mexilh\u00e3o cru",
    "energia[kcal]": 70.0,
    "energia[kj]": 295.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 12.1,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 40.0,
    "vitamina a  [\u00b5g]": 360.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.74,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 2.6,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 19.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 290.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 56.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 3.5,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Milho doce em conserva",
    "energia[kcal]": 106.0,
    "energia[kj]": 448.0,
    "l\u00edpidos[g]": 1.7,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.46,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.4,
    "a\u00e7\u00facares [g]": 5.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 13.1,
    "fibra  [g]": 3.1,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 22.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.29,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.04,
    "equivalentes de niacina [mg]": 1.39,
    "triptofano/60 [mg]": 0.35,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 75.0,
    "cinza [g]": 0.98,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 3.5,
    "f\u00f3sforo [mg]": 54.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 10.0,
    "iodo [\u00b5g]": 1.1
  },
  {
    "name": "Milho, amido (p\u00f3)",
    "energia[kcal]": 364.0,
    "energia[kj]": 1550.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 90.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 90.2,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.19,
    "s\u00f3dio [mg]": 54.0,
    "pot\u00e1ssio [mg]": 63.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Milho, gr\u00e3o seco cru",
    "energia[kcal]": 368.0,
    "energia[kj]": 1560.0,
    "l\u00edpidos[g]": 4.9,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 70.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 70.3,
    "fibra  [g]": 2.9,
    "prote\u00ednas [g]": 9.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.0,
    "tiamina [mg]": 0.63,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 2.6,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.62,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 14.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 110.0,
    "ferro [mg]": 2.5,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Millet, cru, seco",
    "energia[kcal]": 362.0,
    "energia[kj]": 1530.0,
    "l\u00edpidos[g]": 3.2,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 71.2,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 66.0,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 11.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.5,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 1.03,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 91.0,
    "ferro [mg]": 2.4,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 3.5,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Minarina (meia margarina)",
    "energia[kcal]": 380.0,
    "energia[kj]": 1560.0,
    "l\u00edpidos[g]": 41.6,
    "\u00e1cidos gordos saturados[g]": 14.0,
    "\u00e1cidos gordos monoinsaturados [g]": 8.7,
    "\u00e1cidos gordos polinsaturados [g]": 17.5,
    "\u00e1cido linoleico [g]": 15.8,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 750.0,
    "caroteno [\u00b5g]": 440.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 13.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 47.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 9.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Mirtilo",
    "energia[kcal]": 43.0,
    "energia[kj]": 183.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.123,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.4,
    "a\u00e7\u00facares [g]": 6.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.1,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 47.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.9,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.42,
    "equivalentes de niacina [mg]": 0.47,
    "triptofano/60 [mg]": 0.05,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 15.0,
    "folatos [\u00b5g]": 11.5,
    "cinza [g]": 0.25,
    "s\u00f3dio [mg]": 0.3,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 20.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.1,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Mirtilo desidratado",
    "energia[kcal]": 317.0,
    "energia[kj]": 1330.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 0.899,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 46.8,
    "a\u00e7\u00facares [g]": 46.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 22.7,
    "prote\u00ednas [g]": 3.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 10.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 13.89,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.51,
    "niacina [mg]": 3.05,
    "equivalentes de niacina [mg]": 3.42,
    "triptofano/60 [mg]": 0.37,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 84.0,
    "cinza [g]": 1.83,
    "s\u00f3dio [mg]": 2.2,
    "pot\u00e1ssio [mg]": 800.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 66.0,
    "ferro [mg]": 4.4,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Miso, pasta de soja fermentada com arroz integral e cevada",
    "energia[kcal]": 177.0,
    "energia[kj]": 741.0,
    "l\u00edpidos[g]": 6.5,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 3.7,
    "\u00e1cido linoleico [g]": 3.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 15.1,
    "a\u00e7\u00facares [g]": 13.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.4,
    "fibra  [g]": 5.2,
    "prote\u00ednas [g]": 11.9,
    "sal  [g]": 9.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 49.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 39.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 3.0,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 30.5,
    "cinza [g]": 12.2,
    "s\u00f3dio [mg]": 3950.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 61.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 31.0,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Molho \"B\u00e9chamel\" com manteiga",
    "energia[kcal]": 130.0,
    "energia[kj]": 544.0,
    "l\u00edpidos[g]": 8.4,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 9.6,
    "a\u00e7\u00facares [g]": 5.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.3,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 4.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 27.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.7,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho \"B\u00e9chamel\" com margarina",
    "energia[kcal]": 140.0,
    "energia[kj]": 583.0,
    "l\u00edpidos[g]": 9.5,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 1.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 9.9,
    "a\u00e7\u00facares [g]": 5.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.4,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 3.6,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 9.0,
    "vitamina a  [\u00b5g]": 70.0,
    "caroteno [\u00b5g]": 40.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.7,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho branco com caldo de carne de vaca",
    "energia[kcal]": 115.0,
    "energia[kj]": 477.0,
    "l\u00edpidos[g]": 9.2,
    "\u00e1cidos gordos saturados[g]": 4.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.8,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 6.5,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 1.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 48.0,
    "caroteno [\u00b5g]": 34.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.08,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 770.0,
    "pot\u00e1ssio [mg]": 26.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 18.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho branco com margarina",
    "energia[kcal]": 244.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 16.8,
    "\u00e1cidos gordos saturados[g]": 8.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.7,
    "\u00e1cidos gordos polinsaturados [g]": 4.7,
    "\u00e1cido linoleico [g]": 4.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.5,
    "a\u00e7\u00facares [g]": 4.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 14.3,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 4.4,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 140.0,
    "caroteno [\u00b5g]": 87.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.07,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.3,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 560.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 98.0,
    "f\u00f3sforo [mg]": 93.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de bife frito com manteiga",
    "energia[kcal]": 379.0,
    "energia[kj]": 1560.0,
    "l\u00edpidos[g]": 41.0,
    "\u00e1cidos gordos saturados[g]": 23.2,
    "\u00e1cidos gordos monoinsaturados [g]": 9.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 1.7,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 55.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 121.0,
    "vitamina a  [\u00b5g]": 224.0,
    "caroteno [\u00b5g]": 18.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.14,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.4,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 46.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 27.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de carne de vaca assada com azeite e margarina",
    "energia[kcal]": 586.0,
    "energia[kj]": 2410.0,
    "l\u00edpidos[g]": 64.0,
    "\u00e1cidos gordos saturados[g]": 19.5,
    "\u00e1cidos gordos monoinsaturados [g]": 34.0,
    "\u00e1cidos gordos polinsaturados [g]": 10.3,
    "\u00e1cido linoleico [g]": 9.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 2.1,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 32.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 168.0,
    "caroteno [\u00b5g]": 112.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 4.9,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.2,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 47.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 25.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de carne de vaca assada com margarina",
    "energia[kcal]": 513.0,
    "energia[kj]": 2110.0,
    "l\u00edpidos[g]": 56.0,
    "\u00e1cidos gordos saturados[g]": 28.0,
    "\u00e1cidos gordos monoinsaturados [g]": 12.3,
    "\u00e1cidos gordos polinsaturados [g]": 15.4,
    "\u00e1cido linoleico [g]": 13.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 1.9,
    "sal  [g]": 1.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 40.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 336.0,
    "caroteno [\u00b5g]": 224.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.4,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 760.0,
    "pot\u00e1ssio [mg]": 49.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 27.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de carne de vaca estufada com azeite e margarina",
    "energia[kcal]": 260.0,
    "energia[kj]": 1070.0,
    "l\u00edpidos[g]": 27.9,
    "\u00e1cidos gordos saturados[g]": 7.0,
    "\u00e1cidos gordos monoinsaturados [g]": 17.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.6,
    "\u00e1cido linoleico [g]": 3.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.0,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 1.7,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 53.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 3.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.11,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 1.8,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 51.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 23.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de carne de vaca estufada com vegetais, azeite e margarina",
    "energia[kcal]": 267.0,
    "energia[kj]": 1100.0,
    "l\u00edpidos[g]": 28.5,
    "\u00e1cidos gordos saturados[g]": 7.1,
    "\u00e1cidos gordos monoinsaturados [g]": 17.7,
    "\u00e1cidos gordos polinsaturados [g]": 3.6,
    "\u00e1cido linoleico [g]": 3.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.9,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 1.3,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 4.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 53.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 2.9,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 63.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de lombo de porco frito com banha",
    "energia[kcal]": 551.0,
    "energia[kj]": 2260.0,
    "l\u00edpidos[g]": 60.2,
    "\u00e1cidos gordos saturados[g]": 15.9,
    "\u00e1cidos gordos monoinsaturados [g]": 35.3,
    "\u00e1cidos gordos polinsaturados [g]": 6.3,
    "\u00e1cido linoleico [g]": 5.8,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 37.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.4,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 36.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 23.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de lombo de porco frito com banha e margarina",
    "energia[kcal]": 498.0,
    "energia[kj]": 2050.0,
    "l\u00edpidos[g]": 54.3,
    "\u00e1cidos gordos saturados[g]": 20.1,
    "\u00e1cidos gordos monoinsaturados [g]": 22.9,
    "\u00e1cidos gordos polinsaturados [g]": 9.9,
    "\u00e1cido linoleico [g]": 9.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 152.0,
    "caroteno [\u00b5g]": 96.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.4,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 38.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 24.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de lombo de porco frito com margarina",
    "energia[kcal]": 447.0,
    "energia[kj]": 1840.0,
    "l\u00edpidos[g]": 48.5,
    "\u00e1cidos gordos saturados[g]": 24.3,
    "\u00e1cidos gordos monoinsaturados [g]": 10.5,
    "\u00e1cidos gordos polinsaturados [g]": 13.5,
    "\u00e1cido linoleico [g]": 12.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.3,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 47.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 288.0,
    "caroteno [\u00b5g]": 192.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.4,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 620.0,
    "pot\u00e1ssio [mg]": 39.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 25.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de lombo de vaca frito com creme vegetal sem sal",
    "energia[kcal]": 350.0,
    "energia[kj]": 1440.0,
    "l\u00edpidos[g]": 37.8,
    "\u00e1cidos gordos saturados[g]": 9.1,
    "\u00e1cidos gordos monoinsaturados [g]": 9.1,
    "\u00e1cidos gordos polinsaturados [g]": 19.3,
    "\u00e1cido linoleico [g]": 19.2,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 240.0,
    "caroteno [\u00b5g]": 128.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 20.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.21,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.4,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 95.0,
    "pot\u00e1ssio [mg]": 52.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 23.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de lombo de vaca frito com margarina",
    "energia[kcal]": 372.0,
    "energia[kj]": 1530.0,
    "l\u00edpidos[g]": 40.3,
    "\u00e1cidos gordos saturados[g]": 20.2,
    "\u00e1cidos gordos monoinsaturados [g]": 8.7,
    "\u00e1cidos gordos polinsaturados [g]": 11.2,
    "\u00e1cido linoleico [g]": 10.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.2,
    "sal  [g]": 1.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 55.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 240.0,
    "caroteno [\u00b5g]": 160.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.4,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 690.0,
    "pot\u00e1ssio [mg]": 42.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 18.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de mostarda ",
    "energia[kcal]": 444.0,
    "energia[kj]": 1840.0,
    "l\u00edpidos[g]": 40.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 34.2,
    "\u00e1cidos gordos polinsaturados [g]": 6.0,
    "\u00e1cido linoleico [g]": 5.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.0,
    "a\u00e7\u00facares [g]": 16.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.7,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 36.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 137.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 4.7,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 91.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 74.0,
    "magn\u00e9sio [mg]": 50.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de soja",
    "energia[kcal]": 89.0,
    "energia[kj]": 380.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 17.3,
    "a\u00e7\u00facares [g]": 15.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.4,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 4.7,
    "sal  [g]": 14.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 2.8,
    "equivalentes de niacina [mg]": 4.25,
    "triptofano/60 [mg]": 1.45,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.5,
    "cinza [g]": 15.3,
    "s\u00f3dio [mg]": 5570.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 86.0,
    "magn\u00e9sio [mg]": 40.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de tomate",
    "energia[kcal]": 107.0,
    "energia[kj]": 444.0,
    "l\u00edpidos[g]": 8.8,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 5.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.9,
    "a\u00e7\u00facares [g]": 4.5,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 0.0,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 80.0,
    "caroteno [\u00b5g]": 446.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.4,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 18.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de tomate, \"ketchup\"",
    "energia[kcal]": 119.0,
    "energia[kj]": 506.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 26.9,
    "a\u00e7\u00facares [g]": 25.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.1,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 1.7,
    "sal  [g]": 3.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 90.0,
    "caroteno [\u00b5g]": 537.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 1.5,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 11.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 1230.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho de vinagrete",
    "energia[kcal]": 648.0,
    "energia[kj]": 2660.0,
    "l\u00edpidos[g]": 71.8,
    "\u00e1cidos gordos saturados[g]": 10.1,
    "\u00e1cidos gordos monoinsaturados [g]": 50.4,
    "\u00e1cidos gordos polinsaturados [g]": 8.1,
    "\u00e1cido linoleico [g]": 7.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 1.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 21.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 2.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 9.5,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 11.0,
    "equivalentes de niacina [mg]": 13.5,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.76,
    "s\u00f3dio [mg]": 700.0,
    "pot\u00e1ssio [mg]": 34.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 8.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho para francesinha",
    "energia[kcal]": 74.0,
    "energia[kj]": 308.0,
    "l\u00edpidos[g]": 2.6,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.602,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.8,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.3,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 3.8,
    "\u00e1gua [g]": 83.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 47.0,
    "caroteno [\u00b5g]": 182.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.25,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.49,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.17,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 3.8,
    "folatos [\u00b5g]": 5.4,
    "cinza [g]": 1.13,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 29.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Molho verde",
    "energia[kcal]": 246.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 25.6,
    "\u00e1cidos gordos saturados[g]": 3.7,
    "\u00e1cidos gordos monoinsaturados [g]": 20.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.9,
    "\u00e1cido linoleico [g]": 1.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.3,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.6,
    "amido [g]": 0.1,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 0.7,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 359.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 4.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 23.0,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 25.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Morango",
    "energia[kcal]": 34.0,
    "energia[kj]": 141.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.3,
    "a\u00e7\u00facares [g]": 5.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.8,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 26.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 47.0,
    "folatos [\u00b5g]": 47.0,
    "cinza [g]": 0.58,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 26.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.8
  },
  {
    "name": "Morango desidratado ",
    "energia[kcal]": 322.0,
    "energia[kj]": 1350.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.9,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 50.9,
    "a\u00e7\u00facares [g]": 50.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 19.2,
    "prote\u00ednas [g]": 5.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 7.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.92,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.58,
    "niacina [mg]": 5.76,
    "equivalentes de niacina [mg]": 7.68,
    "triptofano/60 [mg]": 1.92,
    "vitamina b6 [mg]": 0.48,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 451.0,
    "cinza [g]": 5.57,
    "s\u00f3dio [mg]": 19.0,
    "pot\u00e1ssio [mg]": 1320.0,
    "c\u00e1lcio [mg]": 240.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 96.0,
    "ferro [mg]": 7.7,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Morcela crua",
    "energia[kcal]": 363.0,
    "energia[kj]": 1500.0,
    "l\u00edpidos[g]": 29.5,
    "\u00e1cidos gordos saturados[g]": 9.7,
    "\u00e1cidos gordos monoinsaturados [g]": 11.3,
    "\u00e1cidos gordos polinsaturados [g]": 3.8,
    "\u00e1cido linoleico [g]": 3.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 12.0,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 11.6,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 12.0,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 44.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 106.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.15,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 3.5,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.6,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 510.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 95.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 10.0,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Morcela grelhada sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 339.0,
    "energia[kj]": 1410.0,
    "l\u00edpidos[g]": 25.9,
    "\u00e1cidos gordos saturados[g]": 8.6,
    "\u00e1cidos gordos monoinsaturados [g]": 9.9,
    "\u00e1cidos gordos polinsaturados [g]": 3.4,
    "\u00e1cido linoleico [g]": 2.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 13.1,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 12.7,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 12.9,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 46.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 114.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 3.6,
    "triptofano/60 [mg]": 2.1,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.3,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 510.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 99.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 11.0,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Mortadela",
    "energia[kcal]": 379.0,
    "energia[kj]": 1570.0,
    "l\u00edpidos[g]": 33.2,
    "\u00e1cidos gordos saturados[g]": 12.4,
    "\u00e1cidos gordos monoinsaturados [g]": 15.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.4,
    "\u00e1cido linoleico [g]": 3.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.7,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.3,
    "sal  [g]": 3.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 1.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 3.9,
    "s\u00f3dio [mg]": 1540.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Mousse de chocolate",
    "energia[kcal]": 285.0,
    "energia[kj]": 1190.0,
    "l\u00edpidos[g]": 13.1,
    "\u00e1cidos gordos saturados[g]": 5.7,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 1.41,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 31.0,
    "a\u00e7\u00facares [g]": 30.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 9.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 256.0,
    "vitamina a  [\u00b5g]": 122.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.34,
    "niacina [mg]": 0.68,
    "equivalentes de niacina [mg]": 3.33,
    "triptofano/60 [mg]": 2.67,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 91.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 41.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "M\u00facua (fruto do embondeiro, baobab)",
    "energia[kcal]": 212.0,
    "energia[kj]": 869.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 16.3,
    "a\u00e7\u00facares [g]": 12.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.7,
    "fibra  [g]": 67.3,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 8.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.25,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 19.98,
    "equivalentes de niacina [mg]": 19.98,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 93.7,
    "folatos [\u00b5g]": 282.0,
    "cinza [g]": 6.05,
    "s\u00f3dio [mg]": 3.2,
    "pot\u00e1ssio [mg]": 2400.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 150.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Nabi\u00e7a crua",
    "energia[kcal]": 17.0,
    "energia[kj]": 73.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 1.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 94.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 478.0,
    "caroteno [\u00b5g]": 2860.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 35.0,
    "folatos [\u00b5g]": 126.0,
    "cinza [g]": 0.81,
    "s\u00f3dio [mg]": 40.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 45.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Nabo (raiz) cozido",
    "energia[kcal]": 19.0,
    "energia[kj]": 78.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.3,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 2.2,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 94.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Nabo (raiz) cru",
    "energia[kcal]": 21.0,
    "energia[kj]": 89.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.0,
    "a\u00e7\u00facares [g]": 2.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 18.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 20.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Nata maturada pasteurizada 30% gordura",
    "energia[kcal]": 306.0,
    "energia[kj]": 1260.0,
    "l\u00edpidos[g]": 32.0,
    "\u00e1cidos gordos saturados[g]": 18.0,
    "\u00e1cidos gordos monoinsaturados [g]": 7.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 1.1,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 2.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 1.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 66.0,
    "vitamina a  [\u00b5g]": 450.0,
    "caroteno [\u00b5g]": 232.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.6,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.19,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 28.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 60.0,
    "f\u00f3sforo [mg]": 54.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Nata para bater pasteurizada 34% gordura",
    "energia[kcal]": 323.0,
    "energia[kj]": 1330.0,
    "l\u00edpidos[g]": 34.0,
    "\u00e1cidos gordos saturados[g]": 19.0,
    "\u00e1cidos gordos monoinsaturados [g]": 8.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 1.2,
    "hidratos de carbono [g]": 2.1,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 122.0,
    "vitamina a  [\u00b5g]": 444.0,
    "caroteno [\u00b5g]": 230.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 30.0,
    "pot\u00e1ssio [mg]": 98.0,
    "c\u00e1lcio [mg]": 55.0,
    "f\u00f3sforo [mg]": 54.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Nata pasteurizada 33% gordura",
    "energia[kcal]": 317.0,
    "energia[kj]": 1300.0,
    "l\u00edpidos[g]": 33.0,
    "\u00e1cidos gordos saturados[g]": 18.5,
    "\u00e1cidos gordos monoinsaturados [g]": 7.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 1.1,
    "hidratos de carbono [g]": 2.9,
    "a\u00e7\u00facares [g]": 2.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 97.0,
    "vitamina a  [\u00b5g]": 360.0,
    "caroteno [\u00b5g]": 186.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 37.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 62.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Nata pasteurizada 36% gordura",
    "energia[kcal]": 340.0,
    "energia[kj]": 1400.0,
    "l\u00edpidos[g]": 36.0,
    "\u00e1cidos gordos saturados[g]": 20.2,
    "\u00e1cidos gordos monoinsaturados [g]": 8.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 1.2,
    "hidratos de carbono [g]": 1.9,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 516.0,
    "caroteno [\u00b5g]": 267.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 28.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 64.0,
    "f\u00f3sforo [mg]": 59.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Nata pasteurizada para caf\u00e9, 15% gordura",
    "energia[kcal]": 153.0,
    "energia[kj]": 630.0,
    "l\u00edpidos[g]": 15.0,
    "\u00e1cidos gordos saturados[g]": 8.4,
    "\u00e1cidos gordos monoinsaturados [g]": 3.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 40.0,
    "vitamina a  [\u00b5g]": 224.0,
    "caroteno [\u00b5g]": 96.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 41.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 68.0,
    "f\u00f3sforo [mg]": 52.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Nata UHT 35% gordura",
    "energia[kcal]": 335.0,
    "energia[kj]": 1380.0,
    "l\u00edpidos[g]": 35.0,
    "\u00e1cidos gordos saturados[g]": 20.0,
    "\u00e1cidos gordos monoinsaturados [g]": 7.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 1.2,
    "hidratos de carbono [g]": 3.1,
    "a\u00e7\u00facares [g]": 3.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 74.0,
    "vitamina a  [\u00b5g]": 479.0,
    "caroteno [\u00b5g]": 247.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.9,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.17,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 28.0,
    "pot\u00e1ssio [mg]": 98.0,
    "c\u00e1lcio [mg]": 61.0,
    "f\u00f3sforo [mg]": 52.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "N\u00e9ctar de \"tutti - frutti\"",
    "energia[kcal]": 46.0,
    "energia[kj]": 197.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.8,
    "a\u00e7\u00facares [g]": 10.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 136.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 46.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 82.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "N\u00e9ctar de alperce",
    "energia[kcal]": 56.0,
    "energia[kj]": 237.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.9,
    "a\u00e7\u00facares [g]": 12.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.6,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 100.0,
    "caroteno [\u00b5g]": 600.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 40.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.14,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 58.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "N\u00e9ctar de anan\u00e1s",
    "energia[kcal]": 46.0,
    "energia[kj]": 195.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.5,
    "a\u00e7\u00facares [g]": 10.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.6,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 43.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 0.12,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 58.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 3.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "N\u00e9ctar de laranja",
    "energia[kcal]": 43.0,
    "energia[kj]": 183.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.6,
    "a\u00e7\u00facares [g]": 9.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.7,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 42.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.14,
    "equivalentes de niacina [mg]": 0.14,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 40.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 0.19,
    "s\u00f3dio [mg]": 7.0,
    "pot\u00e1ssio [mg]": 62.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "N\u00e9ctar de ma\u00e7\u00e3",
    "energia[kcal]": 48.0,
    "energia[kj]": 205.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.3,
    "a\u00e7\u00facares [g]": 11.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.15,
    "equivalentes de niacina [mg]": 0.15,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 39.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.11,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 38.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "N\u00e9ctar de ma\u00e7\u00e3, \"light\"",
    "energia[kcal]": 22.0,
    "energia[kj]": 95.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.6,
    "a\u00e7\u00facares [g]": 4.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 94.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.15,
    "equivalentes de niacina [mg]": 0.15,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 30.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.1,
    "s\u00f3dio [mg]": 8.0,
    "pot\u00e1ssio [mg]": 38.0,
    "c\u00e1lcio [mg]": 2.0,
    "f\u00f3sforo [mg]": 3.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "N\u00e9ctar de pera",
    "energia[kcal]": 48.0,
    "energia[kj]": 204.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.0,
    "a\u00e7\u00facares [g]": 11.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 5.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 37.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.12,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 63.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "N\u00e9ctar de p\u00eassego",
    "energia[kcal]": 51.0,
    "energia[kj]": 215.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.7,
    "a\u00e7\u00facares [g]": 11.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 26.0,
    "caroteno [\u00b5g]": 156.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 32.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.19,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 88.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "N\u00e9ctar de p\u00eassego, \"light\"",
    "energia[kcal]": 18.0,
    "energia[kj]": 75.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 3.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 95.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.8,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 26.0,
    "caroteno [\u00b5g]": 156.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.15,
    "equivalentes de niacina [mg]": 0.15,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 41.0,
    "folatos [\u00b5g]": 0.5,
    "cinza [g]": 0.22,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 71.0,
    "c\u00e1lcio [mg]": 2.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Nectarina",
    "energia[kcal]": 49.0,
    "energia[kj]": 206.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.7,
    "a\u00e7\u00facares [g]": 8.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.2,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.9,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 45.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.8,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 37.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 22.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "N\u00easpera",
    "energia[kcal]": 51.0,
    "energia[kj]": 216.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.2,
    "a\u00e7\u00facares [g]": 10.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 27.0,
    "caroteno [\u00b5g]": 160.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.47,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 11.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Noz macad\u00e2mia",
    "energia[kcal]": 752.0,
    "energia[kj]": 3100.0,
    "l\u00edpidos[g]": 76.0,
    "\u00e1cidos gordos saturados[g]": 12.0,
    "\u00e1cidos gordos monoinsaturados [g]": 59.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.86,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.1,
    "a\u00e7\u00facares [g]": 4.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 8.0,
    "prote\u00ednas [g]": 8.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.7,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 3.12,
    "triptofano/60 [mg]": 1.12,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.57,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 78.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 4.0,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Noz pecan",
    "energia[kcal]": 729.0,
    "energia[kj]": 3000.0,
    "l\u00edpidos[g]": 72.6,
    "\u00e1cidos gordos saturados[g]": 6.6,
    "\u00e1cidos gordos monoinsaturados [g]": 40.6,
    "\u00e1cidos gordos polinsaturados [g]": 23.1,
    "\u00e1cido linoleico [g]": 22.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.4,
    "a\u00e7\u00facares [g]": 3.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.9,
    "fibra  [g]": 8.3,
    "prote\u00ednas [g]": 9.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 50.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.45,
    "tiamina [mg]": 0.65,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 1.29,
    "equivalentes de niacina [mg]": 5.39,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.5,
    "folatos [\u00b5g]": 29.4,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 460.0,
    "c\u00e1lcio [mg]": 65.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 130.0,
    "ferro [mg]": 2.4,
    "zinco [mg]": 5.0,
    "sel\u00e9nio [\u00b5g]": 8.6,
    "iodo [\u00b5g]": 2.0
  },
  {
    "name": "Noz, miolo",
    "energia[kcal]": 699.0,
    "energia[kj]": 2880.0,
    "l\u00edpidos[g]": 67.5,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 15.0,
    "\u00e1cidos gordos polinsaturados [g]": 47.0,
    "\u00e1cido linoleico [g]": 38.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.6,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 5.2,
    "prote\u00ednas [g]": 16.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 4.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 3.8,
    "tiamina [mg]": 0.33,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 3.6,
    "triptofano/60 [mg]": 2.7,
    "vitamina b6 [mg]": 0.67,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 66.0,
    "cinza [g]": 1.98,
    "s\u00f3dio [mg]": 12.0,
    "pot\u00e1ssio [mg]": 500.0,
    "c\u00e1lcio [mg]": 90.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 160.0,
    "ferro [mg]": 2.6,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Noz-moscada",
    "energia[kcal]": 506.0,
    "energia[kj]": 2090.0,
    "l\u00edpidos[g]": 36.3,
    "\u00e1cidos gordos saturados[g]": 12.1,
    "\u00e1cidos gordos monoinsaturados [g]": 12.1,
    "\u00e1cidos gordos polinsaturados [g]": 12.1,
    "\u00e1cido linoleico [g]": 11.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 28.5,
    "a\u00e7\u00facares [g]": 24.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.0,
    "fibra  [g]": 20.8,
    "prote\u00ednas [g]": 5.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 76.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 16.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 180.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 180.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00d3leo \"Becel\"",
    "energia[kcal]": 887.0,
    "energia[kj]": 3640.0,
    "l\u00edpidos[g]": 98.5,
    "\u00e1cidos gordos saturados[g]": 11.1,
    "\u00e1cidos gordos monoinsaturados [g]": 21.3,
    "\u00e1cidos gordos polinsaturados [g]": 61.6,
    "\u00e1cido linoleico [g]": 61.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 84.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.02,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 1.0,
    "f\u00f3sforo [mg]": 1.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00d3leo alimentar",
    "energia[kcal]": 896.0,
    "energia[kj]": 3680.0,
    "l\u00edpidos[g]": 99.5,
    "\u00e1cidos gordos saturados[g]": 11.3,
    "\u00e1cidos gordos monoinsaturados [g]": 20.6,
    "\u00e1cidos gordos polinsaturados [g]": 63.9,
    "\u00e1cido linoleico [g]": 63.8,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 61.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00d3leo de amendoim",
    "energia[kcal]": 887.0,
    "energia[kj]": 3640.0,
    "l\u00edpidos[g]": 98.5,
    "\u00e1cidos gordos saturados[g]": 17.5,
    "\u00e1cidos gordos monoinsaturados [g]": 58.4,
    "\u00e1cidos gordos polinsaturados [g]": 17.7,
    "\u00e1cido linoleico [g]": 17.6,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 15.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00d3leo de coco",
    "energia[kcal]": 900.0,
    "energia[kj]": 3700.0,
    "l\u00edpidos[g]": 100.0,
    "\u00e1cidos gordos saturados[g]": 86.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00d3leo de girassol",
    "energia[kcal]": 896.0,
    "energia[kj]": 3680.0,
    "l\u00edpidos[g]": 99.5,
    "\u00e1cidos gordos saturados[g]": 11.6,
    "\u00e1cidos gordos monoinsaturados [g]": 22.3,
    "\u00e1cidos gordos polinsaturados [g]": 62.2,
    "\u00e1cido linoleico [g]": 62.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 65.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00d3leo de linha\u00e7a",
    "energia[kcal]": 900.0,
    "energia[kj]": 3700.0,
    "l\u00edpidos[g]": 100.0,
    "\u00e1cidos gordos saturados[g]": 7.9,
    "\u00e1cidos gordos monoinsaturados [g]": 20.4,
    "\u00e1cidos gordos polinsaturados [g]": 66.9,
    "\u00e1cido linoleico [g]": 14.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 61.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 6.67,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00d3leo de palma",
    "energia[kcal]": 900.0,
    "energia[kj]": 3700.0,
    "l\u00edpidos[g]": 100.0,
    "\u00e1cidos gordos saturados[g]": 47.8,
    "\u00e1cidos gordos monoinsaturados [g]": 37.6,
    "\u00e1cidos gordos polinsaturados [g]": 10.6,
    "\u00e1cido linoleico [g]": 10.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4340.0,
    "caroteno [\u00b5g]": 30700.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 9.5,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00d3leo de soja",
    "energia[kcal]": 887.0,
    "energia[kj]": 3640.0,
    "l\u00edpidos[g]": 98.5,
    "\u00e1cidos gordos saturados[g]": 15.5,
    "\u00e1cidos gordos monoinsaturados [g]": 22.1,
    "\u00e1cidos gordos polinsaturados [g]": 55.6,
    "\u00e1cido linoleico [g]": 50.7,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 16.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "\u00d3leos de milho",
    "energia[kcal]": 896.0,
    "energia[kj]": 3680.0,
    "l\u00edpidos[g]": 99.5,
    "\u00e1cidos gordos saturados[g]": 13.3,
    "\u00e1cidos gordos monoinsaturados [g]": 25.1,
    "\u00e1cidos gordos polinsaturados [g]": 53.5,
    "\u00e1cido linoleico [g]": 52.8,
    "\u00e1cidos gordos trans [g]": 1.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 140.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 25.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Omelete",
    "energia[kcal]": 136.0,
    "energia[kj]": 564.0,
    "l\u00edpidos[g]": 9.5,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 3.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.67,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 12.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 359.0,
    "vitamina a  [\u00b5g]": 271.0,
    "caroteno [\u00b5g]": 624.0,
    "vitamina d [\u00b5g]": 1.5,
    "alfa-tocoferol [mg]": 2.38,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 0.15,
    "equivalentes de niacina [mg]": 3.55,
    "triptofano/60 [mg]": 3.44,
    "vitamina b6 [mg]": 0.33,
    "vitamina b12 [\u00b5g]": 0.88,
    "vitamina c [mg]": 41.0,
    "folatos [\u00b5g]": 76.0,
    "cinza [g]": 1.74,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 77.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 2.5,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Omelete com manteiga",
    "energia[kcal]": 199.0,
    "energia[kj]": 824.0,
    "l\u00edpidos[g]": 16.3,
    "\u00e1cidos gordos saturados[g]": 5.8,
    "\u00e1cidos gordos monoinsaturados [g]": 5.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.03,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.0,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 423.0,
    "vitamina a  [\u00b5g]": 228.0,
    "caroteno [\u00b5g]": 3.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 2.43,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.95,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 1.85,
    "s\u00f3dio [mg]": 530.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Omelete com margarina",
    "energia[kcal]": 198.0,
    "energia[kj]": 820.0,
    "l\u00edpidos[g]": 16.2,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 3.6,
    "\u00e1cido linoleico [g]": 3.25,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.0,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 408.0,
    "vitamina a  [\u00b5g]": 230.0,
    "caroteno [\u00b5g]": 27.0,
    "vitamina d [\u00b5g]": 1.5,
    "alfa-tocoferol [mg]": 2.3,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.95,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 1.94,
    "s\u00f3dio [mg]": 560.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 44.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Or\u00e9g\u00e3o seco",
    "energia[kcal]": 265.0,
    "energia[kj]": 1100.0,
    "l\u00edpidos[g]": 4.3,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 26.4,
    "a\u00e7\u00facares [g]": 4.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 42.5,
    "prote\u00ednas [g]": 9.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 85.0,
    "caroteno [\u00b5g]": 1007.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 18.26,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.53,
    "niacina [mg]": 4.64,
    "equivalentes de niacina [mg]": 7.14,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 1.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.3,
    "folatos [\u00b5g]": 237.0,
    "cinza [g]": 7.87,
    "s\u00f3dio [mg]": 25.0,
    "pot\u00e1ssio [mg]": 1260.0,
    "c\u00e1lcio [mg]": 1600.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 270.0,
    "ferro [mg]": 37.0,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Orelha de porco, crua",
    "energia[kcal]": 128.0,
    "energia[kj]": 540.0,
    "l\u00edpidos[g]": 2.5,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.0,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 100.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 7.0,
    "triptofano/60 [mg]": 3.0,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.74,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ostra, crua",
    "energia[kcal]": 65.0,
    "energia[kj]": 275.0,
    "l\u00edpidos[g]": 1.7,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.9,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.9,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 8.6,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 53.0,
    "vitamina a  [\u00b5g]": 85.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.85,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 3.7,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 14.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.7,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 66.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 8.0,
    "zinco [mg]": 91.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovas de bacalhau cruas",
    "energia[kcal]": 104.0,
    "energia[kj]": 439.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.02,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.7,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 330.0,
    "vitamina a  [\u00b5g]": 79.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 18.0,
    "alfa-tocoferol [mg]": 6.2,
    "tiamina [mg]": 0.78,
    "riboflavina [mg]": 0.49,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 5.1,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 10.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 80.0,
    "cinza [g]": 0.85,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 320.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 3.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovas de pescada cruas",
    "energia[kcal]": 83.0,
    "energia[kj]": 351.0,
    "l\u00edpidos[g]": 2.9,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 14.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 439.0,
    "vitamina a  [\u00b5g]": 1209.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 18.0,
    "alfa-tocoferol [mg]": 2.68,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 2.6,
    "equivalentes de niacina [mg]": 6.7,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 56.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 53.0,
    "cinza [g]": 1.22,
    "s\u00f3dio [mg]": 8.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovo de codorniz, cru",
    "energia[kcal]": 150.0,
    "energia[kj]": 622.0,
    "l\u00edpidos[g]": 10.7,
    "\u00e1cidos gordos saturados[g]": 3.0,
    "\u00e1cidos gordos monoinsaturados [g]": 4.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 0.555,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 12.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 643.0,
    "vitamina a  [\u00b5g]": 263.0,
    "caroteno [\u00b5g]": 13.0,
    "vitamina d [\u00b5g]": 2.2,
    "alfa-tocoferol [mg]": 1.9,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.79,
    "niacina [mg]": 0.15,
    "equivalentes de niacina [mg]": 3.93,
    "triptofano/60 [mg]": 3.78,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 1.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 58.3,
    "cinza [g]": 0.95,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 61.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 3.1,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 25.0,
    "iodo [\u00b5g]": 44.0
  },
  {
    "name": "Ovo de galinha inteiro, cru",
    "energia[kcal]": 143.0,
    "energia[kj]": 596.0,
    "l\u00edpidos[g]": 10.1,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.5,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 12.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 408.0,
    "vitamina a  [\u00b5g]": 190.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.7,
    "alfa-tocoferol [mg]": 2.3,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.44,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 50.0,
    "cinza [g]": 0.87,
    "s\u00f3dio [mg]": 0.8,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 44.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovo de galinha l\u00edquido, pasteurizado",
    "energia[kcal]": 131.0,
    "energia[kj]": 546.0,
    "l\u00edpidos[g]": 9.3,
    "\u00e1cidos gordos saturados[g]": 2.3,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 11.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 361.0,
    "vitamina a  [\u00b5g]": 168.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.5,
    "alfa-tocoferol [mg]": 2.0,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.39,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 3.5,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 44.0,
    "cinza [g]": 0.83,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 39.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovo de galinha, cozido",
    "energia[kcal]": 149.0,
    "energia[kj]": 621.0,
    "l\u00edpidos[g]": 10.8,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 1.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.0,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 408.0,
    "vitamina a  [\u00b5g]": 170.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.7,
    "alfa-tocoferol [mg]": 2.3,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.35,
    "niacina [mg]": 0.03,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 0.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 40.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 44.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 24.0
  },
  {
    "name": "Ovo de galinha, escalfado",
    "energia[kcal]": 150.0,
    "energia[kj]": 624.0,
    "l\u00edpidos[g]": 10.9,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 1.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.0,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 408.0,
    "vitamina a  [\u00b5g]": 170.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 2.3,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 0.03,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 40.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 42.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 24.0
  },
  {
    "name": "Ovo de galinha, estrelado",
    "energia[kcal]": 193.0,
    "energia[kj]": 803.0,
    "l\u00edpidos[g]": 15.4,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.46,
    "\u00e1cidos gordos trans [g]": 2.7,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.7,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 435.0,
    "vitamina a  [\u00b5g]": 221.0,
    "caroteno [\u00b5g]": 8.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 2.63,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 4.02,
    "triptofano/60 [mg]": 4.02,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 1.22,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovo de galinha, estrelado com azeite",
    "energia[kcal]": 191.0,
    "energia[kj]": 793.0,
    "l\u00edpidos[g]": 15.1,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 7.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.24,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 433.0,
    "vitamina a  [\u00b5g]": 202.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 2.95,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 4.03,
    "triptofano/60 [mg]": 4.03,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 1.13,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovo de galinha, estrelado com manteiga",
    "energia[kcal]": 194.0,
    "energia[kj]": 806.0,
    "l\u00edpidos[g]": 15.5,
    "\u00e1cidos gordos saturados[g]": 5.2,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.11,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.7,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 442.0,
    "vitamina a  [\u00b5g]": 229.0,
    "caroteno [\u00b5g]": 2.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 2.53,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 4.01,
    "triptofano/60 [mg]": 4.01,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 1.22,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovo de galinha, estrelado com margarina",
    "energia[kcal]": 194.0,
    "energia[kj]": 806.0,
    "l\u00edpidos[g]": 15.5,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 3.4,
    "\u00e1cido linoleico [g]": 3.02,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.7,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 430.0,
    "vitamina a  [\u00b5g]": 231.0,
    "caroteno [\u00b5g]": 20.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 2.43,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 4.01,
    "triptofano/60 [mg]": 4.01,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 1.29,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovo de galinha, mexido com manteiga",
    "energia[kcal]": 197.0,
    "energia[kj]": 817.0,
    "l\u00edpidos[g]": 16.1,
    "\u00e1cidos gordos saturados[g]": 5.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.03,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.0,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 423.0,
    "vitamina a  [\u00b5g]": 226.0,
    "caroteno [\u00b5g]": 3.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 2.43,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.95,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 1.37,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovo de galinha, mexido com margarina",
    "energia[kcal]": 196.0,
    "energia[kj]": 813.0,
    "l\u00edpidos[g]": 16.0,
    "\u00e1cidos gordos saturados[g]": 5.3,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 3.5,
    "\u00e1cido linoleico [g]": 3.19,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.0,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 408.0,
    "vitamina a  [\u00b5g]": 229.0,
    "caroteno [\u00b5g]": 26.0,
    "vitamina d [\u00b5g]": 1.5,
    "alfa-tocoferol [mg]": 2.3,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.95,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 1.46,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 44.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Ovo mexido com leite e margarina",
    "energia[kcal]": 213.0,
    "energia[kj]": 881.0,
    "l\u00edpidos[g]": 18.3,
    "\u00e1cidos gordos saturados[g]": 7.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.2,
    "\u00e1cidos gordos polinsaturados [g]": 4.3,
    "\u00e1cido linoleico [g]": 3.89,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.9,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 11.0,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 332.0,
    "vitamina a  [\u00b5g]": 227.0,
    "caroteno [\u00b5g]": 49.0,
    "vitamina d [\u00b5g]": 1.4,
    "alfa-tocoferol [mg]": 1.87,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.38,
    "niacina [mg]": 0.06,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 3.18,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.83,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 41.0,
    "cinza [g]": 1.83,
    "s\u00f3dio [mg]": 510.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 52.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e1 de carneiro assada, sem molho",
    "energia[kcal]": 202.0,
    "energia[kj]": 845.0,
    "l\u00edpidos[g]": 11.3,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 8.6,
    "triptofano/60 [mg]": 4.7,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.1,
    "cinza [g]": 2.05,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e1 de carneiro, assada com azeite e margarina",
    "energia[kcal]": 319.0,
    "energia[kj]": 1320.0,
    "l\u00edpidos[g]": 24.6,
    "\u00e1cidos gordos saturados[g]": 9.3,
    "\u00e1cidos gordos monoinsaturados [g]": 9.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.77,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.3,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 32.0,
    "caroteno [\u00b5g]": 22.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.84,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 9.72,
    "triptofano/60 [mg]": 4.62,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 560.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e1 de carneiro, assada com margarina",
    "energia[kcal]": 310.0,
    "energia[kj]": 1290.0,
    "l\u00edpidos[g]": 23.6,
    "\u00e1cidos gordos saturados[g]": 10.7,
    "\u00e1cidos gordos monoinsaturados [g]": 6.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.52,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.3,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 49.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 65.0,
    "caroteno [\u00b5g]": 43.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 9.72,
    "triptofano/60 [mg]": 4.62,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 2.48,
    "s\u00f3dio [mg]": 630.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e1 de carneiro, cozida",
    "energia[kcal]": 238.0,
    "energia[kj]": 995.0,
    "l\u00edpidos[g]": 14.2,
    "\u00e1cidos gordos saturados[g]": 6.1,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.6,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 8.2,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.7,
    "cinza [g]": 1.74,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e1 de carneiro, crua",
    "energia[kcal]": 195.0,
    "energia[kj]": 815.0,
    "l\u00edpidos[g]": 12.6,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 68.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 8.2,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 66.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e1 de carneiro, estufada sem molho",
    "energia[kcal]": 238.0,
    "energia[kj]": 995.0,
    "l\u00edpidos[g]": 14.2,
    "\u00e1cidos gordos saturados[g]": 6.1,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 99.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.29,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 8.5,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.8,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e1 de vitela crua",
    "energia[kcal]": 138.0,
    "energia[kj]": 582.0,
    "l\u00edpidos[g]": 4.9,
    "\u00e1cidos gordos saturados[g]": 2.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 73.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.24,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 5.3,
    "equivalentes de niacina [mg]": 9.6,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.31,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.3,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 25.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 25.0
  },
  {
    "name": "Paio de lombo",
    "energia[kcal]": 288.0,
    "energia[kj]": 1200.0,
    "l\u00edpidos[g]": 19.0,
    "\u00e1cidos gordos saturados[g]": 6.5,
    "\u00e1cidos gordos monoinsaturados [g]": 7.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 1.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 29.2,
    "sal  [g]": 8.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 41.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 65.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.7,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 6.2,
    "vitamina b6 [mg]": 0.48,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 10.0,
    "s\u00f3dio [mg]": 3510.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 3.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Paio de lombo entremeado",
    "energia[kcal]": 361.0,
    "energia[kj]": 1500.0,
    "l\u00edpidos[g]": 30.0,
    "\u00e1cidos gordos saturados[g]": 13.7,
    "\u00e1cidos gordos monoinsaturados [g]": 15.7,
    "\u00e1cidos gordos polinsaturados [g]": 4.6,
    "\u00e1cido linoleico [g]": 3.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.8,
    "sal  [g]": 4.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 37.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 77.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.65,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 9.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 9.1,
    "s\u00f3dio [mg]": 1770.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "P\u00e3o de centeio",
    "energia[kcal]": 268.0,
    "energia[kj]": 1140.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 56.4,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 54.2,
    "fibra  [g]": 5.8,
    "prote\u00ednas [g]": 5.9,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 29.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 2.4,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 56.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 62.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o de centeio integral",
    "energia[kcal]": 229.0,
    "energia[kj]": 968.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 41.3,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 39.7,
    "fibra  [g]": 7.1,
    "prote\u00ednas [g]": 7.7,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 38.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 4.0,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 54.0,
    "ferro [mg]": 4.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o de forma, de trigo com passas",
    "energia[kcal]": 291.0,
    "energia[kj]": 1230.0,
    "l\u00edpidos[g]": 2.0,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 58.3,
    "a\u00e7\u00facares [g]": 5.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 53.0,
    "fibra  [g]": 3.5,
    "prote\u00ednas [g]": 8.3,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 26.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.49,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 1.7,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.73,
    "s\u00f3dio [mg]": 290.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e3o de forma, de trigo enriquecido",
    "energia[kcal]": 284.0,
    "energia[kj]": 1200.0,
    "l\u00edpidos[g]": 2.7,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 54.5,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 52.5,
    "fibra  [g]": 3.2,
    "prote\u00ednas [g]": 8.7,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 28.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.42,
    "niacina [mg]": 4.4,
    "equivalentes de niacina [mg]": 6.2,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.5,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 2.09,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 50.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e3o de leite (trigo)",
    "energia[kcal]": 259.0,
    "energia[kj]": 1100.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 51.4,
    "a\u00e7\u00facares [g]": 3.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 48.3,
    "fibra  [g]": 2.5,
    "prote\u00ednas [g]": 7.7,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 35.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 82.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.19,
    "tiamina [mg]": 0.31,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 6.5,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 38.0,
    "cinza [g]": 1.05,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 63.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 3.9,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 4.0
  },
  {
    "name": "P\u00e3o de l\u00f3",
    "energia[kcal]": 361.0,
    "energia[kj]": 1520.0,
    "l\u00edpidos[g]": 8.0,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.62,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 62.2,
    "a\u00e7\u00facares [g]": 36.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 26.2,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 9.6,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 18.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 214.0,
    "vitamina a  [\u00b5g]": 114.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 1.31,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 2.96,
    "triptofano/60 [mg]": 2.57,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.52,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.784,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 33.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e3o de milho",
    "energia[kcal]": 188.0,
    "energia[kj]": 797.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 37.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 37.2,
    "fibra  [g]": 3.7,
    "prote\u00ednas [g]": 5.3,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 51.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o de mistura de trigo e centeio",
    "energia[kcal]": 272.0,
    "energia[kj]": 1150.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 53.8,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 51.8,
    "fibra  [g]": 4.3,
    "prote\u00ednas [g]": 9.0,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 29.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.33,
    "niacina [mg]": 3.2,
    "equivalentes de niacina [mg]": 5.0,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 33.0,
    "cinza [g]": 1.66,
    "s\u00f3dio [mg]": 580.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 46.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o de trigo",
    "energia[kcal]": 290.0,
    "energia[kj]": 1230.0,
    "l\u00edpidos[g]": 2.2,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 57.3,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 55.2,
    "fibra  [g]": 3.8,
    "prote\u00ednas [g]": 8.4,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 26.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 3.1,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 2.09,
    "s\u00f3dio [mg]": 610.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 43.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o de trigo integral",
    "energia[kcal]": 232.0,
    "energia[kj]": 978.0,
    "l\u00edpidos[g]": 3.0,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 39.9,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 37.7,
    "fibra  [g]": 7.4,
    "prote\u00ednas [g]": 7.6,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 40.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 4.1,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 32.0,
    "cinza [g]": 1.78,
    "s\u00f3dio [mg]": 500.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 55.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 93.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o de trigo integral com passas",
    "energia[kcal]": 247.0,
    "energia[kj]": 1040.0,
    "l\u00edpidos[g]": 2.6,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 44.0,
    "a\u00e7\u00facares [g]": 8.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 35.5,
    "fibra  [g]": 9.1,
    "prote\u00ednas [g]": 7.4,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 35.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 3.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 4.0,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 1.88,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 79.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 92.0,
    "ferro [mg]": 2.8,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o de trigo integral com sementes de s\u00e9samo",
    "energia[kcal]": 256.0,
    "energia[kj]": 1080.0,
    "l\u00edpidos[g]": 4.0,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 1.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 43.2,
    "a\u00e7\u00facares [g]": 2.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 40.9,
    "fibra  [g]": 8.2,
    "prote\u00ednas [g]": 7.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 35.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 4.2,
    "triptofano/60 [mg]": 1.7,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 33.0,
    "cinza [g]": 1.94,
    "s\u00f3dio [mg]": 55.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 92.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 110.0,
    "ferro [mg]": 3.2,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o de trigo integral com soja",
    "energia[kcal]": 233.0,
    "energia[kj]": 982.0,
    "l\u00edpidos[g]": 2.8,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 38.2,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 35.8,
    "fibra  [g]": 8.6,
    "prote\u00ednas [g]": 9.4,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 38.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 2.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 4.5,
    "triptofano/60 [mg]": 2.0,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 45.0,
    "cinza [g]": 2.49,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 93.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 100.0,
    "ferro [mg]": 3.5,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o do cora\u00e7\u00e3o (trigo)",
    "energia[kcal]": 248.0,
    "energia[kj]": 1050.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 51.0,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 49.1,
    "fibra  [g]": 2.8,
    "prote\u00ednas [g]": 7.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 36.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.36,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 3.9,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.09,
    "s\u00f3dio [mg]": 80.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 91.0,
    "magn\u00e9sio [mg]": 52.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o pita",
    "energia[kcal]": 218.0,
    "energia[kj]": 922.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 42.7,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 41.7,
    "fibra  [g]": 3.1,
    "prote\u00ednas [g]": 7.7,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 41.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 2.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 1.8,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 31.0,
    "cinza [g]": 1.67,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "P\u00e3o ralado",
    "energia[kcal]": 359.0,
    "energia[kj]": 1520.0,
    "l\u00edpidos[g]": 2.3,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 71.6,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 69.0,
    "fibra  [g]": 3.4,
    "prote\u00ednas [g]": 11.2,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 2.1,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 57.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 40.0,
    "ferro [mg]": 2.9,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Papaia",
    "energia[kcal]": 45.0,
    "energia[kj]": 190.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.1,
    "a\u00e7\u00facares [g]": 6.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 135.0,
    "caroteno [\u00b5g]": 810.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 68.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.59,
    "s\u00f3dio [mg]": 22.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 16.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Papaia desidratada",
    "energia[kcal]": 361.0,
    "energia[kj]": 1530.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 73.3,
    "a\u00e7\u00facares [g]": 48.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 18.5,
    "prote\u00ednas [g]": 4.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.6,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 12.08,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 2.42,
    "equivalentes de niacina [mg]": 3.22,
    "triptofano/60 [mg]": 0.81,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.1,
    "cinza [g]": 4.75,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 1690.0,
    "c\u00e1lcio [mg]": 170.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 190.0,
    "ferro [mg]": 3.2,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pargo leg\u00edtimo assado com cebola, tomate e azeite",
    "energia[kcal]": 136.0,
    "energia[kj]": 569.0,
    "l\u00edpidos[g]": 6.7,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.462,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.4,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 15.9,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 72.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 31.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 123.0,
    "vitamina d [\u00b5g]": 6.3,
    "alfa-tocoferol [mg]": 1.59,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 3.4,
    "equivalentes de niacina [mg]": 7.02,
    "triptofano/60 [mg]": 2.93,
    "vitamina b6 [mg]": 0.42,
    "vitamina b12 [\u00b5g]": 1.5,
    "vitamina c [mg]": 4.6,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.08,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pargo leg\u00edtimo cozido",
    "energia[kcal]": 82.0,
    "energia[kj]": 348.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.8,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 41.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 8.8,
    "alfa-tocoferol [mg]": 0.49,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 4.9,
    "equivalentes de niacina [mg]": 8.6,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.8,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 110.0
  },
  {
    "name": "Pargo leg\u00edtimo cru",
    "energia[kcal]": 79.0,
    "energia[kj]": 337.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.4,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 38.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 9.0,
    "alfa-tocoferol [mg]": 0.45,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 4.8,
    "equivalentes de niacina [mg]": 8.4,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.5,
    "vitamina b12 [\u00b5g]": 2.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 65.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pargo mulato assado com cebola, tomate e azeite",
    "energia[kcal]": 137.0,
    "energia[kj]": 572.0,
    "l\u00edpidos[g]": 7.1,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.462,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.4,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 15.2,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 73.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 27.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 123.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 2.75,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 4.37,
    "triptofano/60 [mg]": 2.85,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.94,
    "vitamina c [mg]": 4.6,
    "folatos [\u00b5g]": 8.9,
    "cinza [g]": 1.92,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pargo mulato cozido",
    "energia[kcal]": 85.0,
    "energia[kj]": 360.0,
    "l\u00edpidos[g]": 1.0,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.0,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 37.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 2.1,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 5.0,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.8,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 340.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 110.0
  },
  {
    "name": "Pargo mulato cru",
    "energia[kcal]": 80.0,
    "energia[kj]": 340.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 34.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 1.9,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 5.1,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 81.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pasta de f\u00edgado de aves (pat\u00e9), caseira ",
    "energia[kcal]": 289.0,
    "energia[kj]": 1210.0,
    "l\u00edpidos[g]": 17.6,
    "\u00e1cidos gordos saturados[g]": 5.6,
    "\u00e1cidos gordos monoinsaturados [g]": 7.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.39,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.6,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.1,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 27.0,
    "sal  [g]": 1.9,
    "\u00e1lcool [g]": 0.5,
    "\u00e1gua [g]": 47.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 170.0,
    "vitamina a  [\u00b5g]": 55.0,
    "caroteno [\u00b5g]": 5.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.49,
    "tiamina [mg]": 0.28,
    "riboflavina [mg]": 0.43,
    "niacina [mg]": 5.1,
    "equivalentes de niacina [mg]": 11.2,
    "triptofano/60 [mg]": 5.51,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 2.79,
    "s\u00f3dio [mg]": 750.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 31.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pasta de f\u00edgado de porco (pat\u00e9)",
    "energia[kcal]": 363.0,
    "energia[kj]": 1500.0,
    "l\u00edpidos[g]": 34.5,
    "\u00e1cidos gordos saturados[g]": 10.0,
    "\u00e1cidos gordos monoinsaturados [g]": 10.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 3.2,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.6,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 10.0,
    "sal  [g]": 2.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 50.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 170.0,
    "vitamina a  [\u00b5g]": 4500.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.15,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.55,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 6.1,
    "triptofano/60 [mg]": 2.1,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 6.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 68.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 790.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 4.0,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pastel de bacalhau",
    "energia[kcal]": 227.0,
    "energia[kj]": 949.0,
    "l\u00edpidos[g]": 13.4,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.1,
    "\u00e1cidos gordos polinsaturados [g]": 7.5,
    "\u00e1cido linoleico [g]": 7.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.3,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 11.4,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 13.8,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 102.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 73.0,
    "vitamina d [\u00b5g]": 2.3,
    "alfa-tocoferol [mg]": 7.4,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 4.1,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.5,
    "vitamina c [mg]": 10.0,
    "folatos [\u00b5g]": 32.0,
    "cinza [g]": 2.1,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 52.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pastel de feij\u00e3o",
    "energia[kcal]": 322.0,
    "energia[kj]": 1360.0,
    "l\u00edpidos[g]": 9.4,
    "\u00e1cidos gordos saturados[g]": 3.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 52.0,
    "a\u00e7\u00facares [g]": 33.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 18.3,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 6.8,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 29.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 191.0,
    "vitamina a  [\u00b5g]": 137.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.7,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 23.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 71.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 34.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pastel de nata",
    "energia[kcal]": 299.0,
    "energia[kj]": 1260.0,
    "l\u00edpidos[g]": 10.2,
    "\u00e1cidos gordos saturados[g]": 4.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 48.5,
    "a\u00e7\u00facares [g]": 28.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 20.5,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 2.9,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 36.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 72.0,
    "vitamina a  [\u00b5g]": 98.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 1.3,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.15,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 51.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pastel folhado",
    "energia[kcal]": 415.0,
    "energia[kj]": 1730.0,
    "l\u00edpidos[g]": 26.0,
    "\u00e1cidos gordos saturados[g]": 11.1,
    "\u00e1cidos gordos monoinsaturados [g]": 8.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.8,
    "\u00e1cidos gordos trans [g]": 2.4,
    "hidratos de carbono [g]": 37.4,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 35.2,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 7.1,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 27.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 79.0,
    "vitamina a  [\u00b5g]": 175.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 2.1,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.35,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.07,
    "s\u00f3dio [mg]": 610.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 72.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pastilha el\u00e1stica",
    "energia[kcal]": 328.0,
    "energia[kj]": 1390.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 75.1,
    "a\u00e7\u00facares [g]": 73.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.7,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 6.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 13.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 32.0,
    "pot\u00e1ssio [mg]": 9.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pato com pele, assado sem molho",
    "energia[kcal]": 347.0,
    "energia[kj]": 1440.0,
    "l\u00edpidos[g]": 30.3,
    "\u00e1cidos gordos saturados[g]": 7.7,
    "\u00e1cidos gordos monoinsaturados [g]": 13.4,
    "\u00e1cidos gordos polinsaturados [g]": 3.4,
    "\u00e1cido linoleico [g]": 2.9,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.5,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 49.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 98.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.5,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 8.6,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 2.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.8,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 2.9,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pato com pele, cru",
    "energia[kcal]": 394.0,
    "energia[kj]": 1630.0,
    "l\u00edpidos[g]": 38.3,
    "\u00e1cidos gordos saturados[g]": 9.7,
    "\u00e1cidos gordos monoinsaturados [g]": 16.9,
    "\u00e1cidos gordos polinsaturados [g]": 4.3,
    "\u00e1cido linoleico [g]": 3.7,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 12.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 74.0,
    "vitamina a  [\u00b5g]": 51.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.4,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.36,
    "niacina [mg]": 3.7,
    "equivalentes de niacina [mg]": 6.0,
    "triptofano/60 [mg]": 2.3,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 0.95,
    "s\u00f3dio [mg]": 68.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pato sem pele, assado com margarina",
    "energia[kcal]": 276.0,
    "energia[kj]": 1160.0,
    "l\u00edpidos[g]": 14.6,
    "\u00e1cidos gordos saturados[g]": 4.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 1.88,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 36.2,
    "sal  [g]": 1.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 45.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 174.0,
    "vitamina a  [\u00b5g]": 68.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.42,
    "riboflavina [mg]": 0.74,
    "niacina [mg]": 7.9,
    "equivalentes de niacina [mg]": 17.8,
    "triptofano/60 [mg]": 7.87,
    "vitamina b6 [mg]": 0.39,
    "vitamina b12 [\u00b5g]": 3.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 3.28,
    "s\u00f3dio [mg]": 670.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 310.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 4.3,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pato sem pele, cru",
    "energia[kcal]": 133.0,
    "energia[kj]": 558.0,
    "l\u00edpidos[g]": 6.2,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 93.0,
    "vitamina a  [\u00b5g]": 24.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.36,
    "riboflavina [mg]": 0.45,
    "niacina [mg]": 5.3,
    "equivalentes de niacina [mg]": 9.5,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 3.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 1.06,
    "s\u00f3dio [mg]": 92.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 2.4,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pato sem pele, estufado com margarina",
    "energia[kcal]": 232.0,
    "energia[kj]": 969.0,
    "l\u00edpidos[g]": 13.7,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 4.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.09,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.2,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 131.0,
    "vitamina a  [\u00b5g]": 77.0,
    "caroteno [\u00b5g]": 63.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.26,
    "riboflavina [mg]": 0.6,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 13.4,
    "triptofano/60 [mg]": 5.92,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 2.1,
    "vitamina c [mg]": 1.9,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 610.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 2.8,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00e9 de porco, cru",
    "energia[kcal]": 335.0,
    "energia[kj]": 1400.0,
    "l\u00edpidos[g]": 20.4,
    "\u00e1cidos gordos saturados[g]": 8.8,
    "\u00e1cidos gordos monoinsaturados [g]": 8.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 1.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 38.0,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 39.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.65,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 1.78,
    "equivalentes de niacina [mg]": 8.75,
    "triptofano/60 [mg]": 6.97,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.5,
    "cinza [g]": 2.42,
    "s\u00f3dio [mg]": 640.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 97.0,
    "f\u00f3sforo [mg]": 90.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de cabrito cru",
    "energia[kcal]": 116.0,
    "energia[kj]": 489.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 57.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 6.1,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 4.4,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 82.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 4.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de cabrito estufado com azeite e margarina",
    "energia[kcal]": 173.0,
    "energia[kj]": 723.0,
    "l\u00edpidos[g]": 8.4,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.832,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 77.0,
    "caroteno [\u00b5g]": 388.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 11.4,
    "triptofano/60 [mg]": 4.98,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.74,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 6.5,
    "cinza [g]": 2.44,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de cabrito estufado com azeite e \u00f3leo alimentar",
    "energia[kcal]": 176.0,
    "energia[kj]": 736.0,
    "l\u00edpidos[g]": 8.8,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.82,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 63.0,
    "caroteno [\u00b5g]": 379.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.86,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 11.4,
    "triptofano/60 [mg]": 4.98,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.74,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 6.5,
    "cinza [g]": 2.36,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de cabrito estufado com manteiga e \u00f3leo alimentar",
    "energia[kcal]": 173.0,
    "energia[kj]": 723.0,
    "l\u00edpidos[g]": 8.4,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.9,
    "\u00e1cido linoleico [g]": 1.72,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 76.0,
    "caroteno [\u00b5g]": 380.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.59,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 11.4,
    "triptofano/60 [mg]": 4.98,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.74,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 6.5,
    "cinza [g]": 2.41,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de cabrito estufado com margarina e \u00f3leo alimentar",
    "energia[kcal]": 173.0,
    "energia[kj]": 723.0,
    "l\u00edpidos[g]": 8.4,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.13,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 77.0,
    "caroteno [\u00b5g]": 388.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.54,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 11.4,
    "triptofano/60 [mg]": 4.98,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.74,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 6.5,
    "cinza [g]": 2.44,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de cabrito estufado, sem molho",
    "energia[kcal]": 153.0,
    "energia[kj]": 646.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.4,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 83.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.38,
    "niacina [mg]": 6.2,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.3,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.8,
    "cinza [g]": 2.34,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 5.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de cabrito grelhado",
    "energia[kcal]": 144.0,
    "energia[kj]": 606.0,
    "l\u00edpidos[g]": 4.2,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.5,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 74.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.98,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.35,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.4,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 5.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de carneiro gordo, cozido",
    "energia[kcal]": 333.0,
    "energia[kj]": 1380.0,
    "l\u00edpidos[g]": 26.6,
    "\u00e1cidos gordos saturados[g]": 11.5,
    "\u00e1cidos gordos monoinsaturados [g]": 8.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 1.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.3,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 130.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 7.6,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.91,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.6,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 4.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de carneiro gordo, cru",
    "energia[kcal]": 396.0,
    "energia[kj]": 1640.0,
    "l\u00edpidos[g]": 36.5,
    "\u00e1cidos gordos saturados[g]": 15.7,
    "\u00e1cidos gordos monoinsaturados [g]": 11.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 1.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.8,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 46.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.3,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 7.7,
    "triptofano/60 [mg]": 3.2,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.85,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de frango com pele, cozido",
    "energia[kcal]": 194.0,
    "energia[kj]": 816.0,
    "l\u00edpidos[g]": 7.3,
    "\u00e1cidos gordos saturados[g]": 1.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 32.1,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 110.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 7.9,
    "equivalentes de niacina [mg]": 14.0,
    "triptofano/60 [mg]": 6.0,
    "vitamina b6 [mg]": 0.45,
    "vitamina b12 [\u00b5g]": 0.27,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.1,
    "cinza [g]": 1.12,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Peito de frango com pele, cru",
    "energia[kcal]": 177.0,
    "energia[kj]": 739.0,
    "l\u00edpidos[g]": 8.9,
    "\u00e1cidos gordos saturados[g]": 2.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.14,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 8.9,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.51,
    "vitamina b12 [\u00b5g]": 0.37,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 72.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de frango com pele, estufado com azeite e margarina",
    "energia[kcal]": 189.0,
    "energia[kj]": 791.0,
    "l\u00edpidos[g]": 10.3,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 3.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.9,
    "\u00e1cido linoleico [g]": 1.82,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 22.1,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 183.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.74,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 5.1,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 4.09,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 0.17,
    "vitamina c [mg]": 7.2,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.93,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de frango com pele, estufado com margarina",
    "energia[kcal]": 187.0,
    "energia[kj]": 780.0,
    "l\u00edpidos[g]": 10.0,
    "\u00e1cidos gordos saturados[g]": 2.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 22.1,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 44.0,
    "caroteno [\u00b5g]": 188.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.56,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 5.1,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 4.09,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 0.17,
    "vitamina c [mg]": 7.2,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.98,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de frango com pele, estufado sem molho",
    "energia[kcal]": 212.0,
    "energia[kj]": 892.0,
    "l\u00edpidos[g]": 8.3,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 1.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 34.4,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 55.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 132.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 8.3,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 6.4,
    "vitamina b6 [mg]": 0.48,
    "vitamina b12 [\u00b5g]": 0.29,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.5,
    "cinza [g]": 1.61,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Peito de frango sem pele, cozido",
    "energia[kcal]": 148.0,
    "energia[kj]": 627.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 34.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 9.0,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 6.4,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 0.25,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.26,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Peito de frango sem pele, cru",
    "energia[kcal]": 108.0,
    "energia[kj]": 457.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 70.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 11.0,
    "equivalentes de niacina [mg]": 16.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.49,
    "vitamina b12 [\u00b5g]": 0.37,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 60.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de frango sem pele, estufado com azeite e margarina ",
    "energia[kcal]": 124.0,
    "energia[kj]": 523.0,
    "l\u00edpidos[g]": 3.3,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.544,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 21.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 35.0,
    "caroteno [\u00b5g]": 177.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.71,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 6.0,
    "equivalentes de niacina [mg]": 14.3,
    "triptofano/60 [mg]": 3.97,
    "vitamina b6 [mg]": 0.33,
    "vitamina b12 [\u00b5g]": 0.16,
    "vitamina c [mg]": 7.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de frango sem pele, estufado com margarina",
    "energia[kcal]": 121.0,
    "energia[kj]": 512.0,
    "l\u00edpidos[g]": 3.0,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.721,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 21.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 43.0,
    "caroteno [\u00b5g]": 183.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.53,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 6.0,
    "equivalentes de niacina [mg]": 14.3,
    "triptofano/60 [mg]": 3.97,
    "vitamina b6 [mg]": 0.33,
    "vitamina b12 [\u00b5g]": 0.16,
    "vitamina c [mg]": 7.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 2.05,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de frango sem pele, estufado sem molho",
    "energia[kcal]": 169.0,
    "energia[kj]": 715.0,
    "l\u00edpidos[g]": 2.5,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 36.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 92.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 9.5,
    "equivalentes de niacina [mg]": 16.0,
    "triptofano/60 [mg]": 6.8,
    "vitamina b6 [mg]": 0.36,
    "vitamina b12 [\u00b5g]": 0.27,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.56,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Peito de peru com pele, cru",
    "energia[kcal]": 134.0,
    "energia[kj]": 565.0,
    "l\u00edpidos[g]": 4.7,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 68.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 7.4,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.52,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.4,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 64.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de peru com pele, estufado com margarina",
    "energia[kcal]": 163.0,
    "energia[kj]": 682.0,
    "l\u00edpidos[g]": 7.1,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.9,
    "\u00e1cido linoleico [g]": 1.44,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 23.1,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 67.0,
    "vitamina a  [\u00b5g]": 46.0,
    "caroteno [\u00b5g]": 139.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.31,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 12.1,
    "triptofano/60 [mg]": 4.29,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.49,
    "vitamina c [mg]": 5.5,
    "folatos [\u00b5g]": 9.3,
    "cinza [g]": 2.51,
    "s\u00f3dio [mg]": 460.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de peru sem pele, assado com margarina",
    "energia[kcal]": 175.0,
    "energia[kj]": 734.0,
    "l\u00edpidos[g]": 6.4,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.44,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.9,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 71.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 24.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 7.8,
    "equivalentes de niacina [mg]": 14.8,
    "triptofano/60 [mg]": 5.44,
    "vitamina b6 [mg]": 0.45,
    "vitamina b12 [\u00b5g]": 0.83,
    "vitamina c [mg]": 2.8,
    "folatos [\u00b5g]": 6.3,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de peru sem pele, cru",
    "energia[kcal]": 105.0,
    "energia[kj]": 446.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.4,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 57.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 7.9,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 4.4,
    "vitamina b6 [mg]": 0.58,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 63.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de peru sem pele, panado",
    "energia[kcal]": 226.0,
    "energia[kj]": 946.0,
    "l\u00edpidos[g]": 9.9,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 5.0,
    "\u00e1cido linoleico [g]": 5.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.4,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 25.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 53.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 107.0,
    "vitamina a  [\u00b5g]": 30.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.16,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 6.7,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 5.1,
    "vitamina b6 [mg]": 0.39,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.1,
    "cinza [g]": 1.92,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.4
  },
  {
    "name": "Peito de vitela magro cozido",
    "energia[kcal]": 171.0,
    "energia[kj]": 718.0,
    "l\u00edpidos[g]": 8.0,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 3.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 117.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.32,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 4.6,
    "equivalentes de niacina [mg]": 9.9,
    "triptofano/60 [mg]": 5.3,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.97,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.3,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 4.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de vitela magro cru",
    "energia[kcal]": 147.0,
    "energia[kj]": 614.0,
    "l\u00edpidos[g]": 7.6,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 91.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.17,
    "tiamina [mg]": 0.32,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 6.5,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.8,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 28.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 3.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de vitela magro estufado",
    "energia[kcal]": 208.0,
    "energia[kj]": 867.0,
    "l\u00edpidos[g]": 12.6,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 1.6,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 22.9,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 106.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 37.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.17,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.2,
    "equivalentes de niacina [mg]": 12.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.84,
    "vitamina c [mg]": 1.6,
    "folatos [\u00b5g]": 6.3,
    "cinza [g]": 3.05,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de vitela magro estufado com azeite e margarina",
    "energia[kcal]": 208.0,
    "energia[kj]": 867.0,
    "l\u00edpidos[g]": 12.6,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 6.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.634,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 22.9,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 106.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 37.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.62,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.2,
    "equivalentes de niacina [mg]": 12.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.84,
    "vitamina c [mg]": 1.6,
    "folatos [\u00b5g]": 6.3,
    "cinza [g]": 3.05,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de vitela magro estufado com azeite, margarina e \u00f3leo alimentar",
    "energia[kcal]": 208.0,
    "energia[kj]": 867.0,
    "l\u00edpidos[g]": 12.6,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 22.9,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 106.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 37.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.17,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.2,
    "equivalentes de niacina [mg]": 12.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.84,
    "vitamina c [mg]": 1.6,
    "folatos [\u00b5g]": 6.3,
    "cinza [g]": 3.05,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de vitela magro estufado com margarina e \u00f3leo alimentar",
    "energia[kcal]": 208.0,
    "energia[kj]": 867.0,
    "l\u00edpidos[g]": 12.6,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 1.97,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 22.9,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 106.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 37.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.71,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.2,
    "equivalentes de niacina [mg]": 12.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.84,
    "vitamina c [mg]": 1.6,
    "folatos [\u00b5g]": 6.3,
    "cinza [g]": 3.05,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 3.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peito de vitela magro estufado, sem molho",
    "energia[kcal]": 179.0,
    "energia[kj]": 749.0,
    "l\u00edpidos[g]": 8.3,
    "\u00e1cidos gordos saturados[g]": 3.5,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.0,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 121.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 7.5,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 5.5,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.99,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.5,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 4.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peixe-espada-branco cru",
    "energia[kcal]": 117.0,
    "energia[kj]": 493.0,
    "l\u00edpidos[g]": 4.0,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 38.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 3.6,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 2.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 30.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 77.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peixe-espada-branco frito",
    "energia[kcal]": 159.0,
    "energia[kj]": 669.0,
    "l\u00edpidos[g]": 6.3,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 3.0,
    "\u00e1cido linoleico [g]": 2.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.9,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.8,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 22.7,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 39.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 5.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 7.5,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 25.0
  },
  {
    "name": "Peixe-espada-branco grelhado",
    "energia[kcal]": 123.0,
    "energia[kj]": 517.0,
    "l\u00edpidos[g]": 4.7,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.2,
    "sal  [g]": 2.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 38.0,
    "vitamina a  [\u00b5g]": 13.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 6.9,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 3.6,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 2.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 820.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 25.0
  },
  {
    "name": "Peixe-espada-preto cru",
    "energia[kcal]": 88.0,
    "energia[kj]": 371.0,
    "l\u00edpidos[g]": 2.8,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 15.7,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 24.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.1,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 2.9,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.3,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peixe-espada-preto frito",
    "energia[kcal]": 259.0,
    "energia[kj]": 1080.0,
    "l\u00edpidos[g]": 16.6,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 4.9,
    "\u00e1cidos gordos polinsaturados [g]": 8.6,
    "\u00e1cido linoleico [g]": 8.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.2,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.1,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 24.2,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 36.0,
    "vitamina a  [\u00b5g]": 27.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.6,
    "alfa-tocoferol [mg]": 7.1,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 6.8,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 2.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.6,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 40.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 25.0
  },
  {
    "name": "Peixe-espada-preto grelhado",
    "energia[kcal]": 111.0,
    "energia[kj]": 467.0,
    "l\u00edpidos[g]": 3.2,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.5,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 29.0,
    "vitamina a  [\u00b5g]": 26.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.4,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 5.9,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.5,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 480.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 25.0
  },
  {
    "name": "Peixinhos da horta",
    "energia[kcal]": 94.0,
    "energia[kj]": 396.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.322,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.9,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 12.3,
    "fibra  [g]": 2.9,
    "prote\u00ednas [g]": 4.0,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 38.0,
    "vitamina a  [\u00b5g]": 52.0,
    "caroteno [\u00b5g]": 206.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.42,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.73,
    "equivalentes de niacina [mg]": 1.74,
    "triptofano/60 [mg]": 1.01,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.09,
    "vitamina c [mg]": 13.5,
    "folatos [\u00b5g]": 72.0,
    "cinza [g]": 1.57,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 41.0,
    "f\u00f3sforo [mg]": 67.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pepino cru",
    "energia[kcal]": 19.0,
    "energia[kj]": 81.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 95.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 35.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 0.3,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 18.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pera (5 variedades)",
    "energia[kcal]": 47.0,
    "energia[kj]": 199.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.4,
    "a\u00e7\u00facares [g]": 9.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.2,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 9.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.36,
    "s\u00f3dio [mg]": 8.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Pera conserva em calda de a\u00e7\u00facar",
    "energia[kcal]": 121.0,
    "energia[kj]": 514.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 28.9,
    "a\u00e7\u00facares [g]": 28.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 79.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pera cozida com a\u00e7\u00facar",
    "energia[kcal]": 67.0,
    "energia[kj]": 281.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.5,
    "a\u00e7\u00facares [g]": 14.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 91.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pera cozida sem a\u00e7\u00facar",
    "energia[kcal]": 40.0,
    "energia[kj]": 168.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.8,
    "a\u00e7\u00facares [g]": 7.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 1.1,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 93.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 8.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pera cristalizada",
    "energia[kcal]": 285.0,
    "energia[kj]": 1210.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 69.8,
    "a\u00e7\u00facares [g]": 69.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.2,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 26.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.15,
    "s\u00f3dio [mg]": 65.0,
    "pot\u00e1ssio [mg]": 17.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pera desidratada",
    "energia[kcal]": 300.0,
    "energia[kj]": 1270.0,
    "l\u00edpidos[g]": 2.6,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 59.9,
    "a\u00e7\u00facares [g]": 59.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 14.0,
    "prote\u00ednas [g]": 1.9,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.6,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 3.19,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 1.28,
    "equivalentes de niacina [mg]": 1.28,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.8,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 51.0,
    "pot\u00e1ssio [mg]": 960.0,
    "c\u00e1lcio [mg]": 57.0,
    "f\u00f3sforo [mg]": 64.0,
    "magn\u00e9sio [mg]": 57.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Percebes crus",
    "energia[kcal]": 59.0,
    "energia[kj]": 250.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 14.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.85,
    "tiamina [mg]": 0.3,
    "riboflavina [mg]": 0.6,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 2.6,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 15.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 18.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 94.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perdiz crua",
    "energia[kcal]": 104.0,
    "energia[kj]": 439.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.24,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 6.0,
    "vitamina b6 [mg]": 0.6,
    "vitamina b12 [\u00b5g]": 0.43,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 60.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 4.5,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perdiz estufada com margarina",
    "energia[kcal]": 212.0,
    "energia[kj]": 884.0,
    "l\u00edpidos[g]": 11.4,
    "\u00e1cidos gordos saturados[g]": 5.2,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.55,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.2,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 24.7,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 1.0,
    "\u00e1gua [g]": 58.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 66.0,
    "vitamina a  [\u00b5g]": 68.0,
    "caroteno [\u00b5g]": 71.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 2.9,
    "equivalentes de niacina [mg]": 10.7,
    "triptofano/60 [mg]": 6.38,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 0.24,
    "vitamina c [mg]": 2.3,
    "folatos [\u00b5g]": 6.5,
    "cinza [g]": 2.8,
    "s\u00f3dio [mg]": 580.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 4.0,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de borrego no forno",
    "energia[kcal]": 168.0,
    "energia[kj]": 702.0,
    "l\u00edpidos[g]": 7.6,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.244,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 21.9,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 1.4,
    "\u00e1gua [g]": 66.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 75.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.16,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 5.6,
    "equivalentes de niacina [mg]": 9.67,
    "triptofano/60 [mg]": 4.11,
    "vitamina b6 [mg]": 0.42,
    "vitamina b12 [\u00b5g]": 2.2,
    "vitamina c [mg]": 0.2,
    "folatos [\u00b5g]": 3.4,
    "cinza [g]": 2.14,
    "s\u00f3dio [mg]": 380.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 4.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de cabrito assada com azeite e margarina",
    "energia[kcal]": 180.0,
    "energia[kj]": 751.0,
    "l\u00edpidos[g]": 10.0,
    "\u00e1cidos gordos saturados[g]": 2.9,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 0.977,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.7,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 65.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 67.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.57,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 5.1,
    "equivalentes de niacina [mg]": 11.2,
    "triptofano/60 [mg]": 4.61,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 4.7,
    "cinza [g]": 2.36,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 9.8,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de cabrito assada com \u00f3leo alimentar e margarina",
    "energia[kcal]": 180.0,
    "energia[kj]": 751.0,
    "l\u00edpidos[g]": 10.0,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.1,
    "\u00e1cido linoleico [g]": 2.92,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.7,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 65.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 67.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.15,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 5.1,
    "equivalentes de niacina [mg]": 11.2,
    "triptofano/60 [mg]": 4.61,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 4.7,
    "cinza [g]": 2.36,
    "s\u00f3dio [mg]": 470.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 9.8,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de cabrito assada, sem molho",
    "energia[kcal]": 135.0,
    "energia[kj]": 569.0,
    "l\u00edpidos[g]": 4.2,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.3,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 80.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 5.5,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.3,
    "cinza [g]": 2.15,
    "s\u00f3dio [mg]": 340.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 4.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de cabrito crua",
    "energia[kcal]": 113.0,
    "energia[kj]": 476.0,
    "l\u00edpidos[g]": 4.0,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.3,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 6.0,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 1.22,
    "s\u00f3dio [mg]": 77.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro gorda, assada, sem molho",
    "energia[kcal]": 285.0,
    "energia[kj]": 1190.0,
    "l\u00edpidos[g]": 20.9,
    "\u00e1cidos gordos saturados[g]": 9.0,
    "\u00e1cidos gordos monoinsaturados [g]": 6.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.9,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.3,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 115.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 0.26,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 4.7,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 2.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.2,
    "cinza [g]": 1.81,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 4.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro gorda, crua",
    "energia[kcal]": 276.0,
    "energia[kj]": 1150.0,
    "l\u00edpidos[g]": 22.2,
    "\u00e1cidos gordos saturados[g]": 9.6,
    "\u00e1cidos gordos monoinsaturados [g]": 7.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 1.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 86.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.07,
    "tiamina [mg]": 0.32,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 8.6,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 2.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.82,
    "s\u00f3dio [mg]": 60.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro gorda, estufada sem molho",
    "energia[kcal]": 339.0,
    "energia[kj]": 1410.0,
    "l\u00edpidos[g]": 25.9,
    "\u00e1cidos gordos saturados[g]": 11.2,
    "\u00e1cidos gordos monoinsaturados [g]": 8.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 1.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 45.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 110.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 8.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.9,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 4.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro magra assada com azeite e margarina",
    "energia[kcal]": 242.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 16.4,
    "\u00e1cidos gordos saturados[g]": 5.8,
    "\u00e1cidos gordos monoinsaturados [g]": 7.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.54,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.5,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 32.0,
    "caroteno [\u00b5g]": 22.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.82,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 10.3,
    "triptofano/60 [mg]": 4.4,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 2.66,
    "s\u00f3dio [mg]": 610.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro magra assada com margarina",
    "energia[kcal]": 232.0,
    "energia[kj]": 966.0,
    "l\u00edpidos[g]": 15.3,
    "\u00e1cidos gordos saturados[g]": 7.2,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.6,
    "\u00e1cido linoleico [g]": 2.29,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.5,
    "sal  [g]": 1.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 65.0,
    "caroteno [\u00b5g]": 43.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 10.3,
    "triptofano/60 [mg]": 4.4,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.9,
    "cinza [g]": 2.84,
    "s\u00f3dio [mg]": 670.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro magra, assada, sem molho",
    "energia[kcal]": 178.0,
    "energia[kj]": 744.0,
    "l\u00edpidos[g]": 9.0,
    "\u00e1cidos gordos saturados[g]": 3.9,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.2,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 9.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.2,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro magra, crua",
    "energia[kcal]": 130.0,
    "energia[kj]": 544.0,
    "l\u00edpidos[g]": 5.6,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.8,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 68.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 8.7,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro magra, estufada com azeite e margarina",
    "energia[kcal]": 201.0,
    "energia[kj]": 837.0,
    "l\u00edpidos[g]": 13.4,
    "\u00e1cidos gordos saturados[g]": 4.6,
    "\u00e1cidos gordos monoinsaturados [g]": 6.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.41,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.0,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 17.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.4,
    "\u00e1gua [g]": 63.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 59.0,
    "vitamina a  [\u00b5g]": 61.0,
    "caroteno [\u00b5g]": 218.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 1.19,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 2.6,
    "equivalentes de niacina [mg]": 7.82,
    "triptofano/60 [mg]": 3.23,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 6.4,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 2.23,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro magra, estufada com margarina",
    "energia[kcal]": 193.0,
    "energia[kj]": 804.0,
    "l\u00edpidos[g]": 12.5,
    "\u00e1cidos gordos saturados[g]": 5.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.4,
    "\u00e1cido linoleico [g]": 2.06,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.0,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 17.4,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.4,
    "\u00e1gua [g]": 64.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 59.0,
    "vitamina a  [\u00b5g]": 90.0,
    "caroteno [\u00b5g]": 237.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.54,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 2.6,
    "equivalentes de niacina [mg]": 7.82,
    "triptofano/60 [mg]": 3.23,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 6.4,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 2.38,
    "s\u00f3dio [mg]": 480.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de carneiro magra, estufada sem molho",
    "energia[kcal]": 195.0,
    "energia[kj]": 816.0,
    "l\u00edpidos[g]": 10.3,
    "\u00e1cidos gordos saturados[g]": 4.5,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.6,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 96.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 3.7,
    "equivalentes de niacina [mg]": 8.5,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 1.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.3,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de frango sem pele, cozida",
    "energia[kcal]": 147.0,
    "energia[kj]": 621.0,
    "l\u00edpidos[g]": 2.3,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 31.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 135.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.29,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.9,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.85,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.7,
    "cinza [g]": 1.61,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Perna de frango sem pele, crua",
    "energia[kcal]": 111.0,
    "energia[kj]": 470.0,
    "l\u00edpidos[g]": 2.6,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 100.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.17,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 5.9,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.0,
    "cinza [g]": 1.21,
    "s\u00f3dio [mg]": 90.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de frango sem pele, estufada com azeite e margarina",
    "energia[kcal]": 124.0,
    "energia[kj]": 520.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 1.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.783,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.6,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 19.1,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 51.0,
    "caroteno [\u00b5g]": 173.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.73,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 8.82,
    "triptofano/60 [mg]": 3.53,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.42,
    "vitamina c [mg]": 6.8,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de frango sem pele, estufada com margarina ",
    "energia[kcal]": 121.0,
    "energia[kj]": 509.0,
    "l\u00edpidos[g]": 4.1,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.955,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.6,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 19.1,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 59.0,
    "caroteno [\u00b5g]": 178.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.55,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 8.82,
    "triptofano/60 [mg]": 3.53,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.42,
    "vitamina c [mg]": 6.8,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.34,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de frango sem pele, estufada sem molho",
    "energia[kcal]": 180.0,
    "energia[kj]": 759.0,
    "l\u00edpidos[g]": 5.4,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 32.9,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 128.0,
    "vitamina a  [\u00b5g]": 20.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 5.2,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 6.1,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.71,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 1.97,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.4
  },
  {
    "name": "Perna de peru com pele, assada com margarina",
    "energia[kcal]": 212.0,
    "energia[kj]": 883.0,
    "l\u00edpidos[g]": 12.5,
    "\u00e1cidos gordos saturados[g]": 4.4,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 3.2,
    "\u00e1cido linoleico [g]": 2.45,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.4,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.2,
    "\u00e1gua [g]": 60.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 111.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 5.2,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.52,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 2.51,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 3.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de peru com pele, crua",
    "energia[kcal]": 149.0,
    "energia[kj]": 621.0,
    "l\u00edpidos[g]": 8.1,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 86.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 8.5,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 1.17,
    "s\u00f3dio [mg]": 83.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 2.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de peru com pele, estufada com margarina",
    "energia[kcal]": 170.0,
    "energia[kj]": 710.0,
    "l\u00edpidos[g]": 9.7,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 1.95,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.0,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 19.1,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 86.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 136.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.31,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 3.2,
    "equivalentes de niacina [mg]": 8.73,
    "triptofano/60 [mg]": 3.52,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.99,
    "vitamina c [mg]": 5.6,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 2.46,
    "s\u00f3dio [mg]": 460.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco gorda assada com azeite e margarina",
    "energia[kcal]": 353.0,
    "energia[kj]": 1460.0,
    "l\u00edpidos[g]": 28.2,
    "\u00e1cidos gordos saturados[g]": 9.4,
    "\u00e1cidos gordos monoinsaturados [g]": 10.5,
    "\u00e1cidos gordos polinsaturados [g]": 4.6,
    "\u00e1cido linoleico [g]": 3.99,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.4,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 45.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 18.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.52,
    "tiamina [mg]": 0.49,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 13.6,
    "triptofano/60 [mg]": 4.09,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.36,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco gorda assada com margarina",
    "energia[kcal]": 347.0,
    "energia[kj]": 1440.0,
    "l\u00edpidos[g]": 27.6,
    "\u00e1cidos gordos saturados[g]": 10.2,
    "\u00e1cidos gordos monoinsaturados [g]": 8.7,
    "\u00e1cidos gordos polinsaturados [g]": 5.0,
    "\u00e1cido linoleico [g]": 4.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.4,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 45.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 35.0,
    "caroteno [\u00b5g]": 23.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.49,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 13.6,
    "triptofano/60 [mg]": 4.09,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.45,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco gorda assada com \u00f3leo alimentar e margarina",
    "energia[kcal]": 353.0,
    "energia[kj]": 1460.0,
    "l\u00edpidos[g]": 28.2,
    "\u00e1cidos gordos saturados[g]": 9.3,
    "\u00e1cidos gordos monoinsaturados [g]": 8.8,
    "\u00e1cidos gordos polinsaturados [g]": 6.2,
    "\u00e1cido linoleico [g]": 5.68,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.4,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 45.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 18.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 1.9,
    "tiamina [mg]": 0.49,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 13.6,
    "triptofano/60 [mg]": 4.09,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.36,
    "s\u00f3dio [mg]": 490.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco gorda assada, sem molho",
    "energia[kcal]": 283.0,
    "energia[kj]": 1180.0,
    "l\u00edpidos[g]": 19.2,
    "\u00e1cidos gordos saturados[g]": 7.6,
    "\u00e1cidos gordos monoinsaturados [g]": 7.4,
    "\u00e1cidos gordos polinsaturados [g]": 3.6,
    "\u00e1cido linoleico [g]": 3.2,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.6,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 50.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 108.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.0,
    "alfa-tocoferol [mg]": 0.12,
    "tiamina [mg]": 0.61,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 9.0,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 5.9,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.4,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco gorda crua",
    "energia[kcal]": 239.0,
    "energia[kj]": 992.0,
    "l\u00edpidos[g]": 18.5,
    "\u00e1cidos gordos saturados[g]": 6.3,
    "\u00e1cidos gordos monoinsaturados [g]": 6.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.0,
    "\u00e1cido linoleico [g]": 2.6,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 71.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.76,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 7.5,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.42,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 1.03,
    "s\u00f3dio [mg]": 57.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco gorda grelhada",
    "energia[kcal]": 287.0,
    "energia[kj]": 1200.0,
    "l\u00edpidos[g]": 20.6,
    "\u00e1cidos gordos saturados[g]": 7.0,
    "\u00e1cidos gordos monoinsaturados [g]": 6.9,
    "\u00e1cidos gordos polinsaturados [g]": 3.3,
    "\u00e1cido linoleico [g]": 2.9,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.5,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 51.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 106.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.2,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.9,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 8.3,
    "equivalentes de niacina [mg]": 14.0,
    "triptofano/60 [mg]": 5.4,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.3,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco magra assada com azeite e margarina",
    "energia[kcal]": 245.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 14.6,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 5.9,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.13,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.0,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 55.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 18.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.45,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 6.7,
    "equivalentes de niacina [mg]": 14.9,
    "triptofano/60 [mg]": 5.82,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.47,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 3.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco magra assada com margarina",
    "energia[kcal]": 239.0,
    "energia[kj]": 998.0,
    "l\u00edpidos[g]": 14.0,
    "\u00e1cidos gordos saturados[g]": 5.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.54,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.0,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 55.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 35.0,
    "caroteno [\u00b5g]": 23.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.99,
    "tiamina [mg]": 0.45,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 6.7,
    "equivalentes de niacina [mg]": 14.9,
    "triptofano/60 [mg]": 5.82,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.57,
    "s\u00f3dio [mg]": 550.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 3.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco magra assada com \u00f3leo alimentar e margarina",
    "energia[kcal]": 244.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 14.5,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 4.0,
    "\u00e1cido linoleico [g]": 3.82,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.0,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 1.2,
    "\u00e1gua [g]": 55.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 18.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 2.78,
    "tiamina [mg]": 0.45,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 6.7,
    "equivalentes de niacina [mg]": 14.9,
    "triptofano/60 [mg]": 5.82,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.47,
    "s\u00f3dio [mg]": 520.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 3.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco magra assada, sem molho",
    "energia[kcal]": 213.0,
    "energia[kj]": 893.0,
    "l\u00edpidos[g]": 9.9,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 31.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 93.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.55,
    "riboflavina [mg]": 0.34,
    "niacina [mg]": 8.4,
    "equivalentes de niacina [mg]": 15.0,
    "triptofano/60 [mg]": 6.6,
    "vitamina b6 [mg]": 0.43,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.4,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 4.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Perna de porco magra crua",
    "energia[kcal]": 152.0,
    "energia[kj]": 635.0,
    "l\u00edpidos[g]": 7.5,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 63.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.7,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 7.2,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 4.7,
    "vitamina b6 [mg]": 0.46,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 1.12,
    "s\u00f3dio [mg]": 86.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco magra estufada com azeite e banha",
    "energia[kcal]": 206.0,
    "energia[kj]": 858.0,
    "l\u00edpidos[g]": 11.9,
    "\u00e1cidos gordos saturados[g]": 3.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.48,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 22.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 61.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 68.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 1.24,
    "tiamina [mg]": 0.34,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 12.8,
    "triptofano/60 [mg]": 4.99,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 2.26,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco magra estufada com azeite e margarina",
    "energia[kcal]": 203.0,
    "energia[kj]": 847.0,
    "l\u00edpidos[g]": 11.6,
    "\u00e1cidos gordos saturados[g]": 3.7,
    "\u00e1cidos gordos monoinsaturados [g]": 4.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.65,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 22.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 61.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 67.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 1.23,
    "tiamina [mg]": 0.34,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 12.8,
    "triptofano/60 [mg]": 4.99,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 2.31,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco magra estufada com margarina",
    "energia[kcal]": 198.0,
    "energia[kj]": 828.0,
    "l\u00edpidos[g]": 11.1,
    "\u00e1cidos gordos saturados[g]": 4.3,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 1.98,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 22.4,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 61.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 67.0,
    "vitamina a  [\u00b5g]": 24.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.89,
    "tiamina [mg]": 0.34,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 12.8,
    "triptofano/60 [mg]": 4.99,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 2.39,
    "s\u00f3dio [mg]": 480.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco magra estufada com \u00f3leo alimentar e banha",
    "energia[kcal]": 206.0,
    "energia[kj]": 858.0,
    "l\u00edpidos[g]": 11.9,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 3.0,
    "\u00e1cido linoleico [g]": 2.86,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 22.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.8,
    "\u00e1gua [g]": 61.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 68.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 2.37,
    "tiamina [mg]": 0.34,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 12.8,
    "triptofano/60 [mg]": 4.99,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 2.6,
    "cinza [g]": 2.26,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Perna de porco magra estufada, sem molho",
    "energia[kcal]": 213.0,
    "energia[kj]": 893.0,
    "l\u00edpidos[g]": 11.0,
    "\u00e1cidos gordos saturados[g]": 3.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.6,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 87.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.46,
    "riboflavina [mg]": 0.29,
    "niacina [mg]": 5.3,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 6.1,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.53,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.2
  },
  {
    "name": "Perna de porco magra grelhada",
    "energia[kcal]": 215.0,
    "energia[kj]": 900.0,
    "l\u00edpidos[g]": 11.7,
    "\u00e1cidos gordos saturados[g]": 4.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.9,
    "\u00e1cido linoleico [g]": 1.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.5,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.77,
    "riboflavina [mg]": 0.31,
    "niacina [mg]": 7.4,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 5.9,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 0.97,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.2,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 460.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Peru inteiro com pele, cru",
    "energia[kcal]": 137.0,
    "energia[kj]": 574.0,
    "l\u00edpidos[g]": 6.1,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 1.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.5,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 8.0,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.6,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.15,
    "s\u00f3dio [mg]": 49.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pescada cozida (valor m\u00e9dio)",
    "energia[kcal]": 109.0,
    "energia[kj]": 460.0,
    "l\u00edpidos[g]": 3.6,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.2,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 25.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.67,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 9.7
  },
  {
    "name": "Pescada crua (valor m\u00e9dio)",
    "energia[kcal]": 83.0,
    "energia[kj]": 351.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.6,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 19.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.4,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 4.4,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.72,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 31.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pescada da \u00c1frica do Sul cozida",
    "energia[kcal]": 110.0,
    "energia[kj]": 462.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.9,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 23.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 0.62,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.82,
    "equivalentes de niacina [mg]": 4.3,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.72,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 39.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 9.7
  },
  {
    "name": "Pescada da \u00c1frica do Sul crua",
    "energia[kcal]": 81.0,
    "energia[kj]": 341.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.2,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 19.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.86,
    "equivalentes de niacina [mg]": 4.1,
    "triptofano/60 [mg]": 3.2,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pescada da \u00c1frica do Sul frita",
    "energia[kcal]": 175.0,
    "energia[kj]": 734.0,
    "l\u00edpidos[g]": 8.4,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 4.3,
    "\u00e1cido linoleico [g]": 3.7,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.7,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.2,
    "sal  [g]": 2.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 21.0,
    "vitamina a  [\u00b5g]": 14.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 5.4,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.95,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 950.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 9.7
  },
  {
    "name": "Pescada do Chile cozida",
    "energia[kcal]": 108.0,
    "energia[kj]": 454.0,
    "l\u00edpidos[g]": 3.3,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.5,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 26.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.8,
    "alfa-tocoferol [mg]": 0.51,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.66,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 9.7
  },
  {
    "name": "Pescada do Chile crua",
    "energia[kcal]": 86.0,
    "energia[kj]": 362.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 19.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.3,
    "alfa-tocoferol [mg]": 0.69,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.84,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 85.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 43.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pescada do Chile frita",
    "energia[kcal]": 171.0,
    "energia[kj]": 715.0,
    "l\u00edpidos[g]": 8.8,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 4.6,
    "\u00e1cido linoleico [g]": 4.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.6,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.3,
    "sal  [g]": 2.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 22.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.6,
    "alfa-tocoferol [mg]": 2.6,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 5.2,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.33,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 2.7,
    "s\u00f3dio [mg]": 870.0,
    "pot\u00e1ssio [mg]": 440.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 9.7
  },
  {
    "name": "Pescada europeia cozida",
    "energia[kcal]": 114.0,
    "energia[kj]": 479.0,
    "l\u00edpidos[g]": 3.7,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 28.0,
    "vitamina a  [\u00b5g]": 5.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.2,
    "alfa-tocoferol [mg]": 0.45,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 4.8,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.36,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 23.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 29.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 9.7
  },
  {
    "name": "Pescada europeia crua",
    "energia[kcal]": 75.0,
    "energia[kj]": 319.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 19.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.6,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 4.4,
    "triptofano/60 [mg]": 3.2,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.63,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 69.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pescada europeia frita",
    "energia[kcal]": 164.0,
    "energia[kj]": 685.0,
    "l\u00edpidos[g]": 7.9,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 3.9,
    "\u00e1cido linoleico [g]": 3.4,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.4,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.4,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.7,
    "sal  [g]": 2.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 25.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 7.0,
    "alfa-tocoferol [mg]": 1.6,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 5.9,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.83,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 28.0,
    "cinza [g]": 2.6,
    "s\u00f3dio [mg]": 820.0,
    "pot\u00e1ssio [mg]": 600.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 43.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 9.7
  },
  {
    "name": "Pescada frita (valor m\u00e9dio)",
    "energia[kcal]": 173.0,
    "energia[kj]": 723.0,
    "l\u00edpidos[g]": 8.6,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 4.4,
    "\u00e1cido linoleico [g]": 3.8,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 2.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.1,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.7,
    "sal  [g]": 2.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 22.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.5,
    "alfa-tocoferol [mg]": 1.8,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 5.4,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 910.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 28.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 9.7
  },
  {
    "name": "P\u00eassego (2 variedades)",
    "energia[kcal]": 44.0,
    "energia[kj]": 184.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.1,
    "a\u00e7\u00facares [g]": 8.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 67.0,
    "caroteno [\u00b5g]": 400.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.97,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.45,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 8.0,
    "f\u00f3sforo [mg]": 20.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.7
  },
  {
    "name": "P\u00eassego, conserva em calda de a\u00e7\u00facar",
    "energia[kcal]": 86.0,
    "energia[kj]": 365.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 20.6,
    "a\u00e7\u00facares [g]": 20.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 43.0,
    "caroteno [\u00b5g]": 257.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 6.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 0.29,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 9.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.6
  },
  {
    "name": "Picanha grelhada",
    "energia[kcal]": 194.0,
    "energia[kj]": 809.0,
    "l\u00edpidos[g]": 11.9,
    "\u00e1cidos gordos saturados[g]": 4.6,
    "\u00e1cidos gordos monoinsaturados [g]": 5.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.335,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.7,
    "sal  [g]": 14.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 51.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 68.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 8.48,
    "triptofano/60 [mg]": 4.58,
    "vitamina b6 [mg]": 0.48,
    "vitamina b12 [\u00b5g]": 2.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 13.9,
    "s\u00f3dio [mg]": 5430.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 65.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 3.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Picles de pepino",
    "energia[kcal]": 74.0,
    "energia[kj]": 312.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 16.5,
    "a\u00e7\u00facares [g]": 12.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 0.5,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 7.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 2.04,
    "triptofano/60 [mg]": 2.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.43,
    "s\u00f3dio [mg]": 290.0,
    "pot\u00e1ssio [mg]": 99.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 6.5,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.3,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Pimenta branca",
    "energia[kcal]": 306.0,
    "energia[kj]": 1290.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.496,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 48.3,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 26.2,
    "prote\u00ednas [g]": 10.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.21,
    "equivalentes de niacina [mg]": 1.51,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 21.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 73.0,
    "c\u00e1lcio [mg]": 270.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 90.0,
    "ferro [mg]": 14.0,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 3.1,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pimenta mo\u00edda",
    "energia[kcal]": 273.0,
    "energia[kj]": 1140.0,
    "l\u00edpidos[g]": 2.7,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 38.3,
    "a\u00e7\u00facares [g]": 38.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 26.5,
    "prote\u00ednas [g]": 10.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 19.0,
    "caroteno [\u00b5g]": 114.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 0.68,
    "equivalentes de niacina [mg]": 2.0,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 21.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.96,
    "s\u00f3dio [mg]": 25.0,
    "pot\u00e1ssio [mg]": 670.0,
    "c\u00e1lcio [mg]": 350.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 140.0,
    "ferro [mg]": 22.0,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pimenta preta",
    "energia[kcal]": 302.0,
    "energia[kj]": 1270.0,
    "l\u00edpidos[g]": 3.3,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 44.5,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 25.9,
    "prote\u00ednas [g]": 10.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 55.0,
    "caroteno [\u00b5g]": 329.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.72,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 1.12,
    "equivalentes de niacina [mg]": 2.42,
    "triptofano/60 [mg]": 1.3,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 10.5,
    "folatos [\u00b5g]": 13.5,
    "cinza [g]": 4.3,
    "s\u00f3dio [mg]": 32.0,
    "pot\u00e1ssio [mg]": 1290.0,
    "c\u00e1lcio [mg]": 440.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 180.0,
    "ferro [mg]": 19.0,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 4.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pimento cru",
    "energia[kcal]": 27.0,
    "energia[kj]": 111.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 1.6,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 217.0,
    "caroteno [\u00b5g]": 1300.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.31,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 90.0,
    "folatos [\u00b5g]": 28.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 24.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.4
  },
  {
    "name": "Pimento grelhado",
    "energia[kcal]": 37.0,
    "energia[kj]": 153.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.7,
    "a\u00e7\u00facares [g]": 3.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 2.8,
    "prote\u00ednas [g]": 2.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 383.0,
    "caroteno [\u00b5g]": 2300.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 108.0,
    "folatos [\u00b5g]": 28.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 43.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.4
  },
  {
    "name": "Pinh\u00e3o, miolo",
    "energia[kcal]": 622.0,
    "energia[kj]": 2580.0,
    "l\u00edpidos[g]": 51.7,
    "\u00e1cidos gordos saturados[g]": 3.5,
    "\u00e1cidos gordos monoinsaturados [g]": 15.0,
    "\u00e1cidos gordos polinsaturados [g]": 31.0,
    "\u00e1cido linoleico [g]": 30.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.0,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.6,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 33.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 4.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 10.0,
    "tiamina [mg]": 0.39,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 2.7,
    "equivalentes de niacina [mg]": 8.9,
    "triptofano/60 [mg]": 6.2,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 57.0,
    "cinza [g]": 4.26,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 780.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 350.0,
    "magn\u00e9sio [mg]": 270.0,
    "ferro [mg]": 4.7,
    "zinco [mg]": 6.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pist\u00e1cio torrado e salgado",
    "energia[kcal]": 616.0,
    "energia[kj]": 2550.0,
    "l\u00edpidos[g]": 53.0,
    "\u00e1cidos gordos saturados[g]": 6.7,
    "\u00e1cidos gordos monoinsaturados [g]": 35.7,
    "\u00e1cidos gordos polinsaturados [g]": 8.0,
    "\u00e1cido linoleico [g]": 7.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.6,
    "a\u00e7\u00facares [g]": 8.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.8,
    "fibra  [g]": 8.5,
    "prote\u00ednas [g]": 18.0,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 140.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.2,
    "tiamina [mg]": 0.59,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 5.3,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 58.0,
    "cinza [g]": 4.9,
    "s\u00f3dio [mg]": 650.0,
    "pot\u00e1ssio [mg]": 1050.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 500.0,
    "magn\u00e9sio [mg]": 160.0,
    "ferro [mg]": 7.0,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pist\u00e1cio torrado, sem sal",
    "energia[kcal]": 616.0,
    "energia[kj]": 2550.0,
    "l\u00edpidos[g]": 53.0,
    "\u00e1cidos gordos saturados[g]": 6.7,
    "\u00e1cidos gordos monoinsaturados [g]": 35.7,
    "\u00e1cidos gordos polinsaturados [g]": 8.0,
    "\u00e1cido linoleico [g]": 7.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.6,
    "a\u00e7\u00facares [g]": 8.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.8,
    "fibra  [g]": 8.5,
    "prote\u00ednas [g]": 18.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 140.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.2,
    "tiamina [mg]": 0.59,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 5.3,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 58.0,
    "cinza [g]": 4.25,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 1050.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 500.0,
    "magn\u00e9sio [mg]": 160.0,
    "ferro [mg]": 7.0,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 8.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pizza de queijo e tomate",
    "energia[kcal]": 271.0,
    "energia[kj]": 1140.0,
    "l\u00edpidos[g]": 10.1,
    "\u00e1cidos gordos saturados[g]": 3.3,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.76,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 33.2,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 31.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 10.5,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 41.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.3,
    "colesterol [mg]": 15.0,
    "vitamina a  [\u00b5g]": 82.0,
    "caroteno [\u00b5g]": 260.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 1.36,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 3.43,
    "triptofano/60 [mg]": 2.32,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.23,
    "vitamina c [mg]": 6.5,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 1.93,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 240.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pizza de queijo, tomate e fiambre",
    "energia[kcal]": 281.0,
    "energia[kj]": 1180.0,
    "l\u00edpidos[g]": 12.5,
    "\u00e1cidos gordos saturados[g]": 4.2,
    "\u00e1cidos gordos monoinsaturados [g]": 5.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.05,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 29.2,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 27.3,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 11.7,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 23.0,
    "vitamina a  [\u00b5g]": 72.0,
    "caroteno [\u00b5g]": 229.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.22,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 3.91,
    "triptofano/60 [mg]": 2.44,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.32,
    "vitamina c [mg]": 5.7,
    "folatos [\u00b5g]": 25.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 580.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 220.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00f3 achocolatado com alto teor de gordura",
    "energia[kcal]": 428.0,
    "energia[kj]": 1800.0,
    "l\u00edpidos[g]": 10.6,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 2.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 70.6,
    "a\u00e7\u00facares [g]": 45.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 25.3,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 12.0,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 1.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 4.0,
    "vitamina a  [\u00b5g]": 604.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 6.0,
    "tiamina [mg]": 0.59,
    "riboflavina [mg]": 1.7,
    "niacina [mg]": 9.8,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 2.6,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 4.14,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 940.0,
    "c\u00e1lcio [mg]": 420.0,
    "f\u00f3sforo [mg]": 500.0,
    "magn\u00e9sio [mg]": 100.0,
    "ferro [mg]": 5.0,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "P\u00f3 achocolatado com baixo teor de gordura",
    "energia[kcal]": 395.0,
    "energia[kj]": 1680.0,
    "l\u00edpidos[g]": 2.5,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 86.2,
    "a\u00e7\u00facares [g]": 73.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 12.8,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 6.5,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 1.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 2.2,
    "niacina [mg]": 7.9,
    "equivalentes de niacina [mg]": 9.8,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 3.3,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 730.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 100.0,
    "ferro [mg]": 3.2,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Polvo cozido sem sal",
    "energia[kcal]": 103.0,
    "energia[kj]": 434.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.7,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 105.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.1,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 7.6,
    "triptofano/60 [mg]": 5.1,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 49.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Polvo cru",
    "energia[kcal]": 73.0,
    "energia[kj]": 310.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 15.6,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.73,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 4.6,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 43.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pombo cru",
    "energia[kcal]": 232.0,
    "energia[kj]": 963.0,
    "l\u00edpidos[g]": 16.7,
    "\u00e1cidos gordos saturados[g]": 7.5,
    "\u00e1cidos gordos monoinsaturados [g]": 6.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.7,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 95.0,
    "vitamina a  [\u00b5g]": 73.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.16,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 4.4,
    "equivalentes de niacina [mg]": 9.6,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 0.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.2,
    "s\u00f3dio [mg]": 53.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pota crua",
    "energia[kcal]": 75.0,
    "energia[kj]": 317.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.00125,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.3,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 209.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 2.5,
    "alfa-tocoferol [mg]": 1.65,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 3.78,
    "triptofano/60 [mg]": 2.08,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 2.3,
    "vitamina c [mg]": 0.5,
    "folatos [\u00b5g]": 5.1,
    "cinza [g]": 1.15,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 44.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 28.0,
    "iodo [\u00b5g]": 5.0
  },
  {
    "name": "Preparado fermentado \u00e0 base de soja (Vegurte)",
    "energia[kcal]": 59.0,
    "energia[kj]": 247.0,
    "l\u00edpidos[g]": 2.4,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.9,
    "a\u00e7\u00facares [g]": 4.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 3.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 60.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.91,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 36.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 93.0,
    "f\u00f3sforo [mg]": 65.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Presunto",
    "energia[kcal]": 215.0,
    "energia[kj]": 899.0,
    "l\u00edpidos[g]": 12.8,
    "\u00e1cidos gordos saturados[g]": 4.1,
    "\u00e1cidos gordos monoinsaturados [g]": 5.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.0,
    "sal  [g]": 6.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 55.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 66.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.7,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 6.0,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 5.3,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 7.0,
    "s\u00f3dio [mg]": 2570.0,
    "pot\u00e1ssio [mg]": 580.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 3.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Prote\u00edna texturizada de soja",
    "energia[kcal]": 327.0,
    "energia[kj]": 1380.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 17.2,
    "a\u00e7\u00facares [g]": 13.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.1,
    "fibra  [g]": 16.6,
    "prote\u00ednas [g]": 53.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.29,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 681.3,
    "triptofano/60 [mg]": 680.0,
    "vitamina b6 [mg]": 0.46,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 370.0,
    "cinza [g]": 6.65,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 2230.0,
    "c\u00e1lcio [mg]": 280.0,
    "f\u00f3sforo [mg]": 650.0,
    "magn\u00e9sio [mg]": 280.0,
    "ferro [mg]": 8.3,
    "zinco [mg]": 5.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pudim de leite e ovos",
    "energia[kcal]": 246.0,
    "energia[kj]": 1040.0,
    "l\u00edpidos[g]": 4.7,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.716,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 44.8,
    "a\u00e7\u00facares [g]": 44.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 6.1,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 43.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 157.0,
    "vitamina a  [\u00b5g]": 80.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.88,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 0.09,
    "equivalentes de niacina [mg]": 1.76,
    "triptofano/60 [mg]": 1.69,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.42,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 0.687,
    "s\u00f3dio [mg]": 68.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 58.0,
    "f\u00f3sforo [mg]": 97.0,
    "magn\u00e9sio [mg]": 7.5,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pudim flan caseiro",
    "energia[kcal]": 211.0,
    "energia[kj]": 889.0,
    "l\u00edpidos[g]": 4.5,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 1.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.639,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 36.3,
    "a\u00e7\u00facares [g]": 36.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 6.2,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 51.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 142.0,
    "vitamina a  [\u00b5g]": 76.0,
    "caroteno [\u00b5g]": 7.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.79,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.13,
    "equivalentes de niacina [mg]": 1.78,
    "triptofano/60 [mg]": 1.67,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.779,
    "s\u00f3dio [mg]": 70.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 77.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 8.7,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pudim instant\u00e2neo em p\u00f3",
    "energia[kcal]": 412.0,
    "energia[kj]": 1740.0,
    "l\u00edpidos[g]": 9.4,
    "\u00e1cidos gordos saturados[g]": 8.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 79.4,
    "a\u00e7\u00facares [g]": 54.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 24.8,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 1.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 730.0,
    "pot\u00e1ssio [mg]": 57.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 440.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pudim instant\u00e2neo preparado com leite magro",
    "energia[kcal]": 104.0,
    "energia[kj]": 437.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.018,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.3,
    "a\u00e7\u00facares [g]": 13.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.5,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 3.2,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.08,
    "equivalentes de niacina [mg]": 0.73,
    "triptofano/60 [mg]": 0.65,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.09,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.8,
    "cinza [g]": 1.03,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 98.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pudim instant\u00e2neo preparado com leite meio gordo",
    "energia[kcal]": 113.0,
    "energia[kj]": 476.0,
    "l\u00edpidos[g]": 3.0,
    "\u00e1cidos gordos saturados[g]": 2.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.018,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 18.3,
    "a\u00e7\u00facares [g]": 13.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.5,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 18.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.02,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.16,
    "equivalentes de niacina [mg]": 0.81,
    "triptofano/60 [mg]": 0.65,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.8,
    "cinza [g]": 1.02,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 98.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 9.2,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pulm\u00f5es de porco, crus",
    "energia[kcal]": 101.0,
    "energia[kj]": 425.0,
    "l\u00edpidos[g]": 5.0,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.9,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 320.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 3.2,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 3.0,
    "vitamina c [mg]": 13.1,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 5.8,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 9.1,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pur\u00e9 de batata",
    "energia[kcal]": 126.0,
    "energia[kj]": 528.0,
    "l\u00edpidos[g]": 5.2,
    "\u00e1cidos gordos saturados[g]": 2.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.125,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 16.8,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 15.4,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 2.3,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 15.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 3.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.74,
    "triptofano/60 [mg]": 0.57,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 7.8,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 0.742,
    "s\u00f3dio [mg]": 130.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 38.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijada de queijo fresco",
    "energia[kcal]": 301.0,
    "energia[kj]": 1270.0,
    "l\u00edpidos[g]": 4.7,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 56.7,
    "a\u00e7\u00facares [g]": 35.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 21.5,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 7.5,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 29.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 106.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 2.4,
    "triptofano/60 [mg]": 1.7,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 58.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 65.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijada de queijo magro",
    "energia[kcal]": 292.0,
    "energia[kj]": 1240.0,
    "l\u00edpidos[g]": 4.7,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.428,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 55.4,
    "a\u00e7\u00facares [g]": 36.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 19.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 6.7,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 31.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 98.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.45,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.65,
    "equivalentes de niacina [mg]": 2.25,
    "triptofano/60 [mg]": 1.58,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.65,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.629,
    "s\u00f3dio [mg]": 38.0,
    "pot\u00e1ssio [mg]": 93.0,
    "c\u00e1lcio [mg]": 59.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijada de requeij\u00e3o",
    "energia[kcal]": 311.0,
    "energia[kj]": 1300.0,
    "l\u00edpidos[g]": 13.1,
    "\u00e1cidos gordos saturados[g]": 6.0,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.886,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 40.8,
    "a\u00e7\u00facares [g]": 31.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.9,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 7.2,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 36.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 166.0,
    "vitamina a  [\u00b5g]": 145.0,
    "caroteno [\u00b5g]": 23.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 1.21,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 0.65,
    "equivalentes de niacina [mg]": 2.51,
    "triptofano/60 [mg]": 1.85,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.56,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 1.85,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 140.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Amarelo da Beira Baixa",
    "energia[kcal]": 361.0,
    "energia[kj]": 1500.0,
    "l\u00edpidos[g]": 30.0,
    "\u00e1cidos gordos saturados[g]": 21.1,
    "\u00e1cidos gordos monoinsaturados [g]": 7.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 1.5,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.5,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 43.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 195.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.7,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 5.2,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 85.0,
    "c\u00e1lcio [mg]": 710.0,
    "f\u00f3sforo [mg]": 600.0,
    "magn\u00e9sio [mg]": 56.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Brie",
    "energia[kcal]": 321.0,
    "energia[kj]": 1330.0,
    "l\u00edpidos[g]": 27.3,
    "\u00e1cidos gordos saturados[g]": 17.3,
    "\u00e1cidos gordos monoinsaturados [g]": 7.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.8,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 50.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 329.0,
    "caroteno [\u00b5g]": 9.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.64,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.38,
    "niacina [mg]": 0.46,
    "equivalentes de niacina [mg]": 5.04,
    "triptofano/60 [mg]": 4.58,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.68,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 66.4,
    "cinza [g]": 2.42,
    "s\u00f3dio [mg]": 580.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 340.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 5.7,
    "iodo [\u00b5g]": 15.0
  },
  {
    "name": "Queijo Camembert",
    "energia[kcal]": 254.0,
    "energia[kj]": 1060.0,
    "l\u00edpidos[g]": 19.6,
    "\u00e1cidos gordos saturados[g]": 10.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.9,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.2,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 75.0,
    "vitamina a  [\u00b5g]": 216.0,
    "caroteno [\u00b5g]": 241.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.54,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.47,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 5.7,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.97,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 50.0,
    "cinza [g]": 4.57,
    "s\u00f3dio [mg]": 580.0,
    "pot\u00e1ssio [mg]": 89.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Cheddar",
    "energia[kcal]": 419.0,
    "energia[kj]": 1740.0,
    "l\u00edpidos[g]": 34.9,
    "\u00e1cidos gordos saturados[g]": 21.7,
    "\u00e1cidos gordos monoinsaturados [g]": 9.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 1.4,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.4,
    "sal  [g]": 1.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 36.6,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 97.0,
    "vitamina a  [\u00b5g]": 387.0,
    "caroteno [\u00b5g]": 141.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.52,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.39,
    "niacina [mg]": 0.06,
    "equivalentes de niacina [mg]": 6.9,
    "triptofano/60 [mg]": 6.8,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 31.0,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 720.0,
    "pot\u00e1ssio [mg]": 75.0,
    "c\u00e1lcio [mg]": 740.0,
    "f\u00f3sforo [mg]": 510.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo creme para barrar",
    "energia[kcal]": 263.0,
    "energia[kj]": 1090.0,
    "l\u00edpidos[g]": 22.0,
    "\u00e1cidos gordos saturados[g]": 11.8,
    "\u00e1cidos gordos monoinsaturados [g]": 5.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.9,
    "a\u00e7\u00facares [g]": 6.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 9.3,
    "sal  [g]": 2.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 18.0,
    "vitamina a  [\u00b5g]": 66.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 7.6,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 4.0,
    "s\u00f3dio [mg]": 980.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 660.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo creme para barrar, alto teor polinsaturados",
    "energia[kcal]": 263.0,
    "energia[kj]": 1090.0,
    "l\u00edpidos[g]": 22.0,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 10.4,
    "\u00e1cido linoleico [g]": 10.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.9,
    "a\u00e7\u00facares [g]": 6.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 9.3,
    "sal  [g]": 2.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 18.0,
    "vitamina a  [\u00b5g]": 66.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 7.6,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.26,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 3.8,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 4.0,
    "s\u00f3dio [mg]": 980.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 660.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de Azeit\u00e3o",
    "energia[kcal]": 339.0,
    "energia[kj]": 1410.0,
    "l\u00edpidos[g]": 27.5,
    "\u00e1cidos gordos saturados[g]": 18.7,
    "\u00e1cidos gordos monoinsaturados [g]": 6.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 1.1,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.5,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.3,
    "\u00e1cidos org\u00e2nicos [g]": 1.1,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 210.0,
    "caroteno [\u00b5g]": 70.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.66,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 5.5,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.37,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 31.0,
    "cinza [g]": 4.4,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 76.0,
    "c\u00e1lcio [mg]": 540.0,
    "f\u00f3sforo [mg]": 420.0,
    "magn\u00e9sio [mg]": 50.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de cabra \"Pure ch\u00e8vre\"",
    "energia[kcal]": 286.0,
    "energia[kj]": 1190.0,
    "l\u00edpidos[g]": 25.0,
    "\u00e1cidos gordos saturados[g]": 17.0,
    "\u00e1cidos gordos monoinsaturados [g]": 4.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.8,
    "hidratos de carbono [g]": 2.0,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 51.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 107.0,
    "vitamina a  [\u00b5g]": 320.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.53,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.89,
    "niacina [mg]": 1.43,
    "equivalentes de niacina [mg]": 6.33,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.15,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 84.2,
    "cinza [g]": 2.18,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de cabra atabafado, curado",
    "energia[kcal]": 313.0,
    "energia[kj]": 1300.0,
    "l\u00edpidos[g]": 24.0,
    "\u00e1cidos gordos saturados[g]": 18.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.8,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 2.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.2,
    "sal  [g]": 2.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 47.3,
    "\u00e1cidos org\u00e2nicos [g]": 4.5,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 320.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 5.2,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.15,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 5.72,
    "s\u00f3dio [mg]": 1100.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 810.0,
    "f\u00f3sforo [mg]": 610.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de cabra atabafado, fresco",
    "energia[kcal]": 202.0,
    "energia[kj]": 839.0,
    "l\u00edpidos[g]": 15.4,
    "\u00e1cidos gordos saturados[g]": 10.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 2.5,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 134.0,
    "caroteno [\u00b5g]": 54.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 1.63,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.29,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 2.71,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 430.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de cabra curado",
    "energia[kcal]": 382.0,
    "energia[kj]": 1590.0,
    "l\u00edpidos[g]": 30.5,
    "\u00e1cidos gordos saturados[g]": 23.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 1.0,
    "hidratos de carbono [g]": 2.5,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.0,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 47.3,
    "\u00e1cidos org\u00e2nicos [g]": 4.5,
    "colesterol [mg]": 94.0,
    "vitamina a  [\u00b5g]": 320.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 5.2,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.15,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 3.91,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 810.0,
    "f\u00f3sforo [mg]": 610.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de Castelo Branco",
    "energia[kcal]": 356.0,
    "energia[kj]": 1470.0,
    "l\u00edpidos[g]": 29.2,
    "\u00e1cidos gordos saturados[g]": 19.4,
    "\u00e1cidos gordos monoinsaturados [g]": 7.1,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.85,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 1.8,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.8,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 43.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 195.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.7,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 5.2,
    "s\u00f3dio [mg]": 660.0,
    "pot\u00e1ssio [mg]": 85.0,
    "c\u00e1lcio [mg]": 710.0,
    "f\u00f3sforo [mg]": 600.0,
    "magn\u00e9sio [mg]": 56.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de \u00c9vora",
    "energia[kcal]": 405.0,
    "energia[kj]": 1680.0,
    "l\u00edpidos[g]": 32.9,
    "\u00e1cidos gordos saturados[g]": 22.6,
    "\u00e1cidos gordos monoinsaturados [g]": 8.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 1.4,
    "hidratos de carbono [g]": 2.1,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.8,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 27.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.7,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 180.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.58,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 6.7,
    "triptofano/60 [mg]": 6.2,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.51,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 39.0,
    "cinza [g]": 11.2,
    "s\u00f3dio [mg]": 500.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 750.0,
    "f\u00f3sforo [mg]": 450.0,
    "magn\u00e9sio [mg]": 70.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de mistura (vaca, cabra e ovelha)",
    "energia[kcal]": 329.0,
    "energia[kj]": 1360.0,
    "l\u00edpidos[g]": 26.7,
    "\u00e1cidos gordos saturados[g]": 18.3,
    "\u00e1cidos gordos monoinsaturados [g]": 7.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.6,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 45.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 423.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.47,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 6.95,
    "triptofano/60 [mg]": 6.55,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 1.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 4.91,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 830.0,
    "f\u00f3sforo [mg]": 510.0,
    "magn\u00e9sio [mg]": 48.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 5.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de Nisa",
    "energia[kcal]": 451.0,
    "energia[kj]": 1870.0,
    "l\u00edpidos[g]": 38.0,
    "\u00e1cidos gordos saturados[g]": 19.9,
    "\u00e1cidos gordos monoinsaturados [g]": 9.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 1.6,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.4,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 27.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.7,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 180.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.58,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 6.7,
    "triptofano/60 [mg]": 6.2,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.51,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 39.0,
    "cinza [g]": 11.2,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 750.0,
    "f\u00f3sforo [mg]": 450.0,
    "magn\u00e9sio [mg]": 70.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de ovelha curado",
    "energia[kcal]": 401.0,
    "energia[kj]": 1660.0,
    "l\u00edpidos[g]": 33.0,
    "\u00e1cidos gordos saturados[g]": 22.0,
    "\u00e1cidos gordos monoinsaturados [g]": 8.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 1.4,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.8,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 43.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 195.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.7,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 5.2,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 85.0,
    "c\u00e1lcio [mg]": 710.0,
    "f\u00f3sforo [mg]": 600.0,
    "magn\u00e9sio [mg]": 56.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de ovelha, amanteigado",
    "energia[kcal]": 361.0,
    "energia[kj]": 1500.0,
    "l\u00edpidos[g]": 30.3,
    "\u00e1cidos gordos saturados[g]": 20.8,
    "\u00e1cidos gordos monoinsaturados [g]": 7.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 1.6,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.7,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 43.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 195.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.7,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 5.2,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 85.0,
    "c\u00e1lcio [mg]": 710.0,
    "f\u00f3sforo [mg]": 600.0,
    "magn\u00e9sio [mg]": 56.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de vaca curado",
    "energia[kcal]": 355.0,
    "energia[kj]": 1470.0,
    "l\u00edpidos[g]": 29.0,
    "\u00e1cidos gordos saturados[g]": 19.5,
    "\u00e1cidos gordos monoinsaturados [g]": 7.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 1.4,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.3,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 45.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 423.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.47,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 6.95,
    "triptofano/60 [mg]": 6.55,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 1.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 4.91,
    "s\u00f3dio [mg]": 500.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 830.0,
    "f\u00f3sforo [mg]": 510.0,
    "magn\u00e9sio [mg]": 48.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 5.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de vaca curado, magro",
    "energia[kcal]": 208.0,
    "energia[kj]": 872.0,
    "l\u00edpidos[g]": 10.0,
    "\u00e1cidos gordos saturados[g]": 6.8,
    "\u00e1cidos gordos monoinsaturados [g]": 2.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.1,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 32.0,
    "vitamina a  [\u00b5g]": 134.0,
    "caroteno [\u00b5g]": 54.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.47,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 5.7,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.97,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 50.0,
    "cinza [g]": 4.57,
    "s\u00f3dio [mg]": 500.0,
    "pot\u00e1ssio [mg]": 89.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de vaca curado, meio gordo",
    "energia[kcal]": 280.0,
    "energia[kj]": 1160.0,
    "l\u00edpidos[g]": 20.1,
    "\u00e1cidos gordos saturados[g]": 13.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 1.0,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 75.0,
    "vitamina a  [\u00b5g]": 216.0,
    "caroteno [\u00b5g]": 241.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.54,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.47,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 5.7,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.97,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 50.0,
    "cinza [g]": 4.57,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 89.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo de vaca, amanteigado",
    "energia[kcal]": 343.0,
    "energia[kj]": 1420.0,
    "l\u00edpidos[g]": 28.3,
    "\u00e1cidos gordos saturados[g]": 19.4,
    "\u00e1cidos gordos monoinsaturados [g]": 8.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 1.7,
    "a\u00e7\u00facares [g]": 1.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.2,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 43.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 423.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.47,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 6.95,
    "triptofano/60 [mg]": 6.55,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 1.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 5.2,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 830.0,
    "f\u00f3sforo [mg]": 510.0,
    "magn\u00e9sio [mg]": 48.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 5.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Emmental",
    "energia[kcal]": 384.0,
    "energia[kj]": 1600.0,
    "l\u00edpidos[g]": 29.7,
    "\u00e1cidos gordos saturados[g]": 18.6,
    "\u00e1cidos gordos monoinsaturados [g]": 8.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 28.9,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 36.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 98.0,
    "vitamina a  [\u00b5g]": 250.0,
    "caroteno [\u00b5g]": 108.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.37,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 7.3,
    "triptofano/60 [mg]": 7.2,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 2.1,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 4.0,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 1080.0,
    "f\u00f3sforo [mg]": 670.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 4.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo flamengo",
    "energia[kcal]": 349.0,
    "energia[kj]": 1450.0,
    "l\u00edpidos[g]": 28.3,
    "\u00e1cidos gordos saturados[g]": 18.5,
    "\u00e1cidos gordos monoinsaturados [g]": 8.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.7,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 45.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 423.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.47,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 6.95,
    "triptofano/60 [mg]": 6.55,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 1.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 4.91,
    "s\u00f3dio [mg]": 500.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 830.0,
    "f\u00f3sforo [mg]": 510.0,
    "magn\u00e9sio [mg]": 48.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 5.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 39.0
  },
  {
    "name": "Queijo flamengo light (-50% gordura)",
    "energia[kcal]": 228.0,
    "energia[kj]": 953.0,
    "l\u00edpidos[g]": 13.3,
    "\u00e1cidos gordos saturados[g]": 9.3,
    "\u00e1cidos gordos monoinsaturados [g]": 4.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.8,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 53.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 134.0,
    "caroteno [\u00b5g]": 54.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 1.63,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.29,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 3.94,
    "s\u00f3dio [mg]": 540.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 850.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 39.0
  },
  {
    "name": "Queijo fresco a\u00e7ucarado com sabor a fruta",
    "energia[kcal]": 110.0,
    "energia[kj]": 462.0,
    "l\u00edpidos[g]": 3.6,
    "\u00e1cidos gordos saturados[g]": 1.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 11.6,
    "a\u00e7\u00facares [g]": 11.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 7.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.6,
    "colesterol [mg]": 19.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.8,
    "triptofano/60 [mg]": 1.7,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 38.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 94.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo fresco de cabra",
    "energia[kcal]": 182.0,
    "energia[kj]": 756.0,
    "l\u00edpidos[g]": 13.8,
    "\u00e1cidos gordos saturados[g]": 9.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 2.1,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 12.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 134.0,
    "caroteno [\u00b5g]": 54.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 1.63,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.29,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 2.35,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 430.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo fresco de ovelha",
    "energia[kcal]": 204.0,
    "energia[kj]": 849.0,
    "l\u00edpidos[g]": 15.6,
    "\u00e1cidos gordos saturados[g]": 10.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 3.9,
    "a\u00e7\u00facares [g]": 3.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 11.3,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.5,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 89.0,
    "vitamina a  [\u00b5g]": 234.0,
    "caroteno [\u00b5g]": 95.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.45,
    "niacina [mg]": 0.45,
    "equivalentes de niacina [mg]": 5.35,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 1.45,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 2.81,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 580.0,
    "f\u00f3sforo [mg]": 420.0,
    "magn\u00e9sio [mg]": 39.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 25.0
  },
  {
    "name": "Queijo fresco magro",
    "energia[kcal]": 96.0,
    "energia[kj]": 405.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.04,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 6.9,
    "a\u00e7\u00facares [g]": 6.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.0,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.37,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 2.35,
    "triptofano/60 [mg]": 2.35,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.53,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 26.3,
    "cinza [g]": 2.7,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 480.0,
    "f\u00f3sforo [mg]": 350.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo fresco meio gordo",
    "energia[kcal]": 156.0,
    "energia[kj]": 648.0,
    "l\u00edpidos[g]": 11.9,
    "\u00e1cidos gordos saturados[g]": 7.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 2.7,
    "a\u00e7\u00facares [g]": 2.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 9.2,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 32.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 54.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 2.96,
    "triptofano/60 [mg]": 2.96,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.95,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 390.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo fundido 40% gordura",
    "energia[kcal]": 326.0,
    "energia[kj]": 1350.0,
    "l\u00edpidos[g]": 25.7,
    "\u00e1cidos gordos saturados[g]": 13.8,
    "\u00e1cidos gordos monoinsaturados [g]": 6.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 1.2,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.5,
    "sal  [g]": 3.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 44.5,
    "\u00e1cidos org\u00e2nicos [g]": 1.3,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 242.0,
    "caroteno [\u00b5g]": 112.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.49,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 5.9,
    "triptofano/60 [mg]": 5.3,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.92,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 5.53,
    "s\u00f3dio [mg]": 1390.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 750.0,
    "f\u00f3sforo [mg]": 760.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Gouda",
    "energia[kcal]": 380.0,
    "energia[kj]": 1580.0,
    "l\u00edpidos[g]": 30.6,
    "\u00e1cidos gordos saturados[g]": 20.3,
    "\u00e1cidos gordos monoinsaturados [g]": 7.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 1.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.3,
    "sal  [g]": 2.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 40.4,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 281.0,
    "caroteno [\u00b5g]": 139.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.57,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 0.06,
    "equivalentes de niacina [mg]": 7.1,
    "triptofano/60 [mg]": 7.0,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 43.0,
    "cinza [g]": 4.7,
    "s\u00f3dio [mg]": 930.0,
    "pot\u00e1ssio [mg]": 82.0,
    "c\u00e1lcio [mg]": 770.0,
    "f\u00f3sforo [mg]": 500.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 3.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Gruy\u00e8re",
    "energia[kcal]": 415.0,
    "energia[kj]": 1720.0,
    "l\u00edpidos[g]": 33.3,
    "\u00e1cidos gordos saturados[g]": 20.8,
    "\u00e1cidos gordos monoinsaturados [g]": 9.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 1.4,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 27.2,
    "sal  [g]": 1.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 35.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 100.0,
    "vitamina a  [\u00b5g]": 363.0,
    "caroteno [\u00b5g]": 225.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.58,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.39,
    "niacina [mg]": 0.04,
    "equivalentes de niacina [mg]": 6.4,
    "triptofano/60 [mg]": 6.4,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 1.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 4.1,
    "s\u00f3dio [mg]": 670.0,
    "pot\u00e1ssio [mg]": 99.0,
    "c\u00e1lcio [mg]": 950.0,
    "f\u00f3sforo [mg]": 610.0,
    "magn\u00e9sio [mg]": 37.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 2.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Mascarpone",
    "energia[kcal]": 458.0,
    "energia[kj]": 1890.0,
    "l\u00edpidos[g]": 47.0,
    "\u00e1cidos gordos saturados[g]": 29.0,
    "\u00e1cidos gordos monoinsaturados [g]": 14.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 1.2,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 7.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 44.4,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 95.0,
    "vitamina a  [\u00b5g]": 353.0,
    "caroteno [\u00b5g]": 258.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 6.0,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 86.0,
    "pot\u00e1ssio [mg]": 53.0,
    "c\u00e1lcio [mg]": 68.0,
    "f\u00f3sforo [mg]": 97.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Mesti\u00e7o de Tolosa",
    "energia[kcal]": 407.0,
    "energia[kj]": 1690.0,
    "l\u00edpidos[g]": 33.0,
    "\u00e1cidos gordos saturados[g]": 22.0,
    "\u00e1cidos gordos monoinsaturados [g]": 8.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 1.4,
    "hidratos de carbono [g]": 1.8,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.5,
    "sal  [g]": 2.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 27.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.7,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 180.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.58,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 6.7,
    "triptofano/60 [mg]": 6.2,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.51,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 39.0,
    "cinza [g]": 11.2,
    "s\u00f3dio [mg]": 850.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 750.0,
    "f\u00f3sforo [mg]": 450.0,
    "magn\u00e9sio [mg]": 70.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 3.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Mozzarella fresco",
    "energia[kcal]": 256.0,
    "energia[kj]": 1060.0,
    "l\u00edpidos[g]": 19.5,
    "\u00e1cidos gordos saturados[g]": 11.4,
    "\u00e1cidos gordos monoinsaturados [g]": 6.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.55,
    "\u00e1cidos gordos trans [g]": 0.8,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.7,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 46.0,
    "vitamina a  [\u00b5g]": 323.0,
    "caroteno [\u00b5g]": 531.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.39,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.27,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 5.6,
    "triptofano/60 [mg]": 5.5,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 8.0,
    "c\u00e1lcio [mg]": 340.0,
    "f\u00f3sforo [mg]": 350.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Parmes\u00e3o",
    "energia[kcal]": 406.0,
    "energia[kj]": 1690.0,
    "l\u00edpidos[g]": 27.8,
    "\u00e1cidos gordos saturados[g]": 14.7,
    "\u00e1cidos gordos monoinsaturados [g]": 7.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 1.7,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 37.7,
    "sal  [g]": 1.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 28.2,
    "\u00e1cidos org\u00e2nicos [g]": 1.5,
    "colesterol [mg]": 90.0,
    "vitamina a  [\u00b5g]": 248.0,
    "caroteno [\u00b5g]": 135.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 9.4,
    "triptofano/60 [mg]": 9.0,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 1.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 4.7,
    "s\u00f3dio [mg]": 770.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 1300.0,
    "f\u00f3sforo [mg]": 900.0,
    "magn\u00e9sio [mg]": 51.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 4.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Quark a\u00e7ucarado magro com sabor a fruta",
    "energia[kcal]": 78.0,
    "energia[kj]": 330.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.8,
    "a\u00e7\u00facares [g]": 10.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 8.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 3.0,
    "triptofano/60 [mg]": 2.0,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 42.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Quark natural magro",
    "energia[kcal]": 60.0,
    "energia[kj]": 254.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.0,
    "a\u00e7\u00facares [g]": 4.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 10.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 84.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 7.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 3.5,
    "triptofano/60 [mg]": 2.4,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 41.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Quark natural meio gordo",
    "energia[kcal]": 125.0,
    "energia[kj]": 519.0,
    "l\u00edpidos[g]": 8.5,
    "\u00e1cidos gordos saturados[g]": 4.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 3.9,
    "a\u00e7\u00facares [g]": 3.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 8.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 29.0,
    "vitamina a  [\u00b5g]": 90.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 3.0,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 37.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Raclette",
    "energia[kcal]": 354.0,
    "energia[kj]": 1470.0,
    "l\u00edpidos[g]": 27.8,
    "\u00e1cidos gordos saturados[g]": 18.0,
    "\u00e1cidos gordos monoinsaturados [g]": 6.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.17,
    "\u00e1cidos gordos trans [g]": 1.7,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.5,
    "sal  [g]": 1.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 42.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 92.0,
    "vitamina a  [\u00b5g]": 248.0,
    "caroteno [\u00b5g]": 135.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 9.0,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 1.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 50.0,
    "cinza [g]": 3.37,
    "s\u00f3dio [mg]": 680.0,
    "pot\u00e1ssio [mg]": 73.0,
    "c\u00e1lcio [mg]": 650.0,
    "f\u00f3sforo [mg]": 480.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 3.4,
    "sel\u00e9nio [\u00b5g]": 8.0,
    "iodo [\u00b5g]": 25.0
  },
  {
    "name": "Queijo Roquefort",
    "energia[kcal]": 372.0,
    "energia[kj]": 1540.0,
    "l\u00edpidos[g]": 31.5,
    "\u00e1cidos gordos saturados[g]": 16.6,
    "\u00e1cidos gordos monoinsaturados [g]": 7.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.0,
    "sal  [g]": 3.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 41.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 75.0,
    "vitamina a  [\u00b5g]": 210.0,
    "caroteno [\u00b5g]": 120.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.53,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.5,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 6.0,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 38.0,
    "cinza [g]": 5.0,
    "s\u00f3dio [mg]": 1560.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 770.0,
    "f\u00f3sforo [mg]": 450.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo S\u00e3o Jorge",
    "energia[kcal]": 409.0,
    "energia[kj]": 1700.0,
    "l\u00edpidos[g]": 32.5,
    "\u00e1cidos gordos saturados[g]": 21.4,
    "\u00e1cidos gordos monoinsaturados [g]": 8.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 1.5,
    "hidratos de carbono [g]": 2.5,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.6,
    "sal  [g]": 1.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 37.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 243.0,
    "caroteno [\u00b5g]": 182.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.49,
    "tiamina [mg]": 0.32,
    "riboflavina [mg]": 0.55,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 8.0,
    "triptofano/60 [mg]": 7.4,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 2.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 45.0,
    "cinza [g]": 5.5,
    "s\u00f3dio [mg]": 700.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 870.0,
    "f\u00f3sforo [mg]": 530.0,
    "magn\u00e9sio [mg]": 44.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Serpa",
    "energia[kcal]": 375.0,
    "energia[kj]": 1560.0,
    "l\u00edpidos[g]": 30.5,
    "\u00e1cidos gordos saturados[g]": 20.5,
    "\u00e1cidos gordos monoinsaturados [g]": 7.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.9,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.0,
    "sal  [g]": 2.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 43.0,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 195.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.7,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 37.0,
    "cinza [g]": 5.2,
    "s\u00f3dio [mg]": 800.0,
    "pot\u00e1ssio [mg]": 85.0,
    "c\u00e1lcio [mg]": 710.0,
    "f\u00f3sforo [mg]": 600.0,
    "magn\u00e9sio [mg]": 56.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Serra da Estrela",
    "energia[kcal]": 324.0,
    "energia[kj]": 1350.0,
    "l\u00edpidos[g]": 24.0,
    "\u00e1cidos gordos saturados[g]": 15.8,
    "\u00e1cidos gordos monoinsaturados [g]": 5.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 1.0,
    "hidratos de carbono [g]": 2.0,
    "a\u00e7\u00facares [g]": 2.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.8,
    "sal  [g]": 1.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 45.5,
    "\u00e1cidos org\u00e2nicos [g]": 1.6,
    "colesterol [mg]": 88.0,
    "vitamina a  [\u00b5g]": 240.0,
    "caroteno [\u00b5g]": 80.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.45,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.6,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 5.6,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 31.0,
    "cinza [g]": 4.72,
    "s\u00f3dio [mg]": 600.0,
    "pot\u00e1ssio [mg]": 80.0,
    "c\u00e1lcio [mg]": 700.0,
    "f\u00f3sforo [mg]": 480.0,
    "magn\u00e9sio [mg]": 53.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queijo Serra da Estrela, velho",
    "energia[kcal]": 389.0,
    "energia[kj]": 1620.0,
    "l\u00edpidos[g]": 31.5,
    "\u00e1cidos gordos saturados[g]": 16.6,
    "\u00e1cidos gordos monoinsaturados [g]": 7.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 1.3,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.5,
    "sal  [g]": 2.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 36.5,
    "\u00e1cidos org\u00e2nicos [g]": 1.0,
    "colesterol [mg]": 81.0,
    "vitamina a  [\u00b5g]": 285.0,
    "caroteno [\u00b5g]": 10.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 0.53,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.65,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 6.8,
    "triptofano/60 [mg]": 6.0,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 1.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 38.0,
    "cinza [g]": 5.3,
    "s\u00f3dio [mg]": 790.0,
    "pot\u00e1ssio [mg]": 90.0,
    "c\u00e1lcio [mg]": 820.0,
    "f\u00f3sforo [mg]": 590.0,
    "magn\u00e9sio [mg]": 59.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Queque",
    "energia[kcal]": 408.0,
    "energia[kj]": 1710.0,
    "l\u00edpidos[g]": 18.2,
    "\u00e1cidos gordos saturados[g]": 9.2,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 53.8,
    "a\u00e7\u00facares [g]": 27.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 26.4,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 6.7,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 19.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 162.0,
    "vitamina a  [\u00b5g]": 170.0,
    "caroteno [\u00b5g]": 7.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 2.1,
    "triptofano/60 [mg]": 1.7,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.7,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 89.0,
    "c\u00e1lcio [mg]": 36.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Quiabo cru",
    "energia[kcal]": 37.0,
    "energia[kj]": 153.0,
    "l\u00edpidos[g]": 1.0,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.1,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.5,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 85.0,
    "caroteno [\u00b5g]": 515.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 1.0,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 21.0,
    "folatos [\u00b5g]": 88.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 8.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 59.0,
    "magn\u00e9sio [mg]": 71.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 1.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Quiche de espinafres",
    "energia[kcal]": 275.0,
    "energia[kj]": 1140.0,
    "l\u00edpidos[g]": 19.1,
    "\u00e1cidos gordos saturados[g]": 9.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 1.64,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 12.4,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 11.2,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 12.6,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 124.0,
    "vitamina a  [\u00b5g]": 443.0,
    "caroteno [\u00b5g]": 1360.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 1.71,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.3,
    "niacina [mg]": 0.48,
    "equivalentes de niacina [mg]": 3.58,
    "triptofano/60 [mg]": 3.11,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.69,
    "vitamina c [mg]": 13.3,
    "folatos [\u00b5g]": 81.0,
    "cinza [g]": 2.32,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 270.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 43.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Quiche de vegetais",
    "energia[kcal]": 302.0,
    "energia[kj]": 1260.0,
    "l\u00edpidos[g]": 22.2,
    "\u00e1cidos gordos saturados[g]": 10.4,
    "\u00e1cidos gordos monoinsaturados [g]": 6.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.3,
    "\u00e1cido linoleico [g]": 2.05,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 12.9,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 11.0,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 11.9,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 49.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 130.0,
    "vitamina a  [\u00b5g]": 309.0,
    "caroteno [\u00b5g]": 765.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 1.22,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 3.82,
    "triptofano/60 [mg]": 2.62,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.58,
    "vitamina c [mg]": 7.1,
    "folatos [\u00b5g]": 38.0,
    "cinza [g]": 2.34,
    "s\u00f3dio [mg]": 660.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Quiche lorraine",
    "energia[kcal]": 374.0,
    "energia[kj]": 1560.0,
    "l\u00edpidos[g]": 24.5,
    "\u00e1cidos gordos saturados[g]": 11.9,
    "\u00e1cidos gordos monoinsaturados [g]": 6.6,
    "\u00e1cidos gordos polinsaturados [g]": 3.2,
    "\u00e1cido linoleico [g]": 2.89,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 23.5,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 20.9,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 14.4,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 33.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 134.0,
    "vitamina a  [\u00b5g]": 173.0,
    "caroteno [\u00b5g]": 55.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 4.43,
    "triptofano/60 [mg]": 3.14,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.68,
    "vitamina c [mg]": 0.1,
    "folatos [\u00b5g]": 9.7,
    "cinza [g]": 2.38,
    "s\u00f3dio [mg]": 540.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 240.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Quivi, \"Kiwi\"",
    "energia[kcal]": 60.0,
    "energia[kj]": 253.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.9,
    "a\u00e7\u00facares [g]": 10.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.9,
    "\u00e1cidos org\u00e2nicos [g]": 1.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 42.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 72.0,
    "folatos [\u00b5g]": 42.0,
    "cinza [g]": 0.72,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 28.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 3.7
  },
  {
    "name": "Quivi, \"Kiwi\" desidratado",
    "energia[kcal]": 333.0,
    "energia[kj]": 1410.0,
    "l\u00edpidos[g]": 2.8,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 60.6,
    "a\u00e7\u00facares [g]": 60.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 10.6,
    "prote\u00ednas [g]": 6.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.0,
    "\u00e1cidos org\u00e2nicos [g]": 6.7,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.22,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 1.67,
    "equivalentes de niacina [mg]": 2.78,
    "triptofano/60 [mg]": 1.11,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 233.3,
    "cinza [g]": 4.0,
    "s\u00f3dio [mg]": 50.0,
    "pot\u00e1ssio [mg]": 1680.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 160.0,
    "magn\u00e9sio [mg]": 100.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rabanada",
    "energia[kcal]": 294.0,
    "energia[kj]": 1230.0,
    "l\u00edpidos[g]": 16.6,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 9.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.8,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 31.3,
    "a\u00e7\u00facares [g]": 23.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 7.7,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 4.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 46.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 38.0,
    "vitamina a  [\u00b5g]": 40.0,
    "caroteno [\u00b5g]": 5.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 2.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.8,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.2,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 85.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 100.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rabanete cru",
    "energia[kcal]": 15.0,
    "energia[kj]": 64.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.9,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 95.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.5,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 16.0,
    "folatos [\u00b5g]": 38.0,
    "cinza [g]": 0.34,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 27.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "R\u00e1bano cru",
    "energia[kcal]": 88.0,
    "energia[kj]": 370.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.12,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 10.7,
    "a\u00e7\u00facares [g]": 7.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.7,
    "fibra  [g]": 7.5,
    "prote\u00ednas [g]": 6.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.55,
    "equivalentes de niacina [mg]": 1.25,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 136.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 7.0,
    "pot\u00e1ssio [mg]": 580.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 75.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.2,
    "iodo [\u00b5g]": 0.9
  },
  {
    "name": "Raia crua",
    "energia[kcal]": 58.0,
    "energia[kj]": 247.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 14.1,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 39.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 1.7,
    "equivalentes de niacina [mg]": 4.3,
    "triptofano/60 [mg]": 2.6,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.47,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 320.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rancho com carne de galinha, porco e vaca",
    "energia[kcal]": 84.0,
    "energia[kj]": 352.0,
    "l\u00edpidos[g]": 3.9,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.522,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.5,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 5.3,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 5.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 82.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 13.0,
    "vitamina a  [\u00b5g]": 92.0,
    "caroteno [\u00b5g]": 552.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.17,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 2.44,
    "triptofano/60 [mg]": 1.02,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.22,
    "vitamina c [mg]": 17.8,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 0.92,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 65.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rancho com carne de porco",
    "energia[kcal]": 107.0,
    "energia[kj]": 447.0,
    "l\u00edpidos[g]": 6.2,
    "\u00e1cidos gordos saturados[g]": 1.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.817,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.8,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 5.8,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 4.8,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.3,
    "\u00e1gua [g]": 79.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 12.0,
    "vitamina a  [\u00b5g]": 34.0,
    "caroteno [\u00b5g]": 200.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.16,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 2.12,
    "triptofano/60 [mg]": 0.91,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.16,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 34.0,
    "cinza [g]": 1.01,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 60.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rebentos de alfalfa crus",
    "energia[kcal]": 29.0,
    "energia[kj]": 123.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.24,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 4.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 16.0,
    "caroteno [\u00b5g]": 96.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 36.0,
    "cinza [g]": 0.4,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 79.0,
    "c\u00e1lcio [mg]": 32.0,
    "f\u00f3sforo [mg]": 70.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 1.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rebentos de bambu crus",
    "energia[kcal]": 22.0,
    "energia[kj]": 92.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.4,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 0.0,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 8.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.67,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.48,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.8,
    "folatos [\u00b5g]": 87.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 480.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 52.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rebentos de soja crus",
    "energia[kcal]": 98.0,
    "energia[kj]": 413.0,
    "l\u00edpidos[g]": 3.9,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 0.24,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.8,
    "a\u00e7\u00facares [g]": 3.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.5,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 9.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.06,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 1.33,
    "equivalentes de niacina [mg]": 3.48,
    "triptofano/60 [mg]": 2.15,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 17.7,
    "folatos [\u00b5g]": 166.0,
    "cinza [g]": 1.59,
    "s\u00f3dio [mg]": 22.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 50.0,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 2.0,
    "iodo [\u00b5g]": 1.2
  },
  {
    "name": "Rebu\u00e7ados",
    "energia[kcal]": 381.0,
    "energia[kj]": 1620.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 95.0,
    "a\u00e7\u00facares [g]": 95.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.1,
    "s\u00f3dio [mg]": 25.0,
    "pot\u00e1ssio [mg]": 10.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 7.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Requeij\u00e3o de cabra",
    "energia[kcal]": 197.0,
    "energia[kj]": 818.0,
    "l\u00edpidos[g]": 15.5,
    "\u00e1cidos gordos saturados[g]": 10.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 3.4,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 10.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 134.0,
    "caroteno [\u00b5g]": 54.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 1.63,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.29,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 2.35,
    "s\u00f3dio [mg]": 0.3,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 430.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Requeij\u00e3o de mistura (ovelha e cabra)",
    "energia[kcal]": 136.0,
    "energia[kj]": 566.0,
    "l\u00edpidos[g]": 10.0,
    "\u00e1cidos gordos saturados[g]": 7.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 3.7,
    "a\u00e7\u00facares [g]": 3.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 7.8,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 49.0,
    "vitamina a  [\u00b5g]": 146.0,
    "caroteno [\u00b5g]": 51.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 3.5,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Requeij\u00e3o de mistura (vaca, ovelha e cabra)",
    "energia[kcal]": 179.0,
    "energia[kj]": 747.0,
    "l\u00edpidos[g]": 11.7,
    "\u00e1cidos gordos saturados[g]": 8.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 4.9,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.7,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.6,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 49.0,
    "vitamina a  [\u00b5g]": 146.0,
    "caroteno [\u00b5g]": 51.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 3.5,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Requeij\u00e3o de ovelha",
    "energia[kcal]": 246.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 20.6,
    "\u00e1cidos gordos saturados[g]": 13.7,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 3.8,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.2,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 11.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 134.0,
    "caroteno [\u00b5g]": 54.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.48,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 1.63,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 4.9,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.29,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 2.35,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 430.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Requeij\u00e3o de vaca",
    "energia[kcal]": 149.0,
    "energia[kj]": 619.0,
    "l\u00edpidos[g]": 10.4,
    "\u00e1cidos gordos saturados[g]": 6.9,
    "\u00e1cidos gordos monoinsaturados [g]": 2.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 5.8,
    "a\u00e7\u00facares [g]": 2.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 8.0,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 49.0,
    "vitamina a  [\u00b5g]": 146.0,
    "caroteno [\u00b5g]": 51.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 3.5,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 300.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rim de carneiro, cru",
    "energia[kcal]": 92.0,
    "energia[kj]": 386.0,
    "l\u00edpidos[g]": 3.0,
    "\u00e1cidos gordos saturados[g]": 1.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.2,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 276.0,
    "vitamina a  [\u00b5g]": 100.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.37,
    "tiamina [mg]": 0.34,
    "riboflavina [mg]": 2.4,
    "niacina [mg]": 5.7,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.61,
    "vitamina b12 [\u00b5g]": 17.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 1.33,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 5.2,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rim de carneiro, frito com margarina",
    "energia[kcal]": 205.0,
    "energia[kj]": 856.0,
    "l\u00edpidos[g]": 12.9,
    "\u00e1cidos gordos saturados[g]": 6.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.6,
    "\u00e1cido linoleico [g]": 2.35,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.3,
    "sal  [g]": 1.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 379.0,
    "vitamina a  [\u00b5g]": 203.0,
    "caroteno [\u00b5g]": 44.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.51,
    "tiamina [mg]": 0.4,
    "riboflavina [mg]": 3.1,
    "niacina [mg]": 6.7,
    "equivalentes de niacina [mg]": 12.6,
    "triptofano/60 [mg]": 4.81,
    "vitamina b6 [mg]": 0.67,
    "vitamina b12 [\u00b5g]": 18.0,
    "vitamina c [mg]": 12.4,
    "folatos [\u00b5g]": 9.3,
    "cinza [g]": 3.13,
    "s\u00f3dio [mg]": 700.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 6.8,
    "zinco [mg]": 2.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rim de porco, cru",
    "energia[kcal]": 95.0,
    "energia[kj]": 400.0,
    "l\u00edpidos[g]": 3.0,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.0,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 395.0,
    "vitamina a  [\u00b5g]": 110.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.44,
    "tiamina [mg]": 0.53,
    "riboflavina [mg]": 2.8,
    "niacina [mg]": 8.0,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.43,
    "vitamina b12 [\u00b5g]": 44.0,
    "vitamina c [mg]": 10.0,
    "folatos [\u00b5g]": 43.0,
    "cinza [g]": 1.34,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 5.0,
    "zinco [mg]": 2.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rim de porco, frito com margarina",
    "energia[kcal]": 206.0,
    "energia[kj]": 860.0,
    "l\u00edpidos[g]": 12.4,
    "\u00e1cidos gordos saturados[g]": 5.6,
    "\u00e1cidos gordos monoinsaturados [g]": 3.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.0,
    "\u00e1cido linoleico [g]": 2.62,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.6,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 548.0,
    "vitamina a  [\u00b5g]": 214.0,
    "caroteno [\u00b5g]": 41.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.61,
    "tiamina [mg]": 0.62,
    "riboflavina [mg]": 3.7,
    "niacina [mg]": 9.4,
    "equivalentes de niacina [mg]": 16.6,
    "triptofano/60 [mg]": 4.99,
    "vitamina b6 [mg]": 0.48,
    "vitamina b12 [\u00b5g]": 46.0,
    "vitamina c [mg]": 10.4,
    "folatos [\u00b5g]": 51.0,
    "cinza [g]": 2.51,
    "s\u00f3dio [mg]": 560.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 330.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 6.6,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rim de vaca, cru",
    "energia[kcal]": 92.0,
    "energia[kj]": 387.0,
    "l\u00edpidos[g]": 2.2,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.0,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 213.0,
    "vitamina a  [\u00b5g]": 105.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.55,
    "tiamina [mg]": 0.48,
    "riboflavina [mg]": 0.84,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 3.8,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 14.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 1.35,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 3.3,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rissol",
    "energia[kcal]": 280.0,
    "energia[kj]": 1170.0,
    "l\u00edpidos[g]": 13.4,
    "\u00e1cidos gordos saturados[g]": 3.4,
    "\u00e1cidos gordos monoinsaturados [g]": 3.9,
    "\u00e1cidos gordos polinsaturados [g]": 4.1,
    "\u00e1cido linoleico [g]": 4.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 31.8,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 30.7,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 7.3,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 45.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 66.0,
    "vitamina a  [\u00b5g]": 29.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 2.4,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 3.7,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.18,
    "s\u00f3dio [mg]": 640.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 31.0,
    "f\u00f3sforo [mg]": 98.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rissol de camar\u00e3o",
    "energia[kcal]": 211.0,
    "energia[kj]": 885.0,
    "l\u00edpidos[g]": 8.6,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 5.4,
    "\u00e1cido linoleico [g]": 5.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 26.6,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 25.3,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 6.1,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 21.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 5.2,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 1.9,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.16,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 9.1,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 87.0,
    "c\u00e1lcio [mg]": 48.0,
    "f\u00f3sforo [mg]": 82.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rissol de carne com manteiga",
    "energia[kcal]": 238.0,
    "energia[kj]": 994.0,
    "l\u00edpidos[g]": 14.1,
    "\u00e1cidos gordos saturados[g]": 6.7,
    "\u00e1cidos gordos monoinsaturados [g]": 4.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.807,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 19.8,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 17.1,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 7.3,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 72.0,
    "vitamina a  [\u00b5g]": 98.0,
    "caroteno [\u00b5g]": 126.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 1.09,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 2.78,
    "triptofano/60 [mg]": 1.53,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.45,
    "vitamina c [mg]": 7.3,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.17,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rissol de carne com margarina",
    "energia[kcal]": 236.0,
    "energia[kj]": 985.0,
    "l\u00edpidos[g]": 13.9,
    "\u00e1cidos gordos saturados[g]": 5.9,
    "\u00e1cidos gordos monoinsaturados [g]": 4.1,
    "\u00e1cidos gordos polinsaturados [g]": 3.1,
    "\u00e1cido linoleico [g]": 2.81,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 19.7,
    "a\u00e7\u00facares [g]": 2.5,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 17.1,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 7.3,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 56.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 46.0,
    "vitamina a  [\u00b5g]": 102.0,
    "caroteno [\u00b5g]": 166.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.87,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 2.78,
    "triptofano/60 [mg]": 1.53,
    "vitamina b6 [mg]": 0.22,
    "vitamina b12 [\u00b5g]": 0.45,
    "vitamina c [mg]": 7.3,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 1.32,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Robalo assado com cebola, azeite, banha e manteiga",
    "energia[kcal]": 192.0,
    "energia[kj]": 802.0,
    "l\u00edpidos[g]": 11.8,
    "\u00e1cidos gordos saturados[g]": 3.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 0.613,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.3,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 20.4,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.2,
    "\u00e1gua [g]": 64.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 62.0,
    "vitamina a  [\u00b5g]": 51.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 4.8,
    "alfa-tocoferol [mg]": 0.36,
    "tiamina [mg]": 0.22,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 6.88,
    "triptofano/60 [mg]": 3.86,
    "vitamina b6 [mg]": 0.5,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.8,
    "folatos [\u00b5g]": 9.5,
    "cinza [g]": 2.68,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 60.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Robalo cozido",
    "energia[kcal]": 182.0,
    "energia[kj]": 760.0,
    "l\u00edpidos[g]": 10.9,
    "\u00e1cidos gordos saturados[g]": 2.2,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 3.4,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 87.0,
    "vitamina a  [\u00b5g]": 28.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.8,
    "alfa-tocoferol [mg]": 0.34,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 2.6,
    "equivalentes de niacina [mg]": 6.5,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 1.1,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.3,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 74.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Robalo cru",
    "energia[kcal]": 106.0,
    "energia[kj]": 444.0,
    "l\u00edpidos[g]": 3.5,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.18,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 52.0,
    "vitamina a  [\u00b5g]": 36.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 5.0,
    "alfa-tocoferol [mg]": 0.17,
    "tiamina [mg]": 0.26,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 2.7,
    "equivalentes de niacina [mg]": 6.2,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.49,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.5,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 95.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 52.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Robalo grelhado",
    "energia[kcal]": 209.0,
    "energia[kj]": 872.0,
    "l\u00edpidos[g]": 13.0,
    "\u00e1cidos gordos saturados[g]": 2.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.5,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 1.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 59.0,
    "vitamina a  [\u00b5g]": 35.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 6.7,
    "alfa-tocoferol [mg]": 0.21,
    "tiamina [mg]": 0.21,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 7.8,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 1.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 9.3,
    "cinza [g]": 3.6,
    "s\u00f3dio [mg]": 560.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 120.0,
    "f\u00f3sforo [mg]": 440.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 18.0
  },
  {
    "name": "Roj\u00f5es de carne gorda",
    "energia[kcal]": 244.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 19.0,
    "\u00e1cidos gordos saturados[g]": 6.1,
    "\u00e1cidos gordos monoinsaturados [g]": 7.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.8,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.6,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 14.9,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 1.3,
    "\u00e1gua [g]": 61.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 134.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.19,
    "tiamina [mg]": 0.64,
    "riboflavina [mg]": 0.2,
    "niacina [mg]": 6.1,
    "equivalentes de niacina [mg]": 8.97,
    "triptofano/60 [mg]": 2.72,
    "vitamina b6 [mg]": 0.37,
    "vitamina b12 [\u00b5g]": 0.8,
    "vitamina c [mg]": 9.9,
    "folatos [\u00b5g]": 7.8,
    "cinza [g]": 1.64,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Roj\u00f5es de carne magra",
    "energia[kcal]": 140.0,
    "energia[kj]": 585.0,
    "l\u00edpidos[g]": 5.6,
    "\u00e1cidos gordos saturados[g]": 1.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.652,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.7,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.1,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 11.1,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 1.1,
    "\u00e1gua [g]": 71.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 29.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 1.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.31,
    "tiamina [mg]": 0.41,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 5.38,
    "triptofano/60 [mg]": 2.37,
    "vitamina b6 [mg]": 0.4,
    "vitamina b12 [\u00b5g]": 0.45,
    "vitamina c [mg]": 8.6,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 1.49,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 9.8,
    "f\u00f3sforo [mg]": 120.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Rom\u00e3",
    "energia[kcal]": 60.0,
    "energia[kj]": 254.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.0,
    "a\u00e7\u00facares [g]": 12.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.4,
    "prote\u00ednas [g]": 0.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 38.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.9,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 13.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 0.51,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 14.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "R\u00facula crua",
    "energia[kcal]": 29.0,
    "energia[kj]": 120.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.2,
    "a\u00e7\u00facares [g]": 2.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 308.0,
    "caroteno [\u00b5g]": 1848.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 15.0,
    "folatos [\u00b5g]": 97.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 27.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 160.0,
    "f\u00f3sforo [mg]": 52.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Safio cru",
    "energia[kcal]": 109.0,
    "energia[kj]": 456.0,
    "l\u00edpidos[g]": 4.2,
    "\u00e1cidos gordos saturados[g]": 1.2,
    "\u00e1cidos gordos monoinsaturados [g]": 2.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 49.0,
    "vitamina a  [\u00b5g]": 267.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 91.0,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.5,
    "equivalentes de niacina [mg]": 4.8,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.55,
    "vitamina b12 [\u00b5g]": 0.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 84.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sal",
    "energia[kcal]": 0.0,
    "energia[kj]": 0.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 100.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 98.0,
    "s\u00f3dio [mg]": 40000.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 300.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salada de alface e tomate temperada com azeite e vinagre",
    "energia[kcal]": 30.0,
    "energia[kj]": 125.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.201,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 93.0,
    "caroteno [\u00b5g]": 556.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.14,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.53,
    "equivalentes de niacina [mg]": 0.62,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 14.6,
    "folatos [\u00b5g]": 29.0,
    "cinza [g]": 1.02,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 29.0,
    "f\u00f3sforo [mg]": 26.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salada de atum",
    "energia[kcal]": 104.0,
    "energia[kj]": 436.0,
    "l\u00edpidos[g]": 4.3,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.33,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.4,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 7.5,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 6.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 63.0,
    "vitamina a  [\u00b5g]": 77.0,
    "caroteno [\u00b5g]": 281.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.99,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 3.59,
    "triptofano/60 [mg]": 1.5,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 0.49,
    "vitamina c [mg]": 25.4,
    "folatos [\u00b5g]": 41.0,
    "cinza [g]": 0.882,
    "s\u00f3dio [mg]": 88.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 35.0,
    "f\u00f3sforo [mg]": 85.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salada de bacalhau com gr\u00e3o",
    "energia[kcal]": 115.0,
    "energia[kj]": 481.0,
    "l\u00edpidos[g]": 4.9,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.745,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.6,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 6.3,
    "fibra  [g]": 2.8,
    "prote\u00ednas [g]": 8.7,
    "sal  [g]": 1.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 13.0,
    "vitamina a  [\u00b5g]": 29.0,
    "caroteno [\u00b5g]": 169.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 1.15,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.65,
    "equivalentes de niacina [mg]": 2.05,
    "triptofano/60 [mg]": 1.39,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.24,
    "vitamina c [mg]": 10.6,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 2.29,
    "s\u00f3dio [mg]": 730.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 46.0,
    "f\u00f3sforo [mg]": 75.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salada russa",
    "energia[kcal]": 116.0,
    "energia[kj]": 478.0,
    "l\u00edpidos[g]": 9.3,
    "\u00e1cidos gordos saturados[g]": 1.4,
    "\u00e1cidos gordos monoinsaturados [g]": 7.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.622,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.6,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.1,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 1.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 16.0,
    "vitamina a  [\u00b5g]": 158.0,
    "caroteno [\u00b5g]": 901.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 1.38,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.85,
    "equivalentes de niacina [mg]": 1.2,
    "triptofano/60 [mg]": 0.35,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.04,
    "vitamina c [mg]": 12.6,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 0.682,
    "s\u00f3dio [mg]": 35.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 26.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salame",
    "energia[kcal]": 422.0,
    "energia[kj]": 1740.0,
    "l\u00edpidos[g]": 37.6,
    "\u00e1cidos gordos saturados[g]": 12.9,
    "\u00e1cidos gordos monoinsaturados [g]": 14.9,
    "\u00e1cidos gordos polinsaturados [g]": 4.3,
    "\u00e1cido linoleico [g]": 3.7,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.3,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.5,
    "sal  [g]": 5.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 38.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 80.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.36,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 6.6,
    "triptofano/60 [mg]": 3.6,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 5.5,
    "s\u00f3dio [mg]": 2300.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 4.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salic\u00f3rnia fresca",
    "energia[kcal]": 14.0,
    "energia[kj]": 59.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.1,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.5,
    "prote\u00ednas [g]": 0.7,
    "sal  [g]": 3.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.15,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.9,
    "folatos [\u00b5g]": 200.0,
    "cinza [g]": 3.34,
    "s\u00f3dio [mg]": 1190.0,
    "pot\u00e1ssio [mg]": 1060.0,
    "c\u00e1lcio [mg]": 270.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 75.0,
    "ferro [mg]": 20.0,
    "zinco [mg]": 3.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salm\u00e3o cozido",
    "energia[kcal]": 273.0,
    "energia[kj]": 1130.0,
    "l\u00edpidos[g]": 21.1,
    "\u00e1cidos gordos saturados[g]": 4.0,
    "\u00e1cidos gordos monoinsaturados [g]": 7.8,
    "\u00e1cidos gordos polinsaturados [g]": 6.6,
    "\u00e1cido linoleico [g]": 0.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.7,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 45.0,
    "vitamina a  [\u00b5g]": 65.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 11.0,
    "alfa-tocoferol [mg]": 5.3,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 6.9,
    "triptofano/60 [mg]": 3.9,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 1.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.4,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 61.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 12.0
  },
  {
    "name": "Salm\u00e3o cru",
    "energia[kcal]": 262.0,
    "energia[kj]": 1090.0,
    "l\u00edpidos[g]": 21.9,
    "\u00e1cidos gordos saturados[g]": 4.2,
    "\u00e1cidos gordos monoinsaturados [g]": 10.0,
    "\u00e1cidos gordos polinsaturados [g]": 5.1,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.2,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 40.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 11.0,
    "alfa-tocoferol [mg]": 4.0,
    "tiamina [mg]": 0.18,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 3.6,
    "equivalentes de niacina [mg]": 6.6,
    "triptofano/60 [mg]": 3.0,
    "vitamina b6 [mg]": 0.45,
    "vitamina b12 [\u00b5g]": 1.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 38.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salm\u00e3o grelhado",
    "energia[kcal]": 309.0,
    "energia[kj]": 1280.0,
    "l\u00edpidos[g]": 23.7,
    "\u00e1cidos gordos saturados[g]": 4.5,
    "\u00e1cidos gordos monoinsaturados [g]": 8.7,
    "\u00e1cidos gordos polinsaturados [g]": 7.3,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 23.8,
    "sal  [g]": 2.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 49.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 52.0,
    "vitamina a  [\u00b5g]": 70.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 9.2,
    "alfa-tocoferol [mg]": 4.3,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 4.4,
    "equivalentes de niacina [mg]": 8.8,
    "triptofano/60 [mg]": 4.4,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 780.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 68.0,
    "f\u00f3sforo [mg]": 320.0,
    "magn\u00e9sio [mg]": 40.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 12.0
  },
  {
    "name": "Salmonete cru",
    "energia[kcal]": 136.0,
    "energia[kj]": 573.0,
    "l\u00edpidos[g]": 5.5,
    "\u00e1cidos gordos saturados[g]": 0.7,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.25,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 71.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 64.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.3,
    "alfa-tocoferol [mg]": 2.1,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 2.79,
    "equivalentes de niacina [mg]": 6.49,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 3.74,
    "vitamina c [mg]": 1.6,
    "folatos [\u00b5g]": 14.3,
    "cinza [g]": 1.36,
    "s\u00f3dio [mg]": 96.0,
    "pot\u00e1ssio [mg]": 410.0,
    "c\u00e1lcio [mg]": 23.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salpic\u00e3o",
    "energia[kcal]": 412.0,
    "energia[kj]": 1710.0,
    "l\u00edpidos[g]": 36.7,
    "\u00e1cidos gordos saturados[g]": 12.6,
    "\u00e1cidos gordos monoinsaturados [g]": 14.5,
    "\u00e1cidos gordos polinsaturados [g]": 4.2,
    "\u00e1cido linoleico [g]": 3.6,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.5,
    "sal  [g]": 11.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 32.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 84.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 9.4,
    "triptofano/60 [mg]": 4.4,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 9.5,
    "s\u00f3dio [mg]": 4340.0,
    "pot\u00e1ssio [mg]": 580.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salsa fresca",
    "energia[kcal]": 20.0,
    "energia[kj]": 83.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.9,
    "prote\u00ednas [g]": 3.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 558.0,
    "caroteno [\u00b5g]": 3350.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.9,
    "tiamina [mg]": 0.28,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 220.0,
    "folatos [\u00b5g]": 170.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 34.0,
    "pot\u00e1ssio [mg]": 750.0,
    "c\u00e1lcio [mg]": 200.0,
    "f\u00f3sforo [mg]": 91.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 3.2,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salsa seca",
    "energia[kcal]": 244.0,
    "energia[kj]": 1010.0,
    "l\u00edpidos[g]": 7.0,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 2.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.5,
    "a\u00e7\u00facares [g]": 12.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.9,
    "fibra  [g]": 29.7,
    "prote\u00ednas [g]": 15.8,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 9.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3625.0,
    "caroteno [\u00b5g]": 21750.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 9.15,
    "tiamina [mg]": 0.33,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 5.4,
    "equivalentes de niacina [mg]": 7.9,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 120.0,
    "folatos [\u00b5g]": 167.0,
    "cinza [g]": 5.86,
    "s\u00f3dio [mg]": 180.0,
    "pot\u00e1ssio [mg]": 4090.0,
    "c\u00e1lcio [mg]": 1080.0,
    "f\u00f3sforo [mg]": 340.0,
    "magn\u00e9sio [mg]": 120.0,
    "ferro [mg]": 42.0,
    "zinco [mg]": 3.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salsicha de soja",
    "energia[kcal]": 252.0,
    "energia[kj]": 1040.0,
    "l\u00edpidos[g]": 20.9,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 6.9,
    "\u00e1cidos gordos polinsaturados [g]": 10.5,
    "\u00e1cido linoleico [g]": 10.2,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.6,
    "prote\u00ednas [g]": 13.5,
    "sal  [g]": 1.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 59.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 24.0,
    "caroteno [\u00b5g]": 35.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 8.74,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 4.48,
    "triptofano/60 [mg]": 4.48,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 20.3,
    "folatos [\u00b5g]": 48.5,
    "cinza [g]": 1.95,
    "s\u00f3dio [mg]": 640.0,
    "pot\u00e1ssio [mg]": 59.0,
    "c\u00e1lcio [mg]": 43.0,
    "f\u00f3sforo [mg]": 87.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salsicha fresca crua",
    "energia[kcal]": 210.0,
    "energia[kj]": 870.0,
    "l\u00edpidos[g]": 16.4,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 5.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.1,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 15.5,
    "sal  [g]": 1.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 45.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 3.0,
    "equivalentes de niacina [mg]": 5.4,
    "triptofano/60 [mg]": 2.4,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 700.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 3.2,
    "zinco [mg]": 1.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salsicha fresca estufada com couve e azeite",
    "energia[kcal]": 140.0,
    "energia[kj]": 581.0,
    "l\u00edpidos[g]": 11.6,
    "\u00e1cidos gordos saturados[g]": 3.6,
    "\u00e1cidos gordos monoinsaturados [g]": 4.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 1.1,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 7.2,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 21.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 361.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 2.44,
    "triptofano/60 [mg]": 0.98,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.28,
    "vitamina c [mg]": 19.1,
    "folatos [\u00b5g]": 40.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 96.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salsicha fresca estufada com legumes e azeite",
    "energia[kcal]": 121.0,
    "energia[kj]": 504.0,
    "l\u00edpidos[g]": 8.9,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.774,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.7,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 2.5,
    "fibra  [g]": 1.4,
    "prote\u00ednas [g]": 5.9,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 16.0,
    "vitamina a  [\u00b5g]": 106.0,
    "caroteno [\u00b5g]": 633.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.35,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.22,
    "triptofano/60 [mg]": 0.84,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.22,
    "vitamina c [mg]": 16.6,
    "folatos [\u00b5g]": 35.0,
    "cinza [g]": 1.73,
    "s\u00f3dio [mg]": 320.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 82.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Salsicha tipo Frankfurt",
    "energia[kcal]": 178.0,
    "energia[kj]": 739.0,
    "l\u00edpidos[g]": 14.7,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 5.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.2,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 9.0,
    "sal  [g]": 2.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 70.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 46.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.5,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.75,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.6,
    "s\u00f3dio [mg]": 1010.0,
    "pot\u00e1ssio [mg]": 61.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 99.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Salsicha tipo Frankfurt frita (escorrido o \u00f3leo) sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 201.0,
    "energia[kj]": 832.0,
    "l\u00edpidos[g]": 16.5,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 6.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.8,
    "a\u00e7\u00facares [g]": 1.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.4,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 10.2,
    "sal  [g]": 2.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 52.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 1.1,
    "equivalentes de niacina [mg]": 2.7,
    "triptofano/60 [mg]": 1.6,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 2.7,
    "s\u00f3dio [mg]": 1050.0,
    "pot\u00e1ssio [mg]": 60.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Salsicha tipo Frankfurt grelhada sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 211.0,
    "energia[kj]": 877.0,
    "l\u00edpidos[g]": 16.7,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 6.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.2,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.6,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 12.0,
    "sal  [g]": 3.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 57.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 1.3,
    "equivalentes de niacina [mg]": 3.2,
    "triptofano/60 [mg]": 1.9,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 3.1,
    "s\u00f3dio [mg]": 1210.0,
    "pot\u00e1ssio [mg]": 69.0,
    "c\u00e1lcio [mg]": 19.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 13.0
  },
  {
    "name": "Sangue de porco, cru",
    "energia[kcal]": 72.0,
    "energia[kj]": 304.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.09,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.4,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.6,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 81.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 40.0,
    "vitamina a  [\u00b5g]": 28.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 3.64,
    "triptofano/60 [mg]": 3.04,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.6,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 210.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 62.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 40.0,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sapateira crua",
    "energia[kcal]": 104.0,
    "energia[kj]": 438.0,
    "l\u00edpidos[g]": 2.9,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 0.047,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.5,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 78.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.4,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 6.4,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.21,
    "vitamina b12 [\u00b5g]": 9.55,
    "vitamina c [mg]": 2.5,
    "folatos [\u00b5g]": 25.9,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 84.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 43.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 7.7,
    "sel\u00e9nio [\u00b5g]": 82.0,
    "iodo [\u00b5g]": 60.0
  },
  {
    "name": "Sarda cozida",
    "energia[kcal]": 174.0,
    "energia[kj]": 727.0,
    "l\u00edpidos[g]": 10.6,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 3.1,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.7,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 48.0,
    "vitamina a  [\u00b5g]": 48.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 6.9,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 4.3,
    "equivalentes de niacina [mg]": 8.0,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.32,
    "vitamina b12 [\u00b5g]": 7.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 330.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 60.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 2.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sarda crua",
    "energia[kcal]": 181.0,
    "energia[kj]": 756.0,
    "l\u00edpidos[g]": 11.7,
    "\u00e1cidos gordos saturados[g]": 3.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.8,
    "\u00e1cidos gordos polinsaturados [g]": 3.5,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 45.0,
    "vitamina a  [\u00b5g]": 64.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 9.3,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 9.3,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.43,
    "vitamina b12 [\u00b5g]": 8.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 76.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 56.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sarda grelhada",
    "energia[kcal]": 191.0,
    "energia[kj]": 797.0,
    "l\u00edpidos[g]": 11.2,
    "\u00e1cidos gordos saturados[g]": 3.0,
    "\u00e1cidos gordos monoinsaturados [g]": 3.6,
    "\u00e1cidos gordos polinsaturados [g]": 3.4,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.5,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 64.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 53.0,
    "vitamina a  [\u00b5g]": 64.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 9.3,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.22,
    "niacina [mg]": 6.5,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 4.2,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 8.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 390.0,
    "c\u00e1lcio [mg]": 70.0,
    "f\u00f3sforo [mg]": 310.0,
    "magn\u00e9sio [mg]": 33.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 2.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sardinha frita",
    "energia[kcal]": 300.0,
    "energia[kj]": 1250.0,
    "l\u00edpidos[g]": 19.4,
    "\u00e1cidos gordos saturados[g]": 5.5,
    "\u00e1cidos gordos monoinsaturados [g]": 4.7,
    "\u00e1cidos gordos polinsaturados [g]": 6.6,
    "\u00e1cido linoleico [g]": 0.634,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 8.7,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.5,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 22.5,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 46.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 24.0,
    "vitamina a  [\u00b5g]": 55.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 25.0,
    "alfa-tocoferol [mg]": 0.81,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 5.3,
    "equivalentes de niacina [mg]": 9.47,
    "triptofano/60 [mg]": 4.18,
    "vitamina b6 [mg]": 0.69,
    "vitamina b12 [\u00b5g]": 12.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 2.84,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 88.0,
    "f\u00f3sforo [mg]": 380.0,
    "magn\u00e9sio [mg]": 43.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sardinha gorda crua",
    "energia[kcal]": 221.0,
    "energia[kj]": 920.0,
    "l\u00edpidos[g]": 16.4,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 4.0,
    "\u00e1cidos gordos polinsaturados [g]": 5.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.4,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 20.0,
    "vitamina a  [\u00b5g]": 47.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 21.0,
    "alfa-tocoferol [mg]": 0.66,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 4.4,
    "equivalentes de niacina [mg]": 7.8,
    "triptofano/60 [mg]": 3.4,
    "vitamina b6 [mg]": 0.57,
    "vitamina b12 [\u00b5g]": 10.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 65.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 72.0,
    "f\u00f3sforo [mg]": 310.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sardinha gorda frita",
    "energia[kcal]": 247.0,
    "energia[kj]": 1030.0,
    "l\u00edpidos[g]": 15.6,
    "\u00e1cidos gordos saturados[g]": 4.5,
    "\u00e1cidos gordos monoinsaturados [g]": 3.0,
    "\u00e1cidos gordos polinsaturados [g]": 6.5,
    "\u00e1cido linoleico [g]": 2.3,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.5,
    "fibra  [g]": 0.1,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 55.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 39.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 20.0,
    "alfa-tocoferol [mg]": 2.4,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.08,
    "niacina [mg]": 4.9,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 4.3,
    "vitamina b6 [mg]": 0.56,
    "vitamina b12 [\u00b5g]": 11.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.3,
    "s\u00f3dio [mg]": 360.0,
    "pot\u00e1ssio [mg]": 380.0,
    "c\u00e1lcio [mg]": 88.0,
    "f\u00f3sforo [mg]": 350.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 21.0
  },
  {
    "name": "Sardinha gorda grelhada",
    "energia[kcal]": 211.0,
    "energia[kj]": 880.0,
    "l\u00edpidos[g]": 12.3,
    "\u00e1cidos gordos saturados[g]": 4.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 4.5,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.0,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 58.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 43.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 23.0,
    "alfa-tocoferol [mg]": 0.71,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 5.3,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 4.7,
    "vitamina b6 [mg]": 0.61,
    "vitamina b12 [\u00b5g]": 12.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 97.0,
    "f\u00f3sforo [mg]": 380.0,
    "magn\u00e9sio [mg]": 38.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 2.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 21.0
  },
  {
    "name": "Sardinha meio gorda conserva em \u00e1gua, sem espinha e sem pele",
    "energia[kcal]": 174.0,
    "energia[kj]": 729.0,
    "l\u00edpidos[g]": 9.6,
    "\u00e1cidos gordos saturados[g]": 2.8,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 3.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 22.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 65.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 9.8,
    "alfa-tocoferol [mg]": 2.2,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 5.5,
    "equivalentes de niacina [mg]": 9.6,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 13.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 46.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 2.6,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 24.0
  },
  {
    "name": "Sardinha meio gorda conserva em azeite",
    "energia[kcal]": 204.0,
    "energia[kj]": 852.0,
    "l\u00edpidos[g]": 12.0,
    "\u00e1cidos gordos saturados[g]": 3.0,
    "\u00e1cidos gordos monoinsaturados [g]": 5.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.0,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 65.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 8.8,
    "alfa-tocoferol [mg]": 1.5,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 6.0,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 14.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 3.2,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 450.0,
    "f\u00f3sforo [mg]": 640.0,
    "magn\u00e9sio [mg]": 42.0,
    "ferro [mg]": 3.0,
    "zinco [mg]": 2.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 24.0
  },
  {
    "name": "Sardinha meio gorda conserva em azeite (escorrido)",
    "energia[kcal]": 186.0,
    "energia[kj]": 780.0,
    "l\u00edpidos[g]": 9.0,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 5.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.1,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.3,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 62.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 60.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 7.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 4.9,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 13.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 170.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 470.0,
    "f\u00f3sforo [mg]": 640.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 3.1,
    "zinco [mg]": 2.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 24.0
  },
  {
    "name": "Sardinha meio gorda crua",
    "energia[kcal]": 158.0,
    "energia[kj]": 658.0,
    "l\u00edpidos[g]": 9.1,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.2,
    "\u00e1cidos gordos polinsaturados [g]": 3.3,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 28.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 17.0,
    "alfa-tocoferol [mg]": 0.25,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 6.2,
    "equivalentes de niacina [mg]": 9.7,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.41,
    "vitamina b12 [\u00b5g]": 10.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 24.0,
    "cinza [g]": 1.6,
    "s\u00f3dio [mg]": 65.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 70.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sardinha meio gorda frita",
    "energia[kcal]": 174.0,
    "energia[kj]": 726.0,
    "l\u00edpidos[g]": 10.2,
    "\u00e1cidos gordos saturados[g]": 2.3,
    "\u00e1cidos gordos monoinsaturados [g]": 2.3,
    "\u00e1cidos gordos polinsaturados [g]": 4.4,
    "\u00e1cido linoleico [g]": 1.7,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.5,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.5,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.0,
    "sal  [g]": 0.8,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 29.0,
    "vitamina a  [\u00b5g]": 11.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 16.0,
    "alfa-tocoferol [mg]": 2.1,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 5.8,
    "equivalentes de niacina [mg]": 9.5,
    "triptofano/60 [mg]": 3.7,
    "vitamina b6 [mg]": 0.34,
    "vitamina b12 [\u00b5g]": 9.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 2.5,
    "s\u00f3dio [mg]": 310.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 73.0,
    "f\u00f3sforo [mg]": 280.0,
    "magn\u00e9sio [mg]": 28.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 21.0
  },
  {
    "name": "Sardinha meio gorda grelhada",
    "energia[kcal]": 168.0,
    "energia[kj]": 707.0,
    "l\u00edpidos[g]": 7.2,
    "\u00e1cidos gordos saturados[g]": 2.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 25.9,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 38.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 11.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 8.4,
    "equivalentes de niacina [mg]": 13.0,
    "triptofano/60 [mg]": 4.8,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 9.3,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 31.0,
    "cinza [g]": 3.1,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 500.0,
    "c\u00e1lcio [mg]": 67.0,
    "f\u00f3sforo [mg]": 310.0,
    "magn\u00e9sio [mg]": 35.0,
    "ferro [mg]": 1.9,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 21.0
  },
  {
    "name": "Seitan",
    "energia[kcal]": 139.0,
    "energia[kj]": 587.0,
    "l\u00edpidos[g]": 1.7,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.4,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.1,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 25.0,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.51,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 5.2,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.7,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 30.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 53.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sementes de ab\u00f3bora, cruas, secas, miolo",
    "energia[kcal]": 567.0,
    "energia[kj]": 2350.0,
    "l\u00edpidos[g]": 46.4,
    "\u00e1cidos gordos saturados[g]": 8.5,
    "\u00e1cidos gordos monoinsaturados [g]": 15.3,
    "\u00e1cidos gordos polinsaturados [g]": 20.4,
    "\u00e1cido linoleico [g]": 19.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 10.0,
    "a\u00e7\u00facares [g]": 2.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 7.7,
    "fibra  [g]": 10.8,
    "prote\u00ednas [g]": 22.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 38.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.25,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 1.35,
    "equivalentes de niacina [mg]": 8.45,
    "triptofano/60 [mg]": 7.1,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 58.0,
    "cinza [g]": 4.73,
    "s\u00f3dio [mg]": 13.0,
    "pot\u00e1ssio [mg]": 810.0,
    "c\u00e1lcio [mg]": 66.0,
    "f\u00f3sforo [mg]": 1040.0,
    "magn\u00e9sio [mg]": 480.0,
    "ferro [mg]": 7.8,
    "zinco [mg]": 4.1,
    "sel\u00e9nio [\u00b5g]": 14.0,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Sementes de c\u00e2nhamo secas",
    "energia[kcal]": 424.0,
    "energia[kj]": 1760.0,
    "l\u00edpidos[g]": 27.9,
    "\u00e1cidos gordos saturados[g]": 2.9,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 19.4,
    "\u00e1cido linoleico [g]": 15.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 22.7,
    "prote\u00ednas [g]": 29.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 5.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 20.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.8,
    "tiamina [mg]": 0.35,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 8.45,
    "triptofano/60 [mg]": 6.15,
    "vitamina b6 [mg]": 0.39,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 81.0,
    "cinza [g]": 5.4,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 1100.0,
    "magn\u00e9sio [mg]": 390.0,
    "ferro [mg]": 13.0,
    "zinco [mg]": 6.0,
    "sel\u00e9nio [\u00b5g]": 4.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sementes de chia",
    "energia[kcal]": 456.0,
    "energia[kj]": 1880.0,
    "l\u00edpidos[g]": 34.4,
    "\u00e1cidos gordos saturados[g]": 3.6,
    "\u00e1cidos gordos monoinsaturados [g]": 2.7,
    "\u00e1cidos gordos polinsaturados [g]": 26.5,
    "\u00e1cido linoleico [g]": 6.3,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 4.6,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.4,
    "fibra  [g]": 35.1,
    "prote\u00ednas [g]": 14.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.5,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 1.6,
    "equivalentes de niacina [mg]": 8.87,
    "triptofano/60 [mg]": 7.27,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.6,
    "folatos [\u00b5g]": 49.0,
    "cinza [g]": 4.66,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 710.0,
    "c\u00e1lcio [mg]": 580.0,
    "f\u00f3sforo [mg]": 760.0,
    "magn\u00e9sio [mg]": 320.0,
    "ferro [mg]": 7.7,
    "zinco [mg]": 6.3,
    "sel\u00e9nio [\u00b5g]": 55.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sementes de coentro",
    "energia[kcal]": 358.0,
    "energia[kj]": 1480.0,
    "l\u00edpidos[g]": 17.8,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 13.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 16.2,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 16.2,
    "fibra  [g]": 41.9,
    "prote\u00ednas [g]": 12.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 8.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 3.33,
    "tiamina [mg]": 0.24,
    "riboflavina [mg]": 0.29,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 10.6,
    "triptofano/60 [mg]": 8.5,
    "vitamina b6 [mg]": 0.44,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 21.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.78,
    "s\u00f3dio [mg]": 35.0,
    "pot\u00e1ssio [mg]": 1270.0,
    "c\u00e1lcio [mg]": 710.0,
    "f\u00f3sforo [mg]": 410.0,
    "magn\u00e9sio [mg]": 330.0,
    "ferro [mg]": 16.0,
    "zinco [mg]": 4.7,
    "sel\u00e9nio [\u00b5g]": 26.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sementes de girassol, cruas, secas, miolo",
    "energia[kcal]": 585.0,
    "energia[kj]": 2420.0,
    "l\u00edpidos[g]": 48.5,
    "\u00e1cidos gordos saturados[g]": 5.3,
    "\u00e1cidos gordos monoinsaturados [g]": 15.6,
    "\u00e1cidos gordos polinsaturados [g]": 25.3,
    "\u00e1cido linoleico [g]": 25.3,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 8.3,
    "a\u00e7\u00facares [g]": 4.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.0,
    "fibra  [g]": 13.5,
    "prote\u00ednas [g]": 22.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 4.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 4.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 37.2,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 1.18,
    "equivalentes de niacina [mg]": 6.18,
    "triptofano/60 [mg]": 5.0,
    "vitamina b6 [mg]": 0.26,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 97.0,
    "cinza [g]": 3.25,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 670.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 670.0,
    "magn\u00e9sio [mg]": 300.0,
    "ferro [mg]": 4.5,
    "zinco [mg]": 5.9,
    "sel\u00e9nio [\u00b5g]": 13.0,
    "iodo [\u00b5g]": 4.7
  },
  {
    "name": "Sementes de linha\u00e7a cruas",
    "energia[kcal]": 487.0,
    "energia[kj]": 2020.0,
    "l\u00edpidos[g]": 31.0,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.5,
    "\u00e1cidos gordos polinsaturados [g]": 21.0,
    "\u00e1cido linoleico [g]": 6.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.1,
    "a\u00e7\u00facares [g]": 5.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 10.7,
    "fibra  [g]": 18.0,
    "prote\u00ednas [g]": 25.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 4.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 8.2,
    "tiamina [mg]": 0.79,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 7.9,
    "equivalentes de niacina [mg]": 14.57,
    "triptofano/60 [mg]": 6.67,
    "vitamina b6 [mg]": 0.79,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 97.0,
    "cinza [g]": 3.0,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 470.0,
    "c\u00e1lcio [mg]": 200.0,
    "f\u00f3sforo [mg]": 550.0,
    "magn\u00e9sio [mg]": 350.0,
    "ferro [mg]": 15.0,
    "zinco [mg]": 7.8,
    "sel\u00e9nio [\u00b5g]": 28.0,
    "iodo [\u00b5g]": 0.5
  },
  {
    "name": "Sementes de mel\u00e3o",
    "energia[kcal]": 590.0,
    "energia[kj]": 2440.0,
    "l\u00edpidos[g]": 47.7,
    "\u00e1cidos gordos saturados[g]": 12.0,
    "\u00e1cidos gordos monoinsaturados [g]": 8.3,
    "\u00e1cidos gordos polinsaturados [g]": 26.2,
    "\u00e1cido linoleico [g]": 19.5,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 9.0,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 6.0,
    "fibra  [g]": 10.8,
    "prote\u00ednas [g]": 28.5,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.25,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 2.1,
    "equivalentes de niacina [mg]": 11.8,
    "triptofano/60 [mg]": 9.7,
    "vitamina b6 [mg]": 0.45,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 58.0,
    "cinza [g]": 2.05,
    "s\u00f3dio [mg]": 99.0,
    "pot\u00e1ssio [mg]": 650.0,
    "c\u00e1lcio [mg]": 71.0,
    "f\u00f3sforo [mg]": 690.0,
    "magn\u00e9sio [mg]": 510.0,
    "ferro [mg]": 7.6,
    "zinco [mg]": 4.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sementes de mostarda",
    "energia[kcal]": 449.0,
    "energia[kj]": 1870.0,
    "l\u00edpidos[g]": 28.8,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 19.8,
    "\u00e1cidos gordos polinsaturados [g]": 5.4,
    "\u00e1cido linoleico [g]": 3.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 15.9,
    "a\u00e7\u00facares [g]": 5.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 10.7,
    "fibra  [g]": 12.2,
    "prote\u00ednas [g]": 25.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 37.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.07,
    "tiamina [mg]": 0.54,
    "riboflavina [mg]": 0.38,
    "niacina [mg]": 7.9,
    "equivalentes de niacina [mg]": 16.4,
    "triptofano/60 [mg]": 8.5,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.37,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 680.0,
    "c\u00e1lcio [mg]": 520.0,
    "f\u00f3sforo [mg]": 840.0,
    "magn\u00e9sio [mg]": 300.0,
    "ferro [mg]": 19.0,
    "zinco [mg]": 4.7,
    "sel\u00e9nio [\u00b5g]": 130.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sementes de papoila",
    "energia[kcal]": 565.0,
    "energia[kj]": 2340.0,
    "l\u00edpidos[g]": 45.9,
    "\u00e1cidos gordos saturados[g]": 5.8,
    "\u00e1cidos gordos monoinsaturados [g]": 8.5,
    "\u00e1cidos gordos polinsaturados [g]": 29.2,
    "\u00e1cido linoleico [g]": 27.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 13.7,
    "a\u00e7\u00facares [g]": 3.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 10.7,
    "fibra  [g]": 10.0,
    "prote\u00ednas [g]": 19.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.61,
    "tiamina [mg]": 0.85,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 0.99,
    "equivalentes de niacina [mg]": 5.16,
    "triptofano/60 [mg]": 4.17,
    "vitamina b6 [mg]": 0.5,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 6.8,
    "s\u00f3dio [mg]": 21.0,
    "pot\u00e1ssio [mg]": 700.0,
    "c\u00e1lcio [mg]": 1510.0,
    "f\u00f3sforo [mg]": 840.0,
    "magn\u00e9sio [mg]": 330.0,
    "ferro [mg]": 10.0,
    "zinco [mg]": 9.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sementes de s\u00e9samo",
    "energia[kcal]": 620.0,
    "energia[kj]": 2560.0,
    "l\u00edpidos[g]": 55.0,
    "\u00e1cidos gordos saturados[g]": 8.1,
    "\u00e1cidos gordos monoinsaturados [g]": 20.7,
    "\u00e1cidos gordos polinsaturados [g]": 24.0,
    "\u00e1cido linoleico [g]": 23.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.4,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 6.0,
    "fibra  [g]": 5.5,
    "prote\u00ednas [g]": 22.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 4.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.25,
    "tiamina [mg]": 0.83,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 4.85,
    "equivalentes de niacina [mg]": 10.25,
    "triptofano/60 [mg]": 5.4,
    "vitamina b6 [mg]": 0.45,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 97.0,
    "cinza [g]": 2.37,
    "s\u00f3dio [mg]": 20.0,
    "pot\u00e1ssio [mg]": 570.0,
    "c\u00e1lcio [mg]": 670.0,
    "f\u00f3sforo [mg]": 720.0,
    "magn\u00e9sio [mg]": 370.0,
    "ferro [mg]": 10.0,
    "zinco [mg]": 5.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 5.0
  },
  {
    "name": "S\u00eamola de milho",
    "energia[kcal]": 350.0,
    "energia[kj]": 1480.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.363,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 73.8,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 72.0,
    "fibra  [g]": 5.0,
    "prote\u00ednas [g]": 8.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 11.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.2,
    "equivalentes de niacina [mg]": 2.1,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 80.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 73.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 2.5
  },
  {
    "name": "Shortening para pastelaria",
    "energia[kcal]": 878.0,
    "energia[kj]": 3610.0,
    "l\u00edpidos[g]": 97.5,
    "\u00e1cidos gordos saturados[g]": 86.9,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Shortening para restaurante",
    "energia[kcal]": 900.0,
    "energia[kj]": 3700.0,
    "l\u00edpidos[g]": 100.0,
    "\u00e1cidos gordos saturados[g]": 16.1,
    "\u00e1cidos gordos monoinsaturados [g]": 52.5,
    "\u00e1cidos gordos polinsaturados [g]": 28.3,
    "\u00e1cido linoleico [g]": 28.3,
    "\u00e1cidos gordos trans [g]": 16.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sidra (vinho de ma\u00e7\u00e3)",
    "energia[kcal]": 48.0,
    "energia[kj]": 199.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.3,
    "a\u00e7\u00facares [g]": 2.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 5.5,
    "\u00e1gua [g]": 92.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 7.0,
    "pot\u00e1ssio [mg]": 72.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sobremesa \u00e0 base de soja",
    "energia[kcal]": 95.0,
    "energia[kj]": 403.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.7,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 15.8,
    "a\u00e7\u00facares [g]": 13.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.2,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 3.5,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 77.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 48.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.12,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.38,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.8,
    "s\u00f3dio [mg]": 37.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Soja cozida sem sal",
    "energia[kcal]": 151.0,
    "energia[kj]": 630.0,
    "l\u00edpidos[g]": 7.5,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 1.7,
    "\u00e1cidos gordos polinsaturados [g]": 4.5,
    "\u00e1cido linoleico [g]": 4.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.6,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 1.2,
    "amido [g]": 2.0,
    "fibra  [g]": 5.6,
    "prote\u00ednas [g]": 12.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 7.0,
    "caroteno [\u00b5g]": 40.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.3,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 2.5,
    "triptofano/60 [mg]": 2.0,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 64.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 510.0,
    "c\u00e1lcio [mg]": 82.0,
    "f\u00f3sforo [mg]": 240.0,
    "magn\u00e9sio [mg]": 84.0,
    "ferro [mg]": 2.6,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Soja, farinha com baixo teor de gordura",
    "energia[kcal]": 314.0,
    "energia[kj]": 1320.0,
    "l\u00edpidos[g]": 2.6,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.3,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 21.8,
    "a\u00e7\u00facares [g]": 10.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 10.9,
    "fibra  [g]": 13.5,
    "prote\u00ednas [g]": 44.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 12.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 6.0,
    "caroteno [\u00b5g]": 35.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 1.2,
    "riboflavina [mg]": 0.28,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 12.0,
    "triptofano/60 [mg]": 10.0,
    "vitamina b6 [mg]": 0.49,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 410.0,
    "cinza [g]": 5.3,
    "s\u00f3dio [mg]": 13.0,
    "pot\u00e1ssio [mg]": 1910.0,
    "c\u00e1lcio [mg]": 260.0,
    "f\u00f3sforo [mg]": 610.0,
    "magn\u00e9sio [mg]": 270.0,
    "ferro [mg]": 6.0,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Soja, gr\u00e3o seco, cru",
    "energia[kcal]": 407.0,
    "energia[kj]": 1700.0,
    "l\u00edpidos[g]": 19.3,
    "\u00e1cidos gordos saturados[g]": 2.5,
    "\u00e1cidos gordos monoinsaturados [g]": 4.3,
    "\u00e1cidos gordos polinsaturados [g]": 11.5,
    "\u00e1cido linoleico [g]": 10.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.3,
    "a\u00e7\u00facares [g]": 6.6,
    "oligossac\u00e1ridos [g]": 6.3,
    "amido [g]": 5.4,
    "fibra  [g]": 14.3,
    "prote\u00ednas [g]": 32.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 17.0,
    "caroteno [\u00b5g]": 103.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 2.9,
    "tiamina [mg]": 1.2,
    "riboflavina [mg]": 0.49,
    "niacina [mg]": 1.8,
    "equivalentes de niacina [mg]": 7.0,
    "triptofano/60 [mg]": 5.2,
    "vitamina b6 [mg]": 0.6,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 330.0,
    "cinza [g]": 5.0,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 1750.0,
    "c\u00e1lcio [mg]": 250.0,
    "f\u00f3sforo [mg]": 670.0,
    "magn\u00e9sio [mg]": 250.0,
    "ferro [mg]": 8.0,
    "zinco [mg]": 3.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Solha crua",
    "energia[kcal]": 90.0,
    "energia[kj]": 382.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.0,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 85.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 10.0,
    "alfa-tocoferol [mg]": 0.35,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.51,
    "equivalentes de niacina [mg]": 4.0,
    "triptofano/60 [mg]": 3.5,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 1.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.5,
    "cinza [g]": 1.0,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 54.0,
    "f\u00f3sforo [mg]": 170.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Solha frita",
    "energia[kcal]": 147.0,
    "energia[kj]": 620.0,
    "l\u00edpidos[g]": 2.0,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.053,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.9,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 8.6,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 23.0,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 63.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 99.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 12.0,
    "alfa-tocoferol [mg]": 0.45,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.73,
    "equivalentes de niacina [mg]": 4.98,
    "triptofano/60 [mg]": 4.26,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 1.7,
    "vitamina c [mg]": 0.3,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 440.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 67.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 41.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Solha grelhada",
    "energia[kcal]": 104.0,
    "energia[kj]": 439.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 0.4,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.9,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 98.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 11.0,
    "alfa-tocoferol [mg]": 0.42,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.56,
    "equivalentes de niacina [mg]": 4.7,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 1.5,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.2,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 61.0,
    "f\u00f3sforo [mg]": 190.0,
    "magn\u00e9sio [mg]": 32.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 19.0
  },
  {
    "name": "Sonhos",
    "energia[kcal]": 389.0,
    "energia[kj]": 1620.0,
    "l\u00edpidos[g]": 26.2,
    "\u00e1cidos gordos saturados[g]": 3.7,
    "\u00e1cidos gordos monoinsaturados [g]": 6.2,
    "\u00e1cidos gordos polinsaturados [g]": 14.3,
    "\u00e1cido linoleico [g]": 14.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 29.2,
    "a\u00e7\u00facares [g]": 10.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 18.6,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 8.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 34.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 209.0,
    "vitamina a  [\u00b5g]": 135.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.9,
    "alfa-tocoferol [mg]": 1.1,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.23,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 2.7,
    "triptofano/60 [mg]": 2.4,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.6,
    "s\u00f3dio [mg]": 72.0,
    "pot\u00e1ssio [mg]": 61.0,
    "c\u00e1lcio [mg]": 29.0,
    "f\u00f3sforo [mg]": 130.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 0.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa \u00e0 lavrador",
    "energia[kcal]": 51.0,
    "energia[kj]": 214.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.105,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.6,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 6.5,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 1.2,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 74.0,
    "caroteno [\u00b5g]": 445.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.28,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.47,
    "equivalentes de niacina [mg]": 0.9,
    "triptofano/60 [mg]": 0.26,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 7.6,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 1.04,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 22.0,
    "magn\u00e9sio [mg]": 7.6,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de ab\u00f3bora",
    "energia[kcal]": 33.0,
    "energia[kj]": 138.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0914,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.0,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.4,
    "fibra  [g]": 0.5,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 34.0,
    "caroteno [\u00b5g]": 206.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.43,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.29,
    "equivalentes de niacina [mg]": 0.52,
    "triptofano/60 [mg]": 0.11,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.3,
    "folatos [\u00b5g]": 5.2,
    "cinza [g]": 0.853,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 91.0,
    "c\u00e1lcio [mg]": 9.7,
    "f\u00f3sforo [mg]": 9.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de agri\u00e3o",
    "energia[kcal]": 37.0,
    "energia[kj]": 155.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.105,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.2,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.6,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 84.0,
    "caroteno [\u00b5g]": 503.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.45,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.62,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 0.966,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 34.0,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 6.1,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de agri\u00e3o com leite",
    "energia[kcal]": 33.0,
    "energia[kj]": 139.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0247,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.3,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.8,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 1.3,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 345.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.29,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.48,
    "equivalentes de niacina [mg]": 0.74,
    "triptofano/60 [mg]": 0.26,
    "vitamina b6 [mg]": 0.16,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 17.3,
    "folatos [\u00b5g]": 45.0,
    "cinza [g]": 1.05,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 160.0,
    "c\u00e1lcio [mg]": 42.0,
    "f\u00f3sforo [mg]": 23.0,
    "magn\u00e9sio [mg]": 8.3,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de agri\u00e3o com requeij\u00e3o",
    "energia[kcal]": 28.0,
    "energia[kj]": 118.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.251,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.0,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 1.2,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 57.0,
    "caroteno [\u00b5g]": 271.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.27,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.34,
    "equivalentes de niacina [mg]": 0.55,
    "triptofano/60 [mg]": 0.21,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.03,
    "vitamina c [mg]": 13.3,
    "folatos [\u00b5g]": 32.0,
    "cinza [g]": 1.14,
    "s\u00f3dio [mg]": 280.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 47.0,
    "f\u00f3sforo [mg]": 22.0,
    "magn\u00e9sio [mg]": 7.5,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de carne de porco",
    "energia[kcal]": 60.0,
    "energia[kj]": 253.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.154,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.3,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 5.9,
    "fibra  [g]": 3.4,
    "prote\u00ednas [g]": 4.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 99.0,
    "caroteno [\u00b5g]": 592.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.12,
    "tiamina [mg]": 0.14,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.72,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.7,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.04,
    "vitamina c [mg]": 8.1,
    "folatos [\u00b5g]": 63.0,
    "cinza [g]": 1.24,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 33.0,
    "f\u00f3sforo [mg]": 57.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de cebola",
    "energia[kcal]": 40.0,
    "energia[kj]": 166.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.111,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.4,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 4.5,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.28,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.57,
    "triptofano/60 [mg]": 0.15,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.7,
    "folatos [\u00b5g]": 6.1,
    "cinza [g]": 0.905,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 90.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 14.0,
    "magn\u00e9sio [mg]": 6.2,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de cenoura",
    "energia[kcal]": 29.0,
    "energia[kj]": 123.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0748,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.4,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 2.4,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 4.0,
    "vitamina a  [\u00b5g]": 316.0,
    "caroteno [\u00b5g]": 1850.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.29,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.61,
    "equivalentes de niacina [mg]": 0.79,
    "triptofano/60 [mg]": 0.18,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 8.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 0.761,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 25.0,
    "magn\u00e9sio [mg]": 6.9,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de couve-branca",
    "energia[kcal]": 40.0,
    "energia[kj]": 169.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.4,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.2,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 134.0,
    "caroteno [\u00b5g]": 805.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.31,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.36,
    "equivalentes de niacina [mg]": 0.67,
    "triptofano/60 [mg]": 0.19,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 9.5,
    "cinza [g]": 0.916,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de couve-lombarda",
    "energia[kcal]": 41.0,
    "energia[kj]": 170.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.3,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.2,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 152.0,
    "caroteno [\u00b5g]": 914.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.31,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.39,
    "equivalentes de niacina [mg]": 0.73,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 6.6,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.978,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 20.0,
    "magn\u00e9sio [mg]": 6.4,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de cozido",
    "energia[kcal]": 36.0,
    "energia[kj]": 151.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 2.9,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 2.3,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 5.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 305.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 1.1,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.2,
    "vitamina c [mg]": 7.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 38.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de ervilhas",
    "energia[kcal]": 48.0,
    "energia[kj]": 202.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.134,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.4,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 5.3,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 1.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 71.0,
    "caroteno [\u00b5g]": 427.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.28,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.42,
    "equivalentes de niacina [mg]": 0.89,
    "triptofano/60 [mg]": 0.31,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.2,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.977,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 7.3,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de espinafres",
    "energia[kcal]": 39.0,
    "energia[kj]": 164.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.109,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.8,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.5,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 102.0,
    "caroteno [\u00b5g]": 613.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.53,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.27,
    "equivalentes de niacina [mg]": 0.62,
    "triptofano/60 [mg]": 0.26,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.3,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 0.999,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 22.0,
    "f\u00f3sforo [mg]": 16.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de espinafres com ovos",
    "energia[kcal]": 93.0,
    "energia[kj]": 387.0,
    "l\u00edpidos[g]": 6.9,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 4.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.7,
    "\u00e1cido linoleico [g]": 0.586,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.7,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.3,
    "fibra  [g]": 1.2,
    "prote\u00ednas [g]": 2.5,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 83.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 30.0,
    "vitamina a  [\u00b5g]": 186.0,
    "caroteno [\u00b5g]": 1030.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 1.49,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.11,
    "niacina [mg]": 0.24,
    "equivalentes de niacina [mg]": 0.88,
    "triptofano/60 [mg]": 0.64,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.07,
    "vitamina c [mg]": 11.8,
    "folatos [\u00b5g]": 53.0,
    "cinza [g]": 0.897,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 41.0,
    "f\u00f3sforo [mg]": 40.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de favas",
    "energia[kcal]": 49.0,
    "energia[kj]": 207.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.105,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.4,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 5.5,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 66.0,
    "caroteno [\u00b5g]": 394.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.32,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.43,
    "equivalentes de niacina [mg]": 0.94,
    "triptofano/60 [mg]": 0.34,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 4.5,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 0.965,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 24.0,
    "magn\u00e9sio [mg]": 8.3,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de feij\u00e3o",
    "energia[kcal]": 51.0,
    "energia[kj]": 212.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.188,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.1,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 2.8,
    "fibra  [g]": 2.8,
    "prote\u00ednas [g]": 2.5,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 53.0,
    "caroteno [\u00b5g]": 319.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.37,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.36,
    "equivalentes de niacina [mg]": 0.76,
    "triptofano/60 [mg]": 0.4,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 21.8,
    "folatos [\u00b5g]": 77.0,
    "cinza [g]": 0.998,
    "s\u00f3dio [mg]": 150.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 33.0,
    "f\u00f3sforo [mg]": 45.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de feij\u00e3o-branco com couve-portuguesa",
    "energia[kcal]": 47.0,
    "energia[kj]": 196.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.158,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.9,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 3.3,
    "fibra  [g]": 2.7,
    "prote\u00ednas [g]": 2.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 68.0,
    "caroteno [\u00b5g]": 407.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.23,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.33,
    "equivalentes de niacina [mg]": 0.74,
    "triptofano/60 [mg]": 0.42,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 26.4,
    "folatos [\u00b5g]": 49.0,
    "cinza [g]": 0.551,
    "s\u00f3dio [mg]": 9.6,
    "pot\u00e1ssio [mg]": 190.0,
    "c\u00e1lcio [mg]": 41.0,
    "f\u00f3sforo [mg]": 47.0,
    "magn\u00e9sio [mg]": 25.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de feij\u00e3o-manteiga",
    "energia[kcal]": 46.0,
    "energia[kj]": 196.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.04,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.7,
    "a\u00e7\u00facares [g]": 1.9,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 6.7,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 1.2,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 140.0,
    "caroteno [\u00b5g]": 839.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.05,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.27,
    "equivalentes de niacina [mg]": 0.51,
    "triptofano/60 [mg]": 0.24,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 7.8,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 0.982,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 22.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de feij\u00e3o-manteiga com couve-lombarda",
    "energia[kcal]": 43.0,
    "energia[kj]": 181.0,
    "l\u00edpidos[g]": 1.8,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.159,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 2.5,
    "fibra  [g]": 2.4,
    "prote\u00ednas [g]": 2.1,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 45.0,
    "caroteno [\u00b5g]": 269.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.32,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.31,
    "equivalentes de niacina [mg]": 0.65,
    "triptofano/60 [mg]": 0.35,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 18.6,
    "folatos [\u00b5g]": 64.0,
    "cinza [g]": 1.17,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 29.0,
    "f\u00f3sforo [mg]": 38.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de feij\u00e3o-verde",
    "energia[kcal]": 32.0,
    "energia[kj]": 133.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.074,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 0.8,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 2.6,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 0.7,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 31.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.15,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.35,
    "equivalentes de niacina [mg]": 0.51,
    "triptofano/60 [mg]": 0.16,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 5.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.972,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 14.0,
    "magn\u00e9sio [mg]": 7.5,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de feij\u00e3o-verde e cenoura",
    "energia[kcal]": 42.0,
    "energia[kj]": 177.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.103,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.8,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.7,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 98.0,
    "caroteno [\u00b5g]": 588.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.3,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.76,
    "triptofano/60 [mg]": 0.22,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.8,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 0.984,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 6.9,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de feij\u00e3o-verde e nabo",
    "energia[kcal]": 36.0,
    "energia[kj]": 148.0,
    "l\u00edpidos[g]": 1.9,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.485,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.4,
    "a\u00e7\u00facares [g]": 1.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 2.0,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.7,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 26.0,
    "caroteno [\u00b5g]": 84.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.17,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.45,
    "equivalentes de niacina [mg]": 0.59,
    "triptofano/60 [mg]": 0.14,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.2,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 1.09,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 8.5,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de gr\u00e3o \u00e0 moda da av\u00f3",
    "energia[kcal]": 107.0,
    "energia[kj]": 448.0,
    "l\u00edpidos[g]": 2.3,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.635,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 15.1,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.7,
    "amido [g]": 13.2,
    "fibra  [g]": 3.3,
    "prote\u00ednas [g]": 4.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 113.0,
    "caroteno [\u00b5g]": 677.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.76,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.64,
    "equivalentes de niacina [mg]": 1.29,
    "triptofano/60 [mg]": 0.65,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 41.0,
    "cinza [g]": 0.997,
    "s\u00f3dio [mg]": 100.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 37.0,
    "f\u00f3sforo [mg]": 62.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 1.4,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de gr\u00e3o com arroz, espinafres e coentros",
    "energia[kcal]": 45.0,
    "energia[kj]": 188.0,
    "l\u00edpidos[g]": 1.3,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.214,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.3,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 4.5,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 2.1,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 183.0,
    "caroteno [\u00b5g]": 1100.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.82,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.34,
    "equivalentes de niacina [mg]": 0.75,
    "triptofano/60 [mg]": 0.41,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 13.3,
    "folatos [\u00b5g]": 60.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 300.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 46.0,
    "f\u00f3sforo [mg]": 32.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de gr\u00e3o com espinafres ",
    "energia[kcal]": 60.0,
    "energia[kj]": 252.0,
    "l\u00edpidos[g]": 2.1,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.373,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.0,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 6.0,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 2.4,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 41.0,
    "caroteno [\u00b5g]": 243.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.63,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.22,
    "equivalentes de niacina [mg]": 0.67,
    "triptofano/60 [mg]": 0.35,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.1,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 1.11,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 29.0,
    "magn\u00e9sio [mg]": 15.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de gr\u00e3o com espinafres sem arroz",
    "energia[kcal]": 55.0,
    "energia[kj]": 231.0,
    "l\u00edpidos[g]": 2.3,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.452,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.1,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.4,
    "amido [g]": 4.2,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 2.6,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 135.0,
    "caroteno [\u00b5g]": 810.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.06,
    "niacina [mg]": 0.31,
    "equivalentes de niacina [mg]": 0.74,
    "triptofano/60 [mg]": 0.43,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.02,
    "vitamina c [mg]": 9.2,
    "folatos [\u00b5g]": 54.0,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 290.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 42.0,
    "f\u00f3sforo [mg]": 36.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de nabi\u00e7as (ou de grelos de nabo)",
    "energia[kcal]": 40.0,
    "energia[kj]": 169.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.9,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.11,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.8,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 4.6,
    "fibra  [g]": 1.1,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 109.0,
    "caroteno [\u00b5g]": 654.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.38,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.46,
    "equivalentes de niacina [mg]": 0.86,
    "triptofano/60 [mg]": 0.22,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.6,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.01,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 24.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 7.6,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de peixe ",
    "energia[kcal]": 17.0,
    "energia[kj]": 70.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0646,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.3,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 0.3,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 1.3,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 95.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 14.0,
    "caroteno [\u00b5g]": 70.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.27,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.45,
    "equivalentes de niacina [mg]": 0.68,
    "triptofano/60 [mg]": 0.23,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.11,
    "vitamina c [mg]": 4.2,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 0.92,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 97.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 22.0,
    "magn\u00e9sio [mg]": 7.8,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de peixe com massa",
    "energia[kcal]": 46.0,
    "energia[kj]": 193.0,
    "l\u00edpidos[g]": 1.1,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.173,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.8,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 4.8,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 2.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 2.0,
    "vitamina a  [\u00b5g]": 12.0,
    "caroteno [\u00b5g]": 70.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.34,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.34,
    "equivalentes de niacina [mg]": 0.95,
    "triptofano/60 [mg]": 0.5,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.05,
    "vitamina c [mg]": 1.9,
    "folatos [\u00b5g]": 4.9,
    "cinza [g]": 0.914,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 66.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 35.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de tomate",
    "energia[kcal]": 31.0,
    "energia[kj]": 129.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.11,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.4,
    "a\u00e7\u00facares [g]": 2.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 2.1,
    "fibra  [g]": 0.7,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 8.0,
    "vitamina a  [\u00b5g]": 34.0,
    "caroteno [\u00b5g]": 161.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.46,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.42,
    "equivalentes de niacina [mg]": 0.64,
    "triptofano/60 [mg]": 0.22,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.02,
    "vitamina c [mg]": 8.7,
    "folatos [\u00b5g]": 12.0,
    "cinza [g]": 1.02,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 170.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 22.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa de vegetais",
    "energia[kcal]": 11.0,
    "energia[kj]": 48.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0463,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.1,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 96.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 112.0,
    "caroteno [\u00b5g]": 671.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.14,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.32,
    "equivalentes de niacina [mg]": 0.46,
    "triptofano/60 [mg]": 0.14,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 8.2,
    "folatos [\u00b5g]": 19.0,
    "cinza [g]": 0.833,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 16.0,
    "magn\u00e9sio [mg]": 8.9,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa juliana",
    "energia[kcal]": 31.0,
    "energia[kj]": 130.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0674,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.3,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.4,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 91.0,
    "caroteno [\u00b5g]": 543.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.33,
    "equivalentes de niacina [mg]": 0.64,
    "triptofano/60 [mg]": 0.18,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 7.5,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.968,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 18.0,
    "magn\u00e9sio [mg]": 5.9,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa, caldo verde",
    "energia[kcal]": 43.0,
    "energia[kj]": 182.0,
    "l\u00edpidos[g]": 1.7,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.159,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.3,
    "a\u00e7\u00facares [g]": 0.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.6,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 1.3,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 1.0,
    "vitamina a  [\u00b5g]": 50.0,
    "caroteno [\u00b5g]": 299.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.22,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.45,
    "equivalentes de niacina [mg]": 0.86,
    "triptofano/60 [mg]": 0.23,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.02,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 1.22,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 39.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 6.8,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa, canja de galinha",
    "energia[kcal]": 40.0,
    "energia[kj]": 168.0,
    "l\u00edpidos[g]": 0.9,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.177,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.8,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 5.6,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 6.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.49,
    "equivalentes de niacina [mg]": 1.14,
    "triptofano/60 [mg]": 0.36,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.02,
    "vitamina c [mg]": 0.2,
    "folatos [\u00b5g]": 1.8,
    "cinza [g]": 0.764,
    "s\u00f3dio [mg]": 230.0,
    "pot\u00e1ssio [mg]": 21.0,
    "c\u00e1lcio [mg]": 6.2,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa, creme de cenoura",
    "energia[kcal]": 34.0,
    "energia[kj]": 143.0,
    "l\u00edpidos[g]": 1.4,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0914,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.4,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 3.4,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 0.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 160.0,
    "caroteno [\u00b5g]": 960.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.31,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.32,
    "equivalentes de niacina [mg]": 0.56,
    "triptofano/60 [mg]": 0.13,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 2.3,
    "folatos [\u00b5g]": 7.4,
    "cinza [g]": 0.874,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 5.5,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa, pur\u00e9 de feij\u00e3o",
    "energia[kcal]": 43.0,
    "energia[kj]": 178.0,
    "l\u00edpidos[g]": 1.6,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.128,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.5,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.3,
    "amido [g]": 3.3,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 1.6,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 66.0,
    "caroteno [\u00b5g]": 396.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.28,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.19,
    "equivalentes de niacina [mg]": 0.54,
    "triptofano/60 [mg]": 0.27,
    "vitamina b6 [mg]": 0.04,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.9,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 0.966,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 88.0,
    "c\u00e1lcio [mg]": 21.0,
    "f\u00f3sforo [mg]": 26.0,
    "magn\u00e9sio [mg]": 13.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sopa, pur\u00e9 de vegetais",
    "energia[kcal]": 42.0,
    "energia[kj]": 174.0,
    "l\u00edpidos[g]": 1.5,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 1.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.105,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 5.5,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 4.5,
    "fibra  [g]": 1.0,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 74.0,
    "caroteno [\u00b5g]": 445.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.28,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.39,
    "equivalentes de niacina [mg]": 0.74,
    "triptofano/60 [mg]": 0.21,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 6.9,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 0.992,
    "s\u00f3dio [mg]": 240.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 6.6,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Suced\u00e2neo de caf\u00e9 (p\u00f3) (2 marcas)",
    "energia[kcal]": 375.0,
    "energia[kj]": 1590.0,
    "l\u00edpidos[g]": 3.4,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.7,
    "\u00e1cido linoleico [g]": 1.6,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 81.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 81.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 5.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 2.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 16.0,
    "equivalentes de niacina [mg]": 17.0,
    "triptofano/60 [mg]": 0.9,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.7,
    "s\u00f3dio [mg]": 67.0,
    "pot\u00e1ssio [mg]": 1070.0,
    "c\u00e1lcio [mg]": 57.0,
    "f\u00f3sforo [mg]": 300.0,
    "magn\u00e9sio [mg]": 83.0,
    "ferro [mg]": 4.2,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Suced\u00e2neo de caf\u00e9 (p\u00f3) com 20% de caf\u00e9",
    "energia[kcal]": 359.0,
    "energia[kj]": 1520.0,
    "l\u00edpidos[g]": 2.8,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.3,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 77.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 77.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 6.5,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 16.0,
    "equivalentes de niacina [mg]": 17.0,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 2.69,
    "s\u00f3dio [mg]": 48.0,
    "pot\u00e1ssio [mg]": 1580.0,
    "c\u00e1lcio [mg]": 83.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 130.0,
    "ferro [mg]": 5.0,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sultanas",
    "energia[kcal]": 304.0,
    "energia[kj]": 1290.0,
    "l\u00edpidos[g]": 0.4,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 69.4,
    "a\u00e7\u00facares [g]": 69.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 6.1,
    "prote\u00ednas [g]": 2.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 15.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 1.28,
    "s\u00f3dio [mg]": 19.0,
    "pot\u00e1ssio [mg]": 1060.0,
    "c\u00e1lcio [mg]": 64.0,
    "f\u00f3sforo [mg]": 86.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 5.5
  },
  {
    "name": "Sumo de anan\u00e1s, 100%",
    "energia[kcal]": 42.0,
    "energia[kj]": 179.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.5,
    "a\u00e7\u00facares [g]": 9.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.6,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 20.0,
    "folatos [\u00b5g]": 8.0,
    "cinza [g]": 0.22,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.8
  },
  {
    "name": "Sumo de anan\u00e1s, concentrado",
    "energia[kcal]": 265.0,
    "energia[kj]": 1130.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 64.7,
    "a\u00e7\u00facares [g]": 64.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 29.2,
    "\u00e1cidos org\u00e2nicos [g]": 2.1,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.4,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 240.0,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 1.12,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 490.0,
    "c\u00e1lcio [mg]": 42.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 46.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sumo de laranja, 100% ",
    "energia[kcal]": 42.0,
    "energia[kj]": 179.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.5,
    "a\u00e7\u00facares [g]": 9.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.7,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 50.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 41.0,
    "folatos [\u00b5g]": 28.0,
    "cinza [g]": 0.36,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 6.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.8
  },
  {
    "name": "Sumo de laranja, concentrado",
    "energia[kcal]": 272.0,
    "energia[kj]": 1160.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 64.9,
    "a\u00e7\u00facares [g]": 64.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 31.7,
    "\u00e1cidos org\u00e2nicos [g]": 2.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 111.0,
    "caroteno [\u00b5g]": 666.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.7,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.17,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 240.0,
    "folatos [\u00b5g]": 59.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 20.0,
    "pot\u00e1ssio [mg]": 640.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 38.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sumo de lim\u00e3o, fresco (espremido)",
    "energia[kcal]": 25.0,
    "energia[kj]": 106.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.5,
    "a\u00e7\u00facares [g]": 1.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.7,
    "\u00e1cidos org\u00e2nicos [g]": 5.8,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 56.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 0.36,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Sumo de ma\u00e7\u00e3, 100%",
    "energia[kcal]": 43.0,
    "energia[kj]": 181.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.9,
    "a\u00e7\u00facares [g]": 9.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.2,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 40.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 8.6,
    "pot\u00e1ssio [mg]": 96.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 4.0,
    "magn\u00e9sio [mg]": 3.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.8
  },
  {
    "name": "Sumo de p\u00eassego, 100%",
    "energia[kcal]": 50.0,
    "energia[kj]": 212.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.5,
    "a\u00e7\u00facares [g]": 11.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 87.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.5,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 41.0,
    "caroteno [\u00b5g]": 243.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 39.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.18,
    "s\u00f3dio [mg]": 3.0,
    "pot\u00e1ssio [mg]": 92.0,
    "c\u00e1lcio [mg]": 3.0,
    "f\u00f3sforo [mg]": 5.0,
    "magn\u00e9sio [mg]": 4.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.8
  },
  {
    "name": "Sumo de tomate, 100%",
    "energia[kcal]": 21.0,
    "energia[kj]": 88.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 3.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.6,
    "prote\u00ednas [g]": 1.1,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 62.0,
    "caroteno [\u00b5g]": 372.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.1,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 14.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.04,
    "s\u00f3dio [mg]": 220.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tahini ",
    "energia[kcal]": 661.0,
    "energia[kj]": 2730.0,
    "l\u00edpidos[g]": 58.9,
    "\u00e1cidos gordos saturados[g]": 8.4,
    "\u00e1cidos gordos monoinsaturados [g]": 22.0,
    "\u00e1cidos gordos polinsaturados [g]": 25.8,
    "\u00e1cido linoleico [g]": 25.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.6,
    "a\u00e7\u00facares [g]": 5.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.8,
    "fibra  [g]": 5.5,
    "prote\u00ednas [g]": 18.5,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 3.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 6.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.25,
    "tiamina [mg]": 0.94,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 5.1,
    "equivalentes de niacina [mg]": 9.2,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.76,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 99.0,
    "cinza [g]": 2.42,
    "s\u00f3dio [mg]": 20.0,
    "pot\u00e1ssio [mg]": 580.0,
    "c\u00e1lcio [mg]": 680.0,
    "f\u00f3sforo [mg]": 730.0,
    "magn\u00e9sio [mg]": 380.0,
    "ferro [mg]": 11.0,
    "zinco [mg]": 5.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "T\u00e2mara fresca ",
    "energia[kcal]": 147.0,
    "energia[kj]": 621.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 33.2,
    "a\u00e7\u00facares [g]": 33.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 3.8,
    "prote\u00ednas [g]": 1.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 23.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.99,
    "equivalentes de niacina [mg]": 1.53,
    "triptofano/60 [mg]": 0.54,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 6.9,
    "folatos [\u00b5g]": 6.4,
    "cinza [g]": 0.888,
    "s\u00f3dio [mg]": 2.5,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 21.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "T\u00e2mara seca",
    "energia[kcal]": 298.0,
    "energia[kj]": 1260.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 67.3,
    "a\u00e7\u00facares [g]": 67.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 7.8,
    "prote\u00ednas [g]": 2.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 20.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 47.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 3.1,
    "triptofano/60 [mg]": 1.1,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 700.0,
    "c\u00e1lcio [mg]": 50.0,
    "f\u00f3sforo [mg]": 42.0,
    "magn\u00e9sio [mg]": 55.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tamarilho sem pele",
    "energia[kcal]": 34.0,
    "energia[kj]": 144.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.6,
    "a\u00e7\u00facares [g]": 4.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.3,
    "prote\u00ednas [g]": 2.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 86.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.2,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 155.0,
    "caroteno [\u00b5g]": 920.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.86,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.19,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 23.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.59,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 43.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tamarindo cru",
    "energia[kcal]": 242.0,
    "energia[kj]": 1020.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 54.9,
    "a\u00e7\u00facares [g]": 38.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 16.1,
    "fibra  [g]": 5.1,
    "prote\u00ednas [g]": 2.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 35.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 14.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.29,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 0.88,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 600.0,
    "c\u00e1lcio [mg]": 77.0,
    "f\u00f3sforo [mg]": 94.0,
    "magn\u00e9sio [mg]": 92.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tamboril cru",
    "energia[kcal]": 73.0,
    "energia[kj]": 312.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.9,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 42.0,
    "vitamina a  [\u00b5g]": 24.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.23,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 5.3,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.26,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.3,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 86.0,
    "pot\u00e1ssio [mg]": 330.0,
    "c\u00e1lcio [mg]": 7.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 27.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tamboril grelhado",
    "energia[kcal]": 88.0,
    "energia[kj]": 375.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.4,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 27.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.29,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 6.3,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.05,
    "vitamina b12 [\u00b5g]": 0.26,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.4,
    "cinza [g]": 2.2,
    "s\u00f3dio [mg]": 400.0,
    "pot\u00e1ssio [mg]": 360.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 31.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "T\u00e2ngera",
    "energia[kcal]": 41.0,
    "energia[kj]": 172.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.8,
    "a\u00e7\u00facares [g]": 7.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 0.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 89.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.8,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 60.0,
    "caroteno [\u00b5g]": 358.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.55,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 42.0,
    "folatos [\u00b5g]": 18.0,
    "cinza [g]": 0.41,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 57.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tangerina",
    "energia[kcal]": 44.0,
    "energia[kj]": 188.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 8.7,
    "a\u00e7\u00facares [g]": 8.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 0.7,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 88.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.8,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 33.0,
    "caroteno [\u00b5g]": 200.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.24,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 32.0,
    "folatos [\u00b5g]": 21.0,
    "cinza [g]": 0.43,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 30.0,
    "f\u00f3sforo [mg]": 16.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tapioca",
    "energia[kcal]": 356.0,
    "energia[kj]": 1510.0,
    "l\u00edpidos[g]": 0.2,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 87.5,
    "a\u00e7\u00facares [g]": 0.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 86.6,
    "fibra  [g]": 1.7,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 10.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 0.1,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 20.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 2.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tarte de ma\u00e7\u00e3",
    "energia[kcal]": 200.0,
    "energia[kj]": 841.0,
    "l\u00edpidos[g]": 8.0,
    "\u00e1cidos gordos saturados[g]": 3.5,
    "\u00e1cidos gordos monoinsaturados [g]": 2.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.5,
    "\u00e1cido linoleico [g]": 1.4,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 29.3,
    "a\u00e7\u00facares [g]": 17.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 12.2,
    "fibra  [g]": 2.0,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 57.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 3.0,
    "vitamina a  [\u00b5g]": 37.0,
    "caroteno [\u00b5g]": 53.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.52,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.3,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.01,
    "vitamina c [mg]": 7.0,
    "folatos [\u00b5g]": 4.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 120.0,
    "pot\u00e1ssio [mg]": 100.0,
    "c\u00e1lcio [mg]": 27.0,
    "f\u00f3sforo [mg]": 30.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.5,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tarte de ma\u00e7\u00e3 e p\u00eassego",
    "energia[kcal]": 224.0,
    "energia[kj]": 945.0,
    "l\u00edpidos[g]": 6.2,
    "\u00e1cidos gordos saturados[g]": 2.4,
    "\u00e1cidos gordos monoinsaturados [g]": 2.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.8,
    "\u00e1cido linoleico [g]": 0.688,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 36.7,
    "a\u00e7\u00facares [g]": 21.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 15.0,
    "fibra  [g]": 1.9,
    "prote\u00ednas [g]": 4.4,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 49.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.1,
    "colesterol [mg]": 46.0,
    "vitamina a  [\u00b5g]": 49.0,
    "caroteno [\u00b5g]": 60.0,
    "vitamina d [\u00b5g]": 0.2,
    "alfa-tocoferol [mg]": 1.14,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 0.49,
    "equivalentes de niacina [mg]": 1.49,
    "triptofano/60 [mg]": 1.0,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.12,
    "vitamina c [mg]": 2.3,
    "folatos [\u00b5g]": 9.5,
    "cinza [g]": 0.817,
    "s\u00f3dio [mg]": 54.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 57.0,
    "f\u00f3sforo [mg]": 87.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tisana, infus\u00e3o de ervas",
    "energia[kcal]": 1.0,
    "energia[kj]": 3.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 99.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 1.0,
    "cinza [g]": 0.03,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 10.0,
    "c\u00e1lcio [mg]": 2.0,
    "f\u00f3sforo [mg]": 1.0,
    "magn\u00e9sio [mg]": 1.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tofu frito com azeite",
    "energia[kcal]": 108.0,
    "energia[kj]": 451.0,
    "l\u00edpidos[g]": 7.6,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 3.5,
    "\u00e1cidos gordos polinsaturados [g]": 2.8,
    "\u00e1cido linoleico [g]": 2.42,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.1,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.6,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 8.7,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 1.0,
    "caroteno [\u00b5g]": 11.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.46,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.12,
    "equivalentes de niacina [mg]": 1.56,
    "triptofano/60 [mg]": 1.44,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.6,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 2.08,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 89.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 94.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tofu frito com \u00f3leo de milho",
    "energia[kcal]": 108.0,
    "energia[kj]": 451.0,
    "l\u00edpidos[g]": 7.6,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 4.3,
    "\u00e1cido linoleico [g]": 3.91,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.1,
    "a\u00e7\u00facares [g]": 0.4,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.6,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 8.7,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 15.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.81,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.12,
    "equivalentes de niacina [mg]": 1.56,
    "triptofano/60 [mg]": 1.44,
    "vitamina b6 [mg]": 0.07,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.6,
    "folatos [\u00b5g]": 13.0,
    "cinza [g]": 2.08,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 89.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 94.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tofu simples",
    "energia[kcal]": 77.0,
    "energia[kj]": 322.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.5,
    "\u00e1cido linoleico [g]": 2.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.7,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.3,
    "fibra  [g]": 0.3,
    "prote\u00ednas [g]": 8.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 2.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.0,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 1.5,
    "triptofano/60 [mg]": 1.4,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.4,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 75.0,
    "c\u00e1lcio [mg]": 130.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 91.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tomate cherry cru",
    "energia[kcal]": 30.0,
    "energia[kj]": 127.0,
    "l\u00edpidos[g]": 0.8,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.0,
    "a\u00e7\u00facares [g]": 4.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 91.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 105.0,
    "caroteno [\u00b5g]": 479.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.71,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.7,
    "equivalentes de niacina [mg]": 0.8,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.08,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 19.5,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 0.5,
    "s\u00f3dio [mg]": 3.5,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 8.5,
    "f\u00f3sforo [mg]": 32.0,
    "magn\u00e9sio [mg]": 12.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.6
  },
  {
    "name": "Tomate concentrado",
    "energia[kcal]": 82.0,
    "energia[kj]": 344.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.3,
    "a\u00e7\u00facares [g]": 10.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 4.0,
    "fibra  [g]": 3.9,
    "prote\u00ednas [g]": 3.7,
    "sal  [g]": 0.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 190.0,
    "caroteno [\u00b5g]": 1140.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 5.0,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.84,
    "equivalentes de niacina [mg]": 3.32,
    "triptofano/60 [mg]": 0.48,
    "vitamina b6 [mg]": 0.23,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 14.4,
    "folatos [\u00b5g]": 26.1,
    "cinza [g]": 2.13,
    "s\u00f3dio [mg]": 110.0,
    "pot\u00e1ssio [mg]": 1090.0,
    "c\u00e1lcio [mg]": 50.0,
    "f\u00f3sforo [mg]": 78.0,
    "magn\u00e9sio [mg]": 55.0,
    "ferro [mg]": 2.9,
    "zinco [mg]": 0.5,
    "sel\u00e9nio [\u00b5g]": 0.2,
    "iodo [\u00b5g]": 3.3
  },
  {
    "name": "Tomate cru",
    "energia[kcal]": 23.0,
    "energia[kj]": 95.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.5,
    "a\u00e7\u00facares [g]": 3.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.3,
    "prote\u00ednas [g]": 0.8,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 85.0,
    "caroteno [\u00b5g]": 510.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.6,
    "equivalentes de niacina [mg]": 0.7,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 20.0,
    "folatos [\u00b5g]": 17.0,
    "cinza [g]": 0.54,
    "s\u00f3dio [mg]": 13.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 11.0,
    "f\u00f3sforo [mg]": 17.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Tomate em conserva ao natural",
    "energia[kcal]": 21.0,
    "energia[kj]": 90.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 3.2,
    "a\u00e7\u00facares [g]": 3.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.2,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 333.0,
    "caroteno [\u00b5g]": 2000.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.2,
    "tiamina [mg]": 0.04,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.9,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 15.0,
    "folatos [\u00b5g]": 11.0,
    "cinza [g]": 0.76,
    "s\u00f3dio [mg]": 29.0,
    "pot\u00e1ssio [mg]": 270.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 25.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tomilho fresco",
    "energia[kcal]": 52.0,
    "energia[kj]": 219.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.24,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.4,
    "a\u00e7\u00facares [g]": 7.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.1,
    "fibra  [g]": 3.0,
    "prote\u00ednas [g]": 1.5,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 85.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 102.0,
    "caroteno [\u00b5g]": 610.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.8,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.07,
    "niacina [mg]": 0.8,
    "equivalentes de niacina [mg]": 1.0,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 20.0,
    "cinza [g]": 1.7,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 310.0,
    "f\u00f3sforo [mg]": 32.0,
    "magn\u00e9sio [mg]": 36.0,
    "ferro [mg]": 20.0,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tomilho seco",
    "energia[kcal]": 321.0,
    "energia[kj]": 1350.0,
    "l\u00edpidos[g]": 7.4,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 45.3,
    "a\u00e7\u00facares [g]": 45.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 18.6,
    "prote\u00ednas [g]": 9.1,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 7.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 456.0,
    "caroteno [\u00b5g]": 2736.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 9.15,
    "tiamina [mg]": 0.51,
    "riboflavina [mg]": 0.4,
    "niacina [mg]": 4.9,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.38,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 3.12,
    "s\u00f3dio [mg]": 55.0,
    "pot\u00e1ssio [mg]": 810.0,
    "c\u00e1lcio [mg]": 1890.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 40.0,
    "ferro [mg]": 120.0,
    "zinco [mg]": 6.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Toranja",
    "energia[kcal]": 36.0,
    "energia[kj]": 151.0,
    "l\u00edpidos[g]": 0.1,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.0,
    "a\u00e7\u00facares [g]": 6.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 1.6,
    "prote\u00ednas [g]": 0.9,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 90.9,
    "\u00e1cidos org\u00e2nicos [g]": 1.3,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 3.0,
    "caroteno [\u00b5g]": 17.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.19,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.4,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 43.0,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 0.42,
    "s\u00f3dio [mg]": 1.0,
    "pot\u00e1ssio [mg]": 200.0,
    "c\u00e1lcio [mg]": 26.0,
    "f\u00f3sforo [mg]": 19.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.1,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Torta de chocolate e chantilly",
    "energia[kcal]": 369.0,
    "energia[kj]": 1540.0,
    "l\u00edpidos[g]": 23.9,
    "\u00e1cidos gordos saturados[g]": 12.3,
    "\u00e1cidos gordos monoinsaturados [g]": 6.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.23,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 29.6,
    "a\u00e7\u00facares [g]": 28.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 1.3,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 8.0,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 33.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 210.0,
    "vitamina a  [\u00b5g]": 288.0,
    "caroteno [\u00b5g]": 115.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 1.64,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 0.47,
    "equivalentes de niacina [mg]": 2.49,
    "triptofano/60 [mg]": 2.03,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.46,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 1.16,
    "s\u00f3dio [mg]": 70.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 57.0,
    "f\u00f3sforo [mg]": 150.0,
    "magn\u00e9sio [mg]": 52.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tosta de trigo",
    "energia[kcal]": 377.0,
    "energia[kj]": 1600.0,
    "l\u00edpidos[g]": 4.2,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.6,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.58,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 69.7,
    "a\u00e7\u00facares [g]": 2.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 66.8,
    "fibra  [g]": 5.4,
    "prote\u00ednas [g]": 12.5,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.15,
    "riboflavina [mg]": 0.76,
    "niacina [mg]": 5.6,
    "equivalentes de niacina [mg]": 8.18,
    "triptofano/60 [mg]": 2.64,
    "vitamina b6 [mg]": 0.14,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 32.0,
    "cinza [g]": 1.49,
    "s\u00f3dio [mg]": 390.0,
    "pot\u00e1ssio [mg]": 210.0,
    "c\u00e1lcio [mg]": 77.0,
    "f\u00f3sforo [mg]": 210.0,
    "magn\u00e9sio [mg]": 71.0,
    "ferro [mg]": 2.4,
    "zinco [mg]": 1.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Tosta de trigo integral",
    "energia[kcal]": 373.0,
    "energia[kj]": 1570.0,
    "l\u00edpidos[g]": 5.1,
    "\u00e1cidos gordos saturados[g]": 1.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 2.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 62.6,
    "a\u00e7\u00facares [g]": 3.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 59.1,
    "fibra  [g]": 7.4,
    "prote\u00ednas [g]": 15.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.2,
    "riboflavina [mg]": 1.8,
    "niacina [mg]": 7.0,
    "equivalentes de niacina [mg]": 10.0,
    "triptofano/60 [mg]": 3.2,
    "vitamina b6 [mg]": 0.2,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 12.0,
    "folatos [\u00b5g]": 46.0,
    "cinza [g]": 2.86,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 76.0,
    "f\u00f3sforo [mg]": 290.0,
    "magn\u00e9sio [mg]": 150.0,
    "ferro [mg]": 3.2,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tosta de trigo sem sal",
    "energia[kcal]": 379.0,
    "energia[kj]": 1600.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 72.7,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 70.1,
    "fibra  [g]": 4.5,
    "prote\u00ednas [g]": 11.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 6.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 2.4,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 0.93,
    "s\u00f3dio [mg]": 28.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 78.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 39.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tosta de trigo simples",
    "energia[kcal]": 379.0,
    "energia[kj]": 1600.0,
    "l\u00edpidos[g]": 3.8,
    "\u00e1cidos gordos saturados[g]": 0.9,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 72.7,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 70.1,
    "fibra  [g]": 4.5,
    "prote\u00ednas [g]": 11.3,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 7.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.13,
    "riboflavina [mg]": 0.32,
    "niacina [mg]": 5.0,
    "equivalentes de niacina [mg]": 7.4,
    "triptofano/60 [mg]": 2.4,
    "vitamina b6 [mg]": 0.11,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 26.0,
    "cinza [g]": 0.9,
    "s\u00f3dio [mg]": 430.0,
    "pot\u00e1ssio [mg]": 150.0,
    "c\u00e1lcio [mg]": 78.0,
    "f\u00f3sforo [mg]": 180.0,
    "magn\u00e9sio [mg]": 39.0,
    "ferro [mg]": 2.1,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Toucinho  de porco entremeado ligeiramente salgado, cozido sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 372.0,
    "energia[kj]": 1540.0,
    "l\u00edpidos[g]": 33.9,
    "\u00e1cidos gordos saturados[g]": 11.4,
    "\u00e1cidos gordos monoinsaturados [g]": 13.1,
    "\u00e1cidos gordos polinsaturados [g]": 5.2,
    "\u00e1cido linoleico [g]": 4.5,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.7,
    "sal  [g]": 2.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 46.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 71.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.11,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 3.7,
    "triptofano/60 [mg]": 1.8,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.4,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.9,
    "cinza [g]": 3.2,
    "s\u00f3dio [mg]": 860.0,
    "pot\u00e1ssio [mg]": 180.0,
    "c\u00e1lcio [mg]": 17.0,
    "f\u00f3sforo [mg]": 78.0,
    "magn\u00e9sio [mg]": 14.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 1.9,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Toucinho de porco entremeado fresco, cru",
    "energia[kcal]": 682.0,
    "energia[kj]": 2810.0,
    "l\u00edpidos[g]": 72.0,
    "\u00e1cidos gordos saturados[g]": 24.1,
    "\u00e1cidos gordos monoinsaturados [g]": 27.9,
    "\u00e1cidos gordos polinsaturados [g]": 11.0,
    "\u00e1cido linoleico [g]": 9.4,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 8.4,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 19.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 68.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.17,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 1.4,
    "triptofano/60 [mg]": 1.2,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 68.0,
    "pot\u00e1ssio [mg]": 40.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 70.0,
    "magn\u00e9sio [mg]": 55.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Toucinho de porco entremeado fresco, grelhado sem adi\u00e7\u00e3o de sal",
    "energia[kcal]": 676.0,
    "energia[kj]": 2790.0,
    "l\u00edpidos[g]": 67.9,
    "\u00e1cidos gordos saturados[g]": 22.7,
    "\u00e1cidos gordos monoinsaturados [g]": 26.3,
    "\u00e1cidos gordos polinsaturados [g]": 10.4,
    "\u00e1cido linoleico [g]": 8.9,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.1,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 14.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 100.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 1.1,
    "riboflavina [mg]": 0.29,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 5.3,
    "triptofano/60 [mg]": 3.0,
    "vitamina b6 [mg]": 0.13,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.3,
    "cinza [g]": 2.0,
    "s\u00f3dio [mg]": 140.0,
    "pot\u00e1ssio [mg]": 97.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 16.0,
    "magn\u00e9sio [mg]": 99.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Toucinho de porco entremeado ligeiramente salgado, cru",
    "energia[kcal]": 506.0,
    "energia[kj]": 2090.0,
    "l\u00edpidos[g]": 50.1,
    "\u00e1cidos gordos saturados[g]": 16.8,
    "\u00e1cidos gordos monoinsaturados [g]": 19.4,
    "\u00e1cidos gordos polinsaturados [g]": 7.6,
    "\u00e1cido linoleico [g]": 6.6,
    "\u00e1cidos gordos trans [g]": 0.3,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 13.7,
    "sal  [g]": 3.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 32.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.27,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 2.8,
    "equivalentes de niacina [mg]": 5.4,
    "triptofano/60 [mg]": 2.6,
    "vitamina b6 [mg]": 0.15,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 3.0,
    "cinza [g]": 4.0,
    "s\u00f3dio [mg]": 1420.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 96.0,
    "magn\u00e9sio [mg]": 17.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 1.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Toucinho de porco entremeado magro",
    "energia[kcal]": 333.0,
    "energia[kj]": 1380.0,
    "l\u00edpidos[g]": 29.1,
    "\u00e1cidos gordos saturados[g]": 10.0,
    "\u00e1cidos gordos monoinsaturados [g]": 9.7,
    "\u00e1cidos gordos polinsaturados [g]": 4.7,
    "\u00e1cido linoleico [g]": 4.0,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 0.5,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.4,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 55.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.08,
    "tiamina [mg]": 2.5,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 6.0,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 1.05,
    "s\u00f3dio [mg]": 190.0,
    "pot\u00e1ssio [mg]": 230.0,
    "c\u00e1lcio [mg]": 9.0,
    "f\u00f3sforo [mg]": 140.0,
    "magn\u00e9sio [mg]": 18.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 1.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tremo\u00e7o cozido, sem sal",
    "energia[kcal]": 124.0,
    "energia[kj]": 521.0,
    "l\u00edpidos[g]": 2.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.2,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 6.7,
    "fibra  [g]": 4.8,
    "prote\u00ednas [g]": 16.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 2.7,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.06,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 84.5,
    "cinza [g]": 0.55,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 48.0,
    "f\u00f3sforo [mg]": 110.0,
    "magn\u00e9sio [mg]": 54.0,
    "ferro [mg]": 3.4,
    "zinco [mg]": 1.4,
    "sel\u00e9nio [\u00b5g]": 2.6,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Tremo\u00e7o, cozido e salgado",
    "energia[kcal]": 126.0,
    "energia[kj]": 528.0,
    "l\u00edpidos[g]": 2.4,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 7.2,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 6.7,
    "fibra  [g]": 4.8,
    "prote\u00ednas [g]": 16.4,
    "sal  [g]": 2.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 66.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.1,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 2.7,
    "triptofano/60 [mg]": 2.2,
    "vitamina b6 [mg]": 0.12,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 110.0,
    "cinza [g]": 3.1,
    "s\u00f3dio [mg]": 910.0,
    "pot\u00e1ssio [mg]": 250.0,
    "c\u00e1lcio [mg]": 45.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 54.0,
    "ferro [mg]": 5.5,
    "zinco [mg]": 1.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.3
  },
  {
    "name": "Trigo Sarraceno (gr\u00e3o) cru",
    "energia[kcal]": 337.0,
    "energia[kj]": 1420.0,
    "l\u00edpidos[g]": 2.5,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 61.3,
    "a\u00e7\u00facares [g]": 1.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 59.7,
    "fibra  [g]": 9.7,
    "prote\u00ednas [g]": 12.4,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 13.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.29,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 2.3,
    "equivalentes de niacina [mg]": 4.0,
    "triptofano/60 [mg]": 1.7,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 138.2,
    "cinza [g]": 1.85,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 450.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 380.0,
    "magn\u00e9sio [mg]": 210.0,
    "ferro [mg]": 2.5,
    "zinco [mg]": 2.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tripa de porco fresca",
    "energia[kcal]": 180.0,
    "energia[kj]": 744.0,
    "l\u00edpidos[g]": 16.6,
    "\u00e1cidos gordos saturados[g]": 7.6,
    "\u00e1cidos gordos monoinsaturados [g]": 5.4,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 7.6,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 76.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 154.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.09,
    "niacina [mg]": 1.62,
    "equivalentes de niacina [mg]": 1.62,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.82,
    "vitamina c [mg]": 1.1,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 24.0,
    "pot\u00e1ssio [mg]": 18.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 48.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Tripa de vaca crua",
    "energia[kcal]": 160.0,
    "energia[kj]": 667.0,
    "l\u00edpidos[g]": 10.5,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 3.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.8,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 16.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 73.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 155.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.18,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 2.5,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.82,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.45,
    "s\u00f3dio [mg]": 24.0,
    "pot\u00e1ssio [mg]": 18.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 100.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Truta arco-\u00edris crua",
    "energia[kcal]": 92.0,
    "energia[kj]": 388.0,
    "l\u00edpidos[g]": 2.3,
    "\u00e1cidos gordos saturados[g]": 0.5,
    "\u00e1cidos gordos monoinsaturados [g]": 0.5,
    "\u00e1cidos gordos polinsaturados [g]": 0.9,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 17.8,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 38.0,
    "vitamina a  [\u00b5g]": 9.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 19.0,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 7.3,
    "triptofano/60 [mg]": 3.3,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 1.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.2,
    "cinza [g]": 1.3,
    "s\u00f3dio [mg]": 41.0,
    "pot\u00e1ssio [mg]": 400.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 24.0,
    "ferro [mg]": 0.2,
    "zinco [mg]": 0.6,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Truta arco-\u00edris grelhada",
    "energia[kcal]": 119.0,
    "energia[kj]": 499.0,
    "l\u00edpidos[g]": 3.7,
    "\u00e1cidos gordos saturados[g]": 0.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.3,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 72.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 45.0,
    "vitamina a  [\u00b5g]": 10.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 22.0,
    "alfa-tocoferol [mg]": 0.2,
    "tiamina [mg]": 0.09,
    "riboflavina [mg]": 0.05,
    "niacina [mg]": 4.5,
    "equivalentes de niacina [mg]": 8.5,
    "triptofano/60 [mg]": 4.0,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 2.2,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 8.2,
    "cinza [g]": 2.4,
    "s\u00f3dio [mg]": 350.0,
    "pot\u00e1ssio [mg]": 420.0,
    "c\u00e1lcio [mg]": 25.0,
    "f\u00f3sforo [mg]": 310.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.8,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "T\u00fabera (trufa) crua",
    "energia[kcal]": 77.0,
    "energia[kj]": 326.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.5,
    "a\u00e7\u00facares [g]": 8.7,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 5.8,
    "fibra  [g]": 1.5,
    "prote\u00ednas [g]": 3.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.12,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 2.0,
    "equivalentes de niacina [mg]": 2.6,
    "triptofano/60 [mg]": 0.6,
    "vitamina b6 [mg]": 0.18,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 44.0,
    "cinza [g]": 0.85,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 4.0,
    "f\u00f3sforo [mg]": 80.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 0.4,
    "zinco [mg]": 0.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Uva (m\u00e9dia, branca e tinta)",
    "energia[kcal]": 80.0,
    "energia[kj]": 341.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.0,
    "a\u00e7\u00facares [g]": 18.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 8.0,
    "caroteno [\u00b5g]": 30.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.25,
    "equivalentes de niacina [mg]": 0.25,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.395,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 13.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Uva branca (5 variedades)",
    "energia[kcal]": 78.0,
    "energia[kj]": 329.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 17.3,
    "a\u00e7\u00facares [g]": 17.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.8,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 80.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.3,
    "equivalentes de niacina [mg]": 0.3,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.38,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 14.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 1.2,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Uva seca (passas)",
    "energia[kcal]": 294.0,
    "energia[kj]": 1240.0,
    "l\u00edpidos[g]": 0.7,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 67.0,
    "a\u00e7\u00facares [g]": 67.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 6.1,
    "prote\u00ednas [g]": 1.8,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 20.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 12.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.12,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 0.5,
    "equivalentes de niacina [mg]": 0.6,
    "triptofano/60 [mg]": 0.1,
    "vitamina b6 [mg]": 0.25,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 10.0,
    "cinza [g]": 1.36,
    "s\u00f3dio [mg]": 53.0,
    "pot\u00e1ssio [mg]": 880.0,
    "c\u00e1lcio [mg]": 49.0,
    "f\u00f3sforo [mg]": 36.0,
    "magn\u00e9sio [mg]": 43.0,
    "ferro [mg]": 2.4,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 5.5
  },
  {
    "name": "Uva tinta (5 variedades)",
    "energia[kcal]": 83.0,
    "energia[kj]": 352.0,
    "l\u00edpidos[g]": 0.5,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.1,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 18.6,
    "a\u00e7\u00facares [g]": 18.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.9,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 78.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.4,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 15.0,
    "caroteno [\u00b5g]": 60.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.4,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.2,
    "equivalentes de niacina [mg]": 0.2,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 1.0,
    "folatos [\u00b5g]": 2.0,
    "cinza [g]": 0.41,
    "s\u00f3dio [mg]": 2.0,
    "pot\u00e1ssio [mg]": 220.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 11.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.7
  },
  {
    "name": "Vaca assada",
    "energia[kcal]": 280.0,
    "energia[kj]": 1170.0,
    "l\u00edpidos[g]": 20.4,
    "\u00e1cidos gordos saturados[g]": 2.7,
    "\u00e1cidos gordos monoinsaturados [g]": 2.7,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.53,
    "\u00e1cidos gordos trans [g]": 2.7,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.2,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 1.51,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 9.48,
    "triptofano/60 [mg]": 5.11,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 23.0,
    "cinza [g]": 1.83,
    "s\u00f3dio [mg]": 460.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 4.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca assada com azeite e margarina",
    "energia[kcal]": 280.0,
    "energia[kj]": 1170.0,
    "l\u00edpidos[g]": 20.4,
    "\u00e1cidos gordos saturados[g]": 7.2,
    "\u00e1cidos gordos monoinsaturados [g]": 9.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.4,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.2,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.6,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 9.48,
    "triptofano/60 [mg]": 5.11,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 23.0,
    "cinza [g]": 1.83,
    "s\u00f3dio [mg]": 460.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 4.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca assada com margarina",
    "energia[kcal]": 273.0,
    "energia[kj]": 1140.0,
    "l\u00edpidos[g]": 19.6,
    "\u00e1cidos gordos saturados[g]": 8.2,
    "\u00e1cidos gordos monoinsaturados [g]": 7.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.2,
    "\u00e1cido linoleico [g]": 1.94,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.2,
    "sal  [g]": 1.3,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 47.0,
    "caroteno [\u00b5g]": 31.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.05,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 9.48,
    "triptofano/60 [mg]": 5.11,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 23.0,
    "cinza [g]": 1.96,
    "s\u00f3dio [mg]": 500.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 4.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca assada com \u00f3leo alimentar e margarina",
    "energia[kcal]": 280.0,
    "energia[kj]": 1170.0,
    "l\u00edpidos[g]": 20.4,
    "\u00e1cidos gordos saturados[g]": 7.1,
    "\u00e1cidos gordos monoinsaturados [g]": 7.4,
    "\u00e1cidos gordos polinsaturados [g]": 3.9,
    "\u00e1cido linoleico [g]": 3.65,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 24.2,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 52.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 76.0,
    "vitamina a  [\u00b5g]": 23.0,
    "caroteno [\u00b5g]": 16.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 2.43,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.16,
    "niacina [mg]": 3.3,
    "equivalentes de niacina [mg]": 9.48,
    "triptofano/60 [mg]": 5.11,
    "vitamina b6 [mg]": 0.29,
    "vitamina b12 [\u00b5g]": 1.8,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 23.0,
    "cinza [g]": 1.83,
    "s\u00f3dio [mg]": 460.0,
    "pot\u00e1ssio [mg]": 280.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 26.0,
    "ferro [mg]": 2.2,
    "zinco [mg]": 4.4,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca assada, sem molho",
    "energia[kcal]": 192.0,
    "energia[kj]": 805.0,
    "l\u00edpidos[g]": 9.4,
    "\u00e1cidos gordos saturados[g]": 4.0,
    "\u00e1cidos gordos monoinsaturados [g]": 4.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.6,
    "\u00e1cido linoleico [g]": 0.2,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 26.9,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 60.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 89.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.3,
    "alfa-tocoferol [mg]": 0.03,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.19,
    "niacina [mg]": 4.1,
    "equivalentes de niacina [mg]": 9.8,
    "triptofano/60 [mg]": 5.7,
    "vitamina b6 [mg]": 0.28,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 27.0,
    "cinza [g]": 1.8,
    "s\u00f3dio [mg]": 270.0,
    "pot\u00e1ssio [mg]": 350.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 270.0,
    "magn\u00e9sio [mg]": 30.0,
    "ferro [mg]": 2.5,
    "zinco [mg]": 5.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Vaca magra cozida",
    "energia[kcal]": 259.0,
    "energia[kj]": 1080.0,
    "l\u00edpidos[g]": 13.8,
    "\u00e1cidos gordos saturados[g]": 5.4,
    "\u00e1cidos gordos monoinsaturados [g]": 6.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.6,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 33.7,
    "sal  [g]": 0.5,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 51.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 104.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 1.1,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.21,
    "niacina [mg]": 3.8,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 7.2,
    "vitamina b6 [mg]": 0.31,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 7.0,
    "cinza [g]": 1.5,
    "s\u00f3dio [mg]": 200.0,
    "pot\u00e1ssio [mg]": 430.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 260.0,
    "magn\u00e9sio [mg]": 34.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 8.7,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca magra estufada",
    "energia[kcal]": 226.0,
    "energia[kj]": 943.0,
    "l\u00edpidos[g]": 14.2,
    "\u00e1cidos gordos saturados[g]": 1.8,
    "\u00e1cidos gordos monoinsaturados [g]": 1.8,
    "\u00e1cidos gordos polinsaturados [g]": 1.8,
    "\u00e1cido linoleico [g]": 1.75,
    "\u00e1cidos gordos trans [g]": 1.8,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 21.8,
    "sal  [g]": 1.2,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 59.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 94.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 1.46,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 8.97,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 7.8,
    "cinza [g]": 2.17,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 5.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca magra estufada \u00e0 jardineira",
    "energia[kcal]": 111.0,
    "energia[kj]": 465.0,
    "l\u00edpidos[g]": 4.4,
    "\u00e1cidos gordos saturados[g]": 1.1,
    "\u00e1cidos gordos monoinsaturados [g]": 2.7,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.296,
    "\u00e1cidos gordos trans [g]": 0.1,
    "hidratos de carbono [g]": 11.4,
    "a\u00e7\u00facares [g]": 1.8,
    "oligossac\u00e1ridos [g]": 0.2,
    "amido [g]": 9.4,
    "fibra  [g]": 1.8,
    "prote\u00ednas [g]": 5.5,
    "sal  [g]": 0.9,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 75.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 11.0,
    "vitamina a  [\u00b5g]": 192.0,
    "caroteno [\u00b5g]": 1150.0,
    "vitamina d [\u00b5g]": 0.1,
    "alfa-tocoferol [mg]": 0.56,
    "tiamina [mg]": 0.19,
    "riboflavina [mg]": 0.04,
    "niacina [mg]": 1.4,
    "equivalentes de niacina [mg]": 2.78,
    "triptofano/60 [mg]": 1.07,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 0.19,
    "vitamina c [mg]": 9.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 1.73,
    "s\u00f3dio [mg]": 370.0,
    "pot\u00e1ssio [mg]": 370.0,
    "c\u00e1lcio [mg]": 20.0,
    "f\u00f3sforo [mg]": 73.0,
    "magn\u00e9sio [mg]": 16.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 1.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca magra estufada com azeite e margarina",
    "energia[kcal]": 226.0,
    "energia[kj]": 940.0,
    "l\u00edpidos[g]": 14.2,
    "\u00e1cidos gordos saturados[g]": 4.9,
    "\u00e1cidos gordos monoinsaturados [g]": 7.0,
    "\u00e1cidos gordos polinsaturados [g]": 1.0,
    "\u00e1cido linoleico [g]": 0.894,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 21.8,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 59.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 94.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.76,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 8.97,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 7.8,
    "cinza [g]": 2.17,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 5.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca magra estufada com margarina",
    "energia[kcal]": 220.0,
    "energia[kj]": 918.0,
    "l\u00edpidos[g]": 13.6,
    "\u00e1cidos gordos saturados[g]": 5.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.2,
    "\u00e1cidos gordos polinsaturados [g]": 1.4,
    "\u00e1cido linoleico [g]": 1.31,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 21.8,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 59.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 43.0,
    "caroteno [\u00b5g]": 106.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.34,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 8.97,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 7.8,
    "cinza [g]": 2.27,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 5.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca magra estufada com \u00f3leo alimentar e margarina",
    "energia[kcal]": 226.0,
    "energia[kj]": 940.0,
    "l\u00edpidos[g]": 14.2,
    "\u00e1cidos gordos saturados[g]": 4.8,
    "\u00e1cidos gordos monoinsaturados [g]": 5.3,
    "\u00e1cidos gordos polinsaturados [g]": 2.7,
    "\u00e1cido linoleico [g]": 2.62,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 21.8,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 59.3,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 94.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 2.16,
    "tiamina [mg]": 0.06,
    "riboflavina [mg]": 0.14,
    "niacina [mg]": 2.5,
    "equivalentes de niacina [mg]": 8.97,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.24,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 7.8,
    "cinza [g]": 2.17,
    "s\u00f3dio [mg]": 420.0,
    "pot\u00e1ssio [mg]": 260.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.6,
    "zinco [mg]": 5.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca magra estufada, sem molho",
    "energia[kcal]": 234.0,
    "energia[kj]": 980.0,
    "l\u00edpidos[g]": 12.0,
    "\u00e1cidos gordos saturados[g]": 4.7,
    "\u00e1cidos gordos monoinsaturados [g]": 5.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.4,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 31.5,
    "sal  [g]": 0.7,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 54.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.8,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.05,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 6.7,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 1.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.1,
    "cinza [g]": 1.56,
    "s\u00f3dio [mg]": 260.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 16.0,
    "f\u00f3sforo [mg]": 310.0,
    "magn\u00e9sio [mg]": 23.0,
    "ferro [mg]": 2.0,
    "zinco [mg]": 8.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca meio gorda estufada",
    "energia[kcal]": 278.0,
    "energia[kj]": 1160.0,
    "l\u00edpidos[g]": 20.2,
    "\u00e1cidos gordos saturados[g]": 2.0,
    "\u00e1cidos gordos monoinsaturados [g]": 2.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.0,
    "\u00e1cido linoleico [g]": 1.95,
    "\u00e1cidos gordos trans [g]": 2.0,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 21.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 53.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 94.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 1.48,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 8.67,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.25,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca meio gorda estufada com azeite e margarina",
    "energia[kcal]": 278.0,
    "energia[kj]": 1160.0,
    "l\u00edpidos[g]": 20.2,
    "\u00e1cidos gordos saturados[g]": 7.2,
    "\u00e1cidos gordos monoinsaturados [g]": 9.7,
    "\u00e1cidos gordos polinsaturados [g]": 1.2,
    "\u00e1cido linoleico [g]": 1.09,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 21.4,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 53.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 94.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.78,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 8.67,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.25,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca meio gorda estufada com margarina",
    "energia[kcal]": 273.0,
    "energia[kj]": 1130.0,
    "l\u00edpidos[g]": 19.6,
    "\u00e1cidos gordos saturados[g]": 8.0,
    "\u00e1cidos gordos monoinsaturados [g]": 7.9,
    "\u00e1cidos gordos polinsaturados [g]": 1.6,
    "\u00e1cido linoleico [g]": 1.51,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 21.4,
    "sal  [g]": 1.1,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 54.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 43.0,
    "caroteno [\u00b5g]": 106.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.36,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 8.67,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.35,
    "s\u00f3dio [mg]": 450.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 3.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca meio gorda estufada com \u00f3leo alimentar e margarina",
    "energia[kcal]": 278.0,
    "energia[kj]": 1160.0,
    "l\u00edpidos[g]": 20.2,
    "\u00e1cidos gordos saturados[g]": 7.1,
    "\u00e1cidos gordos monoinsaturados [g]": 8.0,
    "\u00e1cidos gordos polinsaturados [g]": 2.9,
    "\u00e1cido linoleico [g]": 2.81,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.0,
    "oligossac\u00e1ridos [g]": 0.1,
    "amido [g]": 0.1,
    "fibra  [g]": 0.4,
    "prote\u00ednas [g]": 21.4,
    "sal  [g]": 1.0,
    "\u00e1lcool [g]": 0.7,
    "\u00e1gua [g]": 53.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 25.0,
    "caroteno [\u00b5g]": 94.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 2.18,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 2.4,
    "equivalentes de niacina [mg]": 8.67,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.27,
    "vitamina b12 [\u00b5g]": 1.2,
    "vitamina c [mg]": 3.6,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.25,
    "s\u00f3dio [mg]": 410.0,
    "pot\u00e1ssio [mg]": 240.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 3.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca meio gorda estufada, sem molho",
    "energia[kcal]": 280.0,
    "energia[kj]": 1170.0,
    "l\u00edpidos[g]": 17.0,
    "\u00e1cidos gordos saturados[g]": 6.5,
    "\u00e1cidos gordos monoinsaturados [g]": 7.6,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.8,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 31.8,
    "sal  [g]": 0.6,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 48.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 99.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.6,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.24,
    "niacina [mg]": 3.9,
    "equivalentes de niacina [mg]": 11.0,
    "triptofano/60 [mg]": 6.8,
    "vitamina b6 [mg]": 0.35,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 16.0,
    "cinza [g]": 1.9,
    "s\u00f3dio [mg]": 250.0,
    "pot\u00e1ssio [mg]": 290.0,
    "c\u00e1lcio [mg]": 18.0,
    "f\u00f3sforo [mg]": 330.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.1,
    "zinco [mg]": 5.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca para assar crua",
    "energia[kcal]": 174.0,
    "energia[kj]": 726.0,
    "l\u00edpidos[g]": 10.7,
    "\u00e1cidos gordos saturados[g]": 4.1,
    "\u00e1cidos gordos monoinsaturados [g]": 4.8,
    "\u00e1cidos gordos polinsaturados [g]": 0.4,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.5,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 19.4,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 68.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.4,
    "alfa-tocoferol [mg]": 0.04,
    "tiamina [mg]": 0.08,
    "riboflavina [mg]": 0.15,
    "niacina [mg]": 3.5,
    "equivalentes de niacina [mg]": 7.6,
    "triptofano/60 [mg]": 4.1,
    "vitamina b6 [mg]": 0.43,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 22.0,
    "cinza [g]": 0.7,
    "s\u00f3dio [mg]": 67.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 220.0,
    "magn\u00e9sio [mg]": 22.0,
    "ferro [mg]": 1.8,
    "zinco [mg]": 3.5,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca para cozer ou estufar magra crua",
    "energia[kcal]": 175.0,
    "energia[kj]": 732.0,
    "l\u00edpidos[g]": 9.8,
    "\u00e1cidos gordos saturados[g]": 3.8,
    "\u00e1cidos gordos monoinsaturados [g]": 4.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.3,
    "\u00e1cidos gordos trans [g]": 0.4,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.7,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 67.4,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 69.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.7,
    "alfa-tocoferol [mg]": 0.09,
    "tiamina [mg]": 0.07,
    "riboflavina [mg]": 0.18,
    "niacina [mg]": 4.2,
    "equivalentes de niacina [mg]": 8.8,
    "triptofano/60 [mg]": 4.6,
    "vitamina b6 [mg]": 0.42,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 6.0,
    "cinza [g]": 1.02,
    "s\u00f3dio [mg]": 66.0,
    "pot\u00e1ssio [mg]": 340.0,
    "c\u00e1lcio [mg]": 12.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 20.0,
    "ferro [mg]": 1.3,
    "zinco [mg]": 5.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vaca para cozer ou estufar meio gorda crua",
    "energia[kcal]": 227.0,
    "energia[kj]": 947.0,
    "l\u00edpidos[g]": 15.8,
    "\u00e1cidos gordos saturados[g]": 6.1,
    "\u00e1cidos gordos monoinsaturados [g]": 7.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.5,
    "\u00e1cido linoleico [g]": 0.5,
    "\u00e1cidos gordos trans [g]": 0.7,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 21.3,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 61.5,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 61.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.11,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.17,
    "niacina [mg]": 4.0,
    "equivalentes de niacina [mg]": 8.5,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.48,
    "vitamina b12 [\u00b5g]": 2.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 15.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 53.0,
    "pot\u00e1ssio [mg]": 300.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 250.0,
    "magn\u00e9sio [mg]": 19.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Veado cru",
    "energia[kcal]": 112.0,
    "energia[kj]": 474.0,
    "l\u00edpidos[g]": 3.3,
    "\u00e1cidos gordos saturados[g]": 1.5,
    "\u00e1cidos gordos monoinsaturados [g]": 1.4,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.2,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 20.6,
    "sal  [g]": 0.2,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 74.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 50.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.5,
    "alfa-tocoferol [mg]": 0.13,
    "tiamina [mg]": 0.25,
    "riboflavina [mg]": 0.25,
    "niacina [mg]": 3.4,
    "equivalentes de niacina [mg]": 7.9,
    "triptofano/60 [mg]": 4.5,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 1.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 5.0,
    "cinza [g]": 1.1,
    "s\u00f3dio [mg]": 62.0,
    "pot\u00e1ssio [mg]": 310.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 200.0,
    "magn\u00e9sio [mg]": 21.0,
    "ferro [mg]": 2.3,
    "zinco [mg]": 3.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.6
  },
  {
    "name": "Vieira, crua",
    "energia[kcal]": 83.0,
    "energia[kj]": 351.0,
    "l\u00edpidos[g]": 1.0,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.1,
    "\u00e1cidos gordos polinsaturados [g]": 0.3,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 18.5,
    "sal  [g]": 0.4,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 79.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 4.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 4.2,
    "alfa-tocoferol [mg]": 0.86,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.1,
    "niacina [mg]": 1.9,
    "equivalentes de niacina [mg]": 4.4,
    "triptofano/60 [mg]": 2.5,
    "vitamina b6 [mg]": 0.09,
    "vitamina b12 [\u00b5g]": 2.9,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 14.0,
    "cinza [g]": 2.9,
    "s\u00f3dio [mg]": 160.0,
    "pot\u00e1ssio [mg]": 320.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 230.0,
    "magn\u00e9sio [mg]": 29.0,
    "ferro [mg]": 1.5,
    "zinco [mg]": 1.6,
    "sel\u00e9nio [\u00b5g]": 36.0,
    "iodo [\u00b5g]": 35.0
  },
  {
    "name": "Vinagre",
    "energia[kcal]": 22.0,
    "energia[kj]": 93.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.6,
    "a\u00e7\u00facares [g]": 0.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.3,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 93.0,
    "\u00e1cidos org\u00e2nicos [g]": 6.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 16.0,
    "pot\u00e1ssio [mg]": 57.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 5.0,
    "ferro [mg]": 0.3,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho espumante doce",
    "energia[kcal]": 110.0,
    "energia[kj]": 461.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 12.0,
    "a\u00e7\u00facares [g]": 12.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 8.8,
    "\u00e1gua [g]": 78.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.2,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 51.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho espumante extra bruto",
    "energia[kcal]": 69.0,
    "energia[kj]": 288.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 9.8,
    "\u00e1gua [g]": 88.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.07,
    "equivalentes de niacina [mg]": 0.07,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.15,
    "s\u00f3dio [mg]": 4.0,
    "pot\u00e1ssio [mg]": 51.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho espumante meio Seco",
    "energia[kcal]": 80.0,
    "energia[kj]": 331.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.2,
    "a\u00e7\u00facares [g]": 4.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 8.9,
    "\u00e1gua [g]": 82.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.11,
    "s\u00f3dio [mg]": 6.0,
    "pot\u00e1ssio [mg]": 51.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho espumante seco",
    "energia[kcal]": 74.0,
    "energia[kj]": 307.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.6,
    "a\u00e7\u00facares [g]": 2.6,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 9.0,
    "\u00e1gua [g]": 84.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.15,
    "s\u00f3dio [mg]": 5.0,
    "pot\u00e1ssio [mg]": 51.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 10.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho generoso da Madeira",
    "energia[kcal]": 156.0,
    "energia[kj]": 649.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 9.0,
    "a\u00e7\u00facares [g]": 9.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 17.0,
    "\u00e1gua [g]": 72.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.42,
    "s\u00f3dio [mg]": 10.0,
    "pot\u00e1ssio [mg]": 140.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 35.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 1.7,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho generoso do Porto, doce",
    "energia[kcal]": 165.0,
    "energia[kj]": 689.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 14.0,
    "a\u00e7\u00facares [g]": 14.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 15.5,
    "\u00e1gua [g]": 68.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.25,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho generoso do Porto, meio seco",
    "energia[kcal]": 138.0,
    "energia[kj]": 576.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.5,
    "a\u00e7\u00facares [g]": 6.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 16.0,
    "\u00e1gua [g]": 75.6,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.25,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho generoso do Porto, seco",
    "energia[kcal]": 135.0,
    "energia[kj]": 563.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 4.0,
    "a\u00e7\u00facares [g]": 4.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 17.0,
    "\u00e1gua [g]": 77.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.25,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 120.0,
    "c\u00e1lcio [mg]": 5.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 11.0,
    "ferro [mg]": 0.7,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho maduro branco",
    "energia[kcal]": 72.0,
    "energia[kj]": 301.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 1.2,
    "a\u00e7\u00facares [g]": 1.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 9.6,
    "\u00e1gua [g]": 88.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.06,
    "equivalentes de niacina [mg]": 0.06,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.24,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 92.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 9.0,
    "magn\u00e9sio [mg]": 8.0,
    "ferro [mg]": 0.8,
    "zinco [mg]": 0.3,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho maduro palhete",
    "energia[kcal]": 65.0,
    "energia[kj]": 270.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 9.2,
    "\u00e1gua [g]": 89.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.03,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.09,
    "equivalentes de niacina [mg]": 0.09,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.28,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 9.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho maduro tinto",
    "energia[kcal]": 66.0,
    "energia[kj]": 272.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.2,
    "a\u00e7\u00facares [g]": 0.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.1,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 9.2,
    "\u00e1gua [g]": 89.7,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.02,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.28,
    "s\u00f3dio [mg]": 9.0,
    "pot\u00e1ssio [mg]": 130.0,
    "c\u00e1lcio [mg]": 15.0,
    "f\u00f3sforo [mg]": 9.0,
    "magn\u00e9sio [mg]": 9.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho ros\u00e9",
    "energia[kcal]": 72.0,
    "energia[kj]": 299.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 2.4,
    "a\u00e7\u00facares [g]": 2.4,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.2,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 8.8,
    "\u00e1gua [g]": 87.8,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.07,
    "equivalentes de niacina [mg]": 0.07,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.02,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.28,
    "s\u00f3dio [mg]": 7.0,
    "pot\u00e1ssio [mg]": 91.0,
    "c\u00e1lcio [mg]": 14.0,
    "f\u00f3sforo [mg]": 15.0,
    "magn\u00e9sio [mg]": 10.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho verde branco",
    "energia[kcal]": 59.0,
    "energia[kj]": 245.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.1,
    "a\u00e7\u00facares [g]": 0.1,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 8.4,
    "\u00e1gua [g]": 89.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.01,
    "riboflavina [mg]": 0.01,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.01,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.15,
    "s\u00f3dio [mg]": 11.0,
    "pot\u00e1ssio [mg]": 84.0,
    "c\u00e1lcio [mg]": 10.0,
    "f\u00f3sforo [mg]": 6.0,
    "magn\u00e9sio [mg]": 6.0,
    "ferro [mg]": 0.6,
    "zinco [mg]": 0.2,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Vinho verde tinto",
    "energia[kcal]": 57.0,
    "energia[kj]": 237.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.3,
    "a\u00e7\u00facares [g]": 0.3,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 8.0,
    "\u00e1gua [g]": 89.9,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.02,
    "riboflavina [mg]": 0.03,
    "niacina [mg]": 0.1,
    "equivalentes de niacina [mg]": 0.1,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.03,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.22,
    "s\u00f3dio [mg]": 15.0,
    "pot\u00e1ssio [mg]": 110.0,
    "c\u00e1lcio [mg]": 13.0,
    "f\u00f3sforo [mg]": 8.0,
    "magn\u00e9sio [mg]": 7.0,
    "ferro [mg]": 1.0,
    "zinco [mg]": 0.1,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Wasabi, raiz crua",
    "energia[kcal]": 100.0,
    "energia[kj]": 422.0,
    "l\u00edpidos[g]": 0.6,
    "\u00e1cidos gordos saturados[g]": 0.2,
    "\u00e1cidos gordos monoinsaturados [g]": 0.2,
    "\u00e1cidos gordos polinsaturados [g]": 0.2,
    "\u00e1cido linoleico [g]": 0.1,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 15.8,
    "a\u00e7\u00facares [g]": 15.8,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 6.1,
    "prote\u00ednas [g]": 4.8,
    "sal  [g]": 0.1,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 69.1,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 2.0,
    "caroteno [\u00b5g]": 14.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 1.4,
    "tiamina [mg]": 0.1,
    "riboflavina [mg]": 0.13,
    "niacina [mg]": 0.67,
    "equivalentes de niacina [mg]": 0.87,
    "triptofano/60 [mg]": 0.2,
    "vitamina b6 [mg]": 0.3,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 58.5,
    "folatos [\u00b5g]": 34.0,
    "cinza [g]": 1.71,
    "s\u00f3dio [mg]": 21.0,
    "pot\u00e1ssio [mg]": 530.0,
    "c\u00e1lcio [mg]": 110.0,
    "f\u00f3sforo [mg]": 80.0,
    "magn\u00e9sio [mg]": 58.0,
    "ferro [mg]": 0.9,
    "zinco [mg]": 1.2,
    "sel\u00e9nio [\u00b5g]": 9.0,
    "iodo [\u00b5g]": 1.0
  },
  {
    "name": "Vinagre de Sidra",
    "energia[kcal]": 15.0,
    "energia[kj]": 66.0,
    "l\u00edpidos[g]": 0.0,
    "\u00e1cidos gordos saturados[g]": 0.0,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 0.5,
    "a\u00e7\u00facares [g]": 0.5,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 0.0,
    "prote\u00ednas [g]": 0.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Pimento vermelho",
    "energia[kcal]": 31.0,
    "energia[kj]": 130.0,
    "l\u00edpidos[g]": 0.3,
    "\u00e1cidos gordos saturados[g]": 0.1,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 6.0,
    "a\u00e7\u00facares [g]": 4.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 2.1,
    "prote\u00ednas [g]": 1.0,
    "sal  [g]": 0.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 92.2,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Flor de Anis",
    "energia[kcal]": 384.0,
    "energia[kj]": 1608.0,
    "l\u00edpidos[g]": 16.0,
    "\u00e1cidos gordos saturados[g]": 0.6,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 36.0,
    "a\u00e7\u00facares [g]": 0.0,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 15.0,
    "prote\u00ednas [g]": 18.0,
    "sal  [g]": 0.04,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Manteiga amendoim Prozis",
    "energia[kcal]": 630.0,
    "energia[kj]": 2638.0,
    "l\u00edpidos[g]": 53.0,
    "\u00e1cidos gordos saturados[g]": 6.8,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 11.0,
    "a\u00e7\u00facares [g]": 5.9,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 7.2,
    "prote\u00ednas [g]": 26.0,
    "sal  [g]": 0.12,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  },
  {
    "name": "Canela (Pau)",
    "energia[kcal]": 247.0,
    "energia[kj]": 0.0,
    "l\u00edpidos[g]": 1.2,
    "\u00e1cidos gordos saturados[g]": 0.3,
    "\u00e1cidos gordos monoinsaturados [g]": 0.0,
    "\u00e1cidos gordos polinsaturados [g]": 0.0,
    "\u00e1cido linoleico [g]": 0.0,
    "\u00e1cidos gordos trans [g]": 0.0,
    "hidratos de carbono [g]": 81.0,
    "a\u00e7\u00facares [g]": 2.2,
    "oligossac\u00e1ridos [g]": 0.0,
    "amido [g]": 0.0,
    "fibra  [g]": 53.0,
    "prote\u00ednas [g]": 4.0,
    "sal  [g]": 10.0,
    "\u00e1lcool [g]": 0.0,
    "\u00e1gua [g]": 0.0,
    "\u00e1cidos org\u00e2nicos [g]": 0.0,
    "colesterol [mg]": 0.0,
    "vitamina a  [\u00b5g]": 0.0,
    "caroteno [\u00b5g]": 0.0,
    "vitamina d [\u00b5g]": 0.0,
    "alfa-tocoferol [mg]": 0.0,
    "tiamina [mg]": 0.0,
    "riboflavina [mg]": 0.0,
    "niacina [mg]": 0.0,
    "equivalentes de niacina [mg]": 0.0,
    "triptofano/60 [mg]": 0.0,
    "vitamina b6 [mg]": 0.0,
    "vitamina b12 [\u00b5g]": 0.0,
    "vitamina c [mg]": 0.0,
    "folatos [\u00b5g]": 0.0,
    "cinza [g]": 0.0,
    "s\u00f3dio [mg]": 0.0,
    "pot\u00e1ssio [mg]": 0.0,
    "c\u00e1lcio [mg]": 0.0,
    "f\u00f3sforo [mg]": 0.0,
    "magn\u00e9sio [mg]": 0.0,
    "ferro [mg]": 0.0,
    "zinco [mg]": 0.0,
    "sel\u00e9nio [\u00b5g]": 0.0,
    "iodo [\u00b5g]": 0.0
  }
];
    
