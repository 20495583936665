import React from 'react';
import './App.css';
import Table from './Table';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        O Fazedor de etiquetas de comida
      </header>
      <Table />
    </div>
  );
}

export default App;
